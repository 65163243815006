import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useContributionModal } from '../context/ContributionModalContext';

const ContributionModalFooter: FC = () => {
  const { t } = useTranslate();
  const { data, actions } = useContributionModal();

  const buttonEnabled = data?.date && data.amount > 0;
  const buttonText = data.resumeView ? t('SaveContribution') : t('Next');

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={actions.handleSaveContribution}
          disabled={!buttonEnabled}
        >
          {buttonText}
        </button>
      </Modal.Footer>
    </>
  );
};

export default ContributionModalFooter;
