import { TranslateFn } from 'hooks/useTranslate';

type GetClassesTableHeadersProps = {
  t: TranslateFn;
  investorsView: boolean;
};

const styles = {
  LIGHT_COLUMN: 'text-left w-200px bg-light',
  BLANK_COLUMN: 'text-left w-200px',
};

export const getClassesTableHeaders = ({
  investorsView,
  t,
}: GetClassesTableHeadersProps) => {
  const totalSharesColumnHeader = {
    text: t('TotalShares'),
    style1: styles.LIGHT_COLUMN,
    style2: styles.BLANK_COLUMN,
    columns: 1,
  };

  const aditionalColumnsHeaders = investorsView
    ? [
        {
          text: t('%ND'),
          style1: styles.LIGHT_COLUMN,
          columns: 1,
        },
        {
          text: t('%FD'),
          style1: styles.BLANK_COLUMN,
          columns: 1,
        },
      ]
    : [
        {
          text: t('%ND'),
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
          columns: 2,
        },
        {
          text: t('%Act.D'),
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
          columns: 2,
        },
        {
          text: t('%FD'),
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
          columns: 2,
        },
      ];

  const inversionColumnsHeaders = 3;

  return {
    totalSharesColumnHeader,
    aditionalColumnsHeaders,
    inversionColumnsHeaders,
  };
};
