import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { FC } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { CoOwner, Partner } from 'types';

import useCoOwnerForm from '../hooks/useCoOwnerForm';
import { useGetPartnerOptions } from '../hooks/useGetPartnerOptions';

type Props = {
  coOwners: CoOwner[];
  // eslint-disable-next-line no-unused-vars
  handleAddCoOwner: (coOwner: CoOwner) => void;
};

const ShareEncumbranceViewCoOwnersForm: FC<Props> = ({
  coOwners,
  handleAddCoOwner,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const partners = useSelector(
    (state: any) => state?.society?.actualSociety?.partners
  );

  const { partnerOptions } = useGetPartnerOptions({ partners }).data;
  const coOwnerFormResult = useCoOwnerForm({ coOwners });

  const {
    name,
    cif,
    email,
    percent,
    comment,
    selectedPartner,
    disabledAddButton,
    partnerId,
  } = coOwnerFormResult.data;

  const {
    handleChangeName,
    handleChangeCif,
    handleChangeEmail,
    handleChangePercent,
    handleChangeComment,
    handleChangePartner,
    handleChangePartnerId,
  } = coOwnerFormResult.actions;

  const cleanForm = () => {
    handleChangeName('');
    handleChangeCif('');
    handleChangeEmail('');
    handleChangePercent(0);
    handleChangeComment('');
    handleChangePartner(null);
    handleChangePartnerId('');
  };

  const handleChangeSelectedPartner = (selectedOption: any) => {
    const partner = partners.find(
      (partner: Partner) => partner._id === selectedOption.value
    );
    handleChangePartner(selectedOption);
    handleChangePartnerId(selectedOption.value);
    handleChangeName(partner?.name);
    handleChangeCif(partner?.cif);
    handleChangeEmail(partner?.email);
  };

  const handleSaveCoOwner = () => {
    handleAddCoOwner({
      partner: partnerId,
      name,
      cif,
      email,
      percent,
      comment,
    });
    cleanForm();
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-name">
              {t('Shareholder')}
            </label>
            <Select
              value={selectedPartner}
              closeMenuOnSelect
              className="react-select"
              options={partnerOptions}
              components={animatedComponents}
              placeholder={t('SelectPartner')}
              onChange={handleChangeSelectedPartner}
            />
          </div>
        </div>
      </div>
      <span className="sub-text">
        <i>{t('CoOwnerPartnerInfo')}</i>
      </span>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-name">
              {t('Name')} <span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="co-owner-name"
                className="form-control form-control-md"
                value={name}
                onChange={handleChangeName}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-cif">
              {t('Cif')} <span className="text-danger"> *</span>
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="co-owner-cif"
                className="form-control form-control-md"
                value={cif}
                onChange={handleChangeCif}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-emial">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="co-owner-email"
                className="form-control form-control-md"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
          </div>
        </div>

        <div className="col-2">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-percent">
              {t('Percent')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id="co-owner-percent"
                className="form-control form-control-md"
                value={percent}
                onChange={handleChangePercent}
                min={0}
                step={0.01}
                max={100}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="co-owner-comment">
              {t('Comment')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="co-owner-comment"
                className="form-control form-control-md"
                value={comment}
                onChange={handleChangeComment}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-primary"
            disabled={disabledAddButton}
            onClick={handleSaveCoOwner}
          >
            {t('AddCoOwner')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ShareEncumbranceViewCoOwnersForm;
