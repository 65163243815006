/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import validateOutdatedSocietyValue from 'modules/_shared/api/validationActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import UploadPartner from 'modules/_shared/components/Modals/UploadPartner';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';

import { getPartnerList } from 'modules/partners/utils';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { getPartnerCategories } from 'modules/partners/redux/partnerCategoryActions';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';

import activePartner from 'utils/activePartner';
import { getActualSociety } from 'utils/filters';

import { useTranslate } from 'hooks/useTranslate';
import Page from 'modules/_shared/components/Pages/Page';
import menuOptions from './menuOptions';
import tableColumns from './tableColumns';

function PartnerList() {
  const { t, i18n } = useTranslate();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { societyId, userId } = useParams();

  const { isAdmin, isReadOnly } = useGetPartnersSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state?.plans);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const hasAccessCaptable = useSelector(
    (state) => state.society?.actualSociety?.hasAccessCaptable
  );

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [showNationality, setShowNationality] = useState(false);
  const [activePartnersTotal, setActivePartnersTotal] = useState(0);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n, showNationality)
  );
  const [filteredMenuOptions, setFilteredMenuOptions] = useState([]);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const updateFilteredMenuOptions = () => {
    if (!actualSociety.partners.length) {
      const [addPartnerOption, constitutionOption] = currentMenuOptions;
      setFilteredMenuOptions([addPartnerOption, constitutionOption]);
      return;
    }

    if (!actualSociety.shares.length) {
      const isSocietyConstituted = actualSociety.operations.some(
        (operation) => operation.operationType === operationTypes.CONSTITUTION
      );

      const [addPartnerOption, constitutionOption, capitalIncreaseOption] =
        currentMenuOptions;

      setFilteredMenuOptions([
        addPartnerOption,
        isSocietyConstituted ? capitalIncreaseOption : constitutionOption,
      ]);
      return;
    }

    const options = currentMenuOptions.filter((option, index) => index !== 1);
    setFilteredMenuOptions(options);
  };

  const getPageDescription = () => (
    <p>
      {actualSociety?.name &&
        `${actualSociety?.name || ''} ${t('HavePartners', {
          count: activePartnersTotal,
        })}`}
    </p>
  );

  const getPartnerListData = async () => {
    try {
      setIsLoading(true);
      if (actualSociety?.['_id'] === societyId) {
        const { newData, newCustomData } = await getPartnerList({
          actualSociety,
          plans,
          user,
          userId,
          isAdmin,
          isReadOnly,
          hasAccessCaptable,
        });

        setCustomData(newCustomData);

        if (newData?.length > 0) {
          setTableData(
            transformData({
              data: newData,
              columns: tableColumns(i18n, showNationality),
            })
          );
        } else {
          setTableData([]);
        }

        updateFilteredMenuOptions();
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
      dispatch(getHoldingClasses(societyId));
      dispatch(getPartnerCategories(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    getPartnerListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, plans, userId, i18n.language, currentMenuOptions]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
      if (isAdmin) {
        dispatch(validateOutdatedSocietyValue(actualSociety));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety?.partners) {
      const activePartners = actualSociety.partners.filter((partner) =>
        activePartner(partner)
      ).length;
      setActivePartnersTotal(activePartners);
    }
  }, [actualSociety]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n, showNationality));
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, showNationality]);

  return (
    <Page>
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('Partners')}
        description={getPageDescription()}
        actions={
          isAdmin
            ? [
                <button
                  type="button"
                  className="btn btn-white btn-outline-light"
                  onClick={() =>
                    dispatch(
                      setModal(
                        <UploadPartner
                          user={user}
                          societyId={actualSociety['_id']}
                        />
                      )
                    )
                  }
                  disabled={isReadOnly}
                >
                  <em className="icon ni ni-upload-cloud" />
                  <span>{t('UploadListing')}</span>
                </button>,
                <OperationMenuDots
                  menuOptions={filteredMenuOptions}
                  handleModal={(Modal) =>
                    dispatch(
                      setModal(
                        <Modal user={user} societyId={actualSociety['_id']} />
                      )
                    )
                  }
                  params={{
                    society: actualSociety,
                    user,
                    mixpanel,
                  }}
                  disabled={isReadOnly}
                />,
              ]
            : []
        }
      />

      {tableData?.length > 0 ? (
        <CustomDataTable
          data={tableData}
          columns={currentTableColumns}
          searchBy={[t('Partner'), t('Email'), t('CIF')]}
          searchByPlaceholder={[t('name'), t('email'), t('CIF')]}
          defaultSortFieldId={6}
          defaultSortAsc={false}
          pagination
          className="nk-tb-list"
          expandableRows={false}
          actions
          showDense
          showDownload
          showNationality
          toggleNationality={() => setShowNationality(!showNationality)}
          customData={customData}
          filterByParam="isActive"
          filterText={{
            true: t('SeeOld'),
            false: t('HideOld'),
          }}
          filterIcon={{
            true: 'ni ni-eye',
            false: 'ni ni-eye-off',
          }}
          fileName={`${t('PartnersList')} ${actualSociety.name}`}
        />
      ) : (
        <TableLoader rows={10} />
      )}

      {isLoading && <CustomLoading />}
    </Page>
  );
}

export default PartnerList;
