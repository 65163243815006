import { FC, ReactNode, useState } from 'react';

type Props = {
  text: string | ReactNode | undefined;
  hasCloseButton?: boolean;
};
const ModalAlert: FC<Props> = ({ text, hasCloseButton }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseAlert = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="alert alert-icon alert-warning alert-dismissible mt-2 mb-0">
        <em className="icon ni ni-alert-circle" />
        {text}
        {hasCloseButton && (
          <button
            type="button"
            className="close"
            data-bs-dismiss="alert"
            onClick={handleCloseAlert}
          />
        )}
      </div>
    )
  );
};

export default ModalAlert;
