import { FC } from 'react';
import NumberFormat from 'react-number-format';

import { numberFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';

import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import UploadDocument from 'modules/documents/components/UploadDocument';

import { useContributionModal } from '../context/ContributionModalContext';

const ContributionDataView: FC = () => {
  const { t } = useTranslate();

  const MAX_DATE = '2100-01-01';
  const MAX_COMMENT_LENGTH = 500;

  const { data, actions } = useContributionModal();
  return (
    <>
      <div className="form-group">
        <label className="form-label" htmlFor="contribution-date">
          {t('Date')}
        </label>
        <div className="form-control-wrap">
          <input
            id="contribution-date"
            type="date"
            className="form-control form-control-lg"
            value={data.date}
            onChange={actions.handleChangeDate}
            required
            max={MAX_DATE}
            min={data.lastOperationDate}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="contribution-amount">
          {t('Amount')}
        </label>
        <div className="form-control-wrap">
          <div className="form-text-hint">
            <span className="overline-title">EUR</span>
          </div>
          <NumberFormat
            id="contribution-amount"
            className="form-control form-control-lg"
            placeholder={t('Amount')}
            value={data.amount}
            onChange={actions.handleChangeAmount}
            required
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...numberFormat}
          />
        </div>
      </div>

      <UploadDocument
        label="AddDocumentOptional"
        inputLabel={data.file?.name || t('DragDocumentToUpload')}
        file={data.file}
        setFile={actions.handleChangeFile}
      />

      <div className="form-group">
        <label className="form-label" htmlFor="contribution-comments">
          {t('CommentsLabel')}
        </label>
        <div className="form-control-wrap">
          <div className="form-editor-custom">
            <textarea
              id="contribution-comments"
              maxLength={500}
              className="form-control form-control-lg no-resize"
              value={data.comments}
              onChange={actions.handleChangeComments}
            />
          </div>
        </div>

        <div className="form-note d-flex justify-content-between">
          <>{t('CommentsVisibleOnlyAdmins')}</>
          <CommentsCharCounter
            comments={data.comments}
            maxLength={MAX_COMMENT_LENGTH}
          />
        </div>
      </div>
    </>
  );
};

export default ContributionDataView;
