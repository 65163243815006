import { Operation, Society } from 'types';

type Props = {
  actualSociety: Society;
  excludeOperationTypes?: string[];
};

export const getLastOperation = ({
  actualSociety,
  excludeOperationTypes,
}: Props): Operation | undefined => {
  let lastOperation: Operation | undefined;

  actualSociety.operations.forEach((operation) => {
    const isExcluded = excludeOperationTypes?.includes(operation.operationType);
    const isNewerOperation =
      !lastOperation || new Date(operation.date) > new Date(lastOperation.date);

    if (isNewerOperation && !isExcluded) {
      lastOperation = operation;
    }
  });

  return lastOperation;
};
