import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { kFormatter } from 'utils/filters';
import { isMobileScreen } from 'utils/getScreenSize';
import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
} from 'utils/formats';
import { getDataCapitalDetailsChart } from 'modules/society/pages/SocietyDashboard/utils/getDataCapitalDetailsChart';

import WidgetList from '../WidgetList';
import WidgetChartBar from '../WidgetChartBar';
import WidgetChartLine from '../WidgetChartLine';
import WidgetChartLineLoader from '../WidgetChartLineLoader';
import WidgetTableExtended from '../WidgetTableExtended';
import WidgetTableExtendedLoader from '../WidgetTableExtendedLoader';
import WidgetChartDoughnut, { legendModes } from '../WidgetChartDoughnut';
import WidgetChartDoughnutLoader from '../WidgetChartDougnutLoader';
import WidgetTableSimple from '../WidgetTableSimple';
import WidgetTableSimpleLoader from '../WidgetTableSimpleLoader';

type SummaryDashboardProps = {
  data: any;
  isLoading: Record<string, boolean>;
  isReadOnly: boolean;
  societyId: string;
  societyName: string;
  selectedTheme: string;
};

const SummaryDashboard: FC<SummaryDashboardProps> = ({
  data,
  societyId,
  societyName,
  selectedTheme,
  isReadOnly,
  isLoading,
}) => {
  const { t } = useTranslate();

  return (
    <div className="row">
      {!isLoading?.summaryChartViewData && data?.summaryChartViewData && (
        // @ts-ignore
        <WidgetChartLine
          title={t('CompanySummary')}
          subtitle={t('CompanyMainIndicators', { societyName })}
          kpis={[
            {
              title: t('Partners'),
              value: kFormatter(
                data?.summaryChartViewData?.kpisData?.partnersCount
              ),
              icon: 'ni-user',
              link: `/socios/${societyId}`,
            },
            {
              title: t('Shares'),
              value: kFormatter(
                data?.summaryChartViewData?.kpisData?.societyActualShares
              ),
              icon: 'ni-layers',
              link: `/socios/${societyId}`,
            },
            !isMobileScreen()
              ? {
                  title: t('Nominal'),
                  value: formatCurrencyDecimalsLong(
                    data?.summaryChartViewData?.kpisData?.societyNominalValue
                  ),
                  icon: 'ni-layers',
                  link: `/socios/${societyId}`,
                }
              : null,
            {
              title: t('NoteConv.'),
              value: kFormatter(
                data?.summaryChartViewData?.kpisData?.convertibleNotesShares
              ),
              icon: 'ni-invest',
              link: `/borradores/${societyId}`,
            },
            {
              title: t('Incentives'),
              value: kFormatter(
                data?.summaryChartViewData?.kpisData?.plansUsedShares
              ),
              icon: 'ni-layers',
              link: `/beneficiarios-planes/${societyId}`,
            },
          ].filter((item) => item)}
          data={data?.summaryChartViewData}
          extraLabel={t('SocialCapital')}
          extraValue={formatCurrencyDecimals(
            data?.summaryChartViewData?.kpisData?.societyActualShares *
              data?.summaryChartViewData?.kpisData?.societyNominalValue || 0
          )}
          isReadOnly={isReadOnly}
        />
      )}

      {!data?.summaryChartViewData && <WidgetChartLineLoader columns={5} />}

      {!isLoading?.financialChartViewData && data?.financialChartViewData && (
        <WidgetChartBar
          title={t('Financing')}
          subtitle={t('FinancingEvolution')}
          height={222}
          kpis={[
            {
              title: t('Shar.'),
              value: kFormatter(
                data?.financialChartViewData?.kpisData?.societyActualShares
              ),
              icon: 'ni-layers',
            },
            {
              title: t('Investment.'),
              value: kFormatter(
                data?.financialChartViewData?.kpisData?.inversionValue
              ),
              icon: 'ni-invest',
            },
            {
              title: t('ValueShar.'),
              value: `${data?.financialChartViewData?.kpisData?.sharesValue?.toFixed(
                2
              )}€`,
              icon: 'ni-layers',
            },
          ]}
          data={data?.financialChartViewData}
        />
      )}

      {isLoading?.financialChartViewData && (
        <WidgetChartLineLoader columns={3} />
      )}

      {!isLoading?.partnersViewData && data?.partnersViewData && (
        <WidgetTableExtended
          title={t('MainPartners')}
          linkText={t('SeeAlls')}
          linkUrl={`/socios/${societyId}`}
          columns={[t('Partner'), t('Shar'), t('%ND'), t('%FD')]}
          data={data?.partnersViewData}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.partnersViewData && <WidgetTableExtendedLoader />}

      {!isLoading?.partnersChartViewData && data?.partnersChartViewData && (
        // @ts-ignore
        <WidgetChartDoughnut
          title={t('Partners')}
          linkText={t('SeeAlls')}
          linkUrl={`/socios/${societyId}`}
          data={data?.partnersChartViewData?.chart}
          legendMode={legendModes.VERTICAL}
          legend={data?.partnersChartViewData?.partners}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.partnersChartViewData && (
        <WidgetChartDoughnutLoader
          legendMode={legendModes.VERTICAL as keyof typeof legendModes}
        />
      )}

      {!isLoading?.capitalViewData && data?.capitalViewData && (
        // @ts-ignore
        <WidgetTableSimple
          title={t('Capital')}
          subtitle={t('CapitalDistributionTitle')}
          data={data?.capitalViewData}
        />
      )}

      {isLoading?.capitalViewData && <WidgetTableSimpleLoader rows={10} />}

      {!isLoading?.capitalChartViewData && data?.capitalChartViewData && (
        // @ts-ignore
        <WidgetChartDoughnut
          title={t('CapitalDetail')}
          data={getDataCapitalDetailsChart({
            capitalChartViewData: data?.capitalChartViewData,
            selectedTheme,
            t,
          })}
          legendMode={legendModes.HORIZONTAL}
          legend={[
            {
              title: t('Share.'),
              icon: 'ni-reports-alt',
              color: '#798bff',
              value: kFormatter(
                data?.capitalChartViewData?.societyActualShares
              ),
              percent:
                data?.capitalChartViewData?.societyActualSharesPercentage?.toFixed(
                  2
                ),
            },
            {
              title: t('NoteConv.'),
              icon: 'ni-report-profit',
              color: '#b8acff',
              value: kFormatter(
                data?.capitalChartViewData?.convertibleNotesShares
              ),
              percent:
                data?.capitalChartViewData?.convertibleNotesSharesPercentage?.toFixed(
                  2
                ),
            },
            {
              title: t('StockOptions'),
              icon: 'ni-users',
              color: '#ffa9ce',
              value: kFormatter(data?.capitalChartViewData?.stockOptionsShares),
              percent:
                data?.capitalChartViewData?.stockOptionsSharesPercentage?.toFixed(
                  2
                ),
            },
            {
              title: t('Phantoms'),
              icon: 'ni-users-fill',
              color: '#7de1f8',
              value: kFormatter(data?.capitalChartViewData?.phantomShares),
              percent:
                data?.capitalChartViewData?.phantomSharesPercentage?.toFixed(2),
            },
          ]}
        />
      )}

      {isLoading?.capitalChartViewData && (
        <WidgetChartDoughnutLoader
          legendMode={legendModes.HORIZONTAL as keyof typeof legendModes}
        />
      )}

      {!isLoading?.plansViewData && data?.plansViewData && (
        // @ts-ignore
        <WidgetTableSimple
          title={t('IncentivePlans')}
          subtitle={t('SocietyHasXPlans', {
            societyName,
            plansCount: data.plansViewData.length - 1, // One row is the total
          })}
          linkText={t('SeeAlls')}
          linkUrl={`/beneficiarios-planes/${societyId}`}
          data={data?.plansViewData}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.plansViewData && <WidgetTableSimpleLoader rows={10} />}

      {!isLoading?.operationsViewData && data?.operationsViewData && (
        <WidgetList
          title={t('Transactions')}
          subtitle={t('LastTransactions')}
          linkText={t('SeeAll')}
          linkUrl={`/transacciones/${societyId}`}
          data={data?.operationsViewData}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.operationsViewData && <WidgetTableSimpleLoader rows={10} />}

      {!isLoading?.beneficiariesViewData && data?.beneficiariesViewData && (
        <WidgetTableExtended
          title={t('MainBeneficiaries')}
          linkText={t('SeeAlls')}
          linkUrl={`/beneficiarios/${societyId}`}
          columns={[t('Beneficiary'), t('Unit'), t('%FD')]}
          data={data?.beneficiariesViewData}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.beneficiariesViewData && (
        <WidgetTableExtendedLoader rows={10} />
      )}

      {!isLoading?.draftsViewData && data?.draftsViewData && (
        <WidgetTableExtended
          title={t('OperationsSimulator')}
          linkText={t('SeeAlls')}
          linkUrl={`/borradores/${societyId}`}
          columns={[t('Operation'), t('Amount')]}
          data={data?.draftsViewData}
          isReadOnly={isReadOnly}
        />
      )}

      {isLoading?.draftsViewData && <WidgetTableExtendedLoader rows={10} />}
    </div>
  );
};

export default SummaryDashboard;
