import { Document, Partner, Share, Society } from 'types';
import { getPartnerOperations, getTransactionsByPartner } from 'utils/filters';
import { getTransactionKey } from 'modules/partners/utils';

import PartnerTransactionsRow from '../PartnerTransactionsRow';

import { getInvertedIndex } from './getInvertedIndex';
import { getTagFromTransaction } from './getTagFromTransaction';
import { getDocumentsFromTransaction } from './getDocumentsFromTransaction';

export const sortAndCreateRows = (
  society: Society,
  partner: Partner,
  partnerShares: Share[],
  documents: Document[]
) => {
  if (!society || !partner) return [];

  const partnerTransactions = getTransactionsByPartner(
    getPartnerOperations(partner, society),
    partner
  );

  const sortedTransactions = partnerTransactions
    ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    ?.map((transaction, index) => (
      <PartnerTransactionsRow
        key={getTransactionKey(transaction)}
        index={getInvertedIndex(index, partnerTransactions?.length)}
        society={society}
        transaction={transaction}
        documents={getDocumentsFromTransaction(transaction, documents)}
        tag={getTagFromTransaction(transaction, partnerShares, society)}
      />
    ));

  return sortedTransactions;
};
