import { Dividend, Operation } from 'types';

export const getPartnerAmountFromOperation = (
  partnerId: string,
  operation: Operation
): number => {
  const { operationType } = operation;

  if (operationType === 'DIVIDENDS_DISTRIBUTION') {
    const dividend = (operation.dividends as Dividend[]).find((dividend) =>
      typeof dividend.partner === 'string'
        ? dividend.partner === partnerId
        : dividend.partner._id === partnerId
    );

    return dividend?.amount || 0;
  }

  return 0;
};
