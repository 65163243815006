/* eslint-disable react/no-array-index-key */

import {
  getPartnerFDPercent,
  getPartnerNDPercent,
  getPartnerPotentialFD,
  getPartnerPremium,
} from 'utils/filters';
import { HoldingClass, Partner, Society } from 'types';
import DrawCell from './DrawCell';
import DrawInversionColumns from './DrawInversionColumns';

type ClassesTablePartner = Partner & {
  sharesCount: {
    actual: number;
    new: number;
  };
  ndPercent: number;
  fdPercent: number;
  pfdPercent: number;
  realContribution: number;
};

type ClassesTableRowsPartnersProps = {
  currentPartners: any;
  holdingClasses: any;
  currentTableRows: any;
  partnersShares: any;
  text: any;
  inversionView: any;
  investorsView: any;
  hasNDValueColumn: any;
  hasNDPercentColumn: any;
  hasFDValueColumn: any;
  hasFDPercentColumn: any;
  hasPFDValueColumn: any;
  hasPFDPercentColumn: any;
  actualSociety: any;
  plans: any;
  decimalScale: any;
};

const getAccumulatedNominalValue = (
  partner: ClassesTablePartner,
  partnersShares: any,
  actualSociety: Society,
  decimalScale: number
) => {
  const accumulatedNominalValue =
    partnersShares[partner?.['_id']] * actualSociety?.nominalValue;
  return parseFloat(accumulatedNominalValue.toFixed(decimalScale));
};

const getAccumulatedPremiumValue = (
  partner: ClassesTablePartner,
  actualSociety: Society,
  decimalScale: number
) => {
  const premiumValue = getPartnerPremium(partner?.shares || [], actualSociety);

  const draftShares = partner?.sharesCount?.new || 0;
  const draftPremium = draftShares * (actualSociety?.nominalValue || 0);

  const draftPremiumValue =
    (partner?.realContribution || 0) - (draftPremium || 0);

  const totalPremiumValue = premiumValue + draftPremiumValue;
  return parseFloat(totalPremiumValue.toFixed(decimalScale));
};

const getPartnersRowStyle = (index: number, partner: ClassesTablePartner) =>
  `${partner?.['_id']}-${index}`;

const getPreviousColumnsLength = (
  inversionView: boolean,
  holdingClasses: HoldingClass[]
) => (inversionView ? holdingClasses?.length + 4 : holdingClasses?.length + 1);

const getNDPercentColumnValue = (
  partner: ClassesTablePartner,
  actualSociety: Society,
  decimalScale: number
) => {
  const partnerNDPercent =
    partner?.ndPercent || getPartnerNDPercent(partner, actualSociety) || 0;

  return parseFloat(partnerNDPercent.toFixed(decimalScale));
};

const getNDPercentColumnPreviousColumnsLength = (
  inversionView: boolean,
  investorsView: boolean,
  holdingClasses: HoldingClass[]
) => {
  if (inversionView) {
    return holdingClasses?.length + 5;
  }

  if (investorsView) {
    return holdingClasses?.length + 3;
  }

  return holdingClasses?.length + 2;
};

const ClassesTableRowsPartners = ({
  currentPartners,
  holdingClasses,
  currentTableRows,
  partnersShares,
  text,
  inversionView,
  investorsView,
  hasNDValueColumn,
  hasNDPercentColumn,
  hasFDValueColumn,
  hasFDPercentColumn,
  hasPFDValueColumn,
  hasPFDPercentColumn,
  actualSociety,
  plans,
  decimalScale,
}: ClassesTableRowsPartnersProps) =>
  currentPartners
    ?.filter((partner: ClassesTablePartner) => partner?.sharesCount?.actual > 0)
    ?.map((partner: ClassesTablePartner, index: number) => (
      <tr
        key={getPartnersRowStyle(index, partner)}
        className={
          index % 2 === 0 ? 'text-center' : 'bg-light-vert text-center'
        }
      >
        <td
          className="bg-light-vert d-block text-ellipsis"
          style={
            index === currentPartners.length - 1
              ? { borderBottom: '1px solid #999' }
              : {}
          }
        >
          {partner.name}
        </td>

        {holdingClasses?.map(
          (holdingClass: HoldingClass, holdingClassIndex: number) => (
            <DrawCell
              columnIndex={holdingClassIndex}
              value={
                currentTableRows[`${partner?.['_id']}-${holdingClass?.['_id']}`]
              }
              text={text}
              previousColumnsLength={2}
              rowIndex={index}
              rowsCount={currentPartners.length - 1}
              keyText={`classes-${partner?.['_id']}-${holdingClass?.['_id']}`}
            />
          )
        )}

        {/* Total Shares */}
        <DrawCell
          columnIndex={0}
          value={partnersShares[partner?.['_id']]}
          text={text}
          previousColumnsLength={holdingClasses?.length || 0}
          rowIndex={index}
          rowsCount={currentPartners.length - 1}
          keyText={`totalshares-${partner?.['_id']}`}
        />

        {/* Inversion */}
        {inversionView && (
          <DrawInversionColumns
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            text={text}
            previousColumnsLength={holdingClasses?.length + 1}
            accumulatedNominalValue={getAccumulatedNominalValue(
              partner,
              partnersShares,
              actualSociety,
              decimalScale
            )}
            totalPremiumValue={getAccumulatedPremiumValue(
              partner,
              actualSociety,
              decimalScale
            )}
          />
        )}

        {/* ND */}
        {hasNDValueColumn && (
          <DrawCell
            columnIndex={0}
            value={partnersShares[partner?.['_id']]}
            text={text}
            previousColumnsLength={getPreviousColumnsLength(
              inversionView,
              holdingClasses
            )}
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            keyText={`ndshares-${partner?.['_id']}`}
          />
        )}
        {hasNDPercentColumn && (
          <DrawCell
            columnIndex={0}
            value={getNDPercentColumnValue(
              partner,
              actualSociety,
              decimalScale
            )}
            text={text}
            previousColumnsLength={getNDPercentColumnPreviousColumnsLength(
              inversionView,
              investorsView,
              holdingClasses
            )}
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            suffix="%"
            decimal
            keyText={`ndpercent-${partner?.['_id']}`}
          />
        )}

        {/* FD */}
        {hasFDValueColumn && (
          <DrawCell
            columnIndex={0}
            value={partnersShares[partner?.['_id']]}
            text={text}
            previousColumnsLength={getPreviousColumnsLength(
              inversionView,
              holdingClasses
            )}
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            keyText={`fdshares-${partner?.['_id']}`}
          />
        )}
        {hasFDPercentColumn && (
          <DrawCell
            columnIndex={0}
            value={parseFloat(
              partner?.fdPercent?.toFixed(decimalScale) ||
                getPartnerFDPercent(partner, actualSociety).toFixed(
                  decimalScale
                )
            )}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 5
                : holdingClasses?.length + 2
            }
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            suffix="%"
            decimal
            keyText={`fdpercent-${partner?.['_id']}`}
          />
        )}

        {/* Potential FD */}
        {hasPFDValueColumn && (
          <DrawCell
            columnIndex={0}
            value={partnersShares[partner?.['_id']]}
            text={text}
            previousColumnsLength={getPreviousColumnsLength(
              inversionView,
              holdingClasses
            )}
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            keyText={`pfdshares-${partner?.['_id']}`}
          />
        )}
        {hasPFDPercentColumn && (
          <DrawCell
            columnIndex={0}
            value={parseFloat(
              partner?.pfdPercent?.toFixed(decimalScale) ||
                getPartnerPotentialFD(partner, actualSociety, plans).toFixed(
                  decimalScale
                )
            )}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 5
                : holdingClasses?.length + 2
            }
            rowIndex={index}
            rowsCount={currentPartners.length - 1}
            suffix="%"
            decimal
            keyText={`pfdpercent-${partner?.['_id']}`}
          />
        )}
      </tr>
    ));

export default ClassesTableRowsPartners;
