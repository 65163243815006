import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import validateRoute from 'utils/validateRoute';
import { getThemeContentPadding } from 'utils/theme';

import Footer from 'modules/_shared/components/Footer';
import Header from 'modules/_shared/components/Header';
import SideNav from 'modules/_shared/components/SideNav';
import Page403 from 'modules/_shared/pages/403';
import Notification from 'modules/_shared/components/Notification';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { hasInternalView, hasShowDetails } from 'utils/showDetails';

const validateFullWidth = (fullWidth, isFullScreen) =>
  fullWidth || isFullScreen ? 'app-full-width' : '';

const isNotAuthenticated = (isLoading, token, user) =>
  !isLoading && !token && !user;

const selectAppState = (state) => ({
  theme: state.society?.actualSociety?.customization?.theme,
  fullWidth: state.fullWidth,
  notification: state.notification,
});

const GlobalNotification = ({ notification }) =>
  notification?.visible ? (
    <Notification
      id={notification.id}
      title={notification.title}
      message={notification.message}
      type={notification.type}
      link={notification.link}
      linkText={notification.linkText}
      icon={notification.icon}
      fill={notification.fill}
      dismissible={notification.dismissible}
      action={notification.action}
      actionText={notification.actionText}
      actionData={notification.actionData}
    />
  ) : null;

export const LegalRoute = ({
  id,
  path,
  component,
  user,
  isLoading,
  isFullScreen,
  isNewUI,
}) => {
  const location = useLocation();
  const history = useHistory();

  if (hasInternalView()) {
    console.count(`🚀 cclog ~ LegalRoute ~ ${id}`); // eslint-disable-line no-console
  }

  const { theme, fullWidth, notification } = useSelector(
    selectAppState,
    shallowEqual
  );

  const isFullWidth = useMemo(
    () => validateFullWidth(fullWidth, isFullScreen),
    [fullWidth, isFullScreen]
  );

  const isValidRoute = useMemo(
    () => validateRoute(user, id, location.pathname),
    [user, id, location.pathname]
  );

  const RouteComponent = useMemo(
    () => withAuthenticationRequired(component),
    [component]
  );
  useEffect(() => {
    if (isNotAuthenticated(isLoading, localStorage.token, user)) {
      history.push('/login');
    }
  }, [isLoading, user, history]);

  return user ? (
    <div className="app-content nk-wrap">
      <Header isFullScreen={isFullScreen} />
      <GlobalNotification notification={notification} />

      <div
        className={`nk-content ${isNewUI ? 'bg-lighter' : ''}`}
        style={{ paddingTop: getThemeContentPadding(theme) }}
      >
        <div className={`container wide-xl d-flex ${isFullWidth}`}>
          <SideNav className="container__nav" />

          <div className="container__main">
            {isValidRoute ? (
              <Route path={path} component={RouteComponent} />
            ) : (
              <Route component={Page403} />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  ) : (
    <CustomLoading />
  );
};
