import { useTranslate } from 'hooks/useTranslate';

const InvalidEmailBadge = () => {
  const { t } = useTranslate();

  return (
    <span className="badge rounded-pill text-warning ml-3">
      {t('InvalidEmail')}
    </span>
  );
};

export default InvalidEmailBadge;
