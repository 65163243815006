/* eslint-disable react/no-array-index-key */
import { FC } from 'react';

type WidgetTableExtendedLoaderProps = {
  rows?: number;
};

const WidgetTableExtendedLoader: FC<WidgetTableExtendedLoaderProps> = ({
  rows = 7,
}) => (
  <div className="col-12 col-xl-6 mb-4">
    <div className="card card-bordered h-100">
      <div className="card-inner">
        <p className="placeholder-glow ">
          <span className="placeholder col-5    h-15px mb-4" />
          <span className="placeholder offset-4 h-15px mb-4" />
          <span className="placeholder col-3    h-15px mb-4" />

          <span className="placeholder col-3    h-15px mb-3" />
          <span className="placeholder offset-4 h-15px mb-3" />
          <span className="placeholder col-1    h-15px mb-3" />
          <span className="placeholder offset-1 h-15px mb-3" />
          <span className="placeholder col-1    h-15px mb-3" />
          <span className="placeholder offset-1 h-15px mb-3" />
          <span className="placeholder col-1    h-15px mb-3" />

          {new Array(rows).fill(null).map((_, index) => (
            <span key={`main-row-${index}`}>
              <span className="placeholder col-6    h-35px mb-3 mt-1" />
              <span className="placeholder offset-1 h-35px mb-3 mt-1" />
              <span className="placeholder col-1    h-35px mb-3 mt-1" />
              <span className="placeholder offset-1 h-35px mb-3 mt-1" />
              <span className="placeholder col-1    h-35px mb-3 mt-1" />
              <span className="placeholder offset-1 h-35px mb-3 mt-1" />
              <span className="placeholder col-1    h-35px mb-3 mt-1" />
            </span>
          ))}
        </p>
      </div>
    </div>
  </div>
);

export default WidgetTableExtendedLoader;
