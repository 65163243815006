/* eslint-disable prefer-const */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
const COOKIE_CONFIG = {
  secure: true,
  sameSite: 'Strict',
  samesite: 'Strict', // Including both cases for compatibility
  crossSite: false,
  path: '/',
};

// Set initial cookie configuration
window.__mp_cookie_cfg = COOKIE_CONFIG;

(function (f, b) {
  if (!b.__SV) {
    let e;
    let g;
    let i;
    let h;
    window.mixpanel = b;
    b._i = [];
    b.init = function (e, f, c) {
      // Add secure cookie configuration
      f = f || {};
      f.cookie_options = window.__mp_cookie_cfg;
      f.secure_cookie = true;
      f.cross_site_cookie = false;
      f.cross_subdomain_cookie = false;

      function g(a, d) {
        const b = d.split('.');
        b.length == 2 && ((a = a[b[0]]), (d = b[1]));
        a[d] = function () {
          a.push([d].concat(Array.prototype.slice.call(arguments, 0)));
        };
      }
      let a = b;
      typeof c !== 'undefined' ? (a = b[c] = []) : (c = 'mixpanel');
      a.people = a.people || [];
      a.toString = function (a) {
        let d = 'mixpanel';
        c !== 'mixpanel' && (d += `.${c}`);
        a || (d += ' (stub)');
        return d;
      };
      a.people.toString = function () {
        return `${a.toString(1)}.people (stub)`;
      };
      i =
        'disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove'.split(
          ' '
        );
      for (h = 0; h < i.length; h++) g(a, i[h]);
      const j = 'set set_once union unset remove delete'.split(' ');
      a.get_group = function () {
        function b(c) {
          d[c] = function () {
            call2_args = arguments;
            call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
            a.push([e, call2]);
          };
        }
        for (
          var d = {},
            e = ['get_group'].concat(Array.prototype.slice.call(arguments, 0)),
            c = 0;
          c < j.length;
          c++
        )
          b(j[c]);
        return d;
      };
      b._i.push([e, f, c]);
    };
    b.__SV = 1.2;
    e = f.createElement('script');
    e.type = 'text/javascript';
    e.async = !0;
    e.src =
      typeof MIXPANEL_CUSTOM_LIB_URL !== 'undefined'
        ? MIXPANEL_CUSTOM_LIB_URL
        : 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
    g = f.getElementsByTagName('script')[0];
    g.parentNode.insertBefore(e, g);
  }
})(document, window.mixpanel || []);

// Initialize Mixpanel with enforced secure configuration
export const initMixpanel = () => {
  // Ensure we're in a browser environment
  if (typeof window === 'undefined') return;

  const isProd = process.env.NODE_ENV === 'production';
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;

  if (!token) {
    console.warn('Mixpanel token not found'); // eslint-disable-line no-console
    return;
  }

  const config = {
    debug: !isProd,
    api_host: isProd
      ? 'https://api.mixpanel.com'
      : 'https://api-js.mixpanel.com',
    secure_cookie: true,
    ip: false,
    cross_site_cookie: false,
    cross_subdomain_cookie: false,
    persistence: 'cookie',
    upgrade: true,
    property_blacklist: ['$initial_referrer', '$initial_referring_domain'],
    cookie_options: COOKIE_CONFIG,
  };

  // Initialize with secure configuration
  mixpanel.init(token, config);

  // Double-check configuration after initialization
  setTimeout(() => {
    if (window.mixpanel && typeof window.mixpanel.set_config === 'function') {
      window.mixpanel.set_config({
        secure_cookie: true,
        cookie_options: COOKIE_CONFIG,
      });
    }
  }, 0);
};
