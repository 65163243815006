import Login from 'modules/auth/pages/Login';
import LoginPWC from 'modules/auth/pages/LoginPWC';
import TryDemo from 'modules/auth/pages/TryDemo';

export const unprotectedRoutes = [
  {
    id: 'demo',
    path: '/demo',
    component: TryDemo,
  },
  {
    id: 'login',
    path: '/login',
    component: Login,
  },
  {
    id: 'pwc-login',
    path: '/pwc-login',
    component: LoginPWC,
  },
];
