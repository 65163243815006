import { useEffect, useState } from 'react';

import { SharesTitle, Interval, Document } from 'types';

import { getOperationLabel } from 'utils/getOperationLabel';
import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';
import { getPartnersDataFromTitle } from '../utils/getPartnersDataFromTitle';

import { PartnerRow } from '../types/PartnerRow';

type Props = {
  title: SharesTitle;
};

const useSocietyId = (title: SharesTitle) => {
  const [societyId, setSocietyId] = useState<string>('');

  useEffect(() => {
    if (title) {
      setSocietyId(title.society);
    }
  }, [title]);

  return societyId;
};

const useDocument = (title: SharesTitle) => {
  const [document, setDocument] = useState<Document>();

  useEffect(() => {
    const fetchDocument = async () => {
      if (title?.document) {
        const document = await store.dispatch(getDocument(title.document));
        setDocument(document);
      }
    };

    fetchDocument();
  }, [title]);

  return document;
};

const useOperationId = (title: SharesTitle) => {
  const [operationId, setOperationId] = useState<string>('');

  useEffect(() => {
    if (title && title.operation) {
      setOperationId(title.operation._id || '');
    }
  }, [title]);

  return operationId;
};

const useOperationTypeLabel = (title: SharesTitle) => {
  const [operationTypeLabel, setOperationTypeLabel] = useState<string>('');

  useEffect(() => {
    if (title && title.operation) {
      setOperationTypeLabel(getOperationLabel(title.operation.operationType));
    }
  }, [title]);

  return operationTypeLabel;
};

const useIntervals = (title: SharesTitle) => {
  const [intervals, setIntervals] = useState<Interval[]>([]);

  useEffect(() => {
    if (title) {
      setIntervals(title.intervals);
    }
  }, [title]);

  return intervals;
};

const usePartners = (title: SharesTitle) => {
  const [partners, setPartners] = useState<PartnerRow[]>([]);

  useEffect(() => {
    if (title) {
      const partners = getPartnersDataFromTitle(title);
      setPartners(partners);
    }
  }, [title]);

  return partners;
};

export const useGetDataFromTitle = ({ title }: Props) => {
  const societyId = useSocietyId(title);
  const operationId = useOperationId(title);
  const operationTypeLabel = useOperationTypeLabel(title);
  const intervals = useIntervals(title);
  const partners = usePartners(title);
  const document = useDocument(title);

  return {
    data: {
      societyId,
      operationId,
      operationTypeLabel,
      intervals,
      partners,
      document,
    },
  };
};
