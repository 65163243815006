import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';
import { SharesTitle } from 'types';

const resetStateSociety = () => initialState.sharesTitles;

const getSharesTitles = (action: any) => action.sharesTitles;

const updateSharesTitle = (sharesTitles: SharesTitle[], action: any) => {
  const currentId = action.sharesTitle['_id'];

  const updatedSharesTitles = sharesTitles?.map((sharesTitle: SharesTitle) => {
    if (sharesTitle['_id'] === currentId) {
      return action.sharesTitle;
    }
    return sharesTitle;
  });
  return [...updatedSharesTitles];
};

export default function reducer(
  sharesTitles = initialState.sharesTitles,
  action: any
) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return resetStateSociety();

    case actionTypes.GET_SHARES_TITLES:
      return getSharesTitles(action);

    case actionTypes.UPDATE_SHARES_TITLE:
      return updateSharesTitle(sharesTitles, action);

    default:
      return sharesTitles;
  }
}
