import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Dividend, EmailIssue, Partner } from 'types';
import getInvalidEmails from 'utils/getInvalidEmails';
import i18n from 'i18n/config';
import getGeneratedEmails from 'utils/getGeneratedEmails';
import { PartnerData } from '../types/PartnerData';

const getPartnersData = (partners: Partner[], dividends: Dividend[]) => {
  const partnersData: PartnerData[] = [];

  dividends.forEach((dividend) => {
    const partner = partners.find(
      (partner) => dividend.partner === partner._id
    );

    if (partner) {
      const partnerData: PartnerData = {
        id: partner._id,
        name: partner.name,
        email: partner.email,
      };

      partnersData.push(partnerData);
    }
  });
  return partnersData;
};

type Props = {
  dividends: Dividend[];
};

const getEmailAlert = (
  invalidEmailsData: EmailIssue[],
  invalidEmailsMessage: string,
  initialMessage: string
) => (
  <div className="text-left">
    <b>{invalidEmailsMessage}</b>
    <br />
    <p className="text-justify">
      {initialMessage} <br />
    </p>
    <ul>
      {invalidEmailsData.map((data) => (
        <li>• {data.name}</li>
      ))}
    </ul>
  </div>
);

const getInvalidEmailsAlert = (emails: EmailIssue[]) => {
  const invalidEmailsMessage = i18n.t('InvalidEmails');
  const initialMessage = i18n.t('PartnersWithInvalidEmailsMessage');

  const emailAlert = getEmailAlert(
    emails,
    invalidEmailsMessage,
    initialMessage
  );
  return emailAlert;
};

const getGeneratedEmailsAlert = (generatedEmailsData: EmailIssue[]) => {
  const invalidEmailsMessage = i18n.t('GeneratedEmails');
  const initialMessage = i18n.t('PartnersWithGeneratedEmailsMessage');

  const emailAlert = getEmailAlert(
    generatedEmailsData,
    invalidEmailsMessage,
    initialMessage
  );
  return emailAlert;
};

export const useGetPartnersData = ({ dividends }: Props) => {
  const partners = useSelector(
    (state: State) => state.society?.actualSociety?.partners
  );

  const [partnersData, setPartnersData] = useState<PartnerData[]>([]);
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false);
  const [invalidEmailsAlert, setInvalidEmailsAlert] =
    useState<React.ReactNode>(); // eslint-disable-line

  const [hasGeneratedEmails, setHasGeneratedEmails] = useState<boolean>(false);
  const [generatedEmailsAlert, setGeneratedEmailsAlert] =
    useState<React.ReactNode>(); // eslint-disable-line

  useEffect(() => {
    if (partners && dividends) {
      const partnersData = getPartnersData(partners, dividends);
      setPartnersData(partnersData);

      const invalidEmails = getInvalidEmails(partnersData);
      setHasInvalidEmails(invalidEmails.length > 0);

      const invalidEmailsAlert = getInvalidEmailsAlert(invalidEmails);
      setInvalidEmailsAlert(invalidEmailsAlert);

      const generateEmails = getGeneratedEmails(partnersData);
      setHasGeneratedEmails(generateEmails.length > 0);

      const generatedEmailsAlert = getGeneratedEmailsAlert(generateEmails);
      setGeneratedEmailsAlert(generatedEmailsAlert);
    }
  }, [partners, dividends]);

  return {
    data: {
      partnersData,
      hasInvalidEmails,
      invalidEmailsAlert,
      hasGeneratedEmails,
      generatedEmailsAlert,
    },
  };
};
