import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { dateFormat } from 'constants/formats';
import { TranslateFn, useTranslate } from 'hooks/useTranslate';
import { Notary, Operation, Society } from 'types';

import { getOperationCapitalSocial } from 'modules/transactions/utils/getOperationCapitalSocial';
import { getOperationNominalValue } from 'modules/transactions/utils/getOperationNominalValue';
import { getOperationPreviousShares } from 'modules/transactions/utils/getOperationPreviousShares';
import { operationsWithoutNotarialRegistrationSection } from '../constants/operationsWithoutNotarialRegistrationSection';

type TransactionLabelObject = {
  [key: string]: string;
};

type TransactionData = {
  name: string;
  comments: string;
  date: string;
};

type OperationType =
  | 'ADD_PARTNER'
  | 'CONSTITUTION'
  | 'CAPITAL_INCREASE'
  | 'CAPITAL_DECREASE'
  | 'SELL_PARTICIPATION'
  | 'HERITAGE'
  | 'DONATION'
  | 'SPLIT'
  | 'RENUMERATION'
  | 'RECLASSIFICATION'
  | 'DIVIDENDS_DISTRIBUTION';

type TransactionOtherData = {
  socialCapital: number;
  nominalValue: number;
  totalShares: number;
};

const notarialInitialState: Notary = {
  notaryName: '',
  protocolNr: '',
  notaryCity: '',
};

const transactionInitialState = {
  name: '',
  comments: '',
  date: '',
};

const otherDataInitialState = {
  socialCapital: 0,
  nominalValue: 0,
  totalShares: 0,
};

const getTransactionLabels = (operationType: OperationType, t: TranslateFn) => {
  const labels: TransactionLabelObject = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    SELL_PARTICIPATION: t('SELL_PARTICIPATION'),
    HERITAGE: t('Heritage'),
    DONATION: t('Donation'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
    DIVIDENDS_DISTRIBUTION: t('DividendsDistribution'),
    CONTRIBUTION: t('OtherContributions'),
  };

  return labels[operationType] || operationType;
};

const getTransactionData = (transaction: Operation, t: TranslateFn) => {
  const operationType = (transaction?.alias ||
    transaction.operationType) as OperationType;

  const name = getTransactionLabels(operationType, t);
  const comments = transaction?.comments;
  const date = transaction?.date
    ? format(new Date(transaction?.date), dateFormat)
    : '-';

  return { name, comments, date };
};

const getTransactionOtherData = (
  currentOperation: Operation,
  society: Society
) => {
  const socialCapital = getOperationCapitalSocial({
    operationId: currentOperation._id || '',
    society,
  });

  const nominalValue = getOperationNominalValue({
    holdingClass: null,
    operation: currentOperation,
    society,
  });

  const totalShares = getOperationPreviousShares({
    operationId: currentOperation._id || '',
    society,
  });

  return { socialCapital, nominalValue, totalShares };
};

export const useTransactionDetails = (
  transaction: Operation,
  society: Society
) => {
  const { t } = useTranslate();

  const [notarialRegistration, setNotarialRegistration] =
    useState<Notary>(notarialInitialState);
  const [transactionData, setTransactionData] = useState<TransactionData>(
    transactionInitialState
  );
  const [transactionOtherData, setTransactionOtherData] =
    useState<TransactionOtherData>(otherDataInitialState);

  const [hasNotarialRegistrationSection, setHasNotarialRegistrationSection] =
    useState(true);

  useEffect(() => {
    if (transaction) {
      const notarialRegistration =
        transaction?.notarialRegistration || notarialInitialState;

      const { name, comments, date } = getTransactionData(transaction, t);
      const { socialCapital, nominalValue, totalShares } =
        getTransactionOtherData(transaction, society);

      setNotarialRegistration(notarialRegistration);
      setTransactionData({ name, comments, date });
      setTransactionOtherData({ socialCapital, nominalValue, totalShares });

      const hasNotarialRegistration =
        !operationsWithoutNotarialRegistrationSection.includes(
          transaction.operationType
        );
      setHasNotarialRegistrationSection(hasNotarialRegistration);
    }
  }, [transaction, society]); // eslint-disable-line

  return {
    data: {
      notarialRegistration,
      transactionData,
      transactionOtherData,
      hasNotarialRegistrationSection,
    },
  };
};
