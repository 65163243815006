import { Partner, Share, SharesTitle } from 'types';
import { PartnerRow } from '../types/PartnerRow';

const getDirectPartnerData = (share: Share): PartnerRow | undefined => {
  const directPartner = share?.partner as Partner;

  return {
    id: directPartner?._id,
    name: directPartner?.name,
    cif: directPartner?.cif,
    image: directPartner?.image,
  };
};

const getCoOwnersData = (share: Share): PartnerRow[] => {
  const coOwners = share?.encumbrances
    .flatMap((encumbrance) => encumbrance?.coOwners)
    .map((coOwner) => coOwner);

  const partners: PartnerRow[] = [];
  coOwners?.forEach((coOwner) => {
    partners.push({
      id: coOwner?.partner,
      cif: coOwner?.cif,
      name: coOwner?.name,
    });
  });

  return partners;
};

const getPartnersDataFromShare = (share: Share): PartnerRow[] => {
  const partners: PartnerRow[] = [];

  const directPartnerData = getDirectPartnerData(share);
  if (directPartnerData) {
    partners.push(directPartnerData);
  }

  const coOwnersData = getCoOwnersData(share);
  partners.push(...coOwnersData);

  return partners;
};

export const getPartnersDataFromTitle = (title: SharesTitle) => {
  const shares = title?.shares || [];

  const sharesPartners = getPartnersDataFromShare(shares[0]);

  const uniquePartnersSet = new Set();
  const uniquePartners: PartnerRow[] = [];

  sharesPartners.forEach((partner) => {
    if (partner?.id && !uniquePartnersSet.has(partner?.id.toString())) {
      uniquePartnersSet.add(partner.id.toString());
      uniquePartners.push(partner);
    }
  });

  return uniquePartners;
};
