import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import { State } from 'redux/initialState';

import Button from 'modules/_shared/components/Buttons';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import AddCouncil from 'modules/_shared/components/Modals/AddCouncil';
import sizes from 'constants/sizes';
import { CouncilsView, CouncilsViews } from '..';

type CouncilsListHeaderProps = {
  councilsView: CouncilsView;
  onChangeView?: any;
};

const CouncilsListHeader: FC<CouncilsListHeaderProps> = ({
  councilsView,
  onChangeView,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const councils: Council[] = useSelector((state: State) => state.councils);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const buttonIcon = councilsView === CouncilsViews.LIST ? 'dashboard' : 'list';

  const getCouncilsDescription = () =>
    `${actualSociety?.name || t('Society')} ${t('HaveCouncils', {
      count: councils.length,
    })}`;

  const handleAddCouncil = () => {
    // TODO: Uncomment when permissions are implemented
    // if (isReadOnly) return;
    dispatch(setModal(<AddCouncil size={sizes.XL} />));
  };

  return (
    <PageListHeader
      subTitle={t('Councils')}
      description={getCouncilsDescription()}
      actions={
        isAdmin && [
          <Button onClick={handleAddCouncil}>{t('AddCouncil')}</Button>,
          <Button
            onClick={onChangeView}
            icon={buttonIcon}
            variant="light"
            outlineStyle
          />,
        ]
      }
    />
  );
};

export default CouncilsListHeader;
