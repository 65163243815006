/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import Swal from 'sweetalert2';

import operationTypesRealNames from 'constants/operationTypesRealNames';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateOperation } from 'modules/_shared/redux/modalsActions';
import { getActualSociety } from 'utils/filters';

import '../Modals.scss';

function EditCommentsModal({ operation, hasNotarialRegistration }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const [date, setDate] = useState();
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');

  async function editOperationComments(event) {
    event.preventDefault();
    const updateData = {
      comments,
      notarialRegistration: { notaryName, notaryCity, protocolNr },
    };
    await store.dispatch(updateOperation(operation['_id'], updateData));
    getActualSociety(user, actualSociety['_id']);
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'EditedOperationDataSuccesfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
    dispatch(setModal(null));
  }

  useEffect(() => {
    if (operation) {
      setDate(format(new Date(operation?.date), 'yyyy-MM-dd'));
      setComments(operation?.comments);
      setNotaryName(operation?.notarialRegistration?.notaryName || '');
      setNotaryCity(operation?.notarialRegistration?.notaryCity || '');
      setProtocolNr(operation?.notarialRegistration?.protocolNr || '');
    }
  }, [operation]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {operation
            ? operationTypesRealNames[
                operation?.alias || operation?.operationType
              ]
            : t('Edit')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={editOperationComments}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('OperationDate')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control"
                value={date}
                readOnly
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="operation-comments">
              {t('CommentsLabel')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  id="operation-comments"
                  maxLength="500"
                  className="form-control form-control-lg no-resize"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>
            </div>
            <div className="form-note d-flex justify-content-between">
              <>{t('CommentsVisibleOnlyAdmins')}</>
              <CommentsCharCounter comments={comments} />
            </div>
          </div>

          {hasNotarialRegistration && (
            <div className="row mb-4">
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">{t('NotaryName')}</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('Mr. John Smith')}
                      value={notaryName}
                      onChange={(e) => setNotaryName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">
                    {t('NotaryProtocolNumber')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('P-8234290A')}
                      value={protocolNr}
                      onChange={(e) => setProtocolNr(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label className="form-label">{t('NotaryCity')}</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control form-control-md"
                      placeholder={t('Barcelona')}
                      value={notaryCity}
                      onChange={(e) => setNotaryCity(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <button type="submit" className="btn btn-lg btn-primary">
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default EditCommentsModal;
