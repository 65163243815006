import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { State } from 'redux/initialState';
import { Communication } from 'types';

type InboxDetailsHeaderProps = {
  communication: Communication;
  actions: {
    setOpenCertification: (value: boolean) => void; // eslint-disable-line
    handleChangeFavorites: (value: boolean) => void; // eslint-disable-line
  };
  isReadOnly: boolean;
  isFavorite: boolean;
  printRef: any;
};

const InboxDetailsHeader = ({
  communication,
  actions,
  isReadOnly,
  isFavorite,
  printRef,
}: InboxDetailsHeaderProps) => {
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  return (
    <div className="nk-ibx-reply-head">
      <div>
        <h4 className="title">{communication?.subject}</h4>
      </div>

      <ul className="d-flex g-1">
        <li className="">
          <Link
            to={`/comunicaciones/${actualSociety?._id}/detalles/${communication?._id}/certificacion`}
          >
            <span
              className="btn btn-icon btn-trigger btn-tooltip"
              onClick={() => {
                actions.setOpenCertification(true);
              }}
            >
              <em className="icon ni ni-shield-check" />
            </span>
          </Link>
        </li>

        <li className="d-none d-sm-block">
          <ReactToPrint
            trigger={() => (
              <span className="btn btn-icon btn-trigger btn-tooltip">
                <em className="icon ni ni-printer" />
              </span>
            )}
            content={() => printRef.current as any}
            documentTitle={`${actualSociety?.name} - ${
              communication?.subject
            } ${format(new Date(), 'dd-MM-yyyy')}`}
          />
        </li>

        {!isReadOnly && (
          <li className="mr-n1">
            <div className="asterisk">
              <span
                className="btn btn-trigger btn-icon btn-tooltip"
                onClick={() => actions.handleChangeFavorites(!isFavorite)}
              >
                {isFavorite ? (
                  <em className="asterisk-off icon ni ni-star-fill  text-primary" />
                ) : (
                  <em className="asterisk-off icon ni ni-star" />
                )}
              </span>
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};

export default InboxDetailsHeader;
