import { FC } from 'react';

import { SharesTitle } from 'types';
import SharesTitlesTable from './SharesTitlesTable';

type Props = {
  sharesTitles: SharesTitle[];
};

const SharesTitlePageBody: FC<Props> = ({ sharesTitles }) => (
  <div className="nk-block">
    <SharesTitlesTable sharesTitles={sharesTitles} />
  </div>
);

export default SharesTitlePageBody;
