import { Society } from 'types';
import { getSocietyEmailConfiguration } from 'utils/emails';
import { getCommunicationStyles } from 'utils/getCommunicationStyles';

const getCommunicationES = (
  societyTheme: any,
  hasWhiteBranding: boolean,
  societyName: string
) => {
  const style = getCommunicationStyles(societyTheme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestión de Socios`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Correo enviado por <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  return `
 ${style}
  <body>
    <div class="main">
        <div class="custom-background">
        <a href="https://www.sttok.com">
          <img src="${societyTheme?.logo}" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
            <p class="branding">
      ${headerBranding}
        </p>
        <div class="email-body">
          <h3>
            Estimado accionista
          </h3>

            Le informamos que se ha procedido al reparto de dividendos cuyo detalle completo puede consultarlo a través del siguiente enlace:

            <br/>


        <a class="button button-small">
            VER DETALLES
          </a>


            <br/>

            Atentamente,
            ${societyName}

            <br/>

        </div>

         ${footerBranding}
      </div>
    </div>
  </body>
`;
};

const getCommunicationEN = (
  societyTheme: any,
  hasWhiteBranding: boolean,
  societyName: string
) => {
  const style = getCommunicationStyles(societyTheme);
  const headerBranding = hasWhiteBranding ? ' ' : `Management of Shareholders`;
  const footerBranding = hasWhiteBranding
    ? ''
    : ` Mail sent by <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/> You can contact at <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  return `
 ${style}
  <body>
    <div class="main">
        <div class="custom-background">
        <a href="https://www.sttok.com">
          <img src="${societyTheme?.logo}" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
            <p class="branding">
      ${headerBranding}
        </p>
        <div class="email-body">
          <h3>
            Dear shareholder
          </h3>

            We inform you that dividends have been distributed, the full details of which can be consulted through the following link:

            <br/>


        <a class="button button-small">
            SEE DETAILS
          </a>


            <br/>

            Sincerely,
            ${societyName}

            <br/>

        </div>

         ${footerBranding}
      </div>
    </div>
  </body>
`;
};

const getCommunicationFR = (
  societyTheme: any,
  hasWhiteBranding: boolean,
  societyName: string
) => {
  const style = getCommunicationStyles(societyTheme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestion des Partenaires`;
  const footerBranding = hasWhiteBranding
    ? ''
    : ` Courrier envoyé par <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/> Vous pouvez contacter au <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  return `
 ${style}
  <body>
    <div class="main">
        <div class="custom-background">
        <a href="https://www.sttok.com">
          <img src="${societyTheme?.logo}" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
            <p class="branding">
      ${headerBranding}
        </p>
        <div class="email-body">
          <h3>
           Cher actionnaire
          </h3>

            Nous vous informons que des dividendes ont été distribués dont le détail complet peut être consulté via le lien suivant :

            <br/>


        <a class="button button-small">
            VOIR LES DÉTAILS
          </a>


            <br/>

            Sincèrement,
            ${societyName}

            <br/>

        </div>

         ${footerBranding}
      </div>
    </div>
  </body>
`;
};

const getCommunicationByLanguage = (
  societyLanguage: string,
  societyTheme: any,
  hasWhiteBranding: boolean,
  societyName: string
) => {
  switch (societyLanguage) {
    case 'es':
      return getCommunicationES(societyTheme, hasWhiteBranding, societyName);
    case 'en':
      return getCommunicationEN(societyTheme, hasWhiteBranding, societyName);
    case 'fr':
      return getCommunicationFR(societyTheme, hasWhiteBranding, societyName);
    default:
      return getCommunicationES(societyTheme, hasWhiteBranding, societyName);
  }
};

export const getCommunication = (actualSociety: Society) => {
  if (actualSociety) {
    const { societyLanguage } = actualSociety;
    const societyTheme = getSocietyEmailConfiguration(actualSociety, {
      addSuffix: false,
    })?.theme;
    const hasWhiteBranding = actualSociety?.configuration?.hasWhiteBranding;
    const societyName = actualSociety?.name;

    return getCommunicationByLanguage(
      societyLanguage,
      societyTheme,
      hasWhiteBranding,
      societyName
    );
  }
};
