import { FC } from 'react';

import { PartnerData } from '../types/PartnerData';

type Props = {
  index: number;
  partner: PartnerData;
};

const PartnersTableViewRow: FC<Props> = ({ index, partner }: Props) => {
  const partnerName = partner.name;
  const partnerEmail = partner.email;

  return (
    <>
      <td className="tb-tnx-info text-left py-0" key={`partner-name-${index}`}>
        <span className="title">{partnerName}</span>
      </td>
      <td
        className="tb-tnx-info text-soft text-left py-0"
        key={`partner-email-${index}`}
      >
        <span className="title d-block">{partnerEmail}</span>
      </td>
    </>
  );
};

export default PartnersTableViewRow;
