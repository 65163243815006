import React, { useEffect, useState } from 'react';
import { CoOwner, SelectorOption } from 'types';

type Props = {
  coOwners: CoOwner[];
};

export default function useCoOwnerForm({ coOwners }: Props) {
  const [partnerId, setPartnerId] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [cif, setCif] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [percent, setPercent] = useState<number>(0);
  const [comment, setComment] = useState<string>('');
  const [disabledAddButton, setDisabledAddButton] = useState<boolean>(true);
  const [selectedPartner, setSelectedPartner] =
    useState<SelectorOption | null>();

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<any>>) => (event: any) => {
      const value = event?.target?.value;
      if (value !== undefined) {
        setter(value);
      } else {
        setter(event);
      }
    };

  useEffect(() => {
    let currentPercent = 0;

    coOwners.forEach((coOwner) => {
      currentPercent += +coOwner?.percent || 0;
    });

    const isInvalidPercent = +percent + currentPercent > 100;

    const isDisabled = !name || !cif || isInvalidPercent;
    setDisabledAddButton(isDisabled);
  }, [cif, coOwners, name, percent]);

  return {
    data: {
      name,
      cif,
      email,
      percent,
      comment,
      selectedPartner,
      disabledAddButton,
      partnerId,
    },
    isLoading: false,
    isError: false,
    actions: {
      handleChangeName: handleChange(setName),
      handleChangeCif: handleChange(setCif),
      handleChangeEmail: handleChange(setEmail),
      handleChangePercent: handleChange(setPercent),
      handleChangeComment: handleChange(setComment),
      handleChangePartner: handleChange(setSelectedPartner),
      handleChangePartnerId: handleChange(setPartnerId),
    },
  };
}
