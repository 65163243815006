import { FC } from 'react';
import { Document } from 'types';

import DocumentRow from './DocumentRow';

type Props = {
  documents: Document[];
  hasOptions?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDownloadDocument: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveDocument?: (index: number) => void;
  isAdminView?: boolean;
  isSummaryVersion?: boolean;
  textSize?: number;
};

const DocumentsList: FC<Props> = ({
  documents,
  hasOptions = true,
  handleDownloadDocument,
  handleRemoveDocument,
  isAdminView,
  isSummaryVersion,
  textSize,
}: Props) =>
  documents?.map((document, index) => (
    <DocumentRow
      key={document['_id']}
      index={index}
      document={document}
      hasOptions={hasOptions}
      handleDownloadDocument={handleDownloadDocument}
      handleRemoveDocument={handleRemoveDocument}
      isAdminView={isAdminView}
      isSummaryVersion={isSummaryVersion}
      textSize={textSize}
    />
  ));

export default DocumentsList;
