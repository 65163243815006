import {
  Board,
  Committee,
  Communication,
  Council,
  Document,
  Guest,
  HoldingClass,
  PartnerRepresentant,
  Realty,
  SharesTitle,
  User,
} from 'types';

export type State = {
  user: User;
  partnerBook: Record<string, any>;
  menu: Record<string, any>;
  sideNav: boolean;
  alerts: any[];
  documents: Document[];
  modal: any;
  society: Record<string, any>;
  employees: Record<string, any>[];
  employeesPlans: Record<string, any>[];
  plans: Record<string, any>[];
  drafts: Record<string, any>[];
  holdingClasses: HoldingClass[];
  tenderOffers: Record<string, any>[];
  tenderOffersShares: Record<string, any>[];
  boards: Board[];
  communications: Communication[];
  preview: Record<string, any>;
  notification: Record<string, any>;
  fullWidth: boolean;
  partnerCategories: Record<string, any>[];
  partnerRepresentants: PartnerRepresentant[];
  globalNotifications: Record<string, any>[];
  guests: Guest[];
  committees: Committee[];
  councils: Council[];
  realties: Realty[];
  sharesTitles: SharesTitle[];
};

const initialState = {
  user: null,
  partnerBook: null,
  menu: { type: 'MAIN' },
  sideNav: false,
  alerts: [],
  documents: [],
  modal: null,
  society: null,
  employees: [],
  employeesPlans: [],
  plan: [],
  drafts: [],
  holdingClasses: [],
  tenderOffers: [],
  tenderOffersShares: [],
  boards: [],
  communications: [],
  preview: null,
  notification: null,
  fullWidth: false,
  partnerCategories: [],
  partnerRepresentants: [],
  globalNotifications: [],
  guests: [],
  committees: [],
  councils: [],
  realties: [],
  sharesTitles: [],
};

export default initialState;
