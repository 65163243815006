import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Share, SharesTitle } from 'types';
import { getTitleByShare } from '../utils/getTileByShare';

type Props = {
  share: Share;
};

export const useGetShareTitle = ({ share }: Props) => {
  const sharesTitles = useSelector((state: State) => state.sharesTitles);
  const [sharesTitle, setSharesTitle] = useState<SharesTitle | null>(null);
  const [document, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    const title = getTitleByShare(sharesTitles, share);

    if (title) {
      setSharesTitle(title);

      if (title?.document) {
        const fetchDocument = async () => {
          const doc = await store.dispatch(getDocument(title.document));
          setDocument(doc);
        };
        fetchDocument();
      }
    } else {
      setSharesTitle(null);
      setDocument(null);
    }
  }, [share, sharesTitles]);

  return { data: { sharesTitle, document } };
};
