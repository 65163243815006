import { FC } from 'react';

import ContributionTableHeader from './ContributionTableHeader';
import ContributionTableBody from './ContributionTableBody';

const ContributionTable: FC = () => (
  <table className="table table-tranx" id="contribution-table">
    <ContributionTableHeader />
    <ContributionTableBody />
  </table>
);

export default ContributionTable;
