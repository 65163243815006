import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State } from 'redux/initialState';

import { useTranslate } from 'hooks/useTranslate';
import { useGetActualSociety } from 'hooks/useGetActualSociety';

import Page from 'modules/_shared/components/Pages/Page';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import { getLastTitleNumber } from 'modules/partners/utils/getLastTitleNumber';

import SharesTitlePageBody from './components/SharesTitlesPageBody';
import { useSharesTitles } from './hooks/useShareTitles';

function TitleRegistryBook() {
  const { societyId } = useParams();

  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const sharesTitles = useSelector((state: State) => state.sharesTitles);

  useGetMenu();
  useGetActualSociety(societyId);

  const { isLoading } = useSharesTitles();

  return isLoading ? (
    <CustomLoading />
  ) : (
    <Page>
      <PageListHeader
        subTitle={t('TitleRegistryBook')}
        description={t('SocietyLastSharesTitleNumber', {
          number: getLastTitleNumber(actualSociety, sharesTitles),
        })}
        actions={[<GoBackButton />]}
      />

      <SharesTitlePageBody sharesTitles={sharesTitles} />
    </Page>
  );
}

export default TitleRegistryBook;
