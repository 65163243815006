import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { SharesTitle } from 'types';

import NoItemsAvailable from 'modules/_shared/components/EmptyStates/NoItemsAvailable';
import SharesTitlesTableRow from './SharesTitlesTableRow';

type Props = {
  sharesTitles: SharesTitle[];
};

const SharesTitleTableBody: FC<Props> = ({ sharesTitles }) => {
  const { t } = useTranslate();

  const hasData = sharesTitles?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          sharesTitles
            ?.sort((a: SharesTitle, b: SharesTitle) => b.number - a.number)
            ?.map((sharesTitle: SharesTitle, index: number) => (
              <tr className="nk-tb-item" key={`title-row-${sharesTitle?._id}`}>
                <SharesTitlesTableRow index={index} title={sharesTitle} />
              </tr>
            ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoTitlesMessage')} />
        )}
      </tbody>
    </>
  );
};

export default SharesTitleTableBody;
