/* eslint-disable no-nested-ternary */
import { HoldingClass, Partner } from 'types';
import getRowStyle from '../utils/getRowStyle';
import DrawCell from './DrawCell';
import DrawEmptyColumns from './DrawEmptyColumns';
import { ClassesTableRow } from '../types/ClassesTableRow';

/* eslint-disable react/no-array-index-key */
type ClassesTableRowsAdditionalsProps = {
  aditionalRows: ClassesTableRow[];
  currentPartners: Partner[];
  holdingClasses: HoldingClass[];
  text: boolean;
  inversionView: boolean;
  investorsView: boolean;
  hasNDValueColumn: boolean;
  hasNDPercentColumn: boolean;
  hasFDValueColumn: boolean;
  hasFDPercentColumn: boolean;
  hasPFDValueColumn: boolean;
  hasPFDPercentColumn: boolean;
};

const ClassesTableRowsAdditionals = ({
  aditionalRows,
  currentPartners,
  holdingClasses,
  text,
  inversionView,
  investorsView,
  hasNDValueColumn,
  hasNDPercentColumn,
  hasFDValueColumn,
  hasFDPercentColumn,
  hasPFDValueColumn,
  hasPFDPercentColumn,
}: ClassesTableRowsAdditionalsProps) => (
  <>
    {aditionalRows?.map((row, index) => (
      <tr
        key={`${row.text}-${index}`}
        className={
          (currentPartners.length + index) % 2 === 0
            ? 'text-center'
            : 'bg-light-vert text-center'
        }
      >
        <td style={getRowStyle(1, 1)}>{row.text}</td>

        {investorsView ? (
          <DrawEmptyColumns
            columnsCount={holdingClasses.length}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            previousColumnsLength={2}
          />
        ) : (
          <DrawEmptyColumns
            columnsCount={holdingClasses.length + 1}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            previousColumnsLength={2}
          />
        )}

        {inversionView && (
          <DrawEmptyColumns
            columnsCount={3}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            previousColumnsLength={holdingClasses?.length + 1}
          />
        )}

        {investorsView && (
          <DrawCell
            columnIndex={0}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column1Value as string)}
            text={text}
            previousColumnsLength={1}
            suffix=""
            decimal
            keyText="columnvalues0"
          />
        )}

        {hasNDValueColumn && (
          <DrawCell
            columnIndex={0}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column2Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : holdingClasses?.length + 1
            }
            suffix=""
            decimal
            keyText="columnvalues0"
          />
        )}

        {hasNDPercentColumn && (
          <DrawCell
            columnIndex={1}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column3Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : investorsView
                ? holdingClasses?.length + 2
                : holdingClasses?.length + 1
            }
            suffix="%"
            decimal
            keyText="columnvalues1"
          />
        )}

        {hasFDValueColumn && (
          <DrawCell
            columnIndex={2}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column4Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : holdingClasses?.length + 1
            }
            suffix=""
            decimal
            keyText="columnvalues2"
          />
        )}

        {hasFDPercentColumn && (
          <DrawCell
            columnIndex={3}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column5Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : holdingClasses?.length + 1
            }
            suffix="%"
            decimal
            keyText="columnvalues3"
          />
        )}

        {hasPFDValueColumn && (
          <DrawCell
            columnIndex={4}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column6Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : holdingClasses?.length + 1
            }
            suffix=""
            decimal
            keyText="columnvalues4"
          />
        )}

        {hasPFDPercentColumn && (
          <DrawCell
            columnIndex={5}
            rowIndex={index}
            rowsCount={aditionalRows.length - 1}
            value={parseFloat(row.column7Value as string)}
            text={text}
            previousColumnsLength={
              inversionView
                ? holdingClasses?.length + 4
                : holdingClasses?.length + 1
            }
            suffix="%"
            decimal
            keyText="columnvalues5"
          />
        )}
      </tr>
    ))}
  </>
);

export default ClassesTableRowsAdditionals;
