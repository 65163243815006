import { axiosV1 } from 'interceptors/axios';
import { store } from 'redux/store';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from './token';

async function downloadSingleDocument({ documentId, documentName }) {
  const { data } = await axiosV1.get(`/download/${documentId}`);
  const decodedData = await decryptResponse(data);
  const url = decodedData?.url;

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = documentName;
      link.click();
      URL.revokeObjectURL(objectUrl);
    })
    .catch(() => {
      store.dispatch(addAlert(alertBodyTypes.ERROR_DOWNLOADING_DOCUMENT));
    });
}

export default async function downloadDocument({
  documentId = '',
  documentName = '',
  multiple = false,
  documents,
}) {
  if (multiple) {
    documents.forEach((document) => {
      downloadSingleDocument({
        documentId: document['_id'],
        documentName: document?.name,
      });
    });
  } else {
    downloadSingleDocument({ documentId, documentName });
  }
}

export async function getSingleDocument(documentId) {
  const { data } = await axiosV1.get(`/download/${documentId}`);
  const decodedData = await decryptResponse(data);
  const url = decodedData?.url;
  const result = fetch(url)
    .then((response) => response.blob())
    .then((blob) => blob)
    .catch(() => {
      store.dispatch(addAlert(alertBodyTypes.ERROR_DOWNLOADING_DOCUMENT));
    });
  return result;
}
