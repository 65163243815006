import { CouncilParticipant } from 'types';

export const getMemberIdFromCouncilParticipants = (
  email: string,
  participants: CouncilParticipant[]
): string => {
  if (!email || !participants?.length) {
    return '';
  }

  const currentParticipant = participants.find(
    (participant) => participant.email === email
  );

  return currentParticipant?.member || '';
};
