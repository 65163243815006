import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import menuTypes from 'constants/menuTypes';
import { setMenu } from 'modules/_shared/redux/menuActions';

type MenuType = string | null;

export const useGetMenu = (menuType: MenuType = menuTypes.EXTENDED) => {
  const dispatch = useDispatch();

  const society = useSelector((state: State) => state.society?.actualSociety);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuType,
        societyId: society?.['_id'],
        societyName: society?.name,
      })
    );
  }, [dispatch, society, menuType]);

  return {
    data: {},
    isLoading: false,
    isError: false,
    actions: {},
  };
};
