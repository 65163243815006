import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { Society } from 'types';
import { useTranslate } from 'hooks/useTranslate';

import EditButton from 'modules/_shared/components/Buttons/EditButton';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import EditCommentsModal from 'modules/_shared/components/Modals/EditComments';
import { useGetTransactionsSectionPermissions } from 'modules/transactions/hooks/useGetTransactionsSectionPermissions';

import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
  formatNumber,
} from 'utils/formats';
import { hasShowDetails } from 'utils/showDetails';
import { useTransactionDetails } from '../hooks/useTransactionDetails';

type TransactionDetailsHeaderProps = {
  transaction: any;
  actualSociety: Society;
};

const TransactionDetailsHeader: FC<TransactionDetailsHeaderProps> = ({
  transaction,
  actualSociety,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isReadOnly } = useGetTransactionsSectionPermissions().data;

  const {
    data: {
      notarialRegistration,
      transactionData,
      transactionOtherData,
      hasNotarialRegistrationSection,
    },
  } = useTransactionDetails(transaction, actualSociety);

  return (
    <>
      <PrintHeader title={t('PrintHeaderTransactionsList')} />

      <div className="invoice-brand text-center d-flex justify-content-between">
        <div className="user-avatar lg bg-primary">
          {actualSociety?.additional?.logo ? (
            <img src={actualSociety.additional.logo} alt="Logo" />
          ) : (
            <span>{actualSociety.name.slice(0, 2).toUpperCase()}</span>
          )}
        </div>
      </div>

      <div className="invoice-head row">
        <div className="invoice-contact col-4">
          <span className="overline-title">{t('TransactionType')}</span>

          <div className="invoice-contact-info">
            <h4 className="title">{transactionData.name}</h4>

            <ul className="list-plain">
              {transactionData.comments && (
                <li>
                  <span className="text-muted">
                    <em className="icon ni ni-notes-alt" />
                    {transactionData.comments}
                  </span>

                  <EditButton
                    className="ml-1 pb-1"
                    action={() =>
                      dispatch(
                        setModal(
                          <EditCommentsModal
                            operation={transaction}
                            hasNotarialRegistration={
                              hasNotarialRegistrationSection
                            }
                          />
                        )
                      )
                    }
                    disabled={isReadOnly}
                  />
                </li>
              )}
              <li>
                <span className="text-muted">
                  <em className="icon ni ni-calender-date" />
                  <span>{transactionData.date}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="invoice-contact col-4">
          {hasNotarialRegistrationSection && (
            <>
              <span className="overline-title">
                {t('NotarialRegistration')}
                <EditButton
                  className="ml-1 pb-1"
                  action={() =>
                    dispatch(
                      setModal(
                        <EditCommentsModal
                          operation={transaction}
                          hasNotarialRegistration={
                            hasNotarialRegistrationSection
                          }
                        />
                      )
                    )
                  }
                  disabled={isReadOnly}
                />
              </span>
              <ul className="list-plain mt-2">
                <li>
                  <em className="icon ni ni-user-list" />
                  <span>{notarialRegistration?.notaryName || '-'}</span>
                </li>
                <li>
                  <em className="icon ni ni-article" />
                  <span>{notarialRegistration?.protocolNr || '-'}</span>
                </li>
                <li>
                  <em className="icon ni ni-map-pin" />
                  <span>{notarialRegistration?.notaryCity || '-'}</span>
                </li>
              </ul>
            </>
          )}
        </div>

        {hasShowDetails() && (
          <div className="invoice-contact col-4">
            <span className="overline-title">{t('OtherData')}</span>

            <ul className="list-plain mt-2">
              <li>
                <em className="icon ni ni-money" />
                <span>
                  {t('SocialCapital')}:{' '}
                  {formatCurrencyDecimals(
                    transactionOtherData.socialCapital || '-'
                  )}
                </span>
              </li>
              <li>
                <em className="icon ni ni-article" />
                <span>
                  {t('NominalValue')}:{' '}
                  {formatCurrencyDecimalsLong(
                    transactionOtherData.nominalValue || '-'
                  )}
                </span>
              </li>
              <li>
                <em className="icon ni ni-map-pin" />
                <span>
                  {t('Shares')}:{' '}
                  {formatNumber(transactionOtherData.totalShares || '-')}
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(TransactionDetailsHeader);
