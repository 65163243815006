import { axiosV2 } from 'interceptors/axios';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';
import { Operation } from 'types';
import { reSetSociety } from 'modules/society/redux/societyActions';

export const addContribution =
  (operationData: Partial<Operation>) => async (dispatch: any) => {
    try {
      const body = { ...operationData };
      const { data } = await axiosV2.post(`/operations`, body);
      const decodedData = await decryptResponse(data);
      // dispatch({
      //   type: types.CREATE_COMMITTEE,
      //   committee: decodedData,
      // });
      dispatch(reSetSociety(decodedData));
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
