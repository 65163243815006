import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';

type Props = {
  societyId: string;
  uploadedDocument: Document;
};

export const updateSocietyWithDocument = async ({
  societyId,
  uploadedDocument,
}: Props) => {
  const societyData = {
    docs: { add: uploadedDocument },
  };

  await store.dispatch(updateSociety(societyId, societyData, false));
};
