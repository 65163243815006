import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import getDocumentBlob from 'modules/_shared/components/CKEditor/utils/getDocumentBlob';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { getDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';
import { Dividend, Operation, Partner, Society } from 'types';
import { downloadBlob } from 'utils/downloadBlob';

type GenerateTaxIncomeDocumentProps = {
  partner: Partner | null;
  society: Society;
  operation: Operation;
};

const COMPANY_PERCENT_RETENTION = 19;

export const generateTaxIncomeDocument = async ({
  partner,
  society,
  operation,
}: GenerateTaxIncomeDocumentProps) => {
  const templateId = society?.templates?.tax2024;
  if (!templateId) return;

  try {
    const template = await store.dispatch(getDocument(templateId));

    const totalAmount = operation.dividends.reduce(
      (acc, dividend) => acc + (dividend as Dividend).amount,
      0
    );
    const dividend = {
      totalShareholders: operation.dividends.length,
      totalAmount,
      totalNetAmount: totalAmount * (COMPANY_PERCENT_RETENTION / 100),
    };

    const documentUpdated = updateDocumentWithValues(
      template.editorModel,
      tags({
        partner,
        society,
        dividend,
        operation: { operationDate: new Date() },
      })
    );

    // This custom CSS is needed to match the 192-2024-GIP document layout
    const exportContent = `
    <html>
    <head>
        <style>
            @page {
                margin: 0;
            }
            body {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
                margin: 20px;
            }
            .ck-table-resized,
            .ck-editor__nested-editable {
                line-height: 0.88 !important
            }
            .ck-table-resized p {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
            .ck-content {
                background-color: #fedbbb !important
            }
        </style>
    </head>
    <body>
        ${documentUpdated.html}
    </body>
    </html>
    `;

    const templateBlob = await getDocumentBlob(exportContent);

    const formattedDate = new Date().toISOString().split('T')[0];
    const fileName = `${society?.name}_${formattedDate}_193-2024.pdf`;

    downloadBlob(templateBlob, fileName);
    store.dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
  }
};
