import { useEffect, useState } from 'react';
import { SharesTitle } from 'types';
import sharesTitlesStatus from '../constants/sharesTitlesStatus';

type Props = {
  sharesTitle: SharesTitle;
};

const getStatusData = (sharesTitle: SharesTitle) => {
  const defaultStatus = sharesTitlesStatus[0];

  const status = sharesTitlesStatus.find(
    (status) => status.value === sharesTitle?.status
  );

  const statusLabel = status ? status.label : defaultStatus.label;
  const statusBadge = status ? status.badge : defaultStatus.badge;

  return { statusLabel, statusBadge };
};

export default function useGetSharesTitleStatus({ sharesTitle }: Props) {
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [statusBadge, setStatusBadge] = useState<string>('');

  useEffect(() => {
    const { statusLabel, statusBadge } = getStatusData(sharesTitle);
    setStatusLabel(statusLabel);
    setStatusBadge(statusBadge);
  }, [sharesTitle]);

  return {
    data: { statusLabel, statusBadge },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
