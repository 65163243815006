import { EmailIssue, Partner } from 'types';
import { isGeneratedEmail } from './isGeneratedEmail';

const getGeneratedEmails = (partners: Partial<Partner>[]): EmailIssue[] => {
  const result: EmailIssue[] = [];

  partners.forEach((partner) => {
    const name = partner?.name || '';
    const email = partner?.email || '';

    const generatedEmail = isGeneratedEmail(email);
    if (generatedEmail) {
      result.push({ name, email });
    }
  });
  return result;
};

export default getGeneratedEmails;
