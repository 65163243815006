import { PartnerDividendDocumentData } from './getPartnerDataToDividendDocument';

export const generateDividendFileName = (
  dividendData: PartnerDividendDocumentData
): string => {
  const { partner, date } = dividendData;

  const partnerName = partner.name.replace(/\s/g, '_');
  const formattedDate = new Date(date).toISOString().split('T')[0];

  return `${partnerName}_${formattedDate}_dividendo.pdf`;
};
