import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  isSaved: boolean;
  handleClose: () => void;
  validExtraSigner: () => boolean;
  htmlContent?: string;
};

const LetterTabHeader = ({
  isSaved,
  handleClose,
  validExtraSigner,
  htmlContent,
}: Props) => {
  const { t } = useTranslate();

  const isValidExtraSigner = validExtraSigner();

  return (
    <Modal.Header>
      <div className="row">
        <h5 className="title">
          {t('LetterAndConditions')}{' '}
          {!htmlContent && (
            <span className="badge rounded-pill text-danger ml-3">
              {t('EmptyInvitationLetter')}
            </span>
          )}
          {!isValidExtraSigner && (
            <span className="badge rounded-pill text-danger ml-3">
              {t('InvalidSignatoryCompanyData')}
            </span>
          )}
          {!isSaved && (
            <span className="badge rounded-pill text-warning ml-3">
              {t('UnsavedChanges')}
            </span>
          )}
        </h5>
      </div>
      <a className="close cursor-pointer" onClick={handleClose}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default LetterTabHeader;
