import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Encumbrance } from 'types';

import ShareEncumbranceViewTableRow from './ShareEncumbranceViewTableRow';
import NoItemsAvailable from '../../../EmptyStates/NoItemsAvailable';

type Props = {
  encumbrances: Encumbrance[];
  isEditing: boolean;
  // eslint-disable-next-line no-unused-vars
  handleEditEncumbrance: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const ShareEncumbranceViewTableBody: FC<Props> = ({
  encumbrances,
  isEditing,
  handleEditEncumbrance,
  handleRemoveEncumbrance,
}) => {
  const { t } = useTranslate();

  const hasData = encumbrances?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          encumbrances?.map((encumbrance: Encumbrance, index: number) => (
            <ShareEncumbranceViewTableRow
              index={index}
              encumbrance={encumbrance}
              isEditing={isEditing}
              handleEditEncumbrance={handleEditEncumbrance}
              handleRemoveEncumbrance={handleRemoveEncumbrance}
            />
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoEncumbrancesMessage')} />
        )}
      </tbody>
    </>
  );
};

export default ShareEncumbranceViewTableBody;
