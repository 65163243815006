import { axiosV1, axiosV2 } from 'interceptors/axios';

import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import errors from 'modules/auth/pages/TryDemo/utils/errors';

import { identifyUser } from 'utils/logRocket';
import { addAlert } from '../../_shared/redux/alertActions';

export const actionTypes = {
  SET_USER: 'SET_USER',
};

function setUser(user) {
  return { type: actionTypes.SET_USER, user };
}

export const getUser = (id) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/users/${id}`);

    const decodedUserData = await decryptResponse(data);
    identifyUser(decodedUserData);

    dispatch(setUser(decodedUserData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getUsers = (usersList) => async (dispatch) => {
  const query = { params: { ...usersList } };
  try {
    const { data } = await axiosV1.get(`/users`, query);

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateUser =
  (userId, updateUserData, loggedUser, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: userId,
      ...updateUserData,
      loggedUser: loggedUser || userId,
    };
    try {
      const { data } = await axiosV1.put(`/users`, body);

      const decodedUserData = await decryptResponse(data);

      dispatch(setUser(decodedUserData));

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.USER_UPDATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_USER));
    }
  };

export const initAuthenticatedUser = (user) => async (dispatch) => {
  try {
    const { data } = await axiosV1.post(`/auth/initialize`, {
      email: user.email,
      authId: user.sub,
      metadata: {
        locale: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    });

    const decodedUserData = await decryptResponse(data);
    identifyUser(decodedUserData);

    const currentImage = decodedUserData?.image;

    if (currentImage === BLANK_PROFILE_PICTURE && user?.picture) {
      decodedUserData.image = user?.picture;
      dispatch(
        updateUser(
          decodedUserData['_id'],
          { image: user?.picture },
          decodedUserData['_id'],
          false
        )
      );
    }

    sessionStorage.setItem('sessionInit', 'true');
    window.history.replaceState({}, document.title, window.location.pathname);

    dispatch(setUser(decodedUserData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const logoutUser = (logout) => async (dispatch) => {
  try {
    await axiosV1.post(`/auth/logout`);

    dispatch(setUser(null));
    logout({ logoutParams: { returnTo: window.location.origin } });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const addUserDemo = (user) => async (dispatch) => {
  try {
    const { data } = await axiosV2.post(`/users`, {
      ...user,
      source: 'demo',
      isDemo: true,
    });

    const decodedUserData = await decryptResponse(data);
    identifyUser(decodedUserData);

    return decodedUserData;
  } catch (error) {
    if (error.response.status === 409) {
      const responseError = error.response.data.error;
      const currentError = errors[responseError] || errors.GENERIC_ERROR;
      throw new Error(currentError);
    }

    return dispatchError(error, dispatch);
  }
};

export const fetchIdentities = (email, society = '') =>
  axiosV1.post(`/identities`, {
    email,
    society,
  });
