import { FC } from 'react';

import ContributionModalHeader from './components/ContributionModalHeader';
import ContributionModalFooter from './components/ContributionModalFooter';
import ContributionModalBody from './components/ContributionModalBody';

import { useContributionModal } from './context/ContributionModalContext';

import '../Modals.scss';
import CustomLoading from '../../CustomLoading';

const ContributionModal: FC = () => {
  const { data } = useContributionModal();

  return (
    <>
      <ContributionModalHeader />
      <ContributionModalBody />
      <ContributionModalFooter />

      {data.isLoading && <CustomLoading />}
    </>
  );
};

export default ContributionModal;
