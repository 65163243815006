import { FC } from 'react';

import PartnersTableViewHeader from './PartnersTableViewHeader';
import PartnersTableViewBody from './PartersTableViewBody';

const PartnersTableView: FC = () => (
  <div className="card card-bordered">
    <PartnersTableViewHeader />

    <PartnersTableViewBody />
  </div>
);

export default PartnersTableView;
