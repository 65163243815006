import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';
import { useParams } from 'react-router-dom';

import {
  getGlobalNotificationsBySociety,
  getGlobalNotificationsByUser,
} from 'modules/_shared/redux/globalNotificationActions';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

export const useNotifications = () => {
  const { societyId } = useParams();

  const user = useSelector((state: State) => state.user);
  const userId = user?._id;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      if (societyId) {
        await store.dispatch(getGlobalNotificationsBySociety(societyId));
      } else {
        await store.dispatch(getGlobalNotificationsByUser(userId));
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId, userId]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  return { isLoading, isError };
};
