import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useChangeSharesTitleStatusModal } from '../context/ChangeSharesTitleStatusModalContext';

const ChangeSharesTitleStatusModalHeader: FC = () => {
  const { t } = useTranslate();
  const { actions } = useChangeSharesTitleStatusModal();

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-12">{t('ChangeStatus')}</h5>
        </div>
        <a className="close cursor-pointer" onClick={actions.handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default ChangeSharesTitleStatusModalHeader;
