import SendTestEmailModalHeader from './components/SendTestEmailModalHeader';
import SendTestEmailModalBody from './components/SendTestEmailModalBody';
import SendTestEmailModalFooter from './components/SendTestEmailModalFooter';
import CustomLoading from '../../CustomLoading';

import { useSendTestEmailModal } from './context/SendTestEmailModalContext';

import '../Modals.scss';

const SendTestEmail = () => {
  const { data } = useSendTestEmailModal();
  return (
    <>
      <SendTestEmailModalHeader />
      <SendTestEmailModalBody />
      <SendTestEmailModalFooter />

      {data.isLoading && <CustomLoading />}
    </>
  );
};

export default SendTestEmail;
