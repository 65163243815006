import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { currencyFormatDecimals } from 'constants/formats';
import { ContributionData } from '../types/ContributionData';

type Props = {
  partnerContributionData: ContributionData;
};
const ContributionTableRow: FC<Props> = ({
  partnerContributionData,
}: Props) => {
  const { partnerId, partnerName, ndPercent, toPerceive } =
    partnerContributionData;

  const numericCell = (value: number) => (
    <NumberFormat
      displayType="text"
      value={value}
      thousandSeparator={currencyFormatDecimals?.thousandSeparator}
      decimalSeparator={currencyFormatDecimals?.decimalSeparator}
      decimalScale={3}
      fixedDecimalScale={currencyFormatDecimals?.fixedDecimalScale}
    />
  );

  return (
    <>
      <td className="tb-tnx-info" key={`contribution-name-${partnerId}`}>
        <span className="title">{partnerName}</span>
      </td>

      <td className="tb-tnx-amount" key={`contribution-ndPercent-${partnerId}`}>
        <span className="amount">{numericCell(ndPercent)}%</span>
      </td>

      <td
        className="tb-tnx-amount"
        key={`contribution-toPerceive-${partnerId}`}
      >
        <span className="amount">{numericCell(toPerceive)}€</span>
      </td>
    </>
  );
};

export default ContributionTableRow;
