/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
import { format } from 'date-fns';
import percentRound from 'percent-round';
import { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { getPartnerBook } from 'modules/partners/redux/partnerBookActions';
import {
  getActualSociety,
  getDecimalScale,
  getSocietyValue,
} from 'utils/filters';
import { formatNumber } from 'utils/formats';

import DownloadPartnerBookModal from 'modules/partners/modals/DownloadPartnerBookModal';
import { DownloadPartnerBookModalProvider } from 'modules/partners/modals/DownloadPartnerBookModal/context/DownloadPartnerBookModalContext';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import ToggleWidthButton from 'modules/_shared/components/Buttons/ToggleWidthButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import transactionTypesRealNames from 'constants/transactionTypesRealNames';

import { useTranslate } from 'hooks/useTranslate';
import menuOptions from '../PartnerList/menuOptions';
import viewOptions from './utils/viewOptions';

import ClassesTable from './components/ClassesTable';
import CommercialRegisterTable from './components/CommercialRegisterTable';
import views from './constants/views';
import './PartnerBook.scss';
import PartnerBookTable from './components/PartnerBookTable';

function PartnerBook({
  updatedPartners,
  summaryRow,
  draftView,
  convertibleNoteView,
  capitalizedNote,
  buySellDraft,
}) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const buttons = {
    equity: 'equityBtn',
    comercial: 'comercialBtn',
    summary: 'summaryBtn',
    summaryAccumulated: 'summaryAccumulatedBtn',
    summaryInvestors: 'summaryInvestorsBtn',
  };

  const { isAdmin, isReadOnly } = useGetPartnersSectionPermissions().data;

  const user = useSelector((state) => state?.user);
  const partnerBook = useSelector((state) =>
    state?.partnerBook?.societyId === societyId ? state?.partnerBook : null
  );
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const plans = useSelector((state) => state?.plans);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const partners = useSelector(
    (state) => state?.society?.actualSociety?.partners
  );

  const [bookDates, setBookDates] = useState([]);
  const [bookColumns, setBookColumns] = useState([]);
  const [bookHeaders, setBookHeaders] = useState([]);
  const [bookComments, setBookComments] = useState([]);
  const [bookPartners, setBookPartners] = useState([]);
  const [bookOperations, setBookOperations] = useState([]);
  const [bookTotals, setBookTotals] = useState([]);
  const [bookTotalPartners, setBookTotalPartners] = useState();
  const [bookOrder, setBookOrder] = useState([]);
  const [bookDocuments, setBookDocuments] = useState([]);

  const [partnersRegister, setPartnersRegister] = useState();
  const [holdingClassNames, setHoldingClassNames] = useState();
  const [view, setView] = useState(
    isAdmin ? views.EQUITY_STORY : views.SUMMARY
  );
  const [filtredMenuOptions, setFiltredMenuOptions] = useState([]);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [currentViewOptions, setCurrentViewOptions] = useState(
    viewOptions(i18n)
  );

  const getButtonId = () => {
    const isCommercialView =
      view === views.COMMERCIAL_REGISTER &&
      partners?.length > 0 &&
      partnersRegister;

    const isSummaryView =
      view === views.SUMMARY &&
      partners.length > 0 &&
      plans &&
      holdingClasses.length > 0;

    const isSummaryAccumulatedView =
      view === views.SUMMARY_ACCUMULATED &&
      partners.length > 0 &&
      plans &&
      holdingClasses.length > 0;

    const isSummaryInvestorsView =
      view === views.SUMMARY_INVESTORS &&
      partners.length > 0 &&
      plans &&
      holdingClasses.length > 0;

    if (isCommercialView) {
      return buttons.comercial;
    }
    if (isSummaryView) {
      return buttons.summary;
    }
    if (isSummaryAccumulatedView) {
      return buttons.summaryAccumulated;
    }
    if (isSummaryInvestorsView) {
      return buttons.summaryInvestors;
    }

    return '';
  };

  const handleExportEquityStory = () => {
    if (partnerBook) {
      dispatch(
        setModal(
          <DownloadPartnerBookModalProvider>
            <DownloadPartnerBookModal />
          </DownloadPartnerBookModalProvider>
        )
      );
    }
  };

  const handleExportTable = () => {
    if (view === views.EQUITY_STORY) {
      handleExportEquityStory();
    } else {
      const buttonId = getButtonId();

      if (buttonId) {
        document.getElementById(buttonId).click();
      }
    }
  };

  function getHistoryTransactions(partnerId, operations) {
    if (!partnerId) return null;
    const history = operations.reduce((acc, op) => {
      const allTransactions = op.transactions
        .filter((transaction) => transaction.partner === partnerId)
        .map((transaction) => ({
          name: transactionTypesRealNames?.[transaction?.transactionType],
          range: `${formatNumber(transaction?.shareFrom)}-${formatNumber(
            transaction?.shareTo
          )}`,
          class: holdingClassNames?.[transaction?.shareClass],
        }));
      if (allTransactions.length) {
        const typeCondition =
          op.operationType === operationTypes.SELL_PARTICIPATION ||
          op.operationType === operationTypes.HERITAGE ||
          op.operationType === operationTypes.DONATION;

        if (typeCondition) {
          const sells = allTransactions.filter(
            (transaction) =>
              transaction.name ===
              transactionTypesRealNames[transactionTypes.SELL]
          );
          const buys = allTransactions.filter(
            (transaction) =>
              transaction.name ===
              transactionTypesRealNames[transactionTypes.BUY]
          );
          if (sells.length) {
            const transactionType =
              op.operationType === operationTypes.SELL_PARTICIPATION
                ? transactionTypes.SELL
                : op.operationType;

            acc.push({
              id: op._id,
              type: transactionType,
              date: format(new Date(op.date), 'dd.MM.yyyy'),
              transactions: sells,
              comments: op?.comments || '',
            });
          }
          if (buys.length) {
            const transactionType =
              op.operationType === operationTypes.SELL_PARTICIPATION
                ? transactionTypes.BUY
                : op.operationType;
            acc.push({
              id: op._id,
              type: transactionType,
              date: format(new Date(op.date), 'dd.MM.yyyy'),
              transactions: buys,
              comments: op?.comments || '',
            });
          }
        } else {
          acc.push({
            id: op._id,
            type: op.operationType,
            date: format(new Date(op.date), 'dd.MM.yyyy'),
            transactions: allTransactions,
            comments: op?.comments || '',
          });
        }
      }
      return acc;
    }, []);
    return history;
  }

  function getPartnersPercentages(partners, totalShares, decimalScale = 2) {
    const percentages = partners.map((partner) =>
      totalShares
        ? ((partner?.sharesCount?.actual || 0) * 100) / totalShares
        : 0
    );

    const roundPercents = percentRound(percentages, decimalScale);
    const result = partners.reduce((acc, partner, index) => {
      acc[partner['_id']] = roundPercents[index];
      return acc;
    }, {});
    return result;
  }

  function getPartnerShares(partnerId, society) {
    if (!partnerId) return null;
    const shares = society?.shares
      .filter((share) => share.isActive && share.partner === partnerId)
      .sort((a, b) => a.from - b.from);
    return shares;
  }

  const renderPartnerBookTable = () => {
    if (!partnerBook) return <CustomLoading />;

    return (
      <PartnerBookTable
        view={view}
        partnerBook={partnerBook}
        bookDates={bookDates}
        bookColumns={bookColumns}
        bookHeaders={bookHeaders}
        bookComments={bookComments}
        bookPartners={bookPartners}
        bookOperations={bookOperations}
        bookTotals={bookTotals}
        bookTotalPartners={bookTotalPartners}
        bookOrder={bookOrder}
        bookDocuments={bookDocuments}
        isForScreen
      />
    );
  };

  const renderCommercialRegisterTable = () => {
    if (!partnersRegister || !partners.length) return <CustomLoading />;

    return (
      <>
        <CommercialRegisterTable
          view={view}
          partnersRegister={partnersRegister}
          text
        />
        <CommercialRegisterTable
          view={view}
          partnersRegister={partnersRegister}
        />
      </>
    );
  };

  const renderClassesTable = () => {
    if (partners.length === 0 || !plans || holdingClasses.length === 0)
      return <CustomLoading />;

    return (
      <>
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
          text
        />
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
        />
      </>
    );
  };

  const renderClassesTableInversionTable = () => {
    if (partners.length === 0 || !plans || holdingClasses.length === 0)
      return <CustomLoading />;

    return (
      <>
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
          inversionView
          text
        />
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
          inversionView
        />
      </>
    );
  };

  const renderClassesTableInvestorsTable = () => {
    if (partners.length === 0 || !plans || holdingClasses.length === 0)
      return <CustomLoading />;
    return (
      <>
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
          investorsView
          text
        />
        <ClassesTable
          view={view}
          draftView={draftView}
          updatedPartners={updatedPartners}
          convertibleNoteView={convertibleNoteView}
          capitalizedNote={capitalizedNote}
          investorsView
        />
      </>
    );
  };

  useEffect(() => {
    if (draftView) {
      setView(views.SUMMARY);
    }
  }, [draftView]);

  useEffect(() => {
    if (societyId && !holdingClasses?.length) {
      dispatch(getHoldingClasses(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(
    () => {
      dispatch(
        setMenu({
          type: draftView ? menuTypes.EXTENDED : null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      );
    },
    [actualSociety, dispatch, draftView] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!actualSociety?.partners?.length) {
      setFiltredMenuOptions([currentMenuOptions[0]]);
    } else if (!actualSociety?.shares?.length) {
      setFiltredMenuOptions([currentMenuOptions[0], currentMenuOptions[1]]);
    } else {
      const options = currentMenuOptions.filter((option, index) => index !== 1);
      setFiltredMenuOptions(options);
    }
  }, [actualSociety, societyId, currentMenuOptions]);

  useEffect(() => {
    if (societyId) {
      if (draftView && !partnerBook) {
        dispatch(getPartnerBook(societyId));
      } else {
        dispatch(getPartnerBook(societyId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (partnerBook) {
      setBookDates(partnerBook.dates);
      setBookColumns(partnerBook.columns);
      setBookHeaders(partnerBook.headers);
      setBookComments(partnerBook.comments);
      setBookPartners(partnerBook.partners);
      setBookOperations(partnerBook.operations);
      setBookTotals(partnerBook.totals);
      setBookOrder(partnerBook.order);
      setBookTotalPartners(Object.keys(partnerBook).length);
      setBookDocuments(partnerBook.documents);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerBook]);

  useEffect(() => {
    if (partnerBook && updatedPartners?.length && !capitalizedNote) {
      let keysArray = Object.keys(partnerBook.operations);
      setBookOperations({
        ...partnerBook.operations,
        [keysArray.length]: buySellDraft
          ? 'SELL_PARTICIPATION'
          : 'CAPITAL_INCREASE',
        [keysArray.length + 1]: '',
        [keysArray.length + 2]: '',
      });

      keysArray = Object.keys(partnerBook.headers);
      setBookHeaders({
        ...partnerBook.headers,
        [keysArray.length]: buySellDraft
          ? 'SELL_PARTICIPATION_DRAFT'
          : 'CAPITAL_DECREASE_DRAFT',
        [keysArray.length + 1]: '',
        [keysArray.length + 2]: '',
      });

      keysArray = Object.keys(partnerBook.comments);
      setBookComments({
        ...partnerBook.comments,
        [keysArray.length]: '',
        [keysArray.length + 1]: '',
        [keysArray.length + 2]: '',
      });

      keysArray = Object.keys(partnerBook.dates);
      setBookDates({
        ...partnerBook.dates,
        [keysArray.length]: `${new Date().toDateString()}`,
        [keysArray.length + 1]: '',
        [keysArray.length + 2]: '',
      });
      const tempPartners = {};
      const newPartnerIds = [];
      Object.keys(partnerBook.partners).forEach((key) => {
        tempPartners[key] = [...partnerBook.partners[key]];
      });
      let partnerIndex = 0;
      updatedPartners.forEach((partner) => {
        if (
          partner?.societyPartnerId &&
          Object.keys(partnerBook.partners).includes(partner?.societyPartnerId)
        ) {
          const index = partner.societyPartnerId;
          keysArray = Object.keys(partnerBook.partners[index]);

          // Array for partner values
          const partnerValues = [
            {
              key: keysArray.length,
              value: buySellDraft ? partner.shares.initial : partner.shares.new,
            },
            {
              key: keysArray.length + 1,
              value: buySellDraft ? partner.shares.new : partner.shares.total,
            },
            {
              key: keysArray.length + 2,
              value: buySellDraft ? partner.shares.total : partner.NDTotal,
            },
          ];
          // Only have 4 values if its buySellDraft
          if (buySellDraft) {
            partnerValues.push({
              key: keysArray.length + 3,
              value: partner.NDTotal,
            });
          }

          // The condition its to avoid the sum of const values initials and totals
          partnerValues.forEach((prop) => {
            tempPartners[index][prop.key] =
              buySellDraft &&
              (prop.key === keysArray.length ||
                prop.key === keysArray.length + 2 ||
                prop.key === keysArray.length + 3)
                ? prop.value
                : (tempPartners[index][prop.key] ?? 0) + prop.value;
          });
        } else if (buySellDraft) {
          const partnerId = partner.societyPartnerId;
          const existPartner = tempPartners[partnerId];

          if (!existPartner) {
            tempPartners[partnerId] = [];
          }
          // Put 0 in all previous values of the new partner
          Object.keys(partnerBook.columns).forEach((col, index) => {
            if (index === 0) {
              tempPartners[partnerId][index] = partner.name;
            } else {
              tempPartners[partnerId][index] = 0;
            }
          });
          if (!existPartner) {
            tempPartners[partnerId][keysArray.length] = partner.shares.initial;
            tempPartners[partnerId][keysArray.length + 1] = partner.shares.new;
            tempPartners[partnerId][keysArray.length + 2] =
              partner.shares.total;
            tempPartners[partnerId][keysArray.length + 3] = partner.NDTotal;
          } else {
            tempPartners[partnerId][keysArray.length + 1] += partner.shares.new;
          }
        } else {
          tempPartners[partnerIndex] = [];
          Object.keys(partnerBook.columns).forEach((col, index) => {
            if (index === 0) {
              tempPartners[partnerIndex][index] = partner.name;
            } else {
              tempPartners[partnerIndex][index] = 0;
            }
          });
          tempPartners[partnerIndex][keysArray.length] = partner.shares.new;
          tempPartners[partnerIndex][keysArray.length + 1] =
            partner.shares.total;
          tempPartners[partnerIndex][keysArray.length + 2] = partner.NDTotal;
          newPartnerIds.push(partnerIndex);
          partnerIndex += 1;
        }
      });

      setBookTotalPartners(Object.keys(tempPartners).length);

      const oldPartners = [];
      const newPartners = [];
      // Loop to separate old partners and new partners
      Object.entries(tempPartners).forEach(([id, data]) => {
        if (newPartnerIds.includes(+id)) {
          newPartners.push({ id, data });
        } else oldPartners.push({ id, data });
      });
      // Set new partners first then ald partners
      setBookPartners([...oldPartners, ...newPartners]);

      const newColumns = {};
      // Array for column values
      const columnValues = [
        {
          key: keysArray.length,
          value: buySellDraft ? 'Origen' : 'Nuevas',
        },
        {
          key: keysArray.length + 1,
          value: buySellDraft ? 'Post C-V' : 'Total',
        },
        {
          key: keysArray.length + 2,
          value: buySellDraft ? 'Total' : '%',
        },
      ];
      // Only have 4 values if its buySellDraft
      if (buySellDraft) {
        columnValues.push({
          key: keysArray.length + 3,
          value: '%',
        });
      }

      columnValues.forEach((prop) => {
        newColumns[prop.key] = prop.value;
      });

      keysArray = Object.keys(partnerBook.columns);
      setBookColumns({
        ...partnerBook.columns,
        ...newColumns,
      });

      keysArray = Object.keys(partnerBook.totals);

      const newTotals = {};
      // Array for total values
      const totalValues = [
        {
          key: keysArray.length,
          value: buySellDraft
            ? summaryRow?.totalSharesInitial
            : summaryRow?.totalSharesNew,
        },
        {
          key: keysArray.length + 1,
          value: buySellDraft
            ? summaryRow?.totalSharesNew
            : summaryRow?.totalSharesTotal,
        },
        {
          key: keysArray.length + 2,
          value: buySellDraft ? summaryRow?.totalSharesTotal : 100,
        },
      ];
      // Only have 4 values if its buySellDraft
      if (buySellDraft) {
        totalValues.push({
          key: keysArray.length + 3,
          value: 100,
        });
      }

      totalValues.forEach((prop) => {
        newTotals[prop.key] = prop.value;
      });

      setBookTotals({
        ...partnerBook.totals,
        ...newTotals,
      });

      keysArray = Object.keys(partnerBook.order);
      const newOrders = {
        ...partnerBook.order,
        [keysArray.length]: partnerBook.order[keysArray.length - 1] + 1,
        [keysArray.length + 1]: partnerBook.order[keysArray.length - 1] + 1,
        [keysArray.length + 2]: partnerBook.order[keysArray.length - 1] + 1,
      };

      if (buySellDraft) {
        newOrders[keysArray.length + 3] =
          partnerBook.order[keysArray.length - 1] + 1;
      }
      setBookOrder(newOrders);
    } else if (partnerBook && !updatedPartners?.length) {
      const partners = [];
      Object.entries(partnerBook.partners).forEach(([id, data]) => {
        partners.push({ id, data });
      });
      setBookDates(partnerBook.dates);
      setBookColumns(partnerBook.columns);
      setBookHeaders(partnerBook.headers);
      setBookComments(partnerBook.comments);
      setBookPartners(partners);
      setBookOperations(partnerBook.operations);
      setBookTotals(partnerBook.totals);
      setBookOrder(partnerBook.order);
      setBookTotalPartners(Object.keys(partnerBook).length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerBook, updatedPartners, summaryRow]);

  useEffect(() => {
    if (holdingClasses?.length) {
      const names = holdingClasses?.reduce((acc, curr) => {
        acc[curr?.['_id']] = curr?.name;
        return acc;
      }, {});
      setHoldingClassNames(names);
    }
  }, [holdingClasses]);

  useEffect(() => {
    if (actualSociety) {
      const includedOps = [
        operationTypes.CONSTITUTION,
        operationTypes.CAPITAL_INCREASE,
        operationTypes.CAPITAL_DECREASE,
        operationTypes.SELL_PARTICIPATION,
        operationTypes.HERITAGE,
        operationTypes.DONATION,
        operationTypes.SPLIT,
        operationTypes.RENUMERATION,
      ];
      const operations = actualSociety?.operations.filter((op) =>
        includedOps.includes(op.operationType)
      );
      const activePartners = actualSociety?.partners.filter(
        (partner) => partner?.sharesCount?.actual > 0
      );

      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;

      const percentages = getPartnersPercentages(
        activePartners,
        actualSociety?.sharesCount?.actual || 0,
        decimalScale
      );

      const partnersData = activePartners.reduce((acc, partner) => {
        acc[partner['_id']] = {
          percentage: percentages[partner['_id']],
          history: getHistoryTransactions(partner['_id'], operations),
          shares: getPartnerShares(partner['_id'], actualSociety),
        };
        return acc;
      }, {});
      setPartnersRegister(partnersData);
    }
  }, [actualSociety]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    setCurrentViewOptions(
      !draftView
        ? viewOptions(i18n)
        : viewOptions(i18n).filter((option) => option.name !== 'commercial')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return actualSociety ? (
    <>
      {/* <div className={!draft ? 'nk-content-body' : 'd-none'}> */}
      <div className="nk-content-body mb-4">
        <div className="nk-content-wrap">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {draftView ? t('Draft') : actualSociety?.name}
              </h3>
              <p />
            </div>
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('PartnerBook')}
                </h3>
              </div>
              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li>
                        <GoBackButton
                          className={
                            !draftView
                              ? 'btn btn-white btn-outline-light'
                              : 'd-none'
                          }
                        />
                      </li>
                      {isAdmin && (
                        <>
                          <li>
                            <ReactHTMLTableToExcel
                              id="comercialBtn"
                              className="d-none"
                              table="partnersRegisterTable"
                              filename={`${t('RegisterBook')} - ${
                                actualSociety?.name
                              }`}
                              sheet="Libro Registro de Socios"
                              buttonText={`${t('Download')}`}
                              buttonComponent={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <button
                                  type="button"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-file-download" />
                                </button>
                              }
                            />
                            <ReactHTMLTableToExcel
                              id="summaryBtn"
                              className="d-none"
                              table="partnerClassTable"
                              filename={`${t('Summary')} - ${
                                actualSociety?.name
                              }`}
                              sheet="Libro Registro de Socios"
                              buttonText={`${t('Download')}`}
                              buttonComponent={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <button
                                  type="button"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-file-download" />
                                </button>
                              }
                            />
                            <ReactHTMLTableToExcel
                              id="summaryAccumulatedBtn"
                              className="d-none"
                              table="partnerClassTableInversion"
                              filename={`${t('Summary')} - ${
                                actualSociety?.name
                              }`}
                              sheet="Libro Registro de Socios"
                              buttonText={`${t('Download')}`}
                              buttonComponent={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <button
                                  type="button"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-file-download" />
                                </button>
                              }
                            />
                            <ReactHTMLTableToExcel
                              id="summaryInvestorsBtn"
                              className="d-none"
                              table="partnerClassTableInvestors"
                              filename={`${t('SummaryInvestors')} - ${
                                actualSociety?.name
                              }`}
                              sheet="Libro Registro de Socios"
                              buttonText={`${t('Download')}`}
                              buttonComponent={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <button
                                  type="button"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-file-download" />
                                </button>
                              }
                            />
                          </li>

                          <li
                            className={!draftView ? 'preview-item' : 'd-none'}
                          >
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleExportTable}
                            >
                              {`${t('Download')} ${t('Table')}`}
                            </button>
                            {/* <OperationMenuDots
                          title={t('Download')}
                          menuOptions={currentDownloadOptions}
                          params={{
                            handleExportTable,
                            buttons,
                          }}
                        /> */}
                          </li>
                          <li className="preview-item">
                            <OperationMenuDots
                              title={t('View')}
                              menuOptions={currentViewOptions}
                              params={{
                                view,
                                setView,
                                outlined: true,
                              }}
                            />
                          </li>

                          <li
                            className={!draftView ? 'preview-item' : 'd-none'}
                          >
                            <OperationMenuDots
                              menuOptions={filtredMenuOptions}
                              handleModal={(Modal) =>
                                dispatch(
                                  setModal(
                                    <Modal
                                      user={user}
                                      societyId={actualSociety['_id']}
                                    />
                                  )
                                )
                              }
                              disabled={isReadOnly}
                              params={{
                                society: actualSociety,
                                user: user['_id'],
                              }}
                            />
                          </li>
                        </>
                      )}
                      <li className={!draftView ? 'preview-item' : 'd-none'}>
                        <ToggleWidthButton />
                      </li>
                    </ul>

                    <div className="nk-block nk-block-lg">
                      <div className="nk-block-head" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PARTNER BOOK */}
        {view === views.EQUITY_STORY && renderPartnerBookTable()}

        {/* COMMERCIAL REGISTER */}
        {view === views.COMMERCIAL_REGISTER && renderCommercialRegisterTable()}

        {/* CLASSES TABLE */}
        {view === views.SUMMARY && renderClassesTable()}

        {/* CLASSES TABLE INVERSION */}
        {view === views.SUMMARY_ACCUMULATED &&
          renderClassesTableInversionTable()}

        {/* CLASSES TABLE INVESTORS */}
        {view === views.SUMMARY_INVESTORS && renderClassesTableInvestorsTable()}
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default PartnerBook;
