import operationTypes from 'constants/operationTypes';
import { Operation, Partner, Society } from 'types';

type Props = {
  partner: Partner;
  society: Society;
  operationType: (typeof operationTypes)[keyof typeof operationTypes];
};

export const getPartnerOperationsFromType = ({
  partner,
  society,
  operationType,
}: Props) => {
  const partnerOperations: Operation[] = [];
  const partnerOperationsIds = partner?.operations || [];

  society?.operations?.forEach((operation: Operation) => {
    const isOperationFromPartner =
      operation?._id && partnerOperationsIds?.includes(operation?._id);

    if (operation.operationType === operationType && isOperationFromPartner) {
      partnerOperations.push(operation);
    }
  });

  return partnerOperations;
};
