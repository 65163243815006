import { Tooltip } from 'react-tooltip';

import UsersArray from 'modules/profile/components/UsersArray';
import { useTranslate } from 'hooks/useTranslate';
import { Communication } from 'types';
import { getDateTime, getRecipientsText } from '../../utils';

type InboxDetailsCommunicationHeaderProps = {
  communication: Communication;
  actions: any;
  parsedDoc: any;
};

const InboxDetailsCommunicationHeader = ({
  communication,
  actions,
  parsedDoc,
}: InboxDetailsCommunicationHeaderProps) => {
  const { t } = useTranslate();

  return (
    <div className="nk-reply-header nk-ibx-reply-header is-shown">
      <div className="nk-reply-desc">
        <div className="ml-1">
          {/* @ts-ignore */}
          <UsersArray
            users={communication?.recipients}
            show={1}
            size="w-40px"
            bgColor="bg-primary"
          />
        </div>

        <div className="nk-reply-info">
          <div className="nk-reply-author lead-text">
            {communication?.sender?.email}
            <span className="date d-sm-none">
              {getDateTime(communication?.date)}
            </span>
          </div>
          <div className="dropdown nk-reply-msg-info">
            <a
              id="tooltip-recipients"
              className="dropdown-toggle sub-text dropdown-indicator"
              data-toggle="dropdown"
            >
              {t('EmailTo')}{' '}
              {getRecipientsText(
                communication?.recipients,
                communication?.presetGroup
              )}
            </a>
            <Tooltip anchorId="tooltip-recipients" place="bottom">
              <div>
                <ul>{actions.getRecipientsList(communication?.recipients)}</ul>
              </div>
            </Tooltip>
          </div>
          <div className="nk-reply-msg-excerpt">{parsedDoc || ''}</div>
        </div>
      </div>

      <ul className="nk-reply-tools g-1">
        {communication?.attachments?.length > 0 && (
          <li className="attach-msg">
            <em className="icon ni ni-clip-h" />
          </li>
        )}
        <li className="date-msg">
          <span className="date">{getDateTime(communication?.date)}</span>
        </li>
      </ul>
    </div>
  );
};

export default InboxDetailsCommunicationHeader;
