/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
import { useTranslate } from 'hooks/useTranslate';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  getActualSociety,
  getDecimalScale,
  getSocietyValue,
  getUsedSharesFromPlan,
} from 'utils/filters';
import menuTypes from 'constants/menuTypes';
import { getTenderOffers } from 'modules/transactions/redux/tenderOfferActions';

import '../PartnerBook.scss';

import views from '../constants/views';
import getUnitsAndPercents from '../utils/getUnitsAndPercents';
import { getDataFromDraft } from '../utils/getDataFromDraft';
import { getClassesTableRows } from '../utils/getClassesTableRows';
import { getClassesTableHeaders } from '../utils/getClassesTableHeaders';
import { getInvestorsTotalUnits } from '../utils/getInvestorsTotalUnits';

import ClassesTableHeaders from './ClassesTableHeaders';
import ClassesTableFirstColumn from './ClassesTableFirstColumn';
import ClassesTableRowsPartners from './ClassesTableRowsPartners';
import ClassesTableRowsAdditionals from './ClassesTableRowsAdditionals';
import ClassesTableRowsTotals from './ClassesTableRowsTotals';

/**
 * Tabla -> Vista Resumen | Vista Resumen Acumulado | Vista Resumen Inversores
 */
function ClassesTable({
  view,
  updatedPartners,
  draftView,
  text = false,
  inversionView = false,
  investorsView = false,
}) {
  const { t } = useTranslate();

  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const plans = useSelector((state) => state?.plans);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const partners = useSelector((state) =>
    state?.society?.actualSociety?.partners.filter(
      (partner) => partner?.sharesCount?.actual > 0
    )
  );
  const allPartners = useSelector(
    (state) => state?.society?.actualSociety?.partners
  );
  const shares = useSelector((state) => state?.society?.actualSociety?.shares);
  const tenderOffers = useSelector((state) => state?.tenderOffers);
  const investors = useSelector(
    (state) => state?.society?.actualSociety?.investors
  );
  const drafts = useSelector((state) => state?.drafts);

  const [decimalScale, setDecimalScale] = useState(2);

  const [plansTotalShares, setPlansTotalShares] = useState({
    assignedShares: 0,
    sharesTotal: 0,
  });

  const [plansTypeShares, setPlansTypeShares] = useState({
    stockAssignedShares: 0,
    stockUnassignedShares: 0,
    stockTotalShares: 0,
    phantomTotalShares: 0,
    phantomUnassignedShares: 0,
    phantomAssignedShares: 0,
  });

  const [tableRows, setTableRows] = useState([]);
  const [currentTableRows, setCurrentTableRows] = useState([]);
  const [partnersShares, setPartnersShares] = useState([]);
  const [classesShares, setClassesShares] = useState([]);
  const [totalCN, setTotalCN] = useState(0);
  const [newCN] = useState(0);
  const [newShares, setNewShares] = useState(0);
  const [currentPartners, setCurrentPartners] = useState(partners);
  const societyShares =
    actualSociety.sharesCount?.actual +
    (actualSociety.sharesCount?.future || 0) +
    (actualSociety.sharesCount?.drafts || 0);

  const societySharesActual = actualSociety.sharesCount?.actual;

  const {
    totalSharesColumnHeader,
    aditionalColumnsHeaders,
    inversionColumnsHeaders,
  } = getClassesTableHeaders({ t, investorsView });

  const {
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
    inForceCNShares,
    totalShares,
    totalNDShares,
    totalNDPercent,
    totalFDShares,
    totalFDPercent,
    totalPFDShares,
    totalPFDPercent,
  } = getUnitsAndPercents({
    plansTypeShares,
    societyShares,
    newShares,
    newCN,
    totalCN,
    societySharesActual,
    plansTotalShares,
    draftView,
  });

  const { aditionalRowHeaders, totalsRowHeaders, aditionalRows, totalsRows } =
    getClassesTableRows({
      decimalScale,
      totalShares,
      totalNDShares,
      totalNDPercent,
      totalFDShares,
      totalFDPercent,
      totalPFDShares,
      totalPFDPercent,
      stockAssignedShares,
      stockUnassignedShares,
      phantomAssignedShares,
      phantomUnassignedShares,
      inForceCNShares,
      investorsView,
    });

  const isSummaryView = () =>
    [
      views.SUMMARY,
      views.SUMMARY_ACCUMULATED,
      views.SUMMARY_INVESTORS,
    ].includes(view);

  const hasNDValueColumn = isSummaryView() && !investorsView;
  const hasNDPercentColumn = isSummaryView();
  const hasFDValueColumn = isSummaryView() && !investorsView;
  const hasFDPercentColumn = isSummaryView() && !investorsView;
  const hasPFDValueColumn = isSummaryView() && !investorsView;
  const hasPFDPercentColumn = isSummaryView();

  const getWrapperStyle = () => {
    if (isSummaryView() && text) {
      return 'nk-content-body pt-0';
    }
    return 'd-none';
  };

  const getTableId = () => {
    if (text) return 'partnerClassTableText';
    if (inversionView) return 'partnerClassTableInversion';
    if (investorsView) return 'partnerClassTableInvestors';

    return 'partnerClassTable';
  };

  useEffect(() => {
    if (actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const currentDecimals = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;
      setDecimalScale(currentDecimals);
    }
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: draftView ? menuTypes.EXTENDED : null,
        societyId: actualSociety?.['_id'] || null,
        societyName: actualSociety?.name,
      })
    );
  }, [actualSociety, dispatch, draftView]);

  useEffect(() => {
    if (shares) {
      const rows = shares.reduce((acc, cur) => {
        if (cur.isActive) {
          const key = `${cur.partner}-${cur.shareClass?.['_id']}`;
          if (key in acc) {
            acc[key] += cur.to - cur.from + 1;
          } else {
            acc[key] = cur.to - cur.from + 1;
          }
        }
        return acc;
      }, {});

      setTableRows(rows);
      setCurrentTableRows(rows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shares]);

  useEffect(() => {
    if (currentTableRows) {
      const { totalSharesPerPartner, totalSharesPerClass } = Object.entries(
        currentTableRows
      ).reduce(
        (acc, [key, value]) => {
          const [partnerId, shareClassId] = key.split('-');

          if (!acc.totalSharesPerPartner[partnerId]) {
            acc.totalSharesPerPartner[partnerId] = value;
          } else {
            acc.totalSharesPerPartner[partnerId] += value;
          }
          if (!acc.totalSharesPerClass[shareClassId]) {
            acc.totalSharesPerClass[shareClassId] = value;
          } else {
            acc.totalSharesPerClass[shareClassId] += value;
          }

          return acc;
        },
        { totalSharesPerPartner: {}, totalSharesPerClass: {} }
      );

      setPartnersShares(totalSharesPerPartner);
      setClassesShares(totalSharesPerClass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTableRows]);

  useEffect(() => {
    if (plans.length && actualSociety) {
      const result = plans
        .filter((plan) => plan.society === societyId && !plan.isDraft)
        .reduce(
          (acc, plan) => {
            const assigned =
              getUsedSharesFromPlan(plan, actualSociety, tenderOffers) || 0;
            const total = plan?.sharesTotal || 0;
            const unassigned = total - assigned || 0;

            if (
              plan.planType === 'STOCK_OPTION' ||
              plan.planType === 'WARRANTS'
            ) {
              acc.stockTotalShares += total;
              acc.stockAssignedShares += assigned;
              acc.stockUnassignedShares += unassigned;
            }

            if (plan.planType === 'PHANTOM_SHARE') {
              acc.phantomTotalShares += total;
              acc.phantomAssignedShares += assigned;
              acc.phantomUnassignedShares += unassigned;
            }
            return acc;
          },
          {
            stockAssignedShares: 0,
            stockUnassignedShares: 0,
            stockTotalShares: 0,
            phantomTotalShares: 0,
            phantomUnassignedShares: 0,
            phantomAssignedShares: 0,
          }
        );
      setPlansTypeShares(result);
      setPlansTotalShares(
        plans
          .filter((plan) => !plan.isDraft && plan.society === societyId)
          .reduce(
            (acc, plan) => ({
              assignedShares:
                acc.assignedShares +
                getUsedSharesFromPlan(plan, actualSociety, tenderOffers),
              sharesTotal: acc.sharesTotal + plan?.sharesTotal || 0,
            }),
            { assignedShares: 0, sharesTotal: 0 }
          )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, actualSociety, tenderOffers]);

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
      dispatch(getTenderOffers(societyId));
    }
  }, [dispatch, societyId]);

  useEffect(() => {
    if (investors?.length) {
      const totalUnitsCN = getInvestorsTotalUnits(
        investors,
        drafts,
        actualSociety
      );
      setTotalCN(totalUnitsCN);
    } else {
      setTotalCN(0);
    }
  }, [investors, drafts, actualSociety]);

  useEffect(() => {
    if (updatedPartners?.length && tableRows) {
      const { newRows, newPartners, newSharesTotal } = getDataFromDraft({
        tableRows,
        partners,
        allPartners,
        updatedPartners,
      });
      setNewShares(newSharesTotal);

      // const partnersWithShares = newPartners?.filter(
      //   (partner) => partner?.sharesCount?.actual > 0
      // );
      setCurrentPartners(newPartners);
      setCurrentTableRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPartners, tableRows]);

  return (
    <div className={getWrapperStyle()}>
      <div className="nk-content-wrap">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="table-responsive">
              <table
                id="partnerClassTableMainColumn"
                className="table table-striped text-right cloned pos-abs bg-white border-right partner-book-table"
              >
                <tr className="text-center table-header">
                  <th className="w-200px bg-light"> {t('Partners')} &nbsp;</th>
                </tr>

                <ClassesTableFirstColumn
                  currentPartners={currentPartners}
                  totalsRowHeaders={totalsRowHeaders}
                  aditionalRowHeaders={aditionalRowHeaders}
                />
              </table>

              <table
                className="table table-striped text-right border-right partner-book-table"
                id={getTableId()}
                style={{ width: '100%' }}
              >
                <ClassesTableHeaders
                  holdingClasses={holdingClasses}
                  totalSharesColumnHeader={totalSharesColumnHeader}
                  aditionalColumnsHeaders={aditionalColumnsHeaders}
                  inversionColumnsHeaders={inversionColumnsHeaders}
                  inversionView={inversionView}
                />

                <ClassesTableRowsPartners
                  currentPartners={currentPartners}
                  holdingClasses={holdingClasses}
                  currentTableRows={currentTableRows}
                  partnersShares={partnersShares}
                  inversionView={inversionView}
                  investorsView={investorsView}
                  hasNDValueColumn={hasNDValueColumn}
                  hasNDPercentColumn={hasNDPercentColumn}
                  hasFDValueColumn={hasFDValueColumn}
                  hasFDPercentColumn={hasFDPercentColumn}
                  hasPFDValueColumn={hasPFDValueColumn}
                  hasPFDPercentColumn={hasPFDPercentColumn}
                  actualSociety={actualSociety}
                  plans={plans}
                  text={text}
                  decimalScale={decimalScale}
                />

                <ClassesTableRowsAdditionals
                  aditionalRows={aditionalRows}
                  holdingClasses={holdingClasses}
                  currentPartners={currentPartners}
                  inversionView={inversionView}
                  investorsView={investorsView}
                  hasNDValueColumn={hasNDValueColumn}
                  hasNDPercentColumn={hasNDPercentColumn}
                  hasFDValueColumn={hasFDValueColumn}
                  hasFDPercentColumn={hasFDPercentColumn}
                  hasPFDValueColumn={hasPFDValueColumn}
                  hasPFDPercentColumn={hasPFDPercentColumn}
                  text={text}
                />

                <ClassesTableRowsTotals
                  totalsRows={totalsRows}
                  currentPartners={currentPartners}
                  holdingClasses={holdingClasses}
                  classesShares={classesShares}
                  partnersShares={partnersShares}
                  inversionView={inversionView}
                  investorsView={investorsView}
                  hasNDValueColumn={hasNDValueColumn}
                  hasNDPercentColumn={hasNDPercentColumn}
                  hasFDValueColumn={hasFDValueColumn}
                  hasFDPercentColumn={hasFDPercentColumn}
                  hasPFDValueColumn={hasPFDValueColumn}
                  hasPFDPercentColumn={hasPFDPercentColumn}
                  actualSociety={actualSociety}
                  text={text}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassesTable;
