/* eslint-disable react/react-in-jsx-scope */
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';

import editBeneficiary from 'utils/editBeneficiary';
import cancelBeneficiary from 'utils/cancelBeneficiary';
import duplicateBeneficiary from 'utils/duplicateBeneficiary';
import createCertificate from 'utils/createCertificate';

import EditLetterStatusModal from 'modules/_shared/components/Modals/EditLetterStatus';
import EditBeneficiaryModal from 'modules/beneficiaries/modals/EditBeneficiary';
import removeBeneficiary from 'modules/beneficiaries/utils/removeBeneficiary';
import { EditLetterStatusModalProvider } from 'modules/_shared/components/Modals/EditLetterStatus/context/EditLetterStatusModalContext';

function beneficiaryOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Open'),
      url: '',
    },
    {
      icon: 'ni-file',
      text: i18n.t('Certificate'),
      action: createCertificate,
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditPlan'),
      action: editBeneficiary,
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditBeneficiary'),
      action: (beneficiary) => {
        store.dispatch(
          setModal(
            <EditBeneficiaryModal
              beneficiary={beneficiary.data}
              societyId={beneficiary.societyId}
            />
          )
        );
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      icon: 'ni-mail',
      text: i18n.t('EditLetterStatus'),
      action: (beneficiary) => {
        store.dispatch(
          setModal(
            <EditLetterStatusModalProvider beneficiary={beneficiary.data}>
              <EditLetterStatusModal />
            </EditLetterStatusModalProvider>
          )
        );
      },
      dontShowCurrent: ({ data, isReadOnly }) =>
        !data?.invitationStatus || isReadOnly,
    },
    {
      icon: 'ni-copy',
      text: i18n.t('Duplicate'),
      action: duplicateBeneficiary,
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      url: '',
    },
    {
      icon: 'ni-cross-c',
      text: i18n.t('Cancel'),
      action: cancelBeneficiary,
      dontShowCurrent: ({ data, isReadOnly }) => data?.isCanceled || isReadOnly,
    },
    {
      icon: 'ni-download',
      text: i18n.t('DownloadLetter'),
      download: true,
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: removeBeneficiary,
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
  ];
}
export default beneficiaryOptions;
