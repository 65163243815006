import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const SharesTitlesTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <thead>
      <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
        <th className="nk-tb-col w-30px">
          <span>{t('Nr')}</span>
        </th>
        <th className="nk-tb-col">
          <span>
            {t('Partner')} / {t('Partners')}
          </span>
        </th>
        <th className="nk-tb-col">
          <span>{t('Operation')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl text-right">
          <span>{t('NrPart')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl text-right">
          <span>{t('Numeration')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl text-center">
          <span>{t('createdAt')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl text-center">
          <span>{t('Status')}</span>
        </th>
        <th className="nk-tb-col w-40px" />
        <th className="nk-tb-col w-40px" />
      </tr>
    </thead>
  );
};

export default SharesTitlesTableHeader;
