import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useNotifyPartnersDividendsModal } from '../context/NotifyPartnersDividendsModalContext';

const PartnersTableViewHeader: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useNotifyPartnersDividendsModal();

  return (
    <table className="table mb-0">
      <thead>
        <tr className="tb-tnx-head">
          <th className="text-left" colSpan={2}>
            <span>{t('Partners')}</span>
          </th>
          <th className="col-2 text-right">
            <button
              type="button"
              className="badge btn-primary"
              onClick={actions.handleChangeView}
            >
              <em className={`icon ni ni-chevron-${data.expandDirection}`} />
            </button>
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default PartnersTableViewHeader;
