import { i18n } from 'i18next';
import { Council, Society, User } from 'types';
import { deleteCouncil } from 'modules/administratives/utils/deleteCouncil';

type MenuAction = {
  council: Council;
  user: User;
  actualSociety: Society;
  mixpanel: any;
};

type ShowCurrent = {
  isReadOnly: boolean;
};

const menuOptions = (i18n: i18n) => [
  {
    index: 1,
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: ({ council, user, actualSociety, mixpanel }: MenuAction) =>
      deleteCouncil({ council, user, actualSociety, mixpanel }),
    dontShowCurrent: ({ isReadOnly }: ShowCurrent) => isReadOnly,
  },
];

export default menuOptions;
