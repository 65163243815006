import documentTypes from 'constants/documentTypes';
import { Document, Operation } from 'types';

export const getDocumentsFromTransaction = (
  transaction: Operation,
  documents: Document[]
) => {
  if (!documents) return [];

  return documents?.filter(
    (document) =>
      document?.category !== documentTypes.DELETED &&
      (transaction?.documents as string[]).includes(document?.['_id'])
  );
};
