import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import InvalidEmailBadge from 'modules/_shared/components/InvalidEmailBadge';
import { useSendTestEmailModal } from '../context/SendTestEmailModalContext';

const SendTestEmailModalBody: FC = () => {
  const { t } = useTranslate();

  const { data, actions } = useSendTestEmailModal();

  return (
    <Modal.Body className="no-padding">
      <div className="nk-block nk-block-lg">
        <div className="card p-4">
          <div className="card-aside-wrap">
            <div className="card-content">
              <div className="card-inner p-0">
                <div className="row">
                  <div className="col-12">
                    <p className="text-secondary mb-4">
                      {t('SendTestEmailMessage')}
                    </p>
                  </div>
                  <div className="form-group col-12 mb-3">
                    <label className="form-label" htmlFor="recipient-email">
                      {t('Email')}
                    </label>

                    {data.invalidEmail && <InvalidEmailBadge />}

                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="recipient-email"
                        name="recipientEmail"
                        value={data.recipientEmail}
                        onChange={actions.handleChangeRecipientEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default SendTestEmailModalBody;
