import { useTranslate } from 'hooks/useTranslate';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { Operation, Partner } from 'types';
import { State } from 'redux/initialState';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getDocuments } from 'modules/documents/redux/documentActions';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';
import { getActualSociety } from 'utils/filters';

import Page from 'modules/_shared/components/Pages/Page';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import DocumentsListLoader from 'modules/documents/components/DocumentsListLoader';
import DividendDocumentModal from 'modules/transactions/components/DividendDocumentModal';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { generateSEPAFile } from 'modules/transactions/utils/bankSEPAGenerator';
import { generateTaxIncomeDocument } from 'modules/partners/components/PartnerAside/utils/generateTaxIncomeDocument';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import NotifyPartnersDividendsModal from 'modules/transactions/modals/NotifyPartnersDividendsModal';
import { NotifyPartnersDividendsModalProvider } from 'modules/transactions/modals/NotifyPartnersDividendsModal/context/NotifyPartnersDividendsModalContext';

import TransactionDetailsNavigationButtons from './components/TransactionDetailsNavigationButtons';
import TransactionDetailsView from './components/TransactionDetailsOperationTab';
import TransactionDocumentsView from './components/TransactionDetailsDocumentsTab';
import TransactionViewHeader from './components/TransactionDetailsHeader';

import './TransactionDetails.scss';
import { getDocumentTitle } from './utils/getDocumentTitle';
import { parsePartnersToSEPA } from './utils/parsePartnersToSEPA';
import { getOperationPartners } from './utils/getOperationPartners';
import documentsDropdownOptions from './constants/documentsDropdownOptions';

const TransactionDetails = () => {
  const { t } = useTranslate();

  const printRef = useRef(null);
  const dispatch = useDispatch();
  const { societyId, transactionId } = useParams();

  const [isGenerating, setIsGenerating] = useState(false);

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [actualTransaction, setActualTransaction] = useState<Operation>();
  const [transactionDocuments, setTransactionDocuments] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<ReactNode[]>([]);
  const [isDocumentReplaced, setIsDocumentReplaced] = useState(true);

  const loadInitialValues = async () => {
    getActualSociety(user, societyId);
  };

  const handleOpenDividendModal = () => {
    if (!actualTransaction) return;
    dispatch(setModal(<DividendDocumentModal operation={actualTransaction} />));
  };

  const handleNotifyPartners = () => {
    if (!actualTransaction?._id || !actualTransaction?.dividends) return;
    dispatch(
      setModal(
        <NotifyPartnersDividendsModalProvider
          dividends={actualTransaction?.dividends || []}
        >
          <NotifyPartnersDividendsModal />
        </NotifyPartnersDividendsModalProvider>
      )
    );
  };

  const handleGenerateTaxDeclaration = async () => {
    if (!actualSociety || !actualTransaction) return;

    setIsGenerating(true);
    try {
      await generateTaxIncomeDocument({
        partner: null,
        society: actualSociety,
        operation: actualTransaction,
      });
    } catch (error) {
      console.error('🚀 cclog ~ handleGenerateTaxDeclaration ~ error', error); // eslint-disable-line
    } finally {
      setIsGenerating(false);
    }
  };

  const handleGenerateBankDocument = async () => {
    if (!actualSociety || !actualTransaction) return;

    const operationPartners: Partner[] = getOperationPartners(
      actualTransaction,
      actualSociety
    );

    await generateSEPAFile({
      data: parsePartnersToSEPA(
        operationPartners,
        actualTransaction,
        actualSociety
      ) as [],
      society: actualSociety,
      operationType: actualTransaction.operationType,
    });
  };

  const handleNavigateTransaction = () => {
    setIsDocumentReplaced(true);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      loadInitialValues();
    }
  }, [actualSociety, user, societyId]); // eslint-disable-line

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
      dispatch(getHoldingClasses(actualSociety?.['_id']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    const currentTransaction = actualSociety?.operations.find(
      (operation: Operation) => operation['_id'] === transactionId
    );
    if (currentTransaction) {
      setActualTransaction(currentTransaction);
    }
  }, [actualSociety, transactionId]);

  useEffect(() => {
    if (transactionId && isDocumentReplaced) {
      dispatch(
        getDocuments(
          { operation: transactionId, society: societyId },
          setTransactionDocuments
        )
      );
      setIsDocumentReplaced(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, isDocumentReplaced]);

  useEffect(() => {
    if (actualTransaction && actualSociety) {
      const documentCount = actualTransaction?.documents?.length || 0;

      const viewPages = [
        <TransactionDetailsView
          operation={actualTransaction}
          actualSociety={actualSociety}
        />,
        transactionDocuments?.length > 0 ? (
          <TransactionDocumentsView
            documents={transactionDocuments}
            setIsDocumentReplaced={setIsDocumentReplaced}
          />
        ) : (
          documentCount > 0 && <DocumentsListLoader rowsCount={documentCount} />
        ),
      ];

      setPages(viewPages);
    }
  }, [actualTransaction, actualSociety, transactionDocuments]);

  return (
    <Page>
      <PageListHeader
        subTitle={t('Transaction')}
        subTitleSuffix={`#${transactionId}`}
        actions={[
          <TransactionDetailsNavigationButtons
            key="navigationButton"
            society={actualSociety}
            operationId={transactionId}
            onNavigate={handleNavigateTransaction}
          />,
          <GoBackButton key="backButton" />,
        ]}
      />

      <div className="nk-block">
        <div className="invoice">
          <div className="invoice-action d-flex align-items-center">
            {actualTransaction?.operationType ===
              operationTypes.DIVIDENDS_DISTRIBUTION && (
              <>
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={handleNotifyPartners}
                >
                  <em className="icon ni ni-send mr-2" />
                  {t('NotifyPartners')}
                </button>

                <div className="mr-2">
                  <OperationMenuDots
                    title={t('Download')}
                    menuOptions={documentsDropdownOptions}
                    params={{
                      hasSEPAOption:
                        actualSociety?.configuration?.hasSEPAOption,
                      hasTaxTemplate: actualSociety?.templates?.tax2024,
                      handleGenerateBankDocument,
                      handleGenerateTaxDeclaration,
                      handleOpenDividendModal,
                    }}
                    disabled={false}
                    handleModal={null}
                  />
                </div>
              </>
            )}

            <ReactToPrint
              trigger={() => (
                <a className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary">
                  <em className="icon ni ni-printer-fill" />
                </a>
              )}
              content={() => printRef.current || null}
              documentTitle={getDocumentTitle(actualSociety, actualTransaction)}
            />
          </div>

          <div className="invoice-wrap" ref={printRef}>
            <TransactionViewHeader
              transaction={actualTransaction}
              actualSociety={actualSociety}
            />

            <div className="pt-2">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <p
                    id="value-shares-tab"
                    className={`nav-link ${page === 0 ? 'active' : ''}`}
                    onClick={() => setPage(0)}
                  >
                    <em className="icon ni ni-list-index-fill" />
                    <span>{t('Details')}</span>
                  </p>
                </li>
                <li className="nav-item">
                  <p
                    id="certificate-template-tab"
                    className={`nav-link ${page === 1 ? 'active' : ''}`}
                    onClick={() => setPage(1)}
                  >
                    <em className="icon ni ni-file-docs" />
                    <span>{t('Scriptures')}</span>
                  </p>
                </li>
              </ul>
              {pages[page]}
            </div>
          </div>
        </div>
      </div>

      {(!actualTransaction || isGenerating) && <CustomLoading />}
    </Page>
  );
};

export default TransactionDetails;
