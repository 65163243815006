import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { store } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { setModal } from 'modules/_shared/redux/modalActions';

import { Council, Document } from 'types';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import errorSwal from 'utils/errorSwal';

import { addDocument } from 'modules/documents/redux/documentActions';
import { updateCouncil } from 'modules/administratives/redux/councilActions';

import successSwal from 'utils/successSwal';
import { updateSocietyWithDocument } from 'modules/administratives/utils/updateSocietyWithDocument';
import AddCouncilDocumentModalBody from './AddCouncilDocumentModalBody';
import AddCouncilDocumentModalHeader from './AddCouncilDocumentModalHeader';
import AddCouncilDocumentModalFooter from './AddCouncilDocumentModalFooter';
import { updateDocumentToDeleted } from '../utils/updateDocumentToDeleted';

type Props = {
  council: Council;
  privateByDefault?: boolean;
  replaceAnnouncementDocument?: boolean;
  fetchCouncilData: () => void;
};

const AddCouncilDocumentModal: FC<Props> = ({
  council,
  privateByDefault,
  replaceAnnouncementDocument,
  fetchCouncilData,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const PUBLIC_PRIVACY = 'PUBLIC';
  const PRIVATE_PRIVACY = 'PRIVATE';

  const [documentName, setDocumentName] = useState<string>('');
  const [documentFile, setDocumentFile] = useState<File | undefined>(undefined);
  const [isPrivateDocument, setIsPrivateDocument] = useState<boolean>(
    !!privateByDefault
  );
  const [documentPrivacyType, setDocumentPrivacyType] = useState<string>(
    privateByDefault ? PRIVATE_PRIVACY : PUBLIC_PRIVACY
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeDocumentName = (event: any) => {
    setDocumentName(event.target.value);
  };

  const handleChangeDocumentFile = (file: File) => {
    setDocumentFile(file);
    const { name } = file;
    setDocumentName(name);
  };

  const handleChangeIsPrivateDocument = () => {
    setIsPrivateDocument((prev) => !prev);
    setDocumentPrivacyType((prev) =>
      prev === PUBLIC_PRIVACY ? PRIVATE_PRIVACY : PUBLIC_PRIVACY
    );
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const createDocumentData = () => {
    const sanitizedName = documentName.endsWith('.pdf')
      ? documentName.slice(0, -4)
      : documentName;

    return {
      file: documentFile,
      date: new Date(),
      size: documentFile?.size,
      name: `${sanitizedName}.pdf`,
      author: user?._id,
      society: council?.society,
      fileType: fileTypes[documentFile?.type as keyof typeof fileTypes],
      category: documentTypes.DOCUMENTS,
      subcategory: documentPrivacyType,
      description: t('DocumentLinkedManually'),
    };
  };

  const updateCouncilWithDocument = async (uploadedDocument: Document) => {
    const documentsKey =
      uploadedDocument.subcategory === PUBLIC_PRIVACY
        ? 'publicDocuments'
        : 'privateDocuments';

    const councilData = {
      _id: council?._id,
      [documentsKey]: [
        uploadedDocument._id,
        ...(council?.[documentsKey] || []),
      ],
    };

    await store.dispatch(updateCouncil(councilData));
  };

  const updateCouncilAnnouncementDocument = async (
    uploadedDocument: Document
  ) => {
    const councilData = {
      _id: council?._id,
      announcementDocument: uploadedDocument._id,
    };

    await store.dispatch(updateCouncil(councilData));
  };

  const handleAddDocument = async () => {
    try {
      setIsLoading(true);

      const cantAddDocument =
        !documentFile && !council?._id && !council?.society;
      if (cantAddDocument) {
        return;
      }

      const documentData = createDocumentData();

      const uploadedDocument = await store.dispatch(addDocument(documentData));

      if (uploadedDocument) {
        await updateSocietyWithDocument({
          societyId: council.society,
          uploadedDocument,
        });

        if (replaceAnnouncementDocument) {
          await updateDocumentToDeleted(council?.announcementDocument);
          await updateCouncilAnnouncementDocument(uploadedDocument);
        } else {
          await updateCouncilWithDocument(uploadedDocument);
        }
      }

      fetchCouncilData();

      successSwal(t('DocumentUploadedSuccessfully'));
    } catch (error) {
      errorSwal(t('Error'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <AddCouncilDocumentModalHeader
        handleCloseModal={handleCloseModal}
        replaceAnnouncementDocument={replaceAnnouncementDocument}
      />

      <AddCouncilDocumentModalBody
        documentName={documentName}
        documentFile={documentFile}
        isPrivateDocument={isPrivateDocument}
        replaceAnnouncementDocument={replaceAnnouncementDocument}
        handleChangeDocumentName={handleChangeDocumentName}
        handleChangeDocumentFile={handleChangeDocumentFile}
        handleChangeIsPrivateDocument={handleChangeIsPrivateDocument}
      />

      <AddCouncilDocumentModalFooter
        documentName={documentName}
        documentFile={documentFile}
        handleAddDocument={handleAddDocument}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddCouncilDocumentModal;
