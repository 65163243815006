import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Notification } from 'types';
import { store } from 'redux/store';
import { updateGlobalNotification } from 'modules/_shared/redux/globalNotificationActions';
import 'modules/administratives/styles/Administratives.scss';

import { getNotificationStyles } from '../utils/getNotificationStyles';
import notificationStatus from '../constants/notificationStatus';

type Props = {
  notification: Notification;
};

const NotificationCard: FC<Props> = ({ notification }) => {
  const { t } = useTranslate();

  const UNREAD_STATUS = notificationStatus[0].value;
  const READ_STATUS = notificationStatus[1].value;

  const notificationId = notification._id;
  const notificationTitle = notification.title;
  const notificationMessage = notification.message;
  const { societyName } = notification;
  const { status } = notification;

  const notificationStyles = getNotificationStyles(notification);

  const [notificationStatusChecked, setNotificationStatusChecked] =
    useState<boolean>(status === READ_STATUS || false);

  const handleCheckboxChange = () => {
    const notificationUpdateData = {
      _id: notificationId,
      status: notificationStatusChecked ? UNREAD_STATUS : READ_STATUS,
    };
    store.dispatch(updateGlobalNotification(notificationUpdateData));

    setNotificationStatusChecked((prev) => !prev);
  };

  return (
    <div className="nk-history-item d-flex align-items-center">
      <div className="nk-history-symbol me-3">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={notificationStatusChecked}
            onChange={handleCheckboxChange}
            id={`notification-checkbox-${notificationId}`}
          />
          <label
            className="custom-control-label"
            htmlFor={`notification-checkbox-${notificationId}`}
          />
        </div>
      </div>
      <div className="nk-history-content flex-grow-1 mb-4">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center">
                <div
                  className={`user-avatar xs text-${notificationStyles.color} bg-${notificationStyles.color}-dim`}
                >
                  <em className={`icon ${notificationStyles.icon}`} />
                </div>
                <h5 className="fs-14px fw-normal mb-0">
                  {t(notificationTitle)}
                </h5>
              </div>
              <button type="button" className="btn btn-sm clipboard-init mx-n2">
                <span className="clipboard-text align-center">
                  <em className="icon ni ni-eye" />
                  <span>{t('See')}</span>
                </span>
              </button>
            </div>
            <p className="lead text-base" id="notification-message">
              {notificationMessage}
            </p>
            <ul className="nk-history-meta text-soft">
              <li>
                {t('Society')}: {societyName}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
