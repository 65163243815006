import { store } from 'redux/store';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import { FC, createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { addDocument } from 'modules/documents/redux/documentActions';

import { cleanValue } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import cleanText from 'utils/cleanText';
import { addContribution } from 'modules/transactions/redux/operationActions';
import { getNewOperationDate } from 'utils/getNewOperationDate';
import { contributionModalInitValue } from '../constants/contributionModalInitValue';

import { ContributionModalContextType } from '../types/ContributionModalContextType';

import { useGetLastOperationDate } from '../hooks/useGetLastOperationDate';
import { useGetPartnersContributionData } from '../hooks/useGetPartnersContributionData';

const ContributionModalContext = createContext<ContributionModalContextType>(
  contributionModalInitValue
);

export const ContributionModalProvider: FC<{
  children: React.ReactNode; // eslint-disable-line
}> = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const [date, setDate] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [resumeView, setResumeView] = useState<boolean>(false);
  const [comments, setComments] = useState<string>('');
  const [file, setFile] = useState<File | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { lastOperationDate } = useGetLastOperationDate({ actualSociety }).data;
  const { partnersContributionData } = useGetPartnersContributionData({
    actualSociety,
    amount,
  }).data;

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeDate = (
    event: React.ChangeEvent<HTMLInputElement> // eslint-disable-line
  ) => {
    setDate(event.target.value);
  };

  const handleChangeAmount = (
    event: React.ChangeEvent<HTMLInputElement> // eslint-disable-line
  ) => {
    setAmount(cleanValue(event.target.value));
  };

  const handleChangeComments = (
    event: React.ChangeEvent<HTMLTextAreaElement> // eslint-disable-line
  ) => {
    setComments(event.target.value);
  };

  const handleChangeFile = (file: File) => {
    setFile(file);
  };

  const handleSaveContribution = async () => {
    if (!resumeView) {
      setResumeView(true);
    } else {
      try {
        setIsLoading(true);
        let uploadedDocument = null;

        if (file) {
          const newDocument = {
            file,
            date: new Date(date),
            size: file.size,
            name: operationTypes.CONTRIBUTION_FILENAME,
            author: user['_id'],
            society: actualSociety['_id'],
            fileType: fileTypes[file.type as keyof typeof fileTypes],
            category: documentTypes.SCRIPTURES,
            description: t('DocumentLinkedToOperation'),
          };
          uploadedDocument = await store.dispatch(addDocument(newDocument));
        }

        const contributions = partnersContributionData.map((contribution) => ({
          partner: contribution.partnerId,
          percent: contribution.ndPercent,
          amount: contribution.toPerceive,
        }));

        const newOperationDate = getNewOperationDate(lastOperationDate, date);

        const operationData = {
          movements: [],
          transactions: [],
          date: newOperationDate,
          nominalValue: actualSociety.nominalValue,
          society: actualSociety['_id'],
          user: user['_id'],
          contributions,
          documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
          comments: cleanText(comments),
          operationType: operationTypes.CONTRIBUTION,
        };

        await store.dispatch(addContribution(operationData));
        setIsLoading(false);

        successSwal(t('ContributionAddedSuccessfully'));
      } catch (error) {
        errorSwal(t('ErrorAddingContribution'));
      } finally {
        handleCloseModal();
      }
    }
  };

  return (
    <ContributionModalContext.Provider
      value={{
        data: {
          date,
          amount,
          comments,
          file,
          resumeView,
          lastOperationDate,
          partnersContributionData,
          isLoading,
        },
        actions: {
          handleCloseModal,
          handleChangeDate,
          handleChangeAmount,
          handleChangeComments,
          handleChangeFile,
          handleSaveContribution,
        },
      }}
    >
      {children}
    </ContributionModalContext.Provider>
  );
};

export const useContributionModal = () => {
  const context = useContext(ContributionModalContext);
  if (!context) {
    throw new Error(
      'useContributionModal must be used within a ContributionModalProvider'
    );
  }
  return context;
};
