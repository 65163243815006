/* eslint-disable react/no-array-index-key */
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

import { HoldingClass } from 'types';
import DrawInversionHeaders from './DrawInversionHeaders';
import getColumnClass from '../utils/getColumnClass';
import { ClassesTableHeader } from '../types/ClassesTableHeader';

type ClassesTableHeadersProps = {
  holdingClasses: HoldingClass[];
  totalSharesColumnHeader: ClassesTableHeader;
  aditionalColumnsHeaders: ClassesTableHeader[];
  inversionColumnsHeaders: number;
  inversionView: boolean;
};

const getHoldingClassStyle = (index: number) =>
  index % 2 === 0
    ? 'bg-light-vert holding-class-header'
    : 'holding-class-header';

const getHoldingClassKey = (holdingClass: HoldingClass, index: number) =>
  `${holdingClass?.['_id']}-${index}`;

const getAdditionalColumnsStyle = (
  index: number,
  inversionView: boolean,
  inversionColumnsHeaders: number,
  holdingClasses: HoldingClass[]
) =>
  inversionView
    ? getColumnClass(
        index,
        inversionColumnsHeaders + holdingClasses.length + 1,
        true
      )
    : getColumnClass(index, holdingClasses.length + 1, true);

const getAdditionalColumnsKey = (column: ClassesTableHeader, index: number) =>
  `${column.text}-${index}`;

const ClassesTableHeaders = ({
  holdingClasses = [],
  totalSharesColumnHeader,
  aditionalColumnsHeaders,
  inversionColumnsHeaders,
  inversionView,
}: ClassesTableHeadersProps) => {
  const { t } = useTranslate();

  return (
    <tr className="text-center table-header">
      <th className="w-200px"> {t('Partners')} &nbsp;</th>

      {holdingClasses.map(
        (holdingClass, index) =>
          holdingClass.name !== '' && (
            <th
              id={holdingClass['_id']}
              key={getHoldingClassKey(holdingClass, index)}
              className={getHoldingClassStyle(index)}
            >
              {holdingClass.name}
              <Tooltip anchorId={holdingClass?.['_id']} place="top">
                {holdingClass?.name}
              </Tooltip>
            </th>
          )
      )}

      <th
        key={`${totalSharesColumnHeader.text}`}
        className={getColumnClass(0, holdingClasses.length, true)}
        colSpan={totalSharesColumnHeader.columns}
      >
        {totalSharesColumnHeader.text}
      </th>

      {inversionView && (
        <DrawInversionHeaders
          previousColumnsLength={holdingClasses.length + 1 || 0}
        />
      )}

      {aditionalColumnsHeaders?.map(
        (column: ClassesTableHeader, index: number) => (
          <th
            key={getAdditionalColumnsKey(column, index)}
            className={getAdditionalColumnsStyle(
              index,
              inversionView,
              inversionColumnsHeaders,
              holdingClasses
            )}
            colSpan={column.columns}
          >
            {column.text}
          </th>
        )
      )}
    </tr>
  );
};

export default ClassesTableHeaders;
