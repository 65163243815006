import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import signatureAnchorTypes from 'constants/signatureAnchorTypes';
import signatureTypes from 'constants/signatureTypes';
import i18n from 'i18n/config';

import getDocumentBlob from 'modules/_shared/components/CKEditor/utils/getDocumentBlob';
import { getDocumentBlobFromStorage } from 'modules/_shared/utils/getDocumentBlobFromStorage';
import { getCouncilAssistants } from 'modules/administratives/utils/getCouncilAssistants';
import { getCouncilParticipantsTable } from 'modules/administratives/utils/getCouncilParticipantsTable';

import { Council, Document, Society } from 'types';

import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { createBlobMerged } from 'utils/downloadMergedPDF';
import getEnvelopeData from 'utils/getEnvelopeData';
import htmlToPdfFile from 'utils/htmlToPdfFile';

type GetSignatureFileProps = {
  fileName: string;
  council: Council;
  actualSociety: Society;
  document: Document;
  includeAssistants: boolean;
};

type SignatureProcessProps = {
  fileName: string;
  signatureFile: File;
  signatureType: string;
  signers: any[];
  userId: string;
  actualSociety: Society;
  council: Council;
};

type Props = {
  council: Council;
  actualSociety: Society;
  document: Document;
  signatureType: string;
  userId: string;
  includeAssistants: boolean;
};

const findCouncilMember = (society: Society, memberId: string) =>
  society?.directors.find((director) => director._id === memberId);

const getPresidencyData = (
  council: Council,
  society: Society,
  signatureType: string
) => {
  const presidencyData: any[] = [];

  const { president, secretary } = council;

  const councilPresident = findCouncilMember(society, president);
  const councilSecretary = findCouncilMember(society, secretary);

  const presidentData = {
    id: councilPresident?._id,
    name: councilPresident?.socialDenomination,
    email: councilPresident?.email,
    role: signatureAnchorTypes.COUNCIL_PRESIDENT,
    type: signatureType,
  };

  const secretaryData = {
    id: councilSecretary?._id,
    name: councilSecretary?.socialDenomination,
    email: councilSecretary?.email,
    role: signatureAnchorTypes.COUNCIL_SECRETARY,
    type: signatureType,
  };

  presidencyData.push(presidentData);
  presidencyData.push(secretaryData);

  return presidencyData;
};

const getFilteredAssistants = (council: Council, assistants: any[]) => {
  const { president, secretary } = council;
  return assistants.filter(
    (assistant) =>
      assistant?.member !== president && assistant?.member !== secretary
  );
};

const getAssistantsData = (council: Council, includeAssistants: boolean) => {
  if (!includeAssistants) return [];

  const assistants = getCouncilAssistants(council);
  const filteredAssistants = getFilteredAssistants(council, assistants);

  const assistantsData = filteredAssistants.map((assistant) => ({
    id: assistant?.member,
    name: assistant?.name,
    email: assistant?.email,
    role: signatureAnchorTypes.BOARD_ASSISTANT,
    type: signatureTypes.BOARD_ASSISTANTS,
    anchors: [assistant?.member],
  }));

  return assistantsData;
};

const getAssistantsFile = async (council: Council, actualSociety: Society) => {
  const assistantsFileName = `Lista de asistentes - ${council?.name}`;

  const assistantsHtmlContent = await getCouncilParticipantsTable(
    council,
    actualSociety
  );
  const assistantsFile = await htmlToPdfFile({
    fileName: assistantsFileName,
    // @ts-ignore
    certificate: assistantsHtmlContent,
  });

  return assistantsFile;
};

const getSignatureFile = async ({
  fileName,
  council,
  actualSociety,
  document,
  includeAssistants,
}: GetSignatureFileProps) => {
  let assistantsFile = null;
  if (includeAssistants) {
    assistantsFile = await getAssistantsFile(council, actualSociety);
  }

  let templateBlob = null;

  if (document?.category === documentTypes.DOCUMENTS) {
    templateBlob = await getDocumentBlobFromStorage(document?._id);
  } else {
    templateBlob = await getDocumentBlob(document?.editorModel);
  }

  const templateFile = new File([templateBlob as Blob], fileName, {
    type: 'application/pdf',
  });

  const mergedBlob = includeAssistants
    ? await createBlobMerged({
        documents: [templateBlob, assistantsFile],
      })
    : templateFile;

  const signatureFile = new File([mergedBlob], fileName, {
    type: 'application/pdf',
  });

  return signatureFile;
};

const signatureProcess = async ({
  fileName,
  signatureFile,
  signatureType,
  userId,
  actualSociety,
  council,
  signers,
}: SignatureProcessProps) => {
  const signFileBase64 = await toBase64(signatureFile);

  const envelopeData = getEnvelopeData({
    signers,
    signType: signatureType,
    fileName,
    fileType: fileTypes.PDF,
    linkedType: 'COUNCIL',
    role: 'COUNCIL',
    userId,
    societyId: actualSociety?._id,
    beneficiaries: [],
    councilId: council?._id,
  });

  const signProcessData = {
    signers,
    societyName: actualSociety?.name,
    documentName: fileName,
    planName: council?.name,
    message: i18n.t('RequestCouncilSignatureMessage'),
    language: actualSociety?.language || 'es',
    signatureType,
    signatureFile: signFileBase64,
    signatureFileName: fileName,
    envelopeData,
  };

  await startSignProcess(signProcessData);
};

export const requestSignature = async ({
  council,
  actualSociety,
  signatureType,
  document,
  userId,
  includeAssistants,
}: Props) => {
  const isActSignature = signatureType === signatureTypes.COUNCIL_ACT;

  const fileName = isActSignature
    ? `Acta de ${council?.name}`
    : `Certificado de ${council?.name}`;

  const presidencyData = getPresidencyData(
    council,
    actualSociety,
    signatureType
  );

  const assistantsData = getAssistantsData(council, includeAssistants);

  const signers = [...presidencyData, ...assistantsData];

  let signatureFile = null;

  signatureFile = await getSignatureFile({
    fileName,
    council,
    actualSociety,
    document,
    includeAssistants,
  });

  await signatureProcess({
    fileName,
    signatureFile,
    signatureType,
    signers,
    userId,
    actualSociety,
    council,
  });
};
