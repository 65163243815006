import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { SharesTitle, Transaction } from 'types';
import { getTitleByTransaction } from '../utils/getTitleByTransaction';

type Props = {
  transaction: Transaction;
};

export const useGetTransactionTitle = ({ transaction }: Props) => {
  const sharesTitles = useSelector((state: State) => state.sharesTitles);
  const [transactionTitle, setTransactionTitle] = useState<SharesTitle | null>(
    null
  );
  const [document, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    const title = getTitleByTransaction(sharesTitles, transaction);

    if (title) {
      setTransactionTitle(title);

      if (title?.document) {
        const fetchDocument = async () => {
          const doc = await store.dispatch(getDocument(title.document));
          setDocument(doc);
        };
        fetchDocument();
      }
    } else {
      setTransactionTitle(null);
      setDocument(null);
    }
  }, [transaction, sharesTitles]);

  return { data: { transactionTitle, document } };
};
