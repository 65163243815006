import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Council } from 'types';
import actionTypes from 'constants/actionTypes';
import { useGetActualSociety } from 'hooks/useGetActualSociety';

import Page from 'modules/_shared/components/Pages/Page';
import AddCouncil from 'modules/_shared/components/Modals/AddCouncil';
import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import sizes from 'constants/sizes';
import { useGetMenu } from '../../hooks/useGetMenu';

import CouncilDetailsPageHeader from './components/CouncilDetailsPageHeader';
import CouncilDetailsPageBody from './components/CouncilDetailsPageBody';
import { useGetCouncilsList } from '../CouncilsList/hooks/useGetCouncilsList';

type Props = {};

const CouncilDetails: FC<Props> = () => {
  const dispatch = useDispatch();
  const { societyId, councilId } = useParams();

  const council = useSelector((state: State) =>
    state.councils.find((council: Council) => council._id === councilId)
  );

  useGetMenu();
  useGetActualSociety(societyId);
  useGetCouncilsList(societyId);

  const handleEditCouncil = () => {
    if (!council) return;

    dispatch(
      setModal(
        <AddCouncil
          action={actionTypes.EDIT as keyof typeof actionTypes}
          council={council}
          size={sizes.XL}
        />
      )
    );
  };

  return (
    <Page>
      {council && (
        <>
          <CouncilDetailsPageHeader council={council} />

          {societyId && councilId && (
            <CouncilDetailsPageBody
              councilId={councilId}
              handleEditCouncil={handleEditCouncil}
            />
          )}
        </>
      )}

      {!council && <CustomLoading />}
    </Page>
  );
};

export default CouncilDetails;
