import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { currencyFormatDecimals } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';

import { useContributionModal } from '../context/ContributionModalContext';
import ContributionTable from './ContributionTable';

const ContributionResumeView: FC = () => {
  const { t } = useTranslate();

  const { data } = useContributionModal();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <span className="">{t('Contribution')}:</span>
        <span className="badge-primary rounded px-4 py-1">
          <NumberFormat
            displayType="text"
            value={data.amount}
            thousandSeparator={currencyFormatDecimals?.thousandSeparator}
            decimalSeparator={currencyFormatDecimals?.decimalSeparator}
            decimalScale={currencyFormatDecimals?.decimalScale}
            suffix={currencyFormatDecimals?.suffix}
            fixedDecimalScale={currencyFormatDecimals?.fixedDecimalScale}
          />
        </span>
      </div>

      <div className="card card-bordered card-preview mb-4">
        <ContributionTable />
      </div>
    </>
  );
};

export default ContributionResumeView;
