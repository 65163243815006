export const simplifyMentions = (htmlString: string) => {
  if (htmlString === null) {
    return '';
  }

  return htmlString.replace(
    /<span class="mention" data-mention="[^"]*">([^<]*)<\/span>/g,
    '<span>$1</span>'
  );
};
