import { SocietyTheme } from 'types/Society';

export const getCommunicationStyles = (societyTheme: SocietyTheme) => ` <style>
  @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

      @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
              padding: 1.75rem 20px !important;
              width: 85% !important;
          }
      }

      .main {
          padding: 24px;
    font-family: "Archivo", sans-serif !important;
    }
      .custom-background {
          background: ${societyTheme?.frameColor};
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: ${societyTheme?.headingsColor};
          width: 100%;
          text-align: center;
      }

      .custom-logo {
          height: 40px;
          margin-top: 2.75rem;
      }

      .branding {
          font-size: 13px;
          color: ${societyTheme?.headingsColor};
          padding-top: 6px;
          margin-top: 0;
          padding-bottom: 24px;
      }

      .email-body {
          width: 96%;
          max-width: 620px;
          text-align: left;
          margin: 0 auto;
          padding: 1.75rem 2.75rem;
          background: #ffffff;
          color: ${societyTheme?.bodyTextColor};
      }

      .email-body h2 {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          line-height: 1.4;
          margin: 10px 0;
          padding-bottom: 15px;
          text-align: left;
      }

      .email-body p,
      .email-body h3 {
          text-align: left;
      }

      .email-body h3 {
          margin-bottom: 0.5rem;
          font-weight: 700;
          line-height: 1.1;
      }

      .email-body table {
          width: 100%;
      }

      .email-body td {
          text-align: left;
          padding-bottom: 10px;
      }

      h1, h2, h3, h4, h5, h6 {
          color: ${societyTheme?.headingsColor}
      }

      .button {
          background: ${societyTheme?.buttonColor};
          border-radius: ${societyTheme?.buttonRounded};
          border: none;
          color: ${societyTheme?.buttonTextColor} !important;
          display: block;
          font-size: 13px;
          font-weight: 400;
          line-height: 38px;
          text-align: center;
          text-decoration: none;
          text-transform: none;
          padding: 0 30px;
          width: fit-content;
          margin: 0 auto;
      }

      .button-small {
          padding: 0 15px;
      }

      .footer {
          padding-bottom: 2.75rem;
          padding-top: 2.75rem;
          margin: 0;
      }

      .footer a {
          color: ${societyTheme?.linkColor};
          text-decoration: none;
      }
  </style>`;
