import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useSendTestEmailModal } from '../context/SendTestEmailModalContext';

const SendTestEmailModalHeader: FC = () => {
  const { t } = useTranslate();

  const { actions } = useSendTestEmailModal();

  return (
    <Modal.Header>
      <h5 className="modal-title">
        <em className="icon icon-circle ni ni-send bg-primary-alt" />
        &nbsp;&nbsp;
        {t('SendTestEmail')}&nbsp;
      </h5>
      <a className="close cursor-pointer" onClick={actions.handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default SendTestEmailModalHeader;
