import { Council, Society } from 'types';
import { isParticipantAllowedForAnnouncement } from './isParticipantAllowedForAnnouncement';

export const getMemberRecipients = (council: Council, society: Society) => {
  const activeMembers = council?.participants?.filter((participant) =>
    isParticipantAllowedForAnnouncement(participant, society)
  );

  const recipients = activeMembers.map((member) => ({
    email: member.email,
    name: member.name,
  }));

  const mainEmails = activeMembers.map((recipient) => {
    const { member, email, name } = recipient;
    return {
      id: member,
      email: email ? email.trim() : '',
      name,
    };
  });

  return { recipients, mainEmails };
};
