/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PartnerDividends from 'modules/partners/components/PartnerDividends';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { useSharesTitles } from 'modules/partners/pages/TitleRegistryBook/hooks/useShareTitles';

import { getPartnerSharesDetails } from 'utils/filters';
import { useScroll } from 'modules/_shared/contexts/ScrollContext';
import { sortAndCreateRows } from './utils/sortAndCreateRows';
import PartnerTransactionsTable from './PartnerTransactionsTable';

const PartnerTransactions = ({ society, partner }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  useSharesTitles();

  const { documents } = useSelector((state) => ({
    documents: state.documents,
  }));

  const { actions } = useScroll();
  actions.handleScroll();

  const [rows, setRows] = useState([]);
  const [partnerShares] = useState(getPartnerSharesDetails(partner, society));

  useEffect(() => {
    if (!society) return;

    if (partner && documents) {
      const createdRows = sortAndCreateRows(
        society,
        partner,
        partnerShares,
        documents
      );
      setRows(createdRows);
    }
  }, [society, partner, documents]);

  useEffect(() => {
    if (society) {
      dispatch(getDocuments({ society: society['_id'] }));
    }
  }, [society]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('PartnerTransactions')}</h4>
          <div className="nk-block-des">
            <p>
              {t('TransactionsInSocietyName', {
                societyName: society.name,
                partnerName: partner.name || '',
              })}
            </p>
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <PartnerTransactionsTable
            rows={rows}
            hasShareTags={society?.hasShareTags}
          />
        </div>
      ) : (
        <p>
          {t('ItStillDoesNotHaveTransac', {
            partnerName: partner.name || '',
            societyName: society.name,
          })}
        </p>
      )}

      <div className="nk-block-head mt-2" id="dividends-table">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('Dividends')}</h4>
          <div className="nk-block-des">
            <p>
              {t('DividendsDetails', {
                societyName: society.name,
              })}
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <PartnerDividends
          partner={partner}
          society={society}
          showTitle={false}
        />
      </div>
    </div>
  );
};

export default PartnerTransactions;
