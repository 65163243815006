import { useTranslate } from 'hooks/useTranslate';

import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import isSuperAdmin from 'utils/isSuperAdmin';

import 'assets/styles/Styles.scss';

import ExtendedMenu from './ExtendedMenu';
import MainMenu from './MainMenu';
import SecondaryMenu from './SecondaryMenu';

import './SideNav.scss';
import ExtendedMenuLoader from './ExtendedMenuLoader';

const SideNav = ({ type, societyName, societyId, showSideNav, user }) => {
  const { t } = useTranslate();

  const hasAccessCaptable = useSelector(
    (state) => state.society?.actualSociety?.hasAccessCaptable
  );
  const isSocietyDemo = useSelector(
    (state) => state.society?.actualSociety?.isSocietyDemo
  );
  const hasWhiteBranding = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasWhiteBranding
  );
  const { isAdmin, isPartner, isBeneficiary } = useSelector(
    (state) =>
      state?.society?.role || {
        isAdmin: false,
        isPartner: false,
        isBeneficiary: false,
      }
  );
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const getSideNavTitle = () => {
    if (type === menuTypes.MAIN && !hasWhiteBranding) {
      return t('Societies');
    }

    if (
      type !== menuTypes.MAIN &&
      !isAdmin &&
      (!isPartner || !hasAccessCaptable)
    ) {
      return societyName;
    }

    if (type !== menuTypes.MAIN) {
      return (
        <Link to={`/sociedad/${societyId}`}>
          {societyName}
          {isSuperAdmin(user) && isSocietyDemo && (
            <small className="badge badge-pill badge-primary ml-1">DEMO</small>
          )}
        </Link>
      );
    }
  };

  return (
    type && (
      <div
        id="main-sidebar"
        className={
          showSideNav
            ? 'nk-aside toggle-screen-lg mobile-menu content-active'
            : 'nk-aside toggle-screen-lg mobile-menu'
        }
        data-content="sideNav"
        data-toggle-overlay="true"
        data-toggle-screen="lg"
        data-toggle-body="true"
      >
        <div className="nk-sidebar-menu" data-simplebar>
          <ul className="nk-menu">
            <li className="nk-menu-heading">
              {type === menuTypes.EXTENDED && !actualSociety ? (
                <p className="placeholder-glow mb-4 text-primary">
                  <span className="placeholder col-11 h-15px" />
                </p>
              ) : (
                <h6 className="overline-title text-primary-alt">
                  {getSideNavTitle()}
                </h6>
              )}
            </li>

            {type === menuTypes.MAIN && (
              <>{!hasWhiteBranding && <MainMenu />}</>
            )}

            {type === menuTypes.EXTENDED && (
              <>
                {actualSociety ? (
                  <ExtendedMenu societyId={societyId} userId={user['_id']} />
                ) : (
                  <ExtendedMenuLoader rows={10} />
                )}
                {/* <ExtendedMenuLoader rows={10} /> */}
              </>
            )}

            {!hasWhiteBranding && <SecondaryMenu />}
          </ul>
        </div>

        <div className="nk-aside-close">
          <a
            href="/"
            className="toggle"
            aria-label="link"
            data-target="sideNav"
          >
            <em className="icon ni ni-cross" />
          </a>
        </div>
      </div>
    )
  );
};

function mapStateToProps({
  menu: { type, societyName, societyId },
  sideNav: showSideNav,
  user,
}) {
  return {
    type,
    societyName,
    societyId,
    showSideNav,
    user,
  };
}
export default connect(mapStateToProps)(SideNav);
