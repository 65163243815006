import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';

import { useParams } from 'react-router-dom';
import { getSharesTitles } from 'modules/partners/redux/sharesTitlesActions';

export const useSharesTitles = () => {
  const { societyId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchSharesTitles = useCallback(async () => {
    if (!societyId) return;
    setIsLoading(true);
    setIsError(false);
    try {
      await store.dispatch(getSharesTitles({ societyId }));
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId]);

  useEffect(() => {
    fetchSharesTitles();
  }, [fetchSharesTitles]);

  return { isLoading, isError };
};
