import { translate } from 'hooks/useTranslate';
import { SweetAlertOptions } from 'sweetalert2';

export const uploadDocumentAlerts: Record<string, SweetAlertOptions> = {
  ONLY_ONE_DOCUMENT: {
    icon: 'info',
    title: `<h4 class="nk-modal-title">${translate(
      'MustUploadOneDocument'
    )}</h4>`,
    confirmButtonText: translate('Ok'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
  },
  WANT_UPLOAD_DOCUMENT: {
    icon: 'info',
    title: `<h4 class="nk-modal-title">${translate(
      'WantUploadThisDocument'
    )}</h4>`,
    confirmButtonText: translate('Upload'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  },
};
