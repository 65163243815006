export default [
  {
    value: 'ACTIVE',
    label: 'Active',
    badge: 'success',
  },
  {
    value: 'OTHERS',
    label: 'Others',
    badge: 'warning',
  },
  {
    value: 'CANCELLED',
    label: 'Cancelled',
    badge: 'danger',
  },
];
