import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Realty } from 'types';

import NoItemsAvailable from 'modules/_shared/components/EmptyStates/NoItemsAvailable';
import RealtiesTableRow from './RealtiesTableRow';

type Props = {
  realties: Realty[];
};

const RealtiesTableBody: FC<Props> = ({ realties }) => {
  const { t } = useTranslate();

  const hasData = realties?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          realties?.map((realty: Realty, index: number) => (
            <tr className="nk-tb-item" key={`realties-row-${realty?._id}`}>
              <RealtiesTableRow index={index} realty={realty} />
            </tr>
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoRealtiesMessage')} />
        )}
      </tbody>
    </>
  );
};

export default RealtiesTableBody;
