/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-has-content */
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import EmptyStateCard from 'modules/_shared/components/EmptyStates/EmptyStateCard';
import DataTablePagination from 'modules/_shared/components/Tables/DataTablePagination';
import { useGetCommunications } from 'modules/communications/pages/Communications/hooks/useGetCommunications';
import InboxRow from '../InboxRow';

const INITIAL_PAGE = 1;
const INITIAL_ROWS_PER_PAGE = 10;

type InboxProps = {
  onUpdateTotalCount: (count: number) => void;
};

const Inbox: FC<InboxProps> = ({ onUpdateTotalCount }) => {
  const { t } = useTranslate();
  const { societyId, communicationId } = useParams();

  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(INITIAL_ROWS_PER_PAGE);
  const [paginationData, setPaginationData] = useState({
    customItemPerPage: INITIAL_ROWS_PER_PAGE,
    itemPerPage: INITIAL_ROWS_PER_PAGE,
    currentPage: INITIAL_PAGE,
    totalItems: 0,
    totalPages: 0,
  });

  const {
    data: communications,
    pagination,
    isLoading,
    isError,
  } = useGetCommunications({
    societyId,
    communicationId,
    page: currentPage,
    items: rowsPerPage,
    sortBy: 'date',
    sortOrder: 'desc',
  });

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (rowsCount: number) => {
    setRowsPerPage(rowsCount);
    setCurrentPage(INITIAL_PAGE);
  };

  useEffect(() => {
    const hasPaginationChanged =
      pagination &&
      (pagination.items !== paginationData.itemPerPage ||
        pagination.page !== paginationData.currentPage ||
        pagination.totalItems !== paginationData.totalItems ||
        pagination.totalPages !== paginationData.totalPages);

    if (hasPaginationChanged) {
      setPaginationData({
        customItemPerPage: pagination.items,
        itemPerPage: pagination.items,
        currentPage: pagination.page,
        totalItems: pagination.totalItems,
        totalPages: pagination.totalPages,
      });
    }

    onUpdateTotalCount(pagination?.totalItems || 0);
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isError ? (
        <EmptyStateCard
          mainMessage={t('ErrorLoadingCommunications')}
          infoMessage={t('ErrorLoadingCommunicationsMessage')}
          bordered={false}
        />
      ) : (
        <div className="nk-ibx-list inbox-list-wrapper">
          {communications?.map((communication) => (
            <div key={`comm-row-${communication._id}`} className="inbox-item">
              <InboxRow communication={communication} />
            </div>
          ))}
        </div>
      )}

      {isLoading && <CustomLoading />}

      <DataTablePagination
        className="bg-white"
        itemPerPage={paginationData.itemPerPage}
        totalItems={paginationData.totalItems}
        paginate={handleChangePage}
        currentPage={paginationData.currentPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        customItemPerPage={paginationData.customItemPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default Inbox;
