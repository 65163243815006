import { useEffect, useState } from 'react';
import i18n from 'i18n/config';

import sharesTitlesStatus from 'modules/partners/pages/TitleRegistryBook/constants/sharesTitlesStatus';

export default function useGetSharesTitleStatusOptions() {
  const [statusOptions, setStatusOptions] = useState<any[]>([]);

  useEffect(() => {
    const statusOptions = sharesTitlesStatus.map((status) => ({
      value: status.value,
      label: i18n.t(status.label),
    }));
    setStatusOptions(statusOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return {
    data: { statusOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
