import { useEffect, useState } from 'react';

import { Beneficiary, SelectorOption } from 'types';
import invitationStatus from 'constants/invitationStatus';

type Props = {
  beneficiary: Beneficiary;
};

const getBeneficiaryStatusOption = ({ beneficiary }: Props) => {
  const beneficiaryStatus: any = Object.values(invitationStatus).find(
    (status: any) => status.value === beneficiary?.invitationStatus
  );

  return {
    value: beneficiaryStatus?.value,
    label: beneficiaryStatus?.text,
  };
};

const getStatusOptions = (): SelectorOption[] => {
  const invitationStatusOptions = Object.values(invitationStatus).map(
    (status) => ({
      value: status?.value,
      label: status?.text,
    })
  );

  return invitationStatusOptions;
};

export const useGetStatusOptions = ({ beneficiary }: Props) => {
  const [selectedStatusOption, setSelectedStatusOption] =
    useState<SelectorOption | null>(null);
  const [statusOptions, setStatusOptions] = useState<SelectorOption[]>([]);

  const handleChangeSelectedStatusOption = (selectedOption: SelectorOption) => {
    setSelectedStatusOption(selectedOption);
  };

  useEffect(() => {
    if (beneficiary) {
      const statusOptions = getStatusOptions();
      setStatusOptions(statusOptions);

      const selectedStatus = getBeneficiaryStatusOption({ beneficiary });
      setSelectedStatusOption(selectedStatus);
    }
  }, [beneficiary]);

  return {
    data: {
      selectedStatusOption,
      statusOptions,
    },
    actions: {
      handleChangeSelectedStatusOption,
    },
  };
};
