import { FC } from 'react';

import { SharesTitle } from 'types';

import SharesTitlesTableHeader from './SharesTitlesTableHeader';
import SharesTitleTableBody from './SharesTitlesTableBody';

type Props = {
  sharesTitles: SharesTitle[];
};

const SharesTitlesTable: FC<Props> = ({ sharesTitles }) => (
  <div className="card card-bordered card-preview">
    <table className="nk-tb-list nk-tb-ulist table">
      <SharesTitlesTableHeader />

      <SharesTitleTableBody sharesTitles={sharesTitles} />
    </table>
  </div>
);

export default SharesTitlesTable;
