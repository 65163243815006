import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useEditLetterStatusModal } from '../context/EditLetterStatusModalContext';

const EditLetterStatusModalHeader: FC = () => {
  const { t } = useTranslate();

  const { actions } = useEditLetterStatusModal();

  return (
    <Modal.Header>
      <h5 className="modal-title">{t('EditLetterStatus')}&nbsp;</h5>
      <a className="close cursor-pointer" onClick={actions.handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default EditLetterStatusModalHeader;
