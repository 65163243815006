export default [
  {
    value: 'EMPLOYEE',
    icon: 'ni ni-user',
    color: 'primary',
  },
  {
    value: 'REVOKED',
    icon: 'Revoked',
    color: 'danger',
  },
];
