import { translate } from 'hooks/useTranslate';

const styles = {
  LIGHT_COLUMN_HEADER: 'text-left w-200px fw-bold bg-light',
  BLANK_COLUMN_HEADER: 'text-left w-200px fw-bold',
  LIGHT_COLUMN: 'text-left w-200px bg-light',
  BLANK_COLUMN: 'text-left w-200px',
};

const calculatePercentsAndTotals = (data: any) => {
  const {
    totalShares,
    totalNDShares,
    totalFDShares,
    totalPFDShares,
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
    inForceCNShares,
  } = data;

  const stockPercents = {
    ASSIGNED_FD: (stockAssignedShares * 100) / totalFDShares,
    UNASSIGNED_FD: (stockUnassignedShares * 100) / totalFDShares,
    ASSIGNED_PFD: (stockAssignedShares * 100) / totalPFDShares,
    UNASSIGNED_PFD: (stockUnassignedShares * 100) / totalPFDShares,
  };

  const stockSharesTotalPFDPercent =
    stockPercents.ASSIGNED_PFD + stockPercents.UNASSIGNED_PFD;

  const stockSharesTotal = stockAssignedShares + stockUnassignedShares;

  const phantomPercents = {
    ASSIGNED_FD: (phantomAssignedShares * 100) / totalFDShares,
    UNASSIGNED_FD: (phantomUnassignedShares * 100) / totalFDShares,
    ASSIGNED_PFD: (phantomAssignedShares * 100) / totalPFDShares,
    UNASSIGNED_PFD: (phantomUnassignedShares * 100) / totalPFDShares,
  };

  const phantomSharesTotalPFDPercent =
    phantomPercents.ASSIGNED_PFD + phantomPercents.UNASSIGNED_PFD;

  const phantomSharesTotal = phantomAssignedShares + phantomUnassignedShares;

  const ncPercents = {
    INFORCE_FD: (inForceCNShares * 100) / totalFDShares,
    INFORCE_PFD: (inForceCNShares * 100) / totalPFDShares,
  };

  const ncSharesTotal = inForceCNShares;

  const assignedShares = {
    TOTAL: stockAssignedShares + phantomAssignedShares,
    PERCENT:
      ((stockAssignedShares + phantomAssignedShares) * 100) / totalPFDShares,
  };

  const unassignedShares = {
    TOTAL: stockUnassignedShares + phantomUnassignedShares,
    PERCENT:
      ((stockUnassignedShares + phantomUnassignedShares) * 100) /
      totalPFDShares,
  };

  const totalSumShares = {
    TOTAL: totalShares + stockSharesTotal + phantomSharesTotal + ncSharesTotal,
    PERCENT:
      (totalNDShares * 100) / totalPFDShares +
      (stockSharesTotalPFDPercent +
        phantomSharesTotalPFDPercent +
        ncPercents.INFORCE_PFD),
  };

  return {
    stockPercents,
    stockSharesTotalPFDPercent,
    stockSharesTotal,
    phantomPercents,
    phantomSharesTotalPFDPercent,
    phantomSharesTotal,
    ncPercents,
    ncSharesTotal,
    assignedShares,
    unassignedShares,
    totalSumShares,
  };
};

export const getClassesTableRows = (data: any) => {
  const {
    decimalScale,
    totalShares,
    totalNDShares,
    totalNDPercent,
    totalFDShares,
    totalFDPercent,
    totalPFDShares,
    totalPFDPercent,
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
    inForceCNShares,
    investorsView,
  } = data;

  const {
    stockPercents,
    stockSharesTotalPFDPercent,
    stockSharesTotal,
    phantomPercents,
    phantomSharesTotalPFDPercent,
    phantomSharesTotal,
    ncPercents,
    ncSharesTotal,
    assignedShares,
    unassignedShares,
    totalSumShares,
  } = calculatePercentsAndTotals(data);

  const aditionalRowHeaders = investorsView
    ? [
        {
          text: 'Warrants/Stock Option',
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: 'ESOP/Phantom Share',
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: translate('ConvertibleNotes'),
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: translate('Allocated'),
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: translate('Unallocated'),
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
      ]
    : [
        {
          text: 'Warrants/Stock Option',
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: ` • ${translate('Allocated')}`,
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
        },
        {
          text: ` • ${translate('Unallocated')}`,
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
        },
        {
          text: 'ESOP/Phantom Share',
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: ` • ${translate('Allocated')}`,
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
        },
        {
          text: ` • ${translate('Unallocated')}`,
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
        },
        {
          text: translate('ConvertibleNotes'),
          style1: styles.LIGHT_COLUMN_HEADER,
          style2: styles.BLANK_COLUMN_HEADER,
        },
        {
          text: ` • ${translate('InForce')}`,
          style1: styles.LIGHT_COLUMN,
          style2: styles.BLANK_COLUMN,
        },
      ];

  const totalsRowHeaders = investorsView
    ? [
        {
          text: 'TOTAL (ND)',
          style1: 'text-left font-weight-bold bg-light',
          style2: 'text-left font-weight-bold',
        },
        {
          text: 'TOTAL (FD)',
          style1: 'text-left font-weight-bold bg-light',
          style2: 'text-left font-weight-bold',
        },
      ]
    : [
        {
          text: 'TOTAL',
          style1: 'text-left font-weight-bold bg-light',
          style2: 'text-left font-weight-bold',
        },
      ];

  const aditionalRows = investorsView
    ? [
        {
          text: 'Warrants/Stock Option',
          column1Value: stockSharesTotal, // 1 Total Shares
          column3Value: '', // 3 %ND - %
          column7Value: stockSharesTotalPFDPercent.toFixed(decimalScale), // 7 %P.FD - %
        },
        {
          text: 'ESOP/Phantom Share',
          column1Value: phantomSharesTotal, // 1 Total Shares
          column3Value: '',
          column7Value: phantomSharesTotalPFDPercent.toFixed(decimalScale),
        },
        {
          text: translate('ConvertibleNotes'),
          column1Value: ncSharesTotal,
          column3Value: '',
          column7Value: ncPercents.INFORCE_PFD.toFixed(decimalScale),
        },
        {
          text: translate('Allocated'),
          column1Value: assignedShares.TOTAL,
          column3Value: '',
          column7Value: assignedShares.PERCENT.toFixed(decimalScale),
        },
        {
          text: translate('Unallocated'),
          column1Value: unassignedShares.TOTAL,
          column3Value: '',
          column7Value: unassignedShares.PERCENT.toFixed(decimalScale),
        },
      ]
    : [
        {
          text: 'Warrants/Stock Option',
          column1Value: '', // 1 Total Shares
          column2Value: '', // 2 %ND - units
          column3Value: '', // 3 %ND - %
          column4Value: '', // 4 %FD - units
          column5Value: '', // 5 %FD - %
          column6Value: '', // 6 %P.FD - units
          column7Value: '', // 7 %P.FD - %
        },
        {
          text: ` • ${translate('Allocated')}`,
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: stockAssignedShares,
          column5Value: stockPercents.ASSIGNED_FD.toFixed(decimalScale),
          column6Value: stockAssignedShares,
          column7Value: stockPercents.ASSIGNED_PFD.toFixed(decimalScale),
        },
        {
          text: ` • ${translate('Unallocated')}`,
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: '',
          column5Value: '',
          column6Value: stockUnassignedShares,
          column7Value: (
            (stockUnassignedShares * 100) /
            totalPFDShares
          ).toFixed(decimalScale),
        },
        {
          text: 'ESOP/Phantom Share',
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: '',
          column5Value: '',
          column6Value: '',
          column7Value: '',
        },
        {
          text: ` • ${translate('Allocated')}`,
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: phantomAssignedShares,
          column5Value: phantomPercents.ASSIGNED_FD.toFixed(decimalScale),
          column6Value: phantomAssignedShares,
          column7Value: phantomPercents.ASSIGNED_PFD.toFixed(decimalScale),
        },
        {
          text: ` • ${translate('Unallocated')}`,
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: '',
          column5Value: '',
          column6Value: phantomUnassignedShares,
          column7Value: phantomPercents.UNASSIGNED_PFD.toFixed(decimalScale),
        },
        {
          text: translate('ConvertibleNotes'),
          column1Value: '',
          column2Value: '',
          column3Value: '',
        },
        {
          text: ` • ${translate('InForce')}`,
          column1Value: '',
          column2Value: '',
          column3Value: '',
          column4Value: inForceCNShares,
          column5Value: ncPercents.INFORCE_FD.toFixed(decimalScale),
          column6Value: inForceCNShares,
          column7Value: ncPercents.INFORCE_PFD.toFixed(decimalScale),
        },
      ];

  const totalsRows = investorsView
    ? [
        {
          text: 'TOTAL (ND)',
          column1Value: totalShares,
          column2Value: totalNDShares,
          column3Value: totalNDPercent,
          column4Value: totalFDShares,
          column5Value: totalFDPercent,
          column6Value: totalPFDShares,
          column7Value: totalPFDPercent,
        },
        {
          text: 'TOTAL (FD)',
          column1Value: totalSumShares.TOTAL,
          column2Value: '',
          column3Value: '',
          column4Value: '',
          column5Value: '',
          column6Value: '',
          column7Value: totalSumShares.PERCENT.toFixed(decimalScale),
        },
      ]
    : [
        {
          text: 'TOTAL',
          column1Value: totalShares,
          column2Value: totalNDShares,
          column3Value: totalNDPercent,
          column4Value: totalFDShares,
          column5Value: totalFDPercent,
          column6Value: totalPFDShares,
          column7Value: totalPFDPercent,
        },
      ];

  return {
    aditionalRowHeaders,
    totalsRowHeaders,
    aditionalRows,
    totalsRows,
  };
};
