import { FC } from 'react';

import { SocietyDirector } from 'types';

import { isActiveDirector } from 'modules/administratives/utils/isActiveDirector';

import AdminMembersTable from './AdminMembersTable';

type Props = {
  societyId: string;
  adminMembers: SocietyDirector[];
  currentMembersView: boolean;
};

const AdminMembersPageBody: FC<Props> = ({
  societyId,
  adminMembers,
  currentMembersView,
}) => {
  const filteredMembers = currentMembersView
    ? adminMembers.filter((member: SocietyDirector) => isActiveDirector(member))
    : adminMembers;

  return (
    <div className="nk-block">
      <AdminMembersTable societyId={societyId} adminMembers={filteredMembers} />
    </div>
  );
};

export default AdminMembersPageBody;
