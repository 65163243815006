import { Contribution, Operation } from 'types';

export const getContributionAmount = (contributionOperation: Operation) => {
  let amount = 0;

  const contributions = contributionOperation?.contributions || [];

  contributions?.forEach((contribution: Contribution) => {
    amount += contribution.amount;
  });

  return amount;
};
