/* eslint-disable react/no-array-index-key */
import { HoldingClass, Partner, Society } from 'types';
import { ClassesTableRow } from '../types/ClassesTableRow';
import DrawCell from './DrawCell';
import DrawEmptyColumns from './DrawEmptyColumns';
import DrawInversionTotals from './DrawInversionTotals';

type ClassesTableRowsTotalsProps = {
  totalsRows: ClassesTableRow[];
  currentPartners: Partner[];
  holdingClasses: HoldingClass[];
  classesShares: any;
  partnersShares: any;
  text: boolean;
  inversionView: boolean;
  investorsView: boolean;
  hasNDValueColumn: boolean;
  hasNDPercentColumn: boolean;
  hasFDValueColumn: boolean;
  hasFDPercentColumn: boolean;
  hasPFDValueColumn: boolean;
  hasPFDPercentColumn: boolean;
  actualSociety: Society;
};

const ClassesTableRowsTotals = ({
  totalsRows,
  currentPartners,
  holdingClasses,
  classesShares,
  partnersShares,
  text,
  inversionView,
  investorsView,
  hasNDValueColumn,
  hasNDPercentColumn,
  hasFDValueColumn,
  hasFDPercentColumn,
  hasPFDValueColumn,
  hasPFDPercentColumn,
  actualSociety,
}: ClassesTableRowsTotalsProps) => (
  <>
    {totalsRows?.map((row, index) => (
      <tr
        key={`${row.text}-${index}`}
        className={
          (currentPartners.length + index + 9) % 2 === 0
            ? 'text-center font-weight-bold '
            : 'text-center font-weight-bold bg-light-vert'
        }
      >
        <td className="text-left">{row.text}</td>

        {holdingClasses?.map((holdingClass, index1) => (
          <>
            {investorsView && index === 0 && (
              <DrawCell
                columnIndex={index1}
                rowIndex={index}
                value={classesShares[holdingClass?.['_id']]}
                text={text}
                keyText={`classesshares-${holdingClass?.['_id']}`}
              />
            )}

            {investorsView && index === 1 && (
              <DrawEmptyColumns
                columnsCount={1}
                rowIndex={index}
                rowsCount={holdingClasses.length - 1}
                previousColumnsLength={1}
              />
            )}

            {!investorsView && (
              <DrawCell
                columnIndex={index1}
                rowIndex={index}
                value={classesShares[holdingClass?.['_id']]}
                text={text}
                keyText={`classesshares-${holdingClass?.['_id']}`}
              />
            )}
          </>
        ))}

        <DrawCell
          columnIndex={0}
          rowIndex={index}
          value={row.column1Value as number}
          text={text}
          keyText="column1value"
        />

        {inversionView && (
          <DrawInversionTotals
            partners={currentPartners}
            partnersShares={partnersShares}
            actualSociety={actualSociety}
            text={text}
          />
        )}

        {hasNDValueColumn && (
          <DrawCell
            columnIndex={0}
            rowIndex={index}
            value={parseFloat(row.column2Value as string)}
            text={text}
            suffix=""
            keyText={`columnvalues-${index}-0`}
          />
        )}

        {hasNDPercentColumn && (
          <DrawCell
            columnIndex={1}
            rowIndex={index}
            value={parseFloat(row.column3Value as string)}
            text={text}
            suffix="%"
            keyText={`columnvalues-${index}-1`}
          />
        )}

        {hasFDValueColumn && (
          <DrawCell
            columnIndex={2}
            rowIndex={index}
            value={parseFloat(row.column4Value as string)}
            text={text}
            suffix=""
            keyText={`columnvalues-${index}-2`}
          />
        )}

        {hasFDPercentColumn && (
          <DrawCell
            columnIndex={3}
            rowIndex={index}
            value={parseFloat(row.column5Value as string)}
            text={text}
            suffix="%"
            keyText={`columnvalues-${index}-3`}
          />
        )}

        {hasPFDValueColumn && (
          <DrawCell
            columnIndex={4}
            rowIndex={index}
            value={parseFloat(row.column6Value as string)}
            text={text}
            suffix=""
            keyText={`columnvalues-${index}-4`}
          />
        )}

        {hasPFDPercentColumn && (
          <DrawCell
            columnIndex={5}
            rowIndex={index}
            value={parseFloat(row.column7Value as string)}
            text={text}
            suffix="%"
            keyText={`columnvalues-${index}-5`}
          />
        )}
      </tr>
    ))}
  </>
);

export default ClassesTableRowsTotals;
