import { useEffect, useState } from 'react';

import { Partner, Society } from 'types';
import { getPartnerNDPercent } from 'utils/filters';
import { ContributionData } from '../types/ContributionData';

type Props = {
  actualSociety: Society;
  amount: number;
};

const getPartnersContributionData = ({
  actualSociety,
  amount,
}: Props): ContributionData[] => {
  const partners: Partner[] = actualSociety?.partners || [];

  const partnersContributionData: ContributionData[] = [];

  partners.forEach((partner) => {
    if (partner.sharesCount.actual > 0) {
      const ndPercent = getPartnerNDPercent(partner, actualSociety) || 0;

      const toPerceive = (amount * ndPercent) / 100;

      partnersContributionData.push({
        partnerId: partner?._id,
        partnerName: partner?.name,
        ndPercent,
        toPerceive,
      });
    }
  });

  return partnersContributionData;
};

export const useGetPartnersContributionData = ({
  actualSociety,
  amount,
}: Props) => {
  const [partnersContributionData, setPartnersContributionData] = useState<
    ContributionData[]
  >([]);

  useEffect(() => {
    if (actualSociety) {
      const partnersContributionData = getPartnersContributionData({
        actualSociety,
        amount,
      });
      setPartnersContributionData(partnersContributionData);
    }
  }, [actualSociety, amount]);

  return {
    data: {
      partnersContributionData,
    },
  };
};
