export const contributionModalInitValue = {
  data: {
    date: '',
    amount: 0,
    comments: '',
    file: undefined,
    resumeView: false,
    lastOperationDate: '',
    partnersContributionData: [],
    isLoading: false,
  },
  actions: {
    handleCloseModal(): void {
      throw new Error('Function not implemented.');
    },
    handleChangeDate(): void {
      throw new Error('Function not implemented.');
    },
    handleChangeAmount(): void {
      throw new Error('Function not implemented.');
    },
    handleChangeComments(): void {
      throw new Error('Function not implemented.');
    },
    handleChangeFile(): void {
      throw new Error('Function not implemented.');
    },
    handleSaveContribution(): void {
      throw new Error('Function not implemented.');
    },
  },
};
