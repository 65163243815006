export const getTemplate = (societyLanguage: string) => {
  switch (societyLanguage) {
    case 'es':
      return 'dividends-notification-es';
    case 'en':
      return 'dividends-notification-en';
    case 'fr':
      return 'dividends-notification-fr';
    default:
      return '';
  }
};
