import { useCallback, useEffect, useState } from 'react';

import { Communication } from 'types';
import { PaginationResponse } from 'modules/_shared/components/Pagination/Pagination';
import { getCommunications } from 'modules/communications/redux/communicationActions';
import { store } from 'redux/store';

type UseGetCommunicationsProps = {
  societyId: string | undefined;
  communicationId: string | undefined;
  page: number;
  items: number;
  sortBy?: string;
  sortOrder?: string;
};

type CommunicationsResponse = {
  data: Communication[];
  pagination: PaginationResponse;
};

const DEFAULT_SORT_BY = 'date';
const DEFAULT_SORT_ORDER = 'desc';

export const useGetCommunications = ({
  societyId,
  communicationId,
  page,
  items,
  sortBy = DEFAULT_SORT_BY,
  sortOrder = DEFAULT_SORT_ORDER,
}: UseGetCommunicationsProps) => {
  const [data, setData] = useState<Communication[]>([]);
  const [pagination, setPagination] = useState<PaginationResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchCommunications = useCallback(async () => {
    if (!societyId || communicationId) return;

    setIsLoading(true);
    setIsError(false);

    try {
      const params = { page, items, sortBy, sortOrder };

      const response = (await store.dispatch(
        getCommunications(societyId, params)
      )) as CommunicationsResponse;

      setData(response.data);
      setPagination(response.pagination);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [societyId, communicationId, page, items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCommunications();
  }, [fetchCommunications]);

  return {
    data,
    pagination,
    isLoading,
    isError,
  };
};
