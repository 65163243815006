import { FC } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Interval, SharesTitle } from 'types';

import { dateFormat } from 'constants/formats';
import { formatNumber } from 'utils/formats';
import { useTranslate } from 'hooks/useTranslate';

import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';
import { setModal } from 'modules/_shared/redux/modalActions';

import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { ChangeSharesTitleStatusModalProvider } from 'modules/partners/modals/ChangeTitleStatusModal/context/ChangeSharesTitleStatusModalContext';
import ChangeSharesTitleStatusModal from 'modules/partners/modals/ChangeTitleStatusModal';
import { PartnerRow } from '../types/PartnerRow';
import { useGetDataFromTitle } from '../hooks/useGetDataFromTitle';
import useGetSharesTitleStatus from '../hooks/useGetSharesTitleStatus';

import IntervalRowLine from './IntervalRowLine';
import PartnerRowLine from './PartnerRowLine';

type Props = {
  index: number;
  title: SharesTitle;
};

const SharesTitlesTableRow: FC<Props> = ({ index, title }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );

  const {
    societyId,
    operationId,
    operationTypeLabel,
    intervals,
    partners,
    document,
  } = useGetDataFromTitle({ title }).data;

  const documents = document ? [document] : [];

  const { statusLabel, statusBadge } = useGetSharesTitleStatus({
    sharesTitle: title,
  }).data;

  const getMarginSeparator = (index: number) => ({
    marginTop: index > 0 ? '4px' : '0px',
  });

  const handleChageTitleStatus = () => {
    dispatch(
      setModal(
        <ChangeSharesTitleStatusModalProvider sharesTitle={title}>
          <ChangeSharesTitleStatusModal />
        </ChangeSharesTitleStatusModalProvider>
      )
    );
  };

  const toggleOptions = [
    <a
      className="d-flex cursor-pointer"
      key={`title-status-option-${index}`}
      onClick={handleChageTitleStatus}
    >
      <em className="icon ni ni-edit fs-20px" />
      <span className="ml-1">{t('ChangeStatus')}</span>
    </a>,
  ];

  return (
    <>
      <td
        className="nk-tb-col w-30px align-middle"
        key={`title-number-${index}`}
      >
        <span>{title?.number}</span>
      </td>

      <td
        className="nk-tb-col text-left align-middle"
        key={`title-partner-${index}`}
      >
        {partners?.map((partner: PartnerRow, partnerIndex: number) => (
          <div key={partner.id} style={getMarginSeparator(partnerIndex)}>
            <PartnerRowLine societyId={societyId} partner={partner} />
          </div>
        ))}
      </td>

      <td className="nk-tb-col align-middle" key={`title-operation-${index}`}>
        <Link to={`/detalle-transacciones/${societyId}/${operationId}`}>
          <span className="text-primary cursor-pointer">
            {t(operationTypeLabel)}
          </span>
        </Link>
      </td>

      <td
        className="nk-tb-col tb-col-xl align-middle text-right"
        key={`title-shares-${index}`}
      >
        <span>{formatNumber(title?.sharesCount)}</span>
      </td>

      <td
        className="nk-tb-col tb-col-xl align-middle text-right"
        key={`title-intervals-${index}`}
      >
        {intervals?.map((interval: Interval, intervalIndex: number) => (
          <div
            key={`${index}-${interval.shareFrom}-${interval.shareTo}`}
            style={getMarginSeparator(intervalIndex)}
          >
            <IntervalRowLine interval={interval} />
          </div>
        ))}
      </td>

      <td
        className="nk-tb-col tb-col-xl align-middle text-center"
        key={`title-creation-date-${index}`}
      >
        <span>
          {title?.createdAt
            ? format(new Date(title.createdAt), dateFormat)
            : '-'}
        </span>
      </td>

      <td
        className="nk-tb-col tb-col-xl align-middle text-center"
        key={`title-status-${index}`}
      >
        <span className={`badge badge-dot badge-${statusBadge}`}>
          {t(statusLabel)}
        </span>
      </td>

      <DocumentsColumn
        index={index}
        documentsCount={1}
        documents={documents}
        hideOnMobile={false}
        tagsObject={{ society: actualSociety, title }}
      />

      <td className="nk-tb-col align-middle" key={`titles-options-${index}`}>
        <ToggleMenu toggleOptions={toggleOptions} />
      </td>
    </>
  );
};

export default SharesTitlesTableRow;
