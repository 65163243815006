/* eslint-disable no-underscore-dangle */
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';

import EmailsLogo from 'assets/images/icon-sections/emails.svg';

import {
  communicationPages,
  CommunicationPages,
} from 'modules/communications/pages/Communications/hooks/useGetActivePage';

type SidenavProps = {
  activePage: CommunicationPages;
  onChangePage: any;
  societyId: string | undefined;
  totalCount: number;
};

const Sidenav: FC<SidenavProps> = ({
  activePage,
  onChangePage,
  societyId,
  totalCount,
}) => {
  const { t } = useTranslate();
  const history = useHistory();

  const handleOpenInboxPage = () => {
    if (societyId) {
      history.push(`/comunicaciones/${societyId}`);
    }
    onChangePage(communicationPages.INBOX);
  };

  const handleOpenSettingsPage = () => {
    if (societyId) {
      history.push(`/comunicaciones/${societyId}`);
    }
    onChangePage(communicationPages.SETTINGS);
  };

  return (
    <div
      className="nk-ibx-aside toggle-screen-lg"
      data-content="inbox-aside"
      data-toggle-overlay="true"
      data-toggle-screen="lg"
    >
      <div className="nk-ibx-nav overflow-hidden" data-simplebar="init">
        <div className="w-100 d-flex justify-content-center">
          <img
            src={EmailsLogo}
            alt="emails logo"
            className="mh-100px text-center my-2"
          />
        </div>

        <ul className="nk-ibx-menu">
          <li
            className={activePage === communicationPages.INBOX ? 'active' : ''}
            onClick={handleOpenInboxPage}
          >
            <button
              type="button"
              className="btn btn-block nk-ibx-menu-item p-2 pl-1"
            >
              <em className="icon ni ni-send" />
              <span className="nk-ibx-menu-text">{t('Sent')}</span>
              <span className="badge badge-pill badge-primary">
                {totalCount || 0}
              </span>
            </button>
          </li>

          <li
            className={
              activePage === communicationPages.SETTINGS ? 'active' : ''
            }
            onClick={handleOpenSettingsPage}
          >
            <button
              type="button"
              className="btn btn-block nk-ibx-menu-item p-2 pl-1 justify-content-start"
            >
              <em className="icon ni ni-setting" />
              <span className="nk-ibx-menu-text">{t('Settings')}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidenav;
