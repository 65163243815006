import { FC, createContext, useContext } from 'react';

type ScrollContextType = { actions: { handleScroll: () => void } };

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: FC<{
  // eslint-disable-next-line no-undef
  children: React.ReactNode;
}> = ({ children }) => {
  const handleScroll = () => {
    // Parse the hash from the URL
    const hashes = window.location.hash.split('#').filter(Boolean);
    const lastHash = hashes[hashes.length - 1];

    if (lastHash) {
      const targetElement = document.getElementById(lastHash);
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  return (
    <ScrollContext.Provider value={{ actions: { handleScroll } }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
