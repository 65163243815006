/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';

import { Communication } from 'types';
import { State } from 'redux/initialState';

import Certification from 'modules/communications/components/Certification';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import CommunicationPreview from 'modules/communications/components/CommunicationPreview';
import EmptyStateCard from 'modules/_shared/components/EmptyStates/EmptyStateCard';
import { useGetCommunicationsSectionPermissions } from 'modules/communications/hooks/useGetCommunicationSectionPermissions';
import { useInboxDetails } from '../hooks/useInboxDetails';
import InboxDetailsHeader from './InboxDetailsHeader';
import InboxDetailsCommunicationHeader from './InboxDetailsCommunicationHeader';
import InboxDetailsCommunicationAttachments from './InboxDetailsCommunicationAttachments';
import InboxDetailsCommunicationRecipients from './InboxDetailsCommunicationRecipients';
import InboxDetailsCommunicationInvalidRecipients from './InboxDetailsCommunicationInvalidRecipients';

type InboxDetailsProps = {
  communication: Communication | undefined;
};

const InboxDetails = ({ communication }: InboxDetailsProps) => {
  const printRef = useRef();
  const { t } = useTranslate();
  const { location } = useHistory();
  const { societyId, communicationId } = useParams();

  const { isReadOnly } = useGetCommunicationsSectionPermissions().data;

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const {
    data: {
      currentCommunication,
      parsedDoc,
      isFavorite,
      openCertification,
      hasAttachments,
      recipients,
      attachments,
      isLoading,
    },
    actions,
  } = useInboxDetails({
    communicationId,
    location,
    isReadOnly,
    communication,
    society: actualSociety,
    societyId,
  });

  return currentCommunication ? (
    <div
      className="nk-ibx-reply nk-reply"
      data-simplebar="init"
      /* @ts-ignore */
      ref={printRef}
    >
      {openCertification ? (
        <Certification
          communication={currentCommunication}
          recipientsInfo={recipients}
        />
      ) : (
        <>
          <InboxDetailsHeader
            communication={currentCommunication}
            actions={actions}
            isReadOnly={isReadOnly}
            isFavorite={isFavorite}
            printRef={printRef}
          />

          <div className="nk-ibx-reply-group">
            <div className="nk-ibx-reply-item nk-reply-item">
              <InboxDetailsCommunicationHeader
                communication={currentCommunication}
                parsedDoc={parsedDoc}
                actions={actions}
              />

              <div className="nk-reply-body nk-ibx-reply-body">
                <CommunicationPreview
                  data={{ communication: currentCommunication }}
                />

                <InboxDetailsCommunicationAttachments
                  communication={currentCommunication}
                  attachments={attachments}
                  hasAttachments={hasAttachments}
                />

                <hr className="divider" />

                <InboxDetailsCommunicationRecipients
                  communication={currentCommunication}
                  recipients={recipients}
                  actions={actions}
                />

                <InboxDetailsCommunicationInvalidRecipients
                  communication={currentCommunication}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {isLoading && <CustomLoading />}
    </div>
  ) : (
    <EmptyStateCard
      mainMessage={t('ErrorLoadingCommunications')}
      infoMessage={t('ErrorLoadingCommunicationsMessage')}
      bordered={false}
    />
  );
};

export default InboxDetails;
