import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';

import { Council } from 'types';
import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateDocumentToDeleted } from 'modules/administratives/pages/CouncilDetails/utils/updateDocumentToDeleted';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import councilTemplateTypes from 'modules/administratives/pages/CouncilDetails/constants/councilTemplateTypes';

import ChangeCouncilTemplateModalHeader from './components/ChangeCouncilTemplateModalHeader';
import ChangeCouncilTemplateModalBody from './components/ChangeCouncilTemplateModalBody';
import ChangeCouncilTemplateModalFooter from './components/ChangeCouncilTemplateModalFooter';
import { changeActTemplate } from './utils/changeActTemplate';
import { changeCertificationTemplate } from './utils/changeCertificationTemplate';

type Props = {
  councilId: string;
  templateType: string;
};

const ChangeCouncilTemplateModal: FC<Props> = ({
  councilId,
  templateType,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const society = useSelector((state: State) => state?.society?.actualSociety);
  const user = useSelector((state: State) => state?.user);
  const council = useSelector((state: State) =>
    state?.councils.find((council: Council) => council._id === councilId)
  );

  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeSelectedTemplate = (selectedOption: any) => {
    setSelectedTemplate(selectedOption.value);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeTemplate = async () => {
    try {
      setIsLoading(true);
      if (council && selectedTemplate) {
        if (templateType === councilTemplateTypes.ACT) {
          await changeActTemplate({ council, society, user, selectedTemplate });
        } else {
          await changeCertificationTemplate({
            council,
            society,
            user,
            selectedTemplate,
          });
        }

        successSwal(t('TemplateChangedSuccessfully'));
      }
    } catch {
      errorSwal(t('ErrorChangingTemplate'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <ChangeCouncilTemplateModalHeader
        templateType={templateType}
        handleCloseModal={handleCloseModal}
      />

      <ChangeCouncilTemplateModalBody
        societyId={society['_id']}
        handleChangeSelectedTemplate={handleChangeSelectedTemplate}
      />

      <ChangeCouncilTemplateModalFooter
        selectedTemplate={selectedTemplate}
        handleChangeTemplate={handleChangeTemplate}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default ChangeCouncilTemplateModal;
