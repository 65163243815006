import { useEffect, useState } from 'react';

import { Notification } from 'types';
import notificationStatus from '../constants/notificationStatus';

type Props = {
  notifications: Notification[];
};

export const useGetPenfingNotifications = ({ notifications }: Props) => {
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [notificationsPending, setNotificationsPending] = useState<number>(0);
  const [notificationsReadPercent, setNotificationsReadPercent] =
    useState<number>(0);

  useEffect(() => {
    const unreadStatus = notificationStatus[0].value;

    const count = notifications.length;
    const pending = notifications.filter(
      (notification) => notification.status === unreadStatus
    ).length;
    const readPercent = ((count - pending) / count) * 100;

    setNotificationsCount(count);
    setNotificationsPending(pending);
    setNotificationsReadPercent(readPercent);
  }, [notifications]);

  return {
    data: {
      notificationsCount,
      notificationsPending,
      notificationsReadPercent,
    },
  };
};
