import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const DividendDocumentModalHeader = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  return (
    <Modal.Header>
      <h4 className="modal-title">
        <span>{t('GenerateDividendDocuments')}</span>
      </h4>

      <a
        className="close cursor-pointer"
        onClick={() => dispatch(setModal(null))}
      >
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default DividendDocumentModalHeader;
