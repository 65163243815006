/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */

import operationTypes from 'constants/operationTypes';
import { replaceAccentedVowels } from 'utils/replaceAccentedVowels';

const ROW_LENGTH = 600;
const parseSociety = (society) => ({
  name: replaceAccentedVowels(society?.name || ''),
  address: {
    street: society?.legalAddress?.line1 || '',
    postalCode: society?.legalAddress?.zip || '',
    city: society?.legalAddress?.city || '',
    province: society?.legalAddress?.province || '',
  },
  country: society?.legalAddress?.country || '',
  accountNumber: society?.additional?.bankAccountNumber || '',
  identifier: society?.additional?.bankIdentifier || '',
  cif: society?.cif || '',
});

const paymentConceptsByType = {
  [operationTypes.DIVIDENDS_DISTRIBUTION]: 'PAGO DE DIVIDENDOS',
  [operationTypes.CAPITAL_INCREASE]: 'AUMENTO DE CAPITAL',
  [operationTypes.CAPITAL_DECREASE]: 'REDUCCIÓN DE CAPITAL',
  [operationTypes.OTHER]: 'OTROS',
};

const padNumber = (num, length) => num.toString().padStart(length, '0');

const cleanAccents = (str) =>
  str
    ? str
        ?.normalize('NFD')
        ?.replace(/[\u0300-\u036f]/g, '')
        ?.replace(/[ñÑ]/g, (match) => (match === 'ñ' ? 'n' : 'N'))
    : '';

const cleanString = (data) => cleanAccents(data.trim()).toUpperCase();

const formatText = (string, length) => {
  const currentString = cleanString(string);

  if (currentString.length > length) {
    return currentString.slice(0, length);
  }

  return currentString;
};

const formatAmount = (amount, padLenth = 11) =>
  `${padNumber(Math.round(parseFloat(amount) * 100), padLenth)}3`;

const formatTotalAmount = (amount, padLenth = 12) =>
  padNumber(Math.round(parseFloat(amount) * 100), padLenth);

const formatDate = (date = new Date()) =>
  date.toISOString().slice(0, 10).replace(/-/g, '');

const padString = (str, length, char = ' ') =>
  (str || '').toString().padEnd(length, char);

const formatAddress = (address = {}) => {
  const street = padString(formatText(address.street, 40), 40);
  const postalCode = padString(formatText(address.postalCode, 5), 5);
  const city = padString(formatText(address.city, 30), 30);
  const province = padString(formatText(address.province, 30), 30);

  return { street, postalCode, city, province };
};

const cleanAccountNumber = (account) =>
  (account || '').replace(/[^A-Z0-9]/g, '');

const generateRecord01 = (companyData, currentDate) => {
  const {
    name,
    address: { street, postalCode, city, province },
    country,
    accountNumber,
    identifier,
    cif,
  } = companyData;

  const record = [
    '01',
    'ORD',
    identifier,
    '001',
    cif,
    'DIV',
    currentDate,
    currentDate,
    'A',
    cleanAccountNumber(accountNumber),
    padString('', 10), // Empty space
    '0',
    padString(name, 50),
    padString('', 20), // Empty space
    padString(street, 40),
    padString('', 10),
    padString(postalCode, 5),
    padString('', 1),
    padString(city, 30),
    padString('', 14),
    padString(province, 30),
    padString('', 10),
    padString(country || 'ES', 2),
  ].join('');

  const currentLength = record.length;
  const remainingSpaces = ROW_LENGTH - currentLength;

  return remainingSpaces > 0
    ? `${record}${padString('', remainingSpaces)}`
    : record;
};

const generateRecord02 = (companyData) => {
  const { identifier, cif } = companyData;

  return [
    '02',
    'SCT',
    identifier,
    cif,
    'DIV',
    padString('', 578), // Remaining spaces
  ].join('');
};

const generateTransactionRecordData = (data, companyData, paymentConcept) => {
  let amountContent = '';
  // Process each transaction (03)
  let totalAmount = 0;
  let recordCount = 0;

  for (const record of data) {
    recordCount++;
    const amount = parseFloat(record.amount || 0);
    totalAmount += amount;

    const formattedAddress = formatAddress(record.address);
    const accountNumber = cleanAccountNumber(record.accountNumber);
    const cityPostal = formatText(
      `${record.address?.postalCode || ''} ${
        record.address?.city || ''
      }`.trim(),
      50
    );
    const name = formatText(record.name, 40);

    const transactionRecord = [
      '03',
      'SCT',
      companyData.identifier,
      '002',
      padString(record.cif, 9),
      padString('', 26), // Empty space
      'A',
      padString(accountNumber, 25),
      padString('', 9), // Empty space
      formatAmount(amount),
      padString('', 11), // Empty space
      padString(name, 70),
      padString(formattedAddress.street, 50),
      padString(cityPostal, 50),
      padString(formattedAddress.province, 40),
      padString(record.country || 'ES', 2),
      padString(paymentConcept, 50),
    ].join('');

    const currentLength = transactionRecord.length;
    const remainingSpaces = ROW_LENGTH - currentLength;

    let resultRecord = transactionRecord;

    if (remainingSpaces > 0) {
      resultRecord += `${padString('', remainingSpaces)}`;
    }
    amountContent += `${resultRecord}\n`;
  }

  return { amountContent, totalAmount, recordCount };
};

const generateRecord = ({
  prefix,
  type,
  totalAmount,
  recordCount,
  additionalPadding,
}) => {
  const record = [
    prefix,
    type,
    formatTotalAmount(totalAmount, 17),
    padNumber(recordCount, 8),
    padNumber(recordCount + additionalPadding, 10),
  ].join('');

  const currentLength = record.length;
  const remainingSpaces = ROW_LENGTH - currentLength;

  return remainingSpaces > 0
    ? `${record}${padString('', remainingSpaces)}`
    : record;
};

const generateResumeRecord0 = (totalAmount, recordCount) =>
  generateRecord({
    prefix: '04',
    type: 'SCT',
    totalAmount,
    recordCount,
    additionalPadding: 2,
  });

const generateResumeRecord1 = (totalAmount, recordCount) =>
  generateRecord({
    prefix: '99',
    type: 'ORD',
    totalAmount,
    recordCount,
    additionalPadding: 4,
  });

// Main SEPA file generator function
const generateSEPAFile = async ({
  data = [],
  society,
  operationType = operationTypes.OTHER,
}) => {
  try {
    let content = '';
    const currentDate = formatDate();
    const companyData = parseSociety(society);
    const paymentConcept = paymentConceptsByType[operationType] || 'OTROS';

    // Generate header record (01)
    const headerRecord = generateRecord01(companyData, currentDate);
    content += `${headerRecord}\n`;

    // Generate control record (02)
    const controlRecord = generateRecord02(companyData);
    content += `${controlRecord}\n`;

    // Generate amount records (03)
    const { amountContent, totalAmount, recordCount } =
      generateTransactionRecordData(data, companyData, paymentConcept);
    content += amountContent;

    const resumeRecord0 = generateResumeRecord0(totalAmount, recordCount);
    const resumeRecord1 = generateResumeRecord1(totalAmount, recordCount);
    content += `${resumeRecord0}\n`;
    content += `${resumeRecord1}\n`;

    // Save file
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `sepa_transfer_${currentDate}.txt`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    return {
      success: true,
      recordCount,
      totalAmount,
      filename: `sepa_transfer_${currentDate}.txt`,
    };
  } catch (error) {
    console.error('Error generating SEPA file:', error);
    throw error;
  }
};

export {
  generateSEPAFile,
  formatAmount,
  formatDate,
  padString,
  cleanAccountNumber,
};
