/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import sizes from 'constants/sizes';
import { cleanValue } from 'constants/formats';
import { getBeneficiaryFDPercent, getDecimalScale } from 'utils/filters';

import { setModal } from 'modules/_shared/redux/modalActions';
import BeneficiarySocietyValueSimulator from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator';
import getSimulatorValues from 'modules/beneficiaries/components/BeneficiarySocietyValueSimulator/utils/getSimulatorValues';
import BeneficiarySell from 'modules/beneficiaries/modals/BeneficiarySell';

import BeneficiaryAsidePersonalSection from './components/BeneficiaryAsidePersonalSection';
import BeneficiaryAsideTotalIncentiveSection from './components/BeneficiaryAsideTotalIncentiveSection';
import BeneficiaryAsideActualVestedSection from './components/BeneficiaryAsideActualVestedSection';

function BeneficiaryAside({
  beneficiary,
  society,
  setSimulatorValue,
  beneficiaryAllPlans,
}) {
  const dispatch = useDispatch();
  const { societyValue } = beneficiary;
  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [FDpercent] = useState(
    getBeneficiaryFDPercent(beneficiary, society).toFixed(decimalScale)
  );
  const [incentiveValue] = useState(
    societyValue ? (+FDpercent * societyValue.value) / 100 : 0
  );

  const [valuation, setValuation] = useState();
  const [summary, setSummary] = useState({});
  const [currentVestedValue, setCurrentVestedValue] = useState(0.0);
  const [currentVestedDate, setCurrentVestedDate] = useState('???');

  const hasBeneficiaryTotalIncentiveSectionDisabled =
    society?.configuration?.hasBeneficiaryTotalIncentiveSectionDisabled;

  const hasBeneficiarySocietyValueSimulatorSectionDisabled =
    society?.configuration?.hasBeneficiarySocietyValueSimulatorSectionDisabled;

  const calculateValues = () => {
    const {
      currentVestedValue: calculatedVestedValue,
      currentVestedDate: calculatedVestedDate,
      incentiveValue: calculatedIncentiveValue,
      exercisePrice: calculatedExercisePrice,
      FDpercent: calculatedFDpercent,
      summary: calculatedSummary,
    } = getSimulatorValues({ society, societyValue, beneficiaryAllPlans });

    setCurrentVestedValue(calculatedVestedValue);
    setCurrentVestedDate(calculatedVestedDate);
    setSummary(calculatedSummary);

    return {
      calculatedIncentiveValue,
      calculatedExercisePrice,
      calculatedFDpercent,
    };
  };

  const handleChangeSlider = (value) => {
    const { FDpercent: currentFD, exercisePrice } = getSimulatorValues({
      society,
      societyValue,
      beneficiaryAllPlans,
    });

    const newValue = cleanValue(value[0]);
    const valuation = (currentFD * newValue) / 100;
    const higherValue = Math.max(valuation, exercisePrice);

    setSimulatorValue(newValue);
    setValuation(higherValue);
  };

  const handleOpenSellView = () => {
    dispatch(
      setModal(
        // @ts-ignore
        <BeneficiarySell
          size={sizes.XL}
          society={society}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
          setSimulatorValue={setSimulatorValue}
          societyValue={societyValue}
        />
      )
    );
  };

  useEffect(() => {
    if (beneficiaryAllPlans.length) {
      calculateValues();
      const { incentiveValue } = getSimulatorValues({
        society,
        societyValue,
        beneficiaryAllPlans,
      });
      setValuation(incentiveValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BeneficiaryAsidePersonalSection beneficiary={beneficiary} />

      {!hasBeneficiaryTotalIncentiveSectionDisabled && (
        <BeneficiaryAsideTotalIncentiveSection
          beneficiary={beneficiary}
          incentiveValue={incentiveValue}
          summary={summary}
        />
      )}

      <BeneficiaryAsideActualVestedSection
        currentVestedValue={currentVestedValue}
        currentVestedDate={currentVestedDate}
      />

      {incentiveValue &&
        !hasBeneficiarySocietyValueSimulatorSectionDisabled && (
          <BeneficiarySocietyValueSimulator
            societyValue={societyValue}
            valuation={valuation}
            onChange={handleChangeSlider}
            onClickSimulatorButton={handleOpenSellView}
          />
        )}
    </>
  );
}

export default BeneficiaryAside;
