import { COMMENTS_MAX_LENGTH } from 'constants/defaultConstants';

const CommentsCharCounter = ({ comments, maxLength = COMMENTS_MAX_LENGTH }) => {
  const counterClassName =
    comments?.length === maxLength ? 'text-danger' : 'text-primary';

  return (
    <span className={counterClassName}>
      {comments?.length} / {maxLength}
    </span>
  );
};

export default CommentsCharCounter;
