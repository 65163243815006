import operationTypes from 'constants/operationTypes';
import { Dividend, Operation, Partner, Society } from 'types';

export const getOperationPartners = (
  operation: Operation,
  society: Society
): Partner[] => {
  const { operationType } = operation;
  const operationPartnersIds: string[] = [];

  if (operationType === operationTypes.DIVIDENDS_DISTRIBUTION) {
    (operation.dividends as Dividend[]).forEach((dividend: Dividend) => {
      operationPartnersIds.push(
        typeof dividend.partner === 'string'
          ? dividend.partner
          : dividend.partner._id
      );
    });
  }

  const operationPartners = society.partners.filter((partner: Partner) =>
    operationPartnersIds.includes(partner._id)
  );

  return operationPartners;
};
