export const notifyPartnersDividendsModalInitValue = {
  data: {
    isLoading: false,
    partnersData: [],
    expandView: false,
    expandDirection: 'down',
    hasInvalidEmails: false,
    invalidEmailsAlert: undefined,
    hasGeneratedEmails: false,
    generatedEmailsAlert: undefined,
  },
  actions: {
    handleChangeView(): void {
      throw new Error('Function not implemented.');
    },
    handleCloseModal(): void {
      throw new Error('Function not implemented.');
    },
    handleSendEmails(): void {
      throw new Error('Function not implemented.');
    },
    handleSendTestEmail(): void {
      throw new Error('Function not implemented.');
    },
  },
};
