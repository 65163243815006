import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useTranslate } from 'hooks/useTranslate';

import { useEditLetterStatusModal } from '../context/EditLetterStatusModalContext';

const EditLetterStatusModalBody: FC = () => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const { data, actions } = useEditLetterStatusModal();

  return (
    <Modal.Body>
      <div className="nk-modal">
        <div className="form-control-wrap mb-3">
          <Select
            closeMenuOnSelect
            className="react-select react-select-lg"
            options={data.statusOptions}
            components={animatedComponents}
            onChange={actions.handleChangeStatus}
            value={data.selectedStatusOption}
          />
        </div>
      </div>

      <div className="custom-control custom-control-xs custom-checkbox">
        <input
          type="checkbox"
          id="checkbox-notifications"
          className="custom-control-input c"
          checked={data.assumeCheckboxChecked}
          onChange={actions.handleChangeAssumeCheckboxChecked}
        />
        <label
          className="custom-control-label"
          htmlFor="checkbox-notifications"
        >
          {t('EditLetterStatusCheckboxMessage')}
        </label>
      </div>
    </Modal.Body>
  );
};

export default EditLetterStatusModalBody;
