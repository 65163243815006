import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useNotifyPartnersDividendsModal } from '../context/NotifyPartnersDividendsModalContext';

const NotifyPartnersDividendsModalHeader: FC = () => {
  const { t } = useTranslate();

  const { actions } = useNotifyPartnersDividendsModal();

  return (
    <Modal.Header>
      <h5 className="modal-title">
        <em className="icon icon-circle ni ni-send bg-primary-alt" />
        &nbsp;&nbsp;
        {t('NotifyPartners')}&nbsp;
      </h5>
      <a className="close cursor-pointer" onClick={actions.handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default NotifyPartnersDividendsModalHeader;
