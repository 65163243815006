import { validUrlAnchors } from 'constants/validUrlAnchors';
import { saveToStorage } from './token';

const normalizeAnchor = (anchor: string): string => {
  if (!anchor) {
    return '';
  }
  const normalizedAnchor = anchor.startsWith('#/')
    ? anchor.replace('#/', '#')
    : anchor;

  return normalizedAnchor;
};

export const validateAndStoreRedirection = (
  redirectionString: string,
  storageField: string,
  urlAnchor?: string
): void => {
  const redirectTokenString = redirectionString[1];
  const encodedSlash = ['%2F', '%2f'];

  const containsEncodedSlash = new RegExp(encodedSlash.join('|'), 'gi').test(
    redirectTokenString
  );

  let decodedRedirectToken = containsEncodedSlash
    ? redirectTokenString.replace(/%2F|%2f/gi, '/')
    : redirectTokenString;

  if (urlAnchor) {
    const normalizedAnchor = normalizeAnchor(urlAnchor);

    if (validUrlAnchors.includes(normalizedAnchor)) {
      decodedRedirectToken = `${decodedRedirectToken}${normalizedAnchor}`;
    }
  }

  saveToStorage(storageField, decodedRedirectToken);
  window.history.replaceState({}, document.title, '/');
};
