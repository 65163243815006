/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */

import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { formatNumber } from 'utils/formats';

import '../PartnerBook.scss';
import { getEncumbranceCellValue } from '../utils/getCellValues';

const styles = {
  cellTitle: { fontSize: '14px', fontWeight: 'bold', textAlign: 'left' },
  cellKeys: {
    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  cellValues: { fontSize: '10px', textAlign: 'left', verticalAlign: 'top' },
  cellMainValues: {
    fontSize: '10px',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  cellRight: { textAlign: 'right' },
  cellLeft: { textAlign: 'left' },
  cellCenter: { textAlign: 'center' },
  cellHeader: {
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    height: '30px',
    verticalAlign: 'middle',
    backgroundColor: 'blue',
    color: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 'thin',
  },
  cellFooter: {
    fontSize: '12px',
    backgroundColor: '#e0e0e0',
    height: '20px',
    verticalAlign: 'middle',
  },
  cellBold: { fontWeight: 'bold' },
  cellBordered: {
    borderStyle: 'solid',
    borderWidth: 'thin',
  },
};

function CommercialRegisterTable({ view, partnersRegister, text = false }) {
  const { t } = useTranslate();

  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    HERITAGE: t('Heritage'),
    DONATION: t('Donation'),
    BUY: t('Buy'),
    SELL: t('Sale'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
  };

  return (
    <>
      <div className={view === 1 && text ? 'nk-block' : 'd-none '}>
        <div className="card card-bordered">
          <div className="table-responsive">
            <table
              className="table table-striped text-right border-right partner-book-table"
              id={text ? 'partnersRegisterTableText' : 'partnersRegisterTable'}
              style={{
                width: '100%',
              }}
            >
              {!text && (
                <>
                  <tr>
                    <td style={styles.cellTitle} colSpan={3}>
                      Libro Registro de Socios
                    </td>
                  </tr>
                  <tr />
                  <tr>
                    <td style={styles.cellKeys}>Sociedad:</td>
                    <td style={styles.cellValues} colSpan={2}>
                      {actualSociety?.name}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellKeys}>CIF:</td>
                    <td style={styles.cellValues} colSpan={2}>
                      {actualSociety?.cif || '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellKeys}>Domicilio:</td>
                    <td style={styles.cellValues} colSpan={2}>
                      {(actualSociety?.legalAddress &&
                        Object.entries(actualSociety?.legalAddress)
                          .map(([, value]) => value)
                          .join(', ')) ||
                        '-'}
                    </td>
                  </tr>
                  <tr>
                    <td style={styles.cellKeys}>Fecha:</td>
                    <td style={styles.cellValues} colSpan={2}>
                      {format(new Date(), 'dd.MM.yyy')}
                    </td>
                  </tr>
                  <tr />
                </>
              )}
              <tr className={text ? 'text-center table-header' : 'none'}>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px' : 'none'}
                >
                  {t('Name')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px bg-light-vert' : 'none'}
                >
                  {t('FiscalIdentification')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px' : 'none'}
                >
                  {t('Nationality')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px bg-light-vert' : 'none'}
                >
                  {t('RegisteredOffice')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px' : 'none'}
                >
                  {t('Shares')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px bg-light-vert' : 'none'}
                >
                  % {t('Share')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px' : 'none'}
                >
                  {t('Numeration')}({t('Class')})
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px bg-light-vert' : 'none'}
                >
                  {t('Encumbrance')}
                </th>
                <th
                  style={text ? {} : styles.cellHeader}
                  className={text ? 'w-200px ' : 'none'}
                  colSpan={3}
                >
                  {t('TransactionHistory')}
                </th>
              </tr>

              {actualSociety?.partners
                .filter((partner) => partner?.sharesCount?.actual > 0)
                ?.sort(
                  (a, b) => (a?.partnerNumber || 0) - (b?.partnerNumber || 0)
                )
                ?.map((partner, index) => (
                  <tr
                    key={partner._id}
                    className={
                      text
                        ? index % 2 === 0
                          ? 'bg-light text-left w-200px'
                          : 'text-left w-200px'
                        : 'none'
                    }
                  >
                    {/* PARTNER NAME */}
                    <td
                      id={`${partner?.['_id']}rm`}
                      className={
                        text ? 'text-left d-block text-ellipsis' : 'none'
                      }
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                            }
                      }
                    >
                      {partner?.name}{' '}
                      {text && (
                        <Tooltip
                          anchorId={`${partner?.['_id']}rm`}
                          place="bottom"
                        >
                          {partner?.name}
                        </Tooltip>
                      )}
                    </td>

                    {/* CIF */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {partner?.cif}
                    </td>

                    {/* NATIONALITY */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                            }
                      }
                      className="text-center"
                    >
                      {partner?.nationality || '-'}
                    </td>

                    {/* ADDRESS */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {(partner?.address &&
                        Object.entries(partner?.address)
                          .map(([, value]) => value)
                          .join(', ')) ||
                        '-'}
                    </td>

                    {/* SHARES */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                              ...styles.cellCenter,
                            }
                      }
                      className="text-center"
                    >
                      {partner?.sharesCount?.actual}
                    </td>

                    {/* SHARE PERCENTAGE */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                              ...styles.cellCenter,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {`${partnersRegister[partner['_id']]?.percentage}%`}
                    </td>

                    {/* SHARES RANGES */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                              ...styles.cellCenter,
                            }
                      }
                      className="text-center"
                    >
                      {partnersRegister[partner['_id']]?.shares.map((share) => (
                        <span key={share._id}>
                          {`${formatNumber(share?.from)}-${formatNumber(
                            share?.to
                          )} (${share?.shareClass?.name})`}
                          <br />
                        </span>
                      ))}
                    </td>

                    {/* ENCUMBRANCES */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                              ...styles.cellCenter,
                            }
                      }
                      className="text-center"
                    >
                      {partnersRegister[partner['_id']]?.shares?.map((share) =>
                        share?.encumbrances?.map((encumbrance) => (
                          <span
                            key={`${share._id}-encumbrance-${encumbrance.from}`}
                          >
                            {getEncumbranceCellValue(encumbrance)}

                            <br />
                          </span>
                        ))
                      )}
                    </td>

                    {/* TRANSACTIONS HISTORY */}
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {partnersRegister[partner['_id']]?.history.map((op) =>
                        op?.transactions.map((transaction, index) => (
                          <>
                            {index === 0 ? (
                              <>
                                <Link
                                  id={`${partner._id}-${op.id}`}
                                  to={`/detalle-transacciones/${actualSociety._id}/${op.id}`}
                                >
                                  {transactionNames[op?.type]}
                                </Link>
                                {isAdmin && (
                                  <Tooltip
                                    anchorId={`${partner._id}-${op.id}`}
                                    place="bottom"
                                  >
                                    {op?.comments}
                                  </Tooltip>
                                )}
                                <br />
                              </>
                            ) : (
                              <br />
                            )}
                          </>
                        ))
                      )}
                    </td>
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...styles.cellMainValues,
                              ...styles.cellBordered,
                              ...styles.cellCenter,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {partnersRegister[partner['_id']]?.history.map((op) =>
                        op?.transactions.map((transaction, index) => (
                          <>
                            {index === 0 ? (
                              <>
                                {op?.date} <br />
                              </>
                            ) : (
                              <br />
                            )}
                          </>
                        ))
                      )}
                    </td>
                    <td
                      style={
                        text
                          ? {}
                          : {
                              ...{
                                ...styles.cellMainValues,
                                ...styles.cellBordered,
                              },
                              ...styles.cellCenter,
                            }
                      }
                      className={text ? 'bg-light-vert text-center' : 'none'}
                    >
                      {partnersRegister[partner['_id']]?.history.map((op) => (
                        <>
                          {op?.transactions.map((transaction) => (
                            <>
                              {transaction?.range}
                              <br />
                            </>
                          ))}
                        </>
                      ))}
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommercialRegisterTable;
