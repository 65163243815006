/* eslint-disable no-unused-vars */

export const changeSharesTitleStatusInitValue = {
  data: {
    status: '',
    statusOption: null,
    isLoading: false,
  },
  actions: {
    handleChangeStatus(selectedOption: any): void {
      throw new Error('Function not implemented.');
    },
    handleCloseModal(): void {
      throw new Error('Function not implemented.');
    },
    handleSaveStatus(): void {
      throw new Error('Function not implemented.');
    },
  },
};
