/* eslint-disable react/self-closing-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import { dayMonYea } from 'utils/formatDate';

import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';
import { societyHasTitles } from 'utils/societyHasTitles';
import { getTransactionAmount } from 'modules/transactions/pages/TransactionDetails/utils/getTransactionAmount';
import { getTransactionNominalValue } from 'modules/transactions/utils/getTransactionNominalValue';
import { getTransactionHoldingClass } from 'modules/transactions/utils/getTransactionHoldingClass';
import { useGetTransactionTitle } from './hooks/useGetTransactionTitle';
import { showLoadedDocuments } from './utils/showLoadedDocuments';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

function PartnerTransactionsRow({
  transaction,
  index,
  society,
  documents,
  tag,
}) {
  const { t } = useTranslate();

  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    HERITAGE: t('Heritage'),
    DONATION: t('Donation'),
    BUY: t('Buy'),
    SELL: t('Sale'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
    CONTRIBUTION: t('OtherContributions'),
  };

  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const holdingClasses = useSelector((state) => state.holdingClasses);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const [shares] = useState(transaction.shareTo - transaction.shareFrom + 1);

  const holdingClass = getTransactionHoldingClass(holdingClasses, transaction);

  const nominalValue = getTransactionNominalValue({
    holdingClass,
    transaction,
    society,
  });

  const amount =
    transaction?.amount ||
    getTransactionAmount({
      transaction,
      shares,
      nominalValue,
    });

  const hasTilesEnabled = societyHasTitles(actualSociety);
  const { transactionTitle, document } = useGetTransactionTitle({
    transaction,
  }).data;

  return (
    <tr>
      <td className="d-none d-xl-table-cell text-right vertical-align-middle">
        <b>{index}</b>
      </td>

      <td className="vertical-align-middle">
        {transactionNames[transaction?.alias || transaction?.transactionType]}
        {isAdmin && (
          <Tooltip anchorId={transaction['_id']} place="bottom">
            {transaction?.comments}
          </Tooltip>
        )}
      </td>

      <td className="d-none d-xl-table-cell text-right vertical-align-middle">
        {dayMonYea(transaction?.date)}
      </td>

      <td className="tb-col-end vertical-align-middle">
        <NumberFormat
          value={+amount}
          displayType="text"
          {...currencyFormatDecimals}
        />
      </td>

      <td className="text-right vertical-align-middle">
        {shares ? (
          <NumberFormat
            value={shares}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>

      {society?.hasShareTags && showDetails && (
        <td className="d-none d-xl-table-cell vertical-align-middle">
          {tag || '-'}
        </td>
      )}

      <td className="d-none d-xl-table-cell vertical-align-middle">
        {holdingClasses.find(
          (holding) => holding['_id'] === transaction?.shareClass
        )?.name || '-'}
      </td>

      <td className="d-none d-xl-table-cell text-right vertical-align-middle">
        {transaction?.shareFrom ? (
          <NumberFormat
            value={transaction.shareFrom}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>

      <td className="d-none d-xl-table-cell text-right vertical-align-middle">
        {transaction?.shareTo ? (
          <NumberFormat
            value={transaction.shareTo}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>

      <td
        className="text-center p-0 w-20px mx-auto vertical-align-middle"
        style={{ width: '30px' }}
      >
        {showLoadedDocuments({ documents, transaction })}
      </td>

      {hasTilesEnabled &&
        (transactionTitle ? (
          <DocumentsColumn
            index={index}
            documentsCount={1}
            documents={document ? [document] : []}
            hideOnMobile={false}
            tagsObject={{ society: actualSociety, title: transactionTitle }}
          />
        ) : (
          <td className="d-none d-md-table-cell vertical-align-middle text-center">
            <span>-</span>
          </td>
        ))}
    </tr>
  );
}

export default PartnerTransactionsRow;
