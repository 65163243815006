import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';

import { Dispatch } from 'redux';
import Swal, { SweetAlertOptions } from 'sweetalert2';

export const dispatchError = (error: any, dispatch: Dispatch) => {
  if (error?.response?.status === 403) {
    return;
  }

  if (error?.response?.status === 400) {
    return dispatch(addAlert(alertBodyTypes[400]));
  }

  if (error?.response?.status === 429) {
    return Swal.fire({ ...(alertBodyTypes[429] as SweetAlertOptions) }).then(
      async (result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      }
    );
  }

  return null;
  // return dispatch(addAlert(alertBodyTypes[500]));
};
