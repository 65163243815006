import { DATE_FORMAT } from 'constants/defaultConstants';
import { format } from 'date-fns';
import { getAdminMemberStatusData } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberStatusData';
import { getAdminMemberTypeLabel } from 'modules/administratives/pages/AdminMembers/utils/getAdminMemberTypeLabel';
import { SocietyDirector } from 'types';

const filterRoamingDirectors = (
  roamingDirectors: any[],
  director: SocietyDirector
) =>
  roamingDirectors?.filter(
    (roamingDirector) =>
      roamingDirector?.email === director?.email ||
      (roamingDirector?.cif === director?.cif && director?.cif !== '')
  );

const createDirectorObject = (roamingDirector: any) => {
  const { statusLabel, statusBadge } =
    getAdminMemberStatusData(roamingDirector);
  const memberTypeLabel = getAdminMemberTypeLabel(
    roamingDirector?.directorType
  );

  return {
    society: roamingDirector?.society,
    role: memberTypeLabel,
    startDate: roamingDirector?.initDate
      ? format(new Date(roamingDirector.initDate), DATE_FORMAT)
      : '-',
    status: statusLabel,
    statusBadge,
  };
};

export const getDirectorRoamingList = (
  director: SocietyDirector,
  roamingDirectors: any[]
) => {
  const ACTIVE_STATUS_LABEL = 'Valid';
  const filteredDirectors = filterRoamingDirectors(roamingDirectors, director);
  const directors: any = [];

  filteredDirectors.forEach((roamingDirector) => {
    const directorObject = createDirectorObject(roamingDirector);

    if (directorObject.status === ACTIVE_STATUS_LABEL) {
      directors.push(directorObject);
    }
  });

  return directors;
};
