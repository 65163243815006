/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { Dividend, Operation } from 'types';
import {
  currencyFormatDecimals,
  numberFormatDecimals,
} from 'constants/formats';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { State } from 'redux/initialState';
import { useDividendDocumentModalBody } from '../hooks/useDividendDocumentModalBody';
import { PartnerDividendDocumentData } from '../utils/getPartnerDataToDividendDocument';
import { generateDividendsDocuments } from '../utils/generateDividendsDocument';

type DividendDocumentModalBodyProps = {
  operation: Operation;
};

const DividendDocumentModalBody: FC<DividendDocumentModalBodyProps> = ({
  operation,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const {
    data: { dividends, template },
  } = useDividendDocumentModalBody(operation);

  const [selectedDividends, setSelectedDividends] =
    useState<Record<string, boolean>>();
  const [allSelected, setAllSelected] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const checkIfAllSelected = () =>
    dividends?.length > 0 &&
    dividends.every((dividend) => selectedDividends?.[dividend._id]);

  const handleSelectDividend = (dividendId: string) => {
    setSelectedDividends((prevSelectedDividends) => ({
      ...prevSelectedDividends,
      [dividendId]: !prevSelectedDividends?.[dividendId],
    }));
  };

  const handleSelectAllDividends = () => {
    setAllSelected((prevAllSelected) => !prevAllSelected);
    setSelectedDividends(
      dividends.reduce(
        (acc, dividend) => ({
          ...acc,
          [dividend._id]: !allSelected,
        }),
        {}
      )
    );
  };

  const hasSelectedDividends = Object.values(selectedDividends || {}).some(
    (isSelected) => isSelected
  );

  const generateDocument = async () => {
    if (!template) return;

    setIsGenerating(true);

    const dividendsList = dividends.filter(
      (dividend) => selectedDividends?.[dividend._id]
    ) as PartnerDividendDocumentData[];

    await generateDividendsDocuments({
      dividendsDataList: dividendsList,
      template: template.editorModel,
      actualSociety,
    });

    setIsGenerating(false);
    dispatch(setModal(null));
    dispatch(addAlert(alertBodyTypes.DOCUMENTS_GENERATED));
  };

  useEffect(() => {
    const isAllSelected = checkIfAllSelected();
    setAllSelected(isAllSelected);
  }, [selectedDividends]); // eslint-disable-line

  return (
    <>
      <Modal.Body>
        <p>{t('SelectDividendsToGenerateDocument')}</p>

        <div className="custom-control custom-checkbox custom-control-sm mb-3">
          <input
            type="checkbox"
            className="custom-control-input"
            name="dividend-all"
            id="dividend-all"
            checked={allSelected}
            onChange={handleSelectAllDividends}
          />
          <label
            className="custom-control-label title fs-14px"
            htmlFor="dividend-all"
          >
            {t('SelectAllShareholders')}
          </label>
        </div>

        <div className="card card-bordered card-preview mb-4">
          <table
            className="table table-tranx"
            id="dividends-distribution-table"
          >
            <thead>
              <tr className="tb-tnx-head">
                <th className="text-left">
                  <span>{t('Name')}</span>
                </th>

                <th className="text-left">
                  <span>{t('Percentage')}</span>
                </th>

                <th className="text-left d-none">
                  <span>{t('%Retention')}</span>
                </th>
                <th className="text-left d-none">
                  <span>{t('GrossAmount')}</span>
                </th>

                <th className="text-left">
                  <span>{t('ToPerceive')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {dividends.length > 0 &&
                dividends.map((dividend: Dividend) => (
                  <tr className="tb-tnx-item" key={dividend._id}>
                    <td className="tb-tnx-info">
                      <div className="tb-tnx-info w-150px">
                        <div className="custom-control custom-checkbox custom-control-sm">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name={`dividend-${dividend._id}`}
                            id={`dividend-${dividend._id}`}
                            checked={selectedDividends?.[dividend._id]}
                            onChange={() => handleSelectDividend(dividend._id)}
                          />
                          <label
                            className="custom-control-label title fs-14px"
                            htmlFor={`dividend-${dividend._id}`}
                          >
                            {typeof dividend.partner === 'string'
                              ? dividend.partner
                              : dividend.partner?.name}
                          </label>
                        </div>
                      </div>
                    </td>

                    <td className="tb-tnx-amount">
                      <div className="tb-tnx-total text-right w-100">
                        <span className="amount">
                          <NumberFormat
                            displayType="text"
                            value={dividend.percent}
                            {...numberFormatDecimals}
                          />
                          %
                        </span>
                      </div>
                    </td>

                    <td className="tb-tnx-amount d-none">
                      <div className="tb-tnx-total text-right w-100">
                        <span className="amount">
                          <NumberFormat
                            displayType="text"
                            value={dividend.retention}
                            {...currencyFormatDecimals}
                          />
                        </span>
                      </div>
                    </td>

                    <td className="tb-tnx-amount d-none">
                      <div className="tb-tnx-total text-right w-100">
                        <span className="amount">
                          <NumberFormat
                            displayType="text"
                            value={dividend.grossAmount}
                            {...currencyFormatDecimals}
                          />
                        </span>
                      </div>
                    </td>

                    <td className="tb-tnx-amount">
                      <div className="tb-tnx-total text-right w-100">
                        <span className="amount">
                          <NumberFormat
                            displayType="text"
                            value={dividend.amount}
                            {...currencyFormatDecimals}
                          />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end w-100">
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!hasSelectedDividends}
            onClick={generateDocument}
          >
            {t('Generate')}
          </button>
        </div>

        {isGenerating && <CustomLoading />}
      </Modal.Body>
    </>
  );
};

export default DividendDocumentModalBody;
