/* eslint-disable react/require-default-props */
import { useTranslate } from 'hooks/useTranslate';
import Swal from 'sweetalert2';

import { PDF_TYPE_FILE_ALLOWED } from 'constants/defaultConstants';
import { ChangeEvent, DragEvent, FC, useRef } from 'react';
import { allowedFileDocument } from 'utils/allowedFileDocument';
import errorSwal from 'utils/errorSwal';
import MandatoryField from 'modules/_shared/components/MandatoryField';
import { uploadDocumentAlerts } from './uploadDocumentAlerts';

type UploadDocumentProps = {
  label: string;
  inputLabel?: string;
  file: File | undefined;
  setFile: any;
  accept?: string;
  idIndex?: number;
  mandatory?: boolean;
};

const UploadDocument: FC<UploadDocumentProps> = ({
  label,
  inputLabel,
  file,
  setFile,
  accept = PDF_TYPE_FILE_ALLOWED,
  idIndex = 0,
  mandatory = false,
}) => {
  const { t } = useTranslate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAddFile = async (file: File) => {
    const isAllowedFile = await allowedFileDocument(file);
    if (isAllowedFile) {
      setFile(file);
    } else {
      errorSwal(t('InvalidFile'));
    }
  };

  const showUploadConfirmation = async (file: File) => {
    try {
      await Swal.fire(uploadDocumentAlerts.WANT_UPLOAD_DOCUMENT).then(
        (result) => {
          if (result.isConfirmed) {
            handleAddFile(file);
          }
        }
      );
    } catch (error) {
      errorSwal(t('ErrorUploadingFile'));
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];

    if (files.length > 1) {
      await Swal.fire(uploadDocumentAlerts.ONLY_ONE_DOCUMENT);
    } else {
      await showUploadConfirmation(files[0]);
    }
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleChooseDocument = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      setTimeout(() => {
        showUploadConfirmation(selectedFile);
      }, 100);
    }
  };

  return (
    <div
      className="form-group"
      style={{
        zIndex: 0,
      }}
    >
      <label
        className="form-label"
        htmlFor={`drag-drop-input-component-id-${idIndex}`}
      >
        {t(label)} {mandatory && <MandatoryField />}
      </label>
      <div
        className="nk-block-head-content"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          id={`drag-drop-input-component-id-${idIndex}`}
          key={`drag-drop-input-component-key-${idIndex}`}
          style={{ width: '90%' }}
        >
          <div className="card card-bordered sp-plan">
            <div
              className="sp-plan-action card-inner"
              style={{
                height: '10px',
              }}
            >
              <div>
                <span className="text-center w-300px d-block text-ellipsis">
                  {file?.name || inputLabel || t('DragDocumentToUpload')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <input
          id={`file-input-component-id-${idIndex}`}
          key={`file-input-component-key-${idIndex}`}
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleChooseDocument}
          accept={accept}
        />
        <label
          htmlFor={`file-input-component-id-${idIndex}`}
          className="btn btn-light"
          style={{ marginBottom: '0px' }}
        >
          {t('Choose')}
        </label>
      </div>
    </div>
  );
};

export default UploadDocument;
