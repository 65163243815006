import { FC } from 'react';

import { useContributionModal } from '../context/ContributionModalContext';
import { ContributionData } from '../types/ContributionData';

import ContributionTableRow from './ContributionTableRow';

const ContributionTableBody: FC = () => {
  const { data } = useContributionModal();

  return (
    <tbody>
      {data.partnersContributionData?.map(
        (partnerContributionData: ContributionData) => (
          <tr
            className="nk-tb-item"
            key={`contribution-row-${partnerContributionData?.partnerId}`}
          >
            <ContributionTableRow
              partnerContributionData={partnerContributionData}
            />
          </tr>
        )
      )}
    </tbody>
  );
};

export default ContributionTableBody;
