import transactionTypes from 'constants/transactionTypes';
import { HoldingClass, Operation, Society, Transaction } from 'types';

type GetTransactionAmountProps = {
  transaction: Transaction;
  nominalValue: number;
  shares: number;
  holdingClasses: HoldingClass[];
  society: Society;
  operation: Operation;
};

export const getTransactionAmount = ({
  transaction,
  shares,
  nominalValue,
}: GetTransactionAmountProps) => {
  if (!transaction) {
    return 0;
  }

  const amount =
    ((transaction?.transactionType === transactionTypes.BUY ||
      transaction?.transactionType === transactionTypes.SELL ||
      (transaction?.transactionType === transactionTypes.CAPITAL_DECREASE &&
        transaction?.sharePrice)) &&
      `${(transaction?.sharePrice || 0) * shares}`) ||
    `${(nominalValue + (transaction?.sharePremium || 0)) * shares}`;

  return amount;
};
