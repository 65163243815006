/* eslint-disable no-console */
import { axiosV1 } from 'interceptors/axios';
import { decryptResponse } from './token';

export const config = {
  DS_IDP: process.env.REACT_APP_DS_IDP,
  DS_APP_URL: `${process.env.REACT_APP_DOMAIN}/`,
  DS_SIGN_URL: `${process.env.REACT_APP_API_URL}/sign`,
  DS_CLIENT_ID: process.env.REACT_APP_DS_INTEGRATION_KEY,
  IMPLICIT_SCOPES: process.env.REACT_APP_DS_IMPLICIT_SCOPES,
  DS_API_CORS_PROXIES: {
    'https://na2.docusign.net': 'http://na2.docusign.net',
    'https://na3.docusign.net': 'https://na3.docusign.net',
    'https://eu.docusign.net': 'https://eu.docusign.net',
  },
  DS_REDIRECT_AUTHENTICATION: false,
  DS_DEBUG: false,
};

export function saveFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function toBase64(file) {
  try {
    const base64 = await saveFileToBase64(file);
    return base64;
  } catch (error) {
    console.error(error);
    return null;
  }
}

const sendDocumentToSign = async (signProcessData) => {
  const signData = {
    signers: signProcessData.signers,
    signatureType: signProcessData.signatureType,
    documentName: signProcessData.documentName,
    societyName: signProcessData.societyName,
    planName: signProcessData.planName,
    language: signProcessData.language,
    message: signProcessData?.message || '',
    fileBase64: signProcessData?.signatureFile,
    envelopeData: signProcessData?.envelopeData,
  };

  const body = { ...signData };

  const { data } = await axiosV1.post(`/sign`, body);

  const decodedData = await decryptResponse(data);
  return decodedData;
};

export async function startSignProcess(signProcessData) {
  const response = await sendDocumentToSign(signProcessData);
  return response;
}
