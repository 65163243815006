import { useTranslate } from 'hooks/useTranslate';
import { ChangeEvent, FC } from 'react';

type DashboardTypes = 'STANDARD' | 'LEGAL';

export const dashboardTypes: Record<DashboardTypes, string> = {
  STANDARD: 'STANDARD',
  LEGAL: 'LEGAL',
};

export const dashboardIcons: Record<DashboardTypes, string> = {
  STANDARD: 'ni-chart-up',
  LEGAL: 'ni-list-thumb',
};

type SocietyDashboardTypeSelectProps = {
  value: DashboardTypes;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void; // eslint-disable-line no-unused-vars
};

const SocietyDashboardTypeSelect: FC<SocietyDashboardTypeSelectProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslate();

  const dashboardOptions = [
    {
      value: dashboardTypes.STANDARD,
      label: t('ViewStandardDashboard'),
    },
    {
      value: dashboardTypes.LEGAL,
      label: t('ViewLegalDashboard'),
    },
  ];

  return (
    <div className="form-group cursor-pointer">
      <div className="form-control-wrap ">
        <div className="form-control-select">
          <div className="form-icon form-icon-left">
            <em
              className={`icon fw-bold text-base ni ${dashboardIcons[value]}`}
            />
          </div>

          <select
            id="dashboard-view"
            name="dashboard-view"
            value={value}
            onChange={onChange}
            className="form-control cursor-pointer fw-bold text-base pr-4"
          >
            {dashboardOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
                className="cursor-pointer"
              >
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SocietyDashboardTypeSelect;
