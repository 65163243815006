import { store } from 'redux/store';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import { FC, createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { Beneficiary } from 'types';
import { updateBeneficiary } from 'modules/beneficiaries/redux/beneficiaryActions';
import { getActualSociety } from 'utils/filters';
import { EditLetterStatusModalContextType } from '../types/EditLetterStatusModalContextType';
import { editLetterStatusModalInitValue } from '../constants/editLetterStatusModalInitValue';
import { useGetStatusOptions } from '../hooks/useGetStatusOptions';

const EditLetterStatusModalContext =
  createContext<EditLetterStatusModalContextType>(
    editLetterStatusModalInitValue
  );

export const EditLetterStatusModalProvider: FC<{
  children: React.ReactNode; // eslint-disable-line
  beneficiary: Beneficiary;
}> = ({ children, beneficiary }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const [invitationStatus, setInvitationStatus] = useState<string>('');
  const [assumeCheckboxChecked, setAssumeCheckboxChecked] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const statusOptionsResult = useGetStatusOptions({ beneficiary });
  const { selectedStatusOption, statusOptions } = statusOptionsResult.data;
  const { handleChangeSelectedStatusOption } = statusOptionsResult.actions;

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeStatus = (selectedOption: any) => {
    setInvitationStatus(selectedOption.value);
    handleChangeSelectedStatusOption(selectedOption);
  };

  const handleChangeAssumeCheckboxChecked = () => {
    setAssumeCheckboxChecked((prev) => !prev);
  };

  const handleSaveStatus = async () => {
    try {
      setIsLoading(true);
      const updatedBeneficiary = {
        invitationStatus,
      };
      await store.dispatch(
        updateBeneficiary({
          id: beneficiary['_id'],
          data: {
            ...updatedBeneficiary,
            userId: user['_id'],
            societyId: actualSociety['_id'],
            cascade: false,
          },
          showAlert: false,
        })
      );
      await getActualSociety(user, actualSociety['_id']);

      successSwal(t('SuccessfullyEditedStatusLetter'));
      handleCloseModal();
    } catch (error) {
      errorSwal(t('ErrorSendingNotification'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EditLetterStatusModalContext.Provider
      value={{
        data: {
          isLoading,
          invitationStatus,
          selectedStatusOption,
          statusOptions,
          assumeCheckboxChecked,
        },
        actions: {
          handleChangeAssumeCheckboxChecked,
          handleCloseModal,
          handleChangeStatus,
          handleSaveStatus,
        },
      }}
    >
      {children}
    </EditLetterStatusModalContext.Provider>
  );
};

export const useEditLetterStatusModal = () => {
  const context = useContext(EditLetterStatusModalContext);
  if (!context) {
    throw new Error(
      'useEditLetterStatusModal must be used within a EditLetterStatusModalProvider'
    );
  }
  return context;
};
