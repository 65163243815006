import { Communication } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import { invalidEmailReasonsKeys } from 'modules/communications/constants/invalidEmailReasons';

type InboxDetailsCommunicationInvalidRecipientsProps = {
  communication: Communication;
};

const InboxDetailsCommunicationInvalidRecipients = ({
  communication,
}: InboxDetailsCommunicationInvalidRecipientsProps) => {
  const { t } = useTranslate();

  if (!communication?.invalidRecipients?.length) {
    return null;
  }

  return (
    <>
      <hr className="divider" />

      <div className="table-recipients">
        <div className="d-flex flex-column">
          <h6 className="m-0">{t('InvalidRecipients')}</h6>
          <span className="text-soft mt-2 lh-18px d-flex align-items-end">
            <em className="icon ni ni-alert-circle mr-1 fs-18px" />
            {t('InvalidRecipientsDescription')}
          </span>
        </div>

        <div className="card card-bordered mt-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>{t('Email')}</th>
                <th>{t('Recipient')}</th>
                <th className="text-left">{t('Reason')}</th>
              </tr>
            </thead>

            <tbody>
              {communication.invalidRecipients.map((recipient) => (
                <tr key={recipient?._id}>
                  <td>{recipient?.email}</td>
                  <td>{recipient?.name}</td>
                  <td className="text-left text-danger">
                    {t(
                      invalidEmailReasonsKeys?.[
                        recipient?.reason as keyof typeof invalidEmailReasonsKeys
                      ]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default InboxDetailsCommunicationInvalidRecipients;
