import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useSendTestEmailModal } from '../context/SendTestEmailModalContext';

const SendTestEmailModalFooter: FC = () => {
  const { t } = useTranslate();
  const { data, actions } = useSendTestEmailModal();

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-outline-light px-3"
          onClick={actions.handleCloseModal}
        >
          {t('Close')}
        </button>

        <button
          type="button"
          className="btn btn-lg btn-primary"
          onClick={actions.handleSendEmail}
          disabled={!data.recipientEmail || data.invalidEmail}
        >
          {t('Send')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default SendTestEmailModalFooter;
