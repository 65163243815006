import { FC } from 'react';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import ChangeSharesTitleStatusModalHeader from './components/ChangeSharesTitleStatusModalHeader';
import ChangeSharesTitleStatusModalFooter from './components/ChangeSharesTitleStatusModalFooter';
import ChangeSharesTitleStatusModalBody from './components/ChangeSharesTitleStatusModalBody';
import { useChangeSharesTitleStatusModal } from './context/ChangeSharesTitleStatusModalContext';

const ChangeSharesTitleStatusModal: FC = () => {
  const { data } = useChangeSharesTitleStatusModal();

  return (
    <>
      <ChangeSharesTitleStatusModalHeader />
      <ChangeSharesTitleStatusModalBody />
      <ChangeSharesTitleStatusModalFooter />

      {data.isLoading && <CustomLoading />}
    </>
  );
};

export default ChangeSharesTitleStatusModal;
