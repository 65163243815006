import { useCallback, useEffect, useState } from 'react';

import { Council } from 'types';
import { getCouncil } from 'modules/administratives/redux/councilActions';

export const useGetCouncil = (councilId: string | undefined) => {
  const [data, setData] = useState<Council | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchCouncilData = useCallback(async () => {
    if (!councilId) {
      setData(null);
      return;
    }

    try {
      setIsLoading(true);
      if (councilId) {
        const council = await getCouncil(councilId);

        setData(council as Council);
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [councilId]);

  useEffect(() => {
    fetchCouncilData();
  }, [fetchCouncilData]);

  return { data, isLoading, isError, action: fetchCouncilData };
};
