import { Share, Society, Transaction } from 'types';

interface PartnerTransaction extends Transaction {
  nominalValue: number;
  currency: string;
  date: string;
  operation: string;
  documents: string[];
  comments: string;
  alias: string;
}

export const getTagFromTransaction = (
  transaction: PartnerTransaction,
  partnerShares: Share[],
  society: Society
) => {
  if (!society.hasShareTags) return '-';

  const partnerShare = partnerShares.find(
    (share) =>
      share?.from === transaction?.shareFrom &&
      share?.to === transaction?.shareTo &&
      share?.nominalValue === transaction?.nominalValue
  );

  return partnerShare?.tag;
};
