import { AxiosError } from 'axios';
import { translate } from 'hooks/useTranslate';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { DEFAULT_PRIMARY_COLOR } from 'constants/defaultConstants';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import alertOptions from 'modules/_shared/components/Alert/alertOptions';

export const showErrorMessage = (error: AxiosError) => {
  const { data } = error?.response || { data: null };

  if (!data) {
    return;
  }

  if (data.code === 'SESSION_DUPLICATED') {
    const currentLang = (localStorage.getItem('i18nextLng') ||
      'es') as keyof typeof alertBodyTypes.ERROR_401_SESSION_DUPLICATED;

    const alertConfig = {
      ...alertBodyTypes.ERROR_401_SESSION_DUPLICATED[currentLang],
      icon: alertOptions.ERROR.icon,
      confirmButtonText: translate('Close'),
      confirmButtonColor: DEFAULT_PRIMARY_COLOR,
    } as SweetAlertOptions;

    return Swal.fire(alertConfig);
  }
};
