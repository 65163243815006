/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'redux/store';
import { dateFormat, dateFormatYear } from 'constants/formats';

import downloadFile from 'utils/downloadFile';
import { formatCurrencyDecimals } from 'utils/formats';

import MenuDots from 'modules/_shared/components/MenuDots';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { getDocument } from 'modules/documents/redux/documentActions';
import { getPartnerDataToDividendDocument } from 'modules/transactions/components/DividendDocumentModal/utils/getPartnerDataToDividendDocument';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { generateDividendDocument } from 'modules/transactions/components/DividendDocumentModal/utils/generateDividendsDocument';
import dividendsTemplates from 'constants/dividendsTemplates';

const PartnerDividendsRow = ({
  dividend,
  index,
  summaryView = false,
  partnerId,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const {
    date,
    percent,
    totalAmount,
    amount,
    retention,
    grossAmount,
    documents,
    operation,
  } = dividend;

  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [isGenerating, setIsGenerating] = useState(false);

  const showLoadedDocuments = (documents) => {
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => {
            downloadFile({ documentId: documents[0] });
          }}
        >
          <em className="icon ni ni-file-download" />
        </span>
      );
    }
    if (documents?.length > 1) {
      const options = documents.map((doc, index) => ({
        icon: 'ni-file',
        text: doc?.name || `${t('Document')} #${index}`,
        download: true,
        documentId: doc,
      }));
      return (
        <span className="d-flex mt-2">
          <MenuDots
            menuOptions={options}
            direction="left"
            customIcon="ni-files"
          />
        </span>
      );
    }
    return <span>-</span>;
  };

  const handleDownloadLetter = async () => {
    setIsGenerating(true);
    try {
      const dividendData = getPartnerDataToDividendDocument({
        partnerId,
        operation,
        society: actualSociety,
      });
      const societyTemplate = actualSociety?.templates?.dividends;

      const templateId =
        societyTemplate || dividendsTemplates[process.env.NODE_ENV];
      const template = await store.dispatch(getDocument(templateId));

      await generateDividendDocument({
        dividendData,
        template: template?.editorModel,
        actualSociety,
      });

      dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <>
      <tr>
        <td className="text-left vertical-align-middle d-none d-md-table-cell w-20px">
          <b>{index}</b>
        </td>

        <td className="text-left vertical-align-middle pl-2 pl-md-2">
          {format(new Date(date), summaryView ? dateFormatYear : dateFormat)}
        </td>

        <td className="text-right vertical-align-middle d-none d-md-table-cell">
          {formatCurrencyDecimals(totalAmount)}
        </td>

        <td className="text-right vertical-align-middle">
          {(percent || 0).toFixed(2)}%
        </td>

        <td className="text-right vertical-align-middle d-none d-md-table-cell">
          {formatCurrencyDecimals(grossAmount || 0)}
        </td>

        <td className="text-right vertical-align-middle d-none d-md-table-cell">
          {(retention || 0).toFixed(2)}%
        </td>

        <td className="text-right vertical-align-middle pr-2 pr-md-3">
          {formatCurrencyDecimals(amount || 0)}
        </td>

        {!summaryView && (
          <>
            <td className="text-center vertical-align-middle p-0 w-20px">
              {documents.length ? (
                showLoadedDocuments(documents)
              ) : (
                <span className="btn btn-icon disabled">
                  <em className="icon ni ni-file-download" />
                </span>
              )}
            </td>
            <td className="text-center vertical-align-middle p-0 w-20px">
              <span className="btn btn-icon" onClick={handleDownloadLetter}>
                <em className="icon ni ni-file-download" />
              </span>
            </td>
          </>
        )}
      </tr>

      {isGenerating && <CustomLoading />}
    </>
  );
};
export default PartnerDividendsRow;
