import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { societyHasTitles } from 'utils/societyHasTitles';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

const PartnerTransactionsTable = ({
  rows,
  hasShareTags,
  summaryView = false,
}) => {
  const { t } = useTranslate();
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const hasSharesTitleEanbled = societyHasTitles(actualSociety);

  return (
    <div className="card card-bordered card-preview">
      <table
        className={`table table-striped ${summaryView ? 'table-font-sm' : ''}`}
      >
        <thead>
          <tr key="transactions-table">
            <th scope="col" className="d-none d-xl-table-cell w-20px">
              {t('Nr')}
            </th>
            <th scope="col">{t('Type')}</th>
            <th scope="col" className="d-none d-xl-table-cell">
              {t('Date')}
            </th>
            <th scope="col">{t('Amount')}</th>
            <th scope="col" className="">
              {t('NrPart.')}
            </th>
            {hasShareTags && showDetails && (
              <th scope="col" className="d-none d-xl-table-cell">
                {t('Tag')}
              </th>
            )}
            <th scope="col" className="d-none d-xl-table-cell">
              {t('Class')}
            </th>
            <th scope="col" className="d-none d-xl-table-cell">
              {t('From')}
            </th>
            <th scope="col" className="d-none d-xl-table-cell">
              {t('To')}
            </th>
            <>
              <th
                scope="col"
                className="text-left d-none d-md-table-cell w-20px"
              >
                {t('Doc.')}
              </th>
              <th
                scope="col"
                className="text-left d-table-cell d-md-none w-20px"
              />
            </>
            {hasSharesTitleEanbled && (
              <th scope="col" className="d-none d-xl-table-cell">
                {t('Title')}
              </th>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default PartnerTransactionsTable;
