import { store } from 'redux/store';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import { FC, createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';
import SendTestEmail from 'modules/_shared/components/Modals/SendTestEmail';
import { SendTestEmailModalProvider } from 'modules/_shared/components/Modals/SendTestEmail/context/SendTestEmailModalContext';
import { useGetPartnersData } from '../hooks/useGetPartnersData';

import { notifyPartnersDividendsModalInitValue } from '../constants/notifyPartnersDividendsModalInitValue';

import { NotifyPartnersDividendsModalContextType } from '../types/NotifyPartnersDividendsModalContextType';
import { PartnerData } from '../types/PartnerData';

import { getEmailData } from '../utils/getEmailData';

const NotifyPartnersDividendsModalContext =
  createContext<NotifyPartnersDividendsModalContextType>(
    notifyPartnersDividendsModalInitValue
  );

export const NotifyPartnersDividendsModalProvider: FC<{
  children: React.ReactNode; // eslint-disable-line
  dividends: any;
}> = ({ children, dividends }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const {
    partnersData,
    invalidEmailsAlert,
    hasInvalidEmails,
    hasGeneratedEmails,
    generatedEmailsAlert,
  } = useGetPartnersData({ dividends }).data;

  const [expandView, setExpandView] = useState<boolean>(false);
  const [expandDirection, setExpandDirection] = useState<string>('down');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeView = () => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const handleSendEmails = async () => {
    try {
      setIsLoading(true);
      const recipients = partnersData;

      const emailData = getEmailData({
        subject: `${t('PartnersDividends')}-${actualSociety?.name}`,
        actualSociety,
        recipients,
        userId: user?._id,
      });

      await store.dispatch(sendCommEmail(emailData, false));

      successSwal(t('NotificationSentSuccessfully'));
      handleCloseModal();
    } catch (error) {
      errorSwal(t('ErrorSendingNotification'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendTestEmail = async () => {
    const recipients: PartnerData[] = [];

    const emailData = getEmailData({
      subject: `${t('PartnersDividends')}-${actualSociety?.name}`,
      actualSociety,
      recipients,
      userId: user?._id,
    });

    handleCloseModal();

    dispatch(
      setModal(
        <SendTestEmailModalProvider emailData={emailData}>
          <SendTestEmail />
        </SendTestEmailModalProvider>
      )
    );
  };

  return (
    <NotifyPartnersDividendsModalContext.Provider
      value={{
        data: {
          isLoading,
          partnersData,
          expandView,
          expandDirection,
          invalidEmailsAlert,
          hasInvalidEmails,
          hasGeneratedEmails,
          generatedEmailsAlert,
        },
        actions: {
          handleChangeView,
          handleCloseModal,
          handleSendEmails,
          handleSendTestEmail,
        },
      }}
    >
      {children}
    </NotifyPartnersDividendsModalContext.Provider>
  );
};

export const useNotifyPartnersDividendsModal = () =>
  useContext(NotifyPartnersDividendsModalContext);
