import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import LetterTabConditionsView from './LetterTabConditionsView';
import LetterTabEditView from './LetterTabEditView';
import LetterTabDocumentPreviewView from './LetterTabDocumentPreviewView';

type Props = {
  planName: string;
  extraSignerName: string;
  extraSignerEmail: string;
  hasExtraSigner: boolean;
  htmlContent: string;
  attachmentFileName: string;
  attachmentFile: File | undefined;
  // eslint-disable-next-line no-unused-vars
  handleChangeHtmlContent: (content: string) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerEmail: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeAttachmentFile: (file: File) => void;
};

const LetterTabBody = ({
  planName,
  extraSignerName,
  extraSignerEmail,
  hasExtraSigner,
  htmlContent,
  attachmentFileName,
  attachmentFile,
  handleChangeHtmlContent,
  handleChangeExtraSignerName,
  handleChangeExtraSignerEmail,
  handleChangeAttachmentFile,
}: Props) => {
  const { t } = useTranslate();

  const [page, setPage] = useState(0);

  return (
    <Modal.Body>
      <div className="nk-block">
        <ul className="nk-nav nav nav-tabs mt-xl">
          <li className="nav-item" onClick={() => setPage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              {t('Conditions')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>
              {t('EditLetter')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(2)}>
            <p className={`nav-link ${page === 2 && 'active'}`}>
              {t('DocumentPreview')}
            </p>
          </li>
        </ul>

        <div className="nk-modal">
          {page === 0 && (
            <LetterTabConditionsView
              planName={planName}
              extraSignerName={extraSignerName}
              extraSignerEmail={extraSignerEmail}
              hasExtraSigner={hasExtraSigner}
              attachmentFileName={attachmentFileName}
              attachmentFile={attachmentFile}
              handleChangeExtraSignerName={handleChangeExtraSignerName}
              handleChangeExtraSignerEmail={handleChangeExtraSignerEmail}
              handleChangeAttachmentFile={handleChangeAttachmentFile}
            />
          )}
          {page === 1 && (
            <LetterTabEditView
              htmlContent={htmlContent}
              handleChangeHtmlContent={handleChangeHtmlContent}
            />
          )}
          {page === 2 && (
            <LetterTabDocumentPreviewView htmlContent={htmlContent} />
          )}
        </div>
      </div>
    </Modal.Body>
  );
};

export default LetterTabBody;
