import { Link } from 'react-router-dom';

const NotificationButton = () => (
  <Link className="btn btn-trigger px-1" to="/notificaciones">
    <span className="nk-menu-icon">
      <div className="icon-status icon-status-info">
        <em className="icon ni ni-bell fs-24px" />
      </div>
    </span>
  </Link>
);

export default NotificationButton;
