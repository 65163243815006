import { axiosV2 } from 'interceptors/axios';
import { dispatchError } from 'utils/dispatchError';

type UpdatePartnerProps = {
  id: string;
  data: any;
  showAlert?: boolean;
};

export const updatePartner =
  ({ id, data }: UpdatePartnerProps) =>
  async (dispatch: any) => {
    const body = {
      partnerId: id,
      ...data,
    };

    try {
      await axiosV2.put(`/partners`, body);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      return dispatchError(error, dispatch);
    }
  };
