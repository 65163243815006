import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const ContributionTableHeader: FC = () => {
  const { t } = useTranslate();
  return (
    <thead>
      <tr className="tb-tnx-head">
        <th className="text-left">
          <span>{t('Name')}</span>
        </th>

        <th className="text-left">
          <span>{t('%ND')}</span>
        </th>

        <th className="text-left">
          <span>{t('ToPerceive')}</span>
        </th>
      </tr>
    </thead>
  );
};

export default ContributionTableHeader;
