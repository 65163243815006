import { useTranslate } from 'hooks/useTranslate';
import { FC, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Council, Document } from 'types';
import useGetDocuments from 'modules/administratives/pages/CouncilDetails/hooks/useGetDocuments';
import UploadDocument from 'modules/documents/components/UploadDocument';

type Props = {
  council: Council;
  documentFile: File | undefined;
  handleChangeDocumentFile: (file: File) => void; // eslint-disable-line
  handleChangeSelectedDocument: (option: any) => void; // eslint-disable-line
};

const getOptions = (documents: Document[]) => {
  const options = documents?.map((document) => ({
    value: document._id,
    label: document.name,
  }));

  return options;
};

const ReplaceTemplateWithDocumentModalBody: FC<Props> = ({
  council,
  documentFile,
  handleChangeSelectedDocument,
  handleChangeDocumentFile,
}: Props) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const documentsIds = useMemo(
    () => [
      ...(council?.publicDocuments ?? []),
      ...(council?.privateDocuments ?? []),
    ],
    [council?.publicDocuments, council?.privateDocuments]
  );

  const {
    data: { documents },
    isLoading,
  } = useGetDocuments({
    documentsIds,
  });

  const [selectedOption, setSelectedOption] = useState<any>(null);

  const handleChangeSelectedOption = (selectdedOption: any) => {
    setSelectedOption(selectdedOption);
    handleChangeSelectedDocument(selectdedOption);
  };

  return (
    <>
      <Modal.Body>
        <span className="text-muted text-justify">
          {t('ReplaceCouncilTemplateWithDocumentDescription')}
        </span>

        <div className="row my-4">
          <div className="col">
            <div className="form-group">
              <label className="form-label" htmlFor="council-template-select">
                {t('SelectDocument')}
              </label>

              <Select
                id="council-template-select"
                closeMenuOnSelect
                className="react-select-lg"
                value={!documentFile ? selectedOption : null}
                options={getOptions(documents)}
                components={animatedComponents}
                onChange={handleChangeSelectedOption}
                placeholder={t('Select')}
                isLoading={isLoading}
                loadingMessage={() => t('LoadingDocuments')}
                noOptionsMessage={() => t('NoDocumentsAvailable')}
              />
            </div>

            <UploadDocument
              file={documentFile}
              setFile={handleChangeDocumentFile}
              label="AddDocument"
              inputLabel={documentFile?.name}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ReplaceTemplateWithDocumentModalBody;
