import { Guest, Society, User } from 'types';
import { store } from 'redux/store';
import { getSocietyById, setSociety } from '../redux/societyActions';

type SocietyPermissions = {
  dashboard: { enabled: boolean; readOnly?: boolean };
  partners: { enabled: boolean; readOnly?: boolean };
  portfolio: { enabled: boolean; readOnly?: boolean };
  drafts: { enabled: boolean; readOnly?: boolean };
  plans: { enabled: boolean; readOnly?: boolean };
  transactions: { enabled: boolean; readOnly?: boolean };
  communications: { enabled: boolean; readOnly?: boolean };
  boards: { enabled: boolean; readOnly?: boolean };
  documents: { enabled: boolean; readOnly?: boolean };
  societyConfiguration: { enabled: boolean; readOnly?: boolean };
};

type Role = {
  isAdmin?: boolean;
  isPartner?: boolean;
  isBeneficiary?: boolean;
  isInvestor?: boolean;
  isRepresentant?: boolean;
  isGuest?: boolean;
  isHolder?: boolean;
  isDirector?: boolean;
  isDemo?: boolean;
};

type Result = {
  society?: Society;
  role?: Role | undefined;
  permissions?: Partial<SocietyPermissions>;
};

const getRolesBySociety = (
  user: User,
  society: Society,
  usersWithAccess: User[] | string[]
) => {
  const {
    societies: {
      participated,
      administrated,
      beneficiated,
      invested,
      represented,
      invited,
      directed,
    },
  } = user;

  const societyId = society._id;

  const result = {
    role: {
      isAdmin: false,
      isPartner: false,
      isBeneficiary: false,
      isInvestor: false,
      isRepresentant: false,
      isGuest: false,
      isHolder: false,
      isDirector: false,
      isDemo: false,
    },
  };

  result.role.isAdmin = !!administrated?.find((id) => id === societyId);
  result.role.isPartner = !!participated?.find((id) => id === societyId);
  result.role.isBeneficiary = !!beneficiated?.find((id) => id === societyId);
  result.role.isInvestor = !!invested?.find((id) => id === societyId);
  result.role.isRepresentant = !!represented?.find((id) => id === societyId);
  result.role.isGuest = !!invited?.find((id) => id === societyId);
  result.role.isHolder = !!participated?.find(
    (id) => !!(id === societyId) && !!(society.isSocietyHolding === true)
  );
  result.role.isDirector = !!directed?.find((id) => id === societyId);
  result.role.isDemo = !!society?.demos?.find(
    (demo) => demo.userId === user['_id']
  );

  (usersWithAccess as User[]).forEach((currentUser) => {
    result.role.isAdmin =
      result.role.isAdmin ||
      !!currentUser.societies?.administrated?.find((id) => id === societyId);
    result.role.isPartner =
      result.role.isPartner ||
      !!currentUser.societies?.participated?.find((id) => id === societyId);
    result.role.isBeneficiary =
      result.role.isBeneficiary ||
      !!currentUser.societies?.beneficiated?.find((id) => id === societyId);
    result.role.isInvestor =
      result.role.isInvestor ||
      !!currentUser.societies?.invested?.find((id) => id === societyId);
    result.role.isRepresentant =
      result.role.isRepresentant ||
      !!currentUser.societies?.represented?.find((id) => id === societyId);
    result.role.isGuest =
      result.role.isGuest ||
      !!currentUser.societies?.invited?.find((id) => id === societyId);
    result.role.isHolder =
      result.role.isHolder ||
      !!currentUser.societies?.participated?.find(
        (id) => !!(id === societyId) && !!(society.isSocietyHolding === true)
      );
    result.role.isDirector =
      result.role.isDirector ||
      !!currentUser.societies?.directed?.find((id) => id === societyId);
  });

  return result.role;
};

export const getActualSocietyProgressive = async (
  user: User,
  society: Society
) => {
  if (!user || !society) return null;

  const societyId = society._id;
  const { usersWithAccess, adminPermissions, guests } = user;

  const result: Partial<Result> = {
    society,
    role: getRolesBySociety(user, society, usersWithAccess),
  };

  const societyGuests = (guests as Guest[])?.find(
    (guest) =>
      guest?.societies?.includes(societyId) && guest?.email === user.email
  );

  const currentSocietyGuestPermissions = societyGuests?.guestPermissions?.find(
    (permission) => permission?.society === societyId
  );

  const currentSocietyAdminPermissions = adminPermissions?.find(
    (permission) => permission?.society === societyId
  );

  result.permissions =
    (result.role?.isAdmin && currentSocietyAdminPermissions?.permissions) ||
    (result.role?.isGuest && currentSocietyGuestPermissions?.permissions) ||
    {};

  const updateActivePartners = false;

  store.dispatch(setSociety(result, user, updateActivePartners));
};
