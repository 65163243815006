import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Committee } from 'types';

import NoItemsAvailable from 'modules/_shared/components/EmptyStates/NoItemsAvailable';
import CommitteesTableRow from './CommitteeTableRow';

type Props = {
  committees: Committee[];
};

const CommitteesTableBody: FC<Props> = ({ committees }) => {
  const { t } = useTranslate();

  const hasData = committees?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          committees?.map((committee: Committee, index: number) => (
            <tr className="nk-tb-item" key={`committee-row-${committee?._id}`}>
              <CommitteesTableRow index={index} committee={committee} />
            </tr>
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoCommiteesMessage')} />
        )}
      </tbody>
    </>
  );
};

export default CommitteesTableBody;
