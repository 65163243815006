import { BankSEPAEmployee, Operation, Partner, Society } from 'types';
import { getPartnerAmountFromOperation } from './getPartnerAmountFromOperation';

const generateFakeIBAN = (): string => {
  const random = Math.floor(Math.random() * 100000000000000000000);
  return `ES${random}`;
};

const getPartnerAddress = (partner: Partner) => ({
  street: partner?.address?.line1 || '',
  postalCode: partner?.address?.zip || '',
  city: partner?.address?.city || '',
  province: partner?.address?.state || '',
});

const parsePartnerToSEPA = (
  partner: Partner,
  operation: Operation
): BankSEPAEmployee => ({
  name: partner.name,
  accountNumber: partner?.bankAccountNumber || '',
  amount: getPartnerAmountFromOperation(partner._id, operation),
  address: getPartnerAddress(partner),
  country: 'ES',
  cif: partner?.cif || '',
});

export const parsePartnersToSEPA = (
  partners: Partner[],
  operation: Operation,
  society: Society
): BankSEPAEmployee[] => {
  if (!society?.partners?.length || !operation) return [];

  return partners.map((partner: Partner) =>
    parsePartnerToSEPA(partner, operation)
  );
};
