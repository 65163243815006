export const getNewOperationDate = (
  lastOperationDate: Date | string,
  currentDate: Date | string
): Date => {
  const lastDate = lastOperationDate ? new Date(lastOperationDate) : null;
  const currDate = new Date(currentDate);

  if (lastDate) {
    const isSameDay = lastDate.toDateString() === currDate.toDateString();

    if (isSameDay) {
      // Add 15 minutes to the last operation date
      return new Date(lastDate.getTime() + 15 * 60 * 1000);
    }
  }

  // If no last operation or not the same day, return the current date
  return new Date(currDate);
};
