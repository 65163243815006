import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import ModalAlert from 'modules/_shared/components/ModalAlert';
import PartnersTableView from './PartnersTableView';
import { useNotifyPartnersDividendsModal } from '../context/NotifyPartnersDividendsModalContext';

const NotifyPartnersDividendsModalBody: FC = () => {
  const { data } = useNotifyPartnersDividendsModal();

  return (
    <Modal.Body>
      <div className="nk-modal">
        <PartnersTableView />
        {data.hasInvalidEmails && (
          <ModalAlert text={data.invalidEmailsAlert} hasCloseButton />
        )}
        {data.hasGeneratedEmails && (
          <ModalAlert text={data.generatedEmailsAlert} hasCloseButton />
        )}
      </div>
    </Modal.Body>
  );
};

export default NotifyPartnersDividendsModalBody;
