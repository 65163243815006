export const sendTestEmailModalInitValue = {
  data: {
    isLoading: false,
    recipientEmail: undefined,
    invalidEmail: false,
  },
  actions: {
    handleCloseModal() {
      throw new Error('Function not implemented.');
    },
    // eslint-disable-next-line
    handleChangeRecipientEmail(event: React.ChangeEvent<HTMLInputElement>) {
      throw new Error('Function not implemented.');
    },
    handleSendEmail() {
      throw new Error('Function not implemented.');
    },
  },
};
