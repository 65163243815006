import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { State } from 'redux/initialState';
import { Communication } from 'types';
import { CommunicationRecipient } from 'types/Communication';

import { getMailStatus } from '../../utils';

type InboxDetailsCommunicationRecipientsProps = {
  communication: Communication;
  actions: {
    setOpenCertification: (value: boolean) => void; // eslint-disable-line
  };
  recipients: CommunicationRecipient[];
};

const InboxDetailsCommunicationRecipients = ({
  communication,
  actions,
  recipients,
}: InboxDetailsCommunicationRecipientsProps) => {
  const { t } = useTranslate();
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  return (
    <div className="table-recipients">
      <div className="d-flex flex-row justify-content-between">
        <h6 className="m-0">{t('DeliveryStatus')}</h6>
        <Link
          to={`/comunicaciones/${actualSociety?._id}/detalles/${communication?._id}/certificacion`}
        >
          <span
            className="attach-download link"
            onClick={() => {
              actions.setOpenCertification(true);
            }}
          >
            <em className="icon ni ni-external text-primary cursor-pointer" />
            <span className="cursor-pointer mr-2">{t('ViewCertificate')}</span>
          </span>
        </Link>
      </div>

      <div className="card card-bordered mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>{t('Email')}</th>
              <th>{t('Recipient')}</th>
              <th className="text-left">{t('Status')}</th>
            </tr>
          </thead>

          <tbody>
            {recipients?.map((recipient) => (
              <tr key={recipient?._id}>
                <td>{recipient?.email}</td>
                <td>{recipient?.name}</td>
                <td className="text-left">{getMailStatus(recipient, t)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InboxDetailsCommunicationRecipients;
