import { getCommunicationStyles } from './getCommunicationStyles';

function getAnnouncementCommunicationEs(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: any
) {
  const style = getCommunicationStyles(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestión de Socios`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Correo enviado por <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText =
    announcementData?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
    announcementData?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
      ? 'Votar ahora'
      : 'Acceder a la Junta';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
            Convocatoria Junta de ${announcementData?.societyName || ''}
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.boardName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Fecha: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>Hora: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Tipo Junta: ${announcementData?.boardType || '-'}</span>
                </td>
                <td>
                  <span>Videollamada: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Lugar de celebración: ${announcementData?.place || '-'}</p>
          <h2>
            Delegación de voto
         </h2>
         <p>Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
         <a class="button">
            Delegar mi voto
          </a>
          <br/>
          <h2>
            Acceso a la Junta y votación
         </h2>
         <p>Puedes acceder a los detalles y documentación de la Junta:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
              El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria, 					poder revisar la documentación y votar.
          </p>
        </div>
      <p class="footer">

        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}
function getAnnouncementCommunicationEn(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: any
) {
  const style = getCommunicationStyles(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestión de Socios en`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Correo enviado por <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText =
    announcementData?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
    announcementData?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
      ? 'Votar ahora'
      : 'Acceder a la Junta';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
            Convocatoria Junta de ${announcementData?.societyName || ''}
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.boardName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Fecha: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>Hora: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Tipo Junta: ${announcementData?.boardType || '-'}</span>
                </td>
                <td>
                  <span>Videollamada: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Lugar de celebración: ${announcementData?.place || '-'}</p>
          <h2>
            Delegación de voto
         </h2>
         <p>Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
         <a class="button">
            Delegar mi voto
          </a>
          <br/>
          <h2>
            Acceso a la Junta y votación
         </h2>
         <p>Puedes acceder a los detalles y documentación de la Junta:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
              El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria, 					poder revisar la documentación y votar.
          </p>
        </div>
      <p class="footer">

        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}
function getAnnouncementCommunicationFr(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: any
) {
  const style = getCommunicationStyles(theme);
  const headerBranding = hasWhiteBranding ? ' ' : `Gestion des partenaires`;
  const footerBranding = hasWhiteBranding
    ? ''
    : `Correo enviado por <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a><br/>Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>`;

  const buttonText =
    announcementData?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
    announcementData?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
      ? 'VOTEZ MAINTENANT'
      : 'ACCÉDER AU CONSEIL D ADMINISTRATION';

  return `
  ${style}
  <body>
  <div class="main">
      <div class="custom-background">
    <a href="https://www.sttok.com">
          <img src="${
            theme?.logo
          }" alt="Logo" style="height: 40px; margin-top: 2.75rem"/>

      </a>
        <p class="branding">
      ${headerBranding}
        </p>
      <div class="email-body">
        <h2>
        Convocation du Conseil d'administration ${
          announcementData?.societyName || ''
        }
        </h2>
        <p>${announcementData?.announcement || ''}</p>
        <h2>
          ${announcementData?.boardName || ''}
         </h2>
         <table>
             <tr>
                <td>
                  <span>Date: ${announcementData?.date || '-'}</span>
                </td>
                <td>
                  <span>L'heure: ${announcementData?.time || '-'}</span>
                </td>
             </tr>
             <tr>
                <td>
                  <span>Type d'articulation: ${
                    announcementData?.boardType || '-'
                  }</span>
                </td>
                <td>
                  <span>Appel vidéo: ${
                    announcementData?.onlineAccess || '-'
                  }</span>
                </td>
             </tr>
          </table>
          <p>Lieu: ${announcementData?.place || '-'}</p>
          <h2>
             Vote par procuration
         </h2>
         <p>Si vous ne pouvez pas assister à l'Assemblée, vous pouvez déléguer votre vote ici:</p>
         <a class="button">
            DÉLÉGUER MON VOTE
          </a>
          <br/>
          <h2>
           Accès à l'Assemblée et vote
         </h2>
         <p>Vous pouvez accéder aux détails et à la documentation de la réunion:</p>
         <a class="button button-small">
             ${buttonText}
          </a>
          <br/>
          <p class="pt-2">
          Le processus est géré par la plateforme Sttok, à partir de laquelle vous pouvez accéder à l'appel, consulter la documentation et voter.
          </p>
        </div>
      <p class="footer">

        ${footerBranding}
      </p>
    </div>
  </div>
</body>`;
}

export function getAnnouncementCommunication(
  theme: any,
  hasWhiteBranding: boolean,
  announcementData: any,
  language: string
) {
  switch (language) {
    case 'es':
      return getAnnouncementCommunicationEs(
        theme,
        hasWhiteBranding,
        announcementData
      );
    case 'en':
      return getAnnouncementCommunicationEn(
        theme,
        hasWhiteBranding,
        announcementData
      );
    case 'fr':
      return getAnnouncementCommunicationFr(
        theme,
        hasWhiteBranding,
        announcementData
      );
    default:
      return '';
  }
}
