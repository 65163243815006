/* eslint-disable no-undef */
import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import { FC, createContext, useContext, useState } from 'react';
import { SharesTitle } from 'types';
import { useDispatch } from 'react-redux';

import errorSwal from 'utils/errorSwal';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateSharesTitle } from 'modules/partners/redux/sharesTitlesActions';
import successSwal from 'utils/successSwal';
import { ChangeSharesTitleStatusModalContextType } from '../types/ChangeSharesTitleStatusModalContextType';
import { changeSharesTitleStatusInitValue } from '../constants/changeSharesTitleStatusInitValue';

const ChangeSharesTitleStatusModalContext =
  createContext<ChangeSharesTitleStatusModalContextType>(
    changeSharesTitleStatusInitValue
  );

export const ChangeSharesTitleStatusModalProvider: FC<{
  children: React.ReactNode;
  sharesTitle: SharesTitle;
}> = ({ children, sharesTitle }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const [status, setStatus] = useState<string | undefined>();
  const [statusOption, setStatusOption] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChangeStatus = (selectedOption: any) => {
    setStatus(selectedOption.value);
    setStatusOption(selectedOption);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSaveStatus = async () => {
    try {
      setIsLoading(true);

      const sharesTitleData = {
        _id: sharesTitle._id,
        status,
      };

      await store.dispatch(updateSharesTitle(sharesTitleData));

      successSwal(t('TitleStatusChangedSuccessfully'));
    } catch (error) {
      errorSwal(t('ErrorChangingTitleStatus'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <ChangeSharesTitleStatusModalContext.Provider
      value={{
        data: {
          status,
          statusOption,
          isLoading,
        },
        actions: {
          handleChangeStatus,
          handleCloseModal,
          handleSaveStatus,
        },
      }}
    >
      {children}
    </ChangeSharesTitleStatusModalContext.Provider>
  );
};

export const useChangeSharesTitleStatusModal = () =>
  useContext(ChangeSharesTitleStatusModalContext);
