import { Society } from 'types';
import { PartnerData } from '../types/PartnerData';
import { getCommunication } from './getCommunication';
import { getTemplate } from './getTemplate';

type Props = {
  subject: string;
  actualSociety: Society;
  recipients: PartnerData[];
  userId: string;
};

export const getEmailData = ({
  subject,
  actualSociety,
  recipients,
  userId,
}: Props) => {
  const templateBody = getCommunication(actualSociety);
  const template = getTemplate(actualSociety?.societyLanguage || 'es');

  const emailData = {
    subject,
    template,
    templateData: [
      {
        name: 'societyName',
        content: actualSociety?.name,
      },
    ],
    templateDynamicData: recipients.map(({ id, email }) => ({
      rcpt: email,
      vars: [
        {
          name: 'targetUrl',
          content: `${process.env.REACT_APP_DOMAIN}?redirect=detalle-socio/${actualSociety['_id']}/${id}/2/#dividends-table`,
        },
      ],
    })),

    templateBody,
    user: userId,
    society: actualSociety?._id,
    date: new Date(),
    status: 'SENT',
    to: recipients,
  };

  return emailData;
};
