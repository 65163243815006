import exportFromJSON from 'export-from-json';

const SecureExcelButton = ({
  tableId,
  label = 'Download XLSX',
  fileName = 'Document',
  sheetName = 'Sheet 1',
  className = 'btn-white btn-outline-light',
}) => {
  const handleExport = () => {
    // Get the table element
    const table = document.getElementById(tableId);
    if (!table) return;

    // Extract headers (considering translation keys)
    const headers = [];
    const headerCells = table.querySelectorAll('thead th .sub-text');
    headerCells.forEach((cell) => {
      headers.push(cell.textContent);
    });

    // Extract data from tbody
    const data = [];
    const rows = table.querySelectorAll('tbody tr');

    rows.forEach((row) => {
      const rowData = {};
      const cells = row.querySelectorAll('td');

      cells.forEach((cell, index) => {
        // Get the text content, removing any extra whitespace
        let value = cell.textContent.trim();

        // Try to convert numbers if they use comma as decimal separator
        if (value.match(/^-?\d+,\d+$/)) {
          value = value.replace(',', '.');
        }

        rowData[headers[index]] = value;
      });

      data.push(rowData);
    });

    // Export to Excel
    exportFromJSON({
      data,
      fileName,
      exportType: exportFromJSON.types.xls,
      // exportType: exportFromJSON.types.csv,
      withBOM: true,
      sheet: sheetName,
      separator: ';',
    });
  };

  return (
    <button
      type="button"
      id="custom-table-xls-button"
      className={`btn screen-only ${className}`}
      onClick={handleExport}
    >
      {label}
    </button>
  );
};

export default SecureExcelButton;
