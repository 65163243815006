/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import getDocumentBlob from 'modules/_shared/components/CKEditor/utils/getDocumentBlob';

import { Society } from 'types';
import { downloadBlob } from 'utils/downloadBlob';

import { simplifyMentions } from 'modules/_shared/components/CKEditor/utils/simplifyMentions';
import { PartnerDividendDocumentData } from './getPartnerDataToDividendDocument';
import { generateDividendFileName } from './generateDividendFileName';

type SingleDocumentProps = {
  dividendData: PartnerDividendDocumentData;
  template: string;
  actualSociety: Society;
};

type MultipleDocumentsProps = {
  dividendsDataList: PartnerDividendDocumentData[];
  template: string;
  actualSociety: Society;
};

export const generateDividendDocument = async ({
  dividendData,
  template,
  actualSociety,
}: SingleDocumentProps) => {
  const noMentionsHtml = simplifyMentions(template || '');

  const documentUpdated = updateDocumentWithValues(
    noMentionsHtml,
    tags({ dividend: dividendData, society: actualSociety })
  );

  const templateBlob = await getDocumentBlob(documentUpdated.html);
  const fileName = generateDividendFileName(dividendData);

  downloadBlob(templateBlob, fileName);
};

export const generateDividendsDocuments = async ({
  dividendsDataList,
  template,
  actualSociety,
}: MultipleDocumentsProps) => {
  for (const dividendData of dividendsDataList) {
    await generateDividendDocument({ dividendData, template, actualSociety });
  }
};
