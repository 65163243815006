import { useEffect, useState } from 'react';

type UseGetActivePageProps = {
  pageType: string | undefined;
  communicationId: string | undefined;
};

export type CommunicationPages = 'INBOX' | 'DETAILS' | 'SETTINGS';
export const communicationPages: Record<string, CommunicationPages> = {
  INBOX: 'INBOX',
  DETAILS: 'DETAILS',
  SETTINGS: 'SETTINGS',
};

export default function useGetActivePage({
  pageType,
  communicationId,
}: UseGetActivePageProps) {
  const [activePage, setActivePage] = useState<CommunicationPages>(
    communicationPages.INBOX
  );

  const handleActiveChangePage = (page: CommunicationPages) => {
    setActivePage(page);
  };

  const getPageByParams = (
    pageType: string | undefined,
    communicationId: string | undefined
  ): CommunicationPages => {
    if (!communicationId && !pageType) {
      return communicationPages.INBOX;
    }

    if (communicationId && pageType === 'detalles') {
      return communicationPages.DETAILS;
    }

    if (pageType === 'ajustes') {
      return communicationPages.SETTINGS;
    }

    return communicationPages.INBOX;
  };

  useEffect(() => {
    const page = getPageByParams(pageType, communicationId);
    setActivePage(page);
  }, [pageType, communicationId]);

  return {
    data: {
      activePage,
    },
    isLoading: false,
    isError: false,
    actions: { handleActiveChangePage },
  };
}
