import { store } from 'redux/store';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import { DecoupledEditor } from 'ckeditor5';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';

import CKEditor from 'modules/_shared/components/CKEditor';
import { updateDocument } from 'modules/documents/redux/documentActions';
import Loading from 'modules/_shared/components/Loading';
import { setModal } from 'modules/_shared/redux/modalActions';
import RequestSignatureModal from 'modules/administratives/modals/RequestSignatureModal';
import signatureTypes from 'constants/signatureTypes';
import { updateCouncil } from 'modules/administratives/redux/councilActions';
import PDFAdobeViewer from 'modules/_shared/components/PDFAdobeViewer';
import EditorModuleHeader from './EditorModuleHeader';

import editorModuleSections from '../constants/editorModuleSections';
import { saveTemplate } from '../utils/saveTemplate';

import useGetTemplateData from '../hooks/useGetDocumentData';
import councilTemplateTypes from '../constants/councilTemplateTypes';

type Props = {
  councilId: string;
};

const CouncilDetailsCertification: FC<Props> = ({ councilId }: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const councils = useSelector((state: State) => state.councils);

  const council = councils.find((council) => council._id === councilId);

  const section = editorModuleSections[1].value;

  const certificationDocumentId = council?.actCertificateDocument;
  const certificationTemplateId = council?.actCertificateTemplate;

  const templateData = useGetTemplateData({
    documentId: certificationDocumentId,
    templateId: certificationTemplateId,
  });

  const { templateName, document, htmlContent, documentFrame } =
    templateData.data;
  const { isLoading } = templateData;
  const { handleIsLoading } = templateData.actions;

  const documentName = document?.name;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editorInstance, setEditorInstance] = useState<DecoupledEditor | null>(
    null
  );

  const handleChangeIsEditing = (value: boolean) => {
    setIsEditing(value);
  };

  const handleRequestSignature = () => {
    if (!document) {
      return;
    }
    dispatch(
      setModal(
        <RequestSignatureModal
          councilId={councilId}
          document={document}
          templateType={councilTemplateTypes.CERTIFICATION}
          signatureType={signatureTypes.COUNCIL_CERTIFICATE}
        />
      )
    );
  };

  const handleSaveTemplate = async () => {
    try {
      handleIsLoading(true);
      const newContent = saveTemplate({
        editorInstance,
        handleChangeIsEditing,
      });

      await store.dispatch(
        updateDocument({ ...document, editorModel: newContent }, false)
      );

      successSwal(t('CertificationSavedSuccessfully'));
    } catch (error) {
      errorSwal(t('ErrorSavingAct'));
    } finally {
      handleIsLoading(false);
    }
  };

  const handleChangePublicStatus = async (value: boolean) => {
    const newCouncilData = {
      _id: councilId,
      isActCertificateDocumentPublic: value,
    };
    store.dispatch(updateCouncil(newCouncilData));
  };

  useEffect(() => {
    if (htmlContent && editorInstance) {
      editorInstance.setData(htmlContent);
    }
  }, [htmlContent, editorInstance]);

  return (
    <div className="nk-content-body pt-4">
      <EditorModuleHeader
        section={section}
        templateName={templateName}
        isDraft
        isEditing={isEditing}
        isPublic={council?.isActCertificateDocumentPublic || false}
        isTemplate={!documentFrame}
        editorInstance={editorInstance}
        councilId={councilId}
        isLoading={isLoading}
        handleChangeIsEditing={handleChangeIsEditing}
        handleRequestSignature={handleRequestSignature}
        handleSaveTemplate={handleSaveTemplate}
        handleChangePublicStatus={handleChangePublicStatus}
      />

      {documentName && htmlContent && (
        <div className="avoid-default-font">
          <CKEditor
            editorInstance={editorInstance}
            setEditorInstance={setEditorInstance}
            documentName={documentName}
            documentModel={htmlContent}
            enableMentions={false}
            isReadOnly={!isEditing}
          />
        </div>
      )}

      {documentFrame && documentName && (
        <PDFAdobeViewer
          fileUrl={documentFrame}
          fileName={documentName}
          height="750px"
          className="border rounded"
        />
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default CouncilDetailsCertification;
