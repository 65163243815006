import { Document, Operation } from 'types';
import MenuDots from 'modules/_shared/components/MenuDots';
import downloadFile from 'utils/downloadFile';

type ShowLoadedDocumentsProps = {
  documents: Document[];
  transaction: Operation;
};

export const showLoadedDocuments = ({
  documents,
  transaction,
}: ShowLoadedDocumentsProps) => {
  // Have 1 document.
  if (documents?.length === 1) {
    return (
      <span
        className="btn btn-icon btn-trigger mx-auto "
        onClick={() => {
          downloadFile({ documentId: documents[0]?._id });
        }}
      >
        <em className="icon ni ni-file-download text-center" />
      </span>
    );
  }

  // Have more than 1 document.
  if (documents?.length > 1) {
    const options = documents.map((doc) => ({
      icon: 'ni-file',
      text: doc.name,
      download: true,
      documentId: doc._id,
    }));

    return (
      <span className="d-flex justify-content-center mt-2 mr-1">
        {/* @ts-ignore */}
        <MenuDots
          menuOptions={options}
          direction="left"
          customIcon="ni-files"
          dropdownClassName="w-250px"
        />
      </span>
    );
  }

  // Don't have documents but the transaction does. It hasn't loaded yet.
  if (transaction?.documents?.length > 0) {
    return (
      <div className="text-center">
        <em className="spinner-border spinner-border-sm" role="status" />
      </div>
    );
  }

  // Don't have documents.
  return (
    <span className="btn btn-icon disabled">
      <em className="icon ni ni-file-download" />
    </span>
  );
};
