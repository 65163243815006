/* eslint-disable no-unused-vars */
import numeral from 'numeral';
import locales from 'numeral/locales';

const localesParsed = {
  es: 'es',
  en: 'en-gb',
  fr: 'fr',
};

numeral.locale(localesParsed.es);

export const formatNumber = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0')
    : '';

export const formatNumberDecimals = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0.00')
    : '';

export const formatNumberDecimalsLong = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0[.][0000000000]')
    : '';

export const formatCurrencyDecimalsLong = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0[.][0000000000]')}€`
    : '';

export const formatCurrency = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0[.][0000000]')}€`
    : '';

export const formatCurrencyDecimals = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0.00')}€`
    : '';

export const formatPercent = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0')}%`
    : '';

export const formatPercentDecimals = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0.00')}%`
    : '';

export const formatPercentDecimalsLong = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0[.][0000000000]')}%`
    : '';

export const formatDownloadNumber = (value) => {
  const currentLang = localStorage.getItem('i18nextLng') || 'es';
  const currentLocale = localesParsed[currentLang];

  if (typeof value !== 'undefined' && value !== null) {
    numeral.locale(currentLocale);
    return numeral(value).format('0,0[.][0000000000]');
  }

  return '';
};
