/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { SocietyDirector } from 'types';

import DetailsHeaderSection from 'modules/_shared/components/Sections/DetailsHeaderSection';
import { getDirectorRoamingList } from 'modules/society/pages/DirectorDetail/utils/getDirectorRoamingList';

type DirectorRoamingProps = {
  director: SocietyDirector;
  roamingDirectors: any[];
};

const columns = ['Society', 'Position', 'StartDate', 'Status'];

const DirectorRoaming: FC<DirectorRoamingProps> = ({
  director,
  roamingDirectors,
}) => {
  const { t } = useTranslate();

  const data = getDirectorRoamingList(director, roamingDirectors);

  return (
    <>
      <DetailsHeaderSection
        title={t('SocietiesAndBoards')}
        description={t('SocietiesAndBoardsInformation')}
      />

      <section className="card-inner">
        <div className="row card card-bordered card-preview">
          <table className="table table-striped">
            {columns && columns.length > 0 && (
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th scope="col" key={column}>
                      {t(column)}
                    </th>
                  ))}
                </tr>
              </thead>
            )}

            <tbody>
              {data?.map((director: any, index: number) => (
                <tr key={`director-roaming-row-${index}`}>
                  <td className="fw-bold">{director.society}</td>
                  <td className="ws-pre-wrap">{t(director.role)}</td>
                  <td className="ws-pre-wrap">{director.startDate}</td>
                  <td className="ws-pre-wrap">
                    <span
                      className={`badge badge-dot badge-${director.statusBadge}`}
                    >
                      {t(director.status)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default DirectorRoaming;
