import { Share, Society } from 'types';
import { hasShowDetails } from 'utils/showDetails';

const getNominalValue = (share: Share, society: Society) => {
  const { shareClass } = share;

  if (shareClass?.nominalValue) {
    return shareClass.nominalValue;
  }

  return society?.nominalValue || 0;
};

export const getPartnerAmount = (
  partnerShares: string[],
  society: Society
): number => {
  if (!society) return 0;

  const shares = society?.shares?.filter(
    (share) =>
      share.isActive &&
      partnerShares?.some((shareId) => shareId === share['_id'])
  );

  const result = shares?.reduce((acc, share) => {
    let result = 0;
    const shareCount = share.to - share.from + 1;
    const nominalValue = getNominalValue(share, society);

    if (share.sharePrice) {
      result = share.sharePrice * shareCount;
    } else {
      result = (nominalValue + (share.sharePremium || 0)) * shareCount;
    }

    return acc + result;
  }, 0);

  return result;
};
