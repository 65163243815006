import { Dividend, Operation, Partner, Society } from 'types';

export interface PartnerDividendDocumentData
  extends Dividend,
    Pick<Operation, 'date' | 'currency' | 'nominalValue'> {
  partner: Partner;
}

type GetPartnerDataToDividendDocument = {
  partnerId: string;
  operation: Operation;
  society: Society;
};

export const getPartnerDataToDividendDocument = ({
  partnerId,
  operation,
  society,
}: GetPartnerDataToDividendDocument): PartnerDividendDocumentData => {
  const dividend = operation?.dividends?.find(
    (dividend) => typeof dividend !== 'string' && dividend.partner === partnerId
  ) as Dividend;

  const currentPartner = society?.partners.find(
    (partner: Partner) => partner['_id'] === partnerId
  ) as Partner;

  const { nominalValue, date, currency } = operation || {};

  return {
    ...dividend,
    date,
    currency,
    nominalValue,
    partner: currentPartner,
  };
};
