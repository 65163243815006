import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useChangeSharesTitleStatusModal } from '../context/ChangeSharesTitleStatusModalContext';
import useGetSharesTitleStatusOptions from '../hooks/useGetSharesTitleStatusOptions';

const ChangeSharesTitleStatusModalBody: FC = () => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const { data, actions } = useChangeSharesTitleStatusModal();

  const { statusOptions } = useGetSharesTitleStatusOptions().data;

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label" htmlFor="shares-title-status">
                {t('Status')}
              </label>
              <div id="shares-title-status" className="form-control-wrap ">
                <Select
                  closeMenuOnSelect
                  className="react-select react-select-lg"
                  value={data.statusOption}
                  options={statusOptions}
                  components={animatedComponents}
                  onChange={actions.handleChangeStatus}
                  placeholder={t('Select')}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ChangeSharesTitleStatusModalBody;
