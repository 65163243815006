import { useTranslate } from 'hooks/useTranslate';
import { Communication, Document } from 'types';
import downloadDocument from 'utils/downloadDocument';

type InboxDetailsCommunicationAttachmentsProps = {
  communication: Communication;
  attachments: Document[];
  hasAttachments: boolean;
};

const InboxDetailsCommunicationAttachments = ({
  communication,
  attachments,
  hasAttachments,
}: InboxDetailsCommunicationAttachmentsProps) => {
  const { t } = useTranslate();

  return (
    <div className="attach-files">
      <ul className="attach-list flex-column justify-content-center px-2 pt-2">
        {attachments?.map((attachment: any) => (
          <li className="attach-item d-flex w-100 mb-1" key={attachment._id}>
            <em className="icon ni ni-file-doc" />
            <span>{attachment?.name}</span>
          </li>
        ))}
      </ul>

      <div className="attach-foot">
        <span className="attach-info">
          {communication?.attachments?.length}
          &nbsp;
          {t('FilesAttached')}
        </span>

        <span
          className={`attach-download link ${
            !hasAttachments ? 'is-disable' : ''
          }`}
          onClick={() => {
            downloadDocument({
              multiple: true,
              documents: attachments,
            });
          }}
        >
          <em
            className={`icon ni ni-download text-primary cursor-pointer ${
              !hasAttachments ? 'is-disable' : ''
            }`}
          />
          <span className={`${!hasAttachments ? 'is-disable' : ''}`}>
            {t('DownloadAll')}
          </span>
        </span>
      </div>
    </div>
  );
};

export default InboxDetailsCommunicationAttachments;
