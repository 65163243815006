import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useNotifyPartnersDividendsModal } from '../context/NotifyPartnersDividendsModalContext';

const NotifyPartnersDividendsModalFooter: FC = () => {
  const { t } = useTranslate();
  const { data, actions } = useNotifyPartnersDividendsModal();

  const buttonEnabled =
    data?.partnersData?.length > 0 && !data?.hasInvalidEmails;

  return (
    <>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <button
            type="button"
            className="btn btn-primary"
            onClick={actions.handleSendTestEmail}
          >
            {t('SendTestEmail')}
          </button>

          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={actions.handleSendEmails}
            disabled={!buttonEnabled}
          >
            {t('SendNotification')}
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default NotifyPartnersDividendsModalFooter;
