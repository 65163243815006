import { Notification } from 'types';
import notificationCategories from '../constants/notificationCategories';

export const getNotificationStyles = (notification: Notification) => {
  const notificationCategory = notification.category;

  const category = notificationCategories.find(
    (category) => category.value === notificationCategory
  );

  return { icon: category?.icon, color: category?.color };
};
