import { useTranslate } from 'hooks/useTranslate';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import { Society } from 'types';
import { generateSocietyEmail, getSocietySenderDomain } from 'utils/emails';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import InputCharCounter from 'modules/_shared/components/InputCharCounter';
import InputError from 'modules/_shared/components/InputError';
import SendTestEmail from 'modules/_shared/components/Modals/SendTestEmail';
import defaultSignature from 'modules/communications/constants/defaultSignature';
import generateEmailSignature from 'modules/communications/utils/generateEmailSignature';
import { State } from 'redux/initialState';
import { SocietyEmailSignature } from 'types/Society';
import isValidEmail, { isValidEmailPrefix } from 'utils/isValidEmail';
import { useGetCommunicationsSectionPermissions } from 'modules/communications/hooks/useGetCommunicationSectionPermissions';
import { SendTestEmailModalProvider } from 'modules/_shared/components/Modals/SendTestEmail/context/SendTestEmailModalContext';
import CommunicationSettingsSignature from '../CommunicationSettingsSignature';

type CommunicationSettingsProps = {
  society: Society;
};

const CommunicationSettings: FC<CommunicationSettingsProps> = ({ society }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const { isReadOnly } = useGetCommunicationsSectionPermissions().data;

  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const communications = useSelector(
    (state: State) => state.society.actualSociety?.configuration?.communications
  );

  const currentEmailSignature =
    useSelector(
      (state: State) =>
        state.society.actualSociety?.configuration?.communications
          ?.emailSignature
    ) || defaultSignature;

  const [isLoading, setIsLoading] = useState(false);
  const [senderEmail, setSenderEmail] = useState('');
  const [senderName, setSenderName] = useState('');
  const [replyEnabled, setReplyEnabled] = useState(false);
  const [replyEmail, setReplyEmail] = useState('');
  const [emailSignature, setEmailSignature] = useState(currentEmailSignature);

  const DEFAULT_SUBJECT = `✉️ ${society.name} te ha enviado un mail de prueba!`;
  const DEFAULT_BODY = `Hola, este es un email enviado desde ${society.name} para que puedas validar la configuración de tus comunicaciones`;

  const emailData = {
    subject: DEFAULT_SUBJECT,
    template: 'sttok-general',
    body: [DEFAULT_BODY],
    user: user['_id'],
    society: society['_id'],
    date: new Date(),
    status: 'SENT',
  };

  const isTestButtonDisabled = !senderEmail || !senderName;

  const isSaveButtonDisabled =
    !senderEmail ||
    !senderName ||
    (replyEnabled && !isValidEmail(replyEmail)) ||
    isReadOnly;

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isValidEmailPrefix(e.target.value) && e.target.value !== '') {
      return;
    }
    setSenderEmail(e.target.value?.toLowerCase());
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setSenderName(e.target.value);
  };

  const handleChangeReplyEnabled = () => {
    if (replyEnabled) {
      setReplyEmail('');
    }
    setReplyEnabled(!replyEnabled);
  };

  const handleChangeReplyEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setReplyEmail(e.target.value);
  };

  const handleChangeSignature = (name: string, value: string) => {
    setEmailSignature((prev: SocietyEmailSignature) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveSettings = async () => {
    if (isSaveButtonDisabled) return;
    setIsLoading(true);

    const updatedCommunications = {
      ...communications,
      senderEmail,
      senderName,
      replyEnabled,
      replyEmail,
      emailSignature,
      emailSignatureHTML: generateEmailSignature(emailSignature, society),
    };

    const updatedConfiguration = {
      ...society.configuration,
      communications: updatedCommunications,
    };

    await dispatch(
      updateSociety(society._id, { configuration: updatedConfiguration }, false)
    );
    setIsLoading(false);
  };

  const handleSendTestEmail = async () => {
    setIsLoading(true);
    await handleSaveSettings();
    dispatch(
      setModal(
        <SendTestEmailModalProvider emailData={emailData}>
          <SendTestEmail />
        </SendTestEmailModalProvider>
      )
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const { communications } = society?.configuration || {};
    setSenderEmail(
      communications?.senderEmail || generateSocietyEmail(society, false)
    );
    setSenderName(communications?.senderName || society?.name || '');
    setReplyEnabled(communications?.replyEnabled || false);
    setReplyEmail(communications?.replyEmail || '');
  }, [society]);

  return (
    <div className="nk-ibx-reply nk-reply" data-simplebar="init">
      <div className="simplebar-wrapper" style={{ margin: '0px' }}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer" />
        </div>
        <div className="simplebar-mask">
          <div
            className="simplebar-offset"
            style={{ right: '0px', bottom: '0px' }}
          >
            <div
              className="simplebar-content-wrapper h-100"
              role="region"
              aria-label="scrollable content"
              style={{
                overflow: 'hidden scroll',
              }}
            >
              <div className="simplebar-content" style={{ padding: '0px' }}>
                <div className="nk-ibx-reply-head">
                  <div>
                    <h4 className="title">{t('Settings')}</h4>
                    <span className="sub-text">
                      {t('CommunicationSettingsDescription', {
                        societyName: society?.name || '',
                      })}
                    </span>
                  </div>
                </div>

                <div className="nk-ibx-reply-group card-inner">
                  <form className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <div className="steps clearfix">
                      <ul role="tablist">
                        <li role="tab" className="first last current">
                          <h5>{t('Sender')}</h5>
                        </li>
                      </ul>
                    </div>

                    <div className="nk-wizard-content body current">
                      <div className="row g-5">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="sender-email"
                            >
                              {t('Email')}
                            </label>

                            <div className="form-control-wrap">
                              <div className="form-text-hint">
                                <span className="overline-title text-lowercase">
                                  {getSocietySenderDomain(society)}
                                </span>
                              </div>
                              <input
                                type="text"
                                id="sender-email"
                                className="form-control form-control-lg"
                                name="sender-email"
                                value={senderEmail}
                                maxLength={50}
                                onChange={handleChangeEmail}
                              />
                            </div>

                            <InputCharCounter value={senderEmail} limit={50} />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="sender-email"
                            >
                              {t('Name')}
                            </label>

                            <div className="form-control-wrap">
                              <input
                                type="text"
                                className="form-control form-control-lg pr-100px"
                                id="sender-email"
                                name="sender-email"
                                value={senderName}
                                maxLength={50}
                                onChange={handleChangeName}
                              />
                            </div>

                            <InputCharCounter value={senderName} limit={50} />
                          </div>
                        </div>

                        <div
                          className="col-md-6"
                          style={{ minHeight: '120px' }}
                        >
                          <label className="form-label">
                            {t('DoYouWantToModifyTheResponseEmail')}
                          </label>
                          <ul className="d-flex flex-wrap g-2 mt-1">
                            <li>
                              <div className="custom-control custom-radio checked">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="reply-enabled"
                                  id="reply-enabled-yes"
                                  checked={!replyEnabled}
                                  onChange={handleChangeReplyEnabled}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="reply-enabled-yes"
                                >
                                  No
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="custom-control custom-radio ml-3">
                                <input
                                  type="radio"
                                  data-msg="Required"
                                  className="custom-control-input"
                                  name="reply-enabled"
                                  id="reply-enabled-no"
                                  checked={replyEnabled}
                                  onChange={handleChangeReplyEnabled}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="reply-enabled-no"
                                >
                                  {t('Yes')}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                        {replyEnabled && (
                          <div
                            className="col-md-6"
                            style={{ minHeight: '120px' }}
                          >
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="reply-email"
                              >
                                {t('ReplyEmail')}
                                <span>
                                  {' '}
                                  {!isValidEmail(replyEmail) && (
                                    <InputError
                                      errorMessage={t('InvalidEmail')}
                                    />
                                  )}
                                </span>
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="email"
                                  className="form-control form-control-lg"
                                  id="reply-email"
                                  name="reply-email"
                                  value={replyEmail}
                                  maxLength={50}
                                  onChange={handleChangeReplyEmail}
                                />
                              </div>

                              <InputCharCounter value={replyEmail} limit={50} />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {hasWhiteBranding && (
                      <CommunicationSettingsSignature
                        signature={currentEmailSignature}
                        onChangeSignature={handleChangeSignature}
                        society={society}
                      />
                    )}

                    <div className="row mt-4">
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-block btn-outline-primary mt-4"
                          onClick={handleSendTestEmail}
                          disabled={isTestButtonDisabled}
                        >
                          <em className="icon ni ni-send" />
                          <span>{t('SendTestEmail')}</span>
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-block btn-primary mt-4"
                          onClick={handleSaveSettings}
                          disabled={isSaveButtonDisabled}
                        >
                          <em className="icon ni ni-save" />
                          <span>{t('SaveChanges')}</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default CommunicationSettings;
