export const editLetterStatusModalInitValue = {
  data: {
    isLoading: false,
    invitationStatus: '',
    selectedStatusOption: null,
    statusOptions: [],
    assumeCheckboxChecked: false,
  },
  actions: {
    handleChangeAssumeCheckboxChecked: () => {
      throw new Error('Function not implemented.');
    },
    handleCloseModal: () => {
      throw new Error('Function not implemented.');
    },
    // eslint-disable-next-line
    handleChangeStatus: (selectedOption: any) => {
      throw new Error('Function not implemented.');
    },
    handleSaveStatus: () => {
      throw new Error('Function not implemented.');
    },
  },
};
