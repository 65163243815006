import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import downloadFile from 'utils/downloadFile';
import { downloadBlob } from 'utils/downloadBlob';
import documentTypes from 'constants/documentTypes';

import { Document, Tags } from 'types';

import getDocumentBlob from '../../CKEditor/utils/getDocumentBlob';
import { updateDocumentWithValues } from '../../EditorWYSIWYG/helpers';
import tags from '../../EditorWYSIWYG/tags';

import CustomLoading from '../../CustomLoading';
import { simplifyMentions } from '../../CKEditor/utils/simplifyMentions';

type Props = {
  document: Document;
  tagsObject?: Tags;
};

const DownloadDocument: FC<Props> = ({ document, tagsObject }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadDocument = async () => {
    try {
      setIsLoading(true);
      if (document.subcategory === documentTypes.DOCUMENTS) {
        const tagsValues = tags(tagsObject);

        const noMentionsHtml = simplifyMentions(document?.editorModel || '');

        const documentWithValues = updateDocumentWithValues(
          noMentionsHtml,
          tagsValues
        );
        const blob = await getDocumentBlob(documentWithValues?.html || '');

        downloadBlob(blob, document?.name || '');
      } else {
        await downloadFile({ documentId: document._id });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span
        className="btn btn-icon btn-trigger"
        onClick={handleDownloadDocument}
      >
        <em className="icon ni ni-file-download" />
      </span>

      {isLoading && <CustomLoading />}
    </>
  );
};

export default DownloadDocument;
