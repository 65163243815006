import Swal from 'sweetalert2';
import { store } from 'redux/store';
import { translate } from 'hooks/useTranslate';
import { updateCouncil } from 'modules/administratives/redux/councilActions';
import { Council } from 'types';

export const closeCouncil = async (council: Council) => {
  const councilStatusClosed = 'CLOSED' as const;
  const councilData = {
    _id: council._id,
    status: councilStatusClosed,
  };

  return Swal.fire({
    html: `<div class="modal-body modal-body-lg text-center p-0">
            <div class="nk-modal">
              <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-na bg-danger"></em>
              <h4 class="nk-modal-title">${translate('CloseCouncil?')}</h4>
              <div class="nk-modal-text">
                <p class="lead">
                  ${translate('ClosingCouncilMessage')}
                </p>
                <p class="text-soft fs-14px">
                  ${translate('ThisActionCannotBeUndone')}
                </p>
              </div>
            </div>
          </div>`,
    confirmButtonText: `${translate('Yes')}, ${translate('Close')}`,
    confirmButtonColor: '#E85347',
    showCloseButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      store.dispatch(updateCouncil(councilData));
      return true;
    }

    return false;
  });
};
