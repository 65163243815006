import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';

import { Communication, CommunicationRecipient } from 'types/Communication';

import { getDocument } from 'modules/documents/redux/documentActions';
import {
  getCommunication,
  updateCommEmail,
} from 'modules/communications/redux/communicationActions';
import { updateRecipients } from 'modules/communications/components/utils';
import { Society } from 'types';
import { store } from 'redux/store';

type UseInboxDetailsProps = {
  communicationId: string | undefined;
  communication: Communication | undefined;
  location: any;
  society: Society;
  societyId: string | undefined;
  isReadOnly: boolean;
};

export const useInboxDetails = ({
  communicationId,
  communication,
  location,
  society,
  societyId,
  isReadOnly,
}: UseInboxDetailsProps) => {
  const dispatch = useDispatch();

  const [currentCommunication, setCurrentCommunication] = useState<
    Communication | undefined
  >(communication);
  const [parsedDoc, setParsedDoc] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [openCertification, setOpenCertification] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [recipients, setRecipients] = useState(communication?.recipients || []);
  const [isLoading, setIsLoading] = useState(false);

  const getRecipientsStatus = async () => {
    try {
      setIsLoading(true);
      const recipientsInfo = await updateRecipients(currentCommunication);
      setIsLoading(false);

      setRecipients(recipientsInfo);
    } catch (error) {
      console.error(error); // eslint-disable-line
      setIsLoading(false);
    }
  };

  const getCommunicationData = async () => {
    try {
      setIsLoading(true);
      const communication = await store.dispatch(
        getCommunication(communicationId)
      );

      setCurrentCommunication(communication);
      setIsLoading(false);
    } catch (error) {
      console.error(error); // eslint-disable-line
      setIsLoading(false);
    }
  };

  const getCommunicationAttachments = async (attachmentIds: string[]) => {
    try {
      const attachmentsData = await Promise.all(
        attachmentIds.map(async (attachmentId) => {
          const documentThunk = getDocument(attachmentId);
          return dispatch(documentThunk);
        })
      );

      setAttachments(attachmentsData as any);
    } catch (error) {
      console.error(error); // eslint-disable-line
      setAttachments([]);
    }
  };

  const getRecipientsList = (recipients: CommunicationRecipient[]) =>
    recipients?.map((recipient) => (
      <li key={recipient?._id}>{recipient?.name || recipient?.email}</li>
    ));

  const handleChangeFavorites = async (favoriteStatus: boolean) => {
    setIsFavorite(favoriteStatus);
    try {
      await dispatch(
        updateCommEmail(communication?._id, societyId, {
          isFavorite: favoriteStatus,
        })
      );
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  };

  const handleUpdateCommunication = async () => {
    try {
      await dispatch(
        updateCommEmail(communication?._id, societyId, {
          isUnread: false,
        })
      );
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  useEffect(() => {
    if (!currentCommunication && communicationId) {
      getCommunicationData();
    }
    if (!currentCommunication) return;

    if (currentCommunication?.isUnread && society && !openCertification) {
      handleUpdateCommunication();
    }
    if (currentCommunication?.attachments?.length > 0) {
      setHasAttachments(true);
      getCommunicationAttachments(currentCommunication?.attachments);
    }
    if (currentCommunication?.isFavorite) {
      setIsFavorite(true);
    }
    if (currentCommunication?.body) {
      setParsedDoc(parse(currentCommunication?.body) as any);
    }

    getRecipientsStatus();
  }, [society, currentCommunication]); // eslint-disable-line

  useEffect(() => {
    if (!isReadOnly) {
      getRecipientsStatus();
    }

    if (location?.pathname?.includes('/certificacion')) {
      setOpenCertification(true);
    }
  }, []); // eslint-disable-line

  return {
    data: {
      currentCommunication,
      parsedDoc,
      isFavorite,
      openCertification,
      hasAttachments,
      attachments,
      recipients,
      isLoading,
    },
    actions: {
      handleChangeFavorites,
      setOpenCertification,
      getRecipientsList,
    },
  };
};
