import { FC } from 'react';
import { Interval } from 'types';
import { formatNumber } from 'utils/formats';

type Props = {
  interval: Interval;
};

const IntervalRowLine: FC<Props> = ({ interval }) => (
  <span>
    {`${formatNumber(interval.shareFrom)} - ${formatNumber(interval.shareTo)}`}
  </span>
);

export default IntervalRowLine;
