import { HoldingClass, Transaction, Society } from 'types';

type GetTransactionNominalValueProps = {
  holdingClass: HoldingClass | null;
  transaction: Transaction;
  society: Society;
};

export const getTransactionNominalValue = ({
  holdingClass,
  transaction,
  society,
}: GetTransactionNominalValueProps): number => {
  if (holdingClass?.nominalValue) {
    return holdingClass.nominalValue;
  }

  if (transaction?.nominalValue) {
    return transaction.nominalValue;
  }

  return society?.nominalValue || 0;
};
