import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  templateType: string;
  handleCloseModal: () => void;
};

const ReplaceTemplateWithDocumentModalHeader = ({
  templateType,
  handleCloseModal,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal.Header>
      <h5 className="modal-title">
        {t('ReplaceTemplateWithDocument')}&nbsp;
        <span className="text-muted ff-alt fs-14px text-right">
          ({t(templateType)})
        </span>
      </h5>

      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </Modal.Header>
  );
};

export default ReplaceTemplateWithDocumentModalHeader;
