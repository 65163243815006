import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export const initLogRocket = () => {
  if (process.env.NODE_ENV !== 'development') {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
      shouldDebugLog: false,
    });

    setupLogRocketReact(LogRocket);
  }
};
