export const getOperationLabel = (operationType: string) => {
  const labels: { [key: string]: string } = {
    ADD_PARTNER: 'AddPartner',
    CONSTITUTION: 'Constitution',
    CAPITAL_INCREASE: 'CapitalIncrease',
    CAPITAL_DECREASE: 'CapitalDecrease',
    SELL_PARTICIPATION: 'SELL_PARTICIPATION',
    HERITAGE: 'Heritage',
    DONATION: 'Donation',
    SPLIT: 'Split',
    RENUMERATION: 'Renumeration',
    RECLASSIFICATION: 'Reclassification',
    DIVIDENDS_DISTRIBUTION: 'DividendsDistribution',
  };

  return labels[operationType] || operationType;
};
