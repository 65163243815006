import operationTypes from 'constants/operationTypes';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Society } from 'types';
import { getLastOperation } from 'utils/getLastOperation';

type Props = {
  actualSociety: Society;
};

const getLastOperationDate = ({ actualSociety }: Props) => {
  const defaultDate = '1990-01-01T00:00';

  const excludeOperationTypes = [
    operationTypes.ADD_PARTNERS,
    operationTypes.ADD_PARTNER,
    operationTypes.ADD_BENEFICIARY,
    operationTypes.STOCK_OPTION,
    operationTypes.PHANTOM_SHARE,
  ];
  const lastOperation = getLastOperation({
    actualSociety,
    excludeOperationTypes,
  });

  const date = lastOperation
    ? format(new Date(lastOperation?.date), 'yyyy-MM-dd')
    : defaultDate;

  return date;
};

export const useGetLastOperationDate = ({ actualSociety }: Props) => {
  const [lastOperationDate, setLastOperationDate] = useState<string>('');

  useEffect(() => {
    if (actualSociety) {
      const lastOperationDate = getLastOperationDate({ actualSociety });
      setLastOperationDate(lastOperationDate);
    }
  }, [actualSociety]);

  return {
    data: {
      lastOperationDate,
    },
  };
};
