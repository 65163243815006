import { SharesTitle, Society } from 'types';

export const getLastTitleNumber = (
  society: Society,
  titles: SharesTitle[]
): number => {
  const initialNumber =
    society?.configuration?.sharesTitles?.initialNumber ?? 1;

  const societyTitlesLastNumber =
    titles.length > 0
      ? Math.max(...titles.map((title) => title.number ?? 0))
      : 0;

  return Math.max(initialNumber - 1, societyTitlesLastNumber);
};
