import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { axiosV2 } from 'interceptors/axios';
import { SharesTitle } from 'types';

export const getSharesTitles = (sourceIds: any) => async (dispatch: any) => {
  const query = { params: { ...sourceIds } };
  try {
    const { data } = await axiosV2.get(`/sharesTitles`, query);
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_SHARES_TITLES,
      sharesTitles: decodedData,
    });

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const updateSharesTitle =
  (sharesTitleData: Partial<SharesTitle>) => async (dispatch: any) => {
    try {
      const body = { ...sharesTitleData };

      const { data } = await axiosV2.put(`/sharesTitles`, body);
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_SHARES_TITLE,
        sharesTitle: decodedData,
      });

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
