import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { hasShowDetails } from 'utils/showDetails';

import { SocietyTypes } from 'types/Society';
import MenuItem from './MenuItem';
import DilutionBadge from '../DilutionBadge';
import '../SideNav.scss';

type Props = {
  societyId: string;
  userId?: string;
  isAdmin: boolean;
  isPartner: boolean;
  isInvestor: boolean;
  hasAccessCaptable: boolean;
};

const PartnersMenu: FC<Props> = ({
  societyId,
  userId,
  isAdmin,
  isPartner,
  isInvestor,
  hasAccessCaptable,
}) => {
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const hasMyCardLinkDisabled =
    (!isAdmin && (!isPartner || !hasAccessCaptable)) || (isAdmin && !isPartner);
  const hasPartnersLinkDisabled = !isAdmin && !isPartner;
  const hasPartnerBookLinkDisabled =
    !isAdmin && (!isPartner || !hasAccessCaptable);
  const hasPartnersCardsLinkDisabled = !isAdmin && !isPartner;
  const hasInvestorsLinkDisabled = !isAdmin && !isInvestor;
  const hasRepresentantsLinkDisabled = !isAdmin;
  const hasHoldingClassesLinkDisabled = !isAdmin;
  const hasPartnerCategoriesLinkDisabled = !isAdmin;

  const isSASociety = actualSociety?.societyType === SocietyTypes.SA;
  const hasSharesTitleEnabled =
    actualSociety?.configuration?.sharesTitles?.enabled;
  const hasTitlesLinkDisabled =
    !isSASociety || !hasSharesTitleEnabled || !isAdmin;

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="1">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-users" />
            </span>
            <span className="nk-menu-text">{t('Partners')}</span>
            <DilutionBadge />
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="1">
        <ul className="nk-menu-sub">
          {!hasMyCardLinkDisabled && (
            <MenuItem linkTo={`/socios/${societyId}/${userId}`} text="MyCard" />
          )}

          {!hasPartnersLinkDisabled && (
            <MenuItem linkTo={`/socios/${societyId}`} text="Partners" />
          )}

          {!hasPartnerBookLinkDisabled && (
            <MenuItem
              linkTo={`/socios-libro/${societyId}`}
              text="PartnerBook"
            />
          )}

          {!hasTitlesLinkDisabled && (
            <MenuItem
              linkTo={`/titulos-libro/${societyId}`}
              text="TitleRegistryBook"
            />
          )}

          {!hasPartnersCardsLinkDisabled && (
            <MenuItem
              linkTo={`/socios-fichas/${societyId}`}
              text="PartnersCards"
            />
          )}

          {!hasInvestorsLinkDisabled && (
            <MenuItem linkTo={`/inversores/${societyId}`} text="Investors" />
          )}

          {!hasRepresentantsLinkDisabled && (
            <MenuItem
              linkTo={`/socios-representantes/${societyId}`}
              text="Representants"
            />
          )}

          {!hasHoldingClassesLinkDisabled && (
            <MenuItem
              linkTo={`/clases-participaciones/${societyId}`}
              text="HoldingClasses"
            />
          )}

          {!hasPartnerCategoriesLinkDisabled && (
            <MenuItem
              linkTo={`/socios-categorias/${societyId}`}
              text="PartnerCategories"
            />
          )}
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default PartnersMenu;
