import i18n from 'i18n/config';

import { store } from 'redux/store';
import {
  Council,
  CouncilAttendanceTypes,
  CouncilStatus,
  CouncilTypes,
  Society,
  User,
} from 'types';

import createDocumentFromHtml from 'utils/createDocumentFromHtml';
import councilActTemplates from 'constants/councilActTemplates';
import councilCertificationTemplates from 'constants/councilCertificationTemplates';

import duplicateCurrentTemplate from 'modules/boards/modals/ReplaceBoardTemplate/utils/duplicateCurrentTemplate';
import { AddCouncilFormState } from 'modules/_shared/components/Modals/AddCouncil/types/AddCouncilForm';
import { addCouncilDocuments } from 'modules/administratives/utils/addCouncilDocuments';

import { parseDirectorsToParticipants } from './parseDirectorsToParticipants';
import { generateCouncilTemplates } from './generateCouncilTemplates';
import { createCouncil } from '../redux/councilActions';
import { generateCouncilDocuments } from './generateCouncilDocuments';

interface NewCouncilData extends AddCouncilFormState {
  user: User;
  society: Society;
  announcementTemplate?: string;
  actTemplate?: string;
  actCertificateTemplate?: string;
}

export const saveCouncil = async (
  councilData: NewCouncilData,
  isDuplicate = false
) => {
  const {
    user,
    society,
    name,
    details,
    topics,
    minQuorum,
    files,
    date,
    councilType,
    attendanceType,
    place,
    announcementText,
    onlineAccess,
    digitalVoting,
    president,
    secretary,
    announcementTemplate: existingAnnouncementTemplate,
  } = councilData;

  try {
    const uploadedDocuments = !isDuplicate
      ? await addCouncilDocuments({ files, user, society })
      : [];

    let announcementTemplate;

    if (isDuplicate) {
      announcementTemplate = await duplicateCurrentTemplate(
        existingAnnouncementTemplate as string
      );
    } else {
      const templates = await generateCouncilTemplates({ user, society });

      announcementTemplate = templates.announcementTemplate;
    }

    const participants = parseDirectorsToParticipants(society?.directors);
    const status = 'PENDING' as CouncilStatus;

    const actTemplateId = councilActTemplates[process.env.NODE_ENV];
    const certificationTemplateId =
      councilCertificationTemplates[process.env.NODE_ENV];

    const newCouncil: Council = {
      name,
      status,
      date: date ? new Date(date) : new Date(),
      attendanceType: attendanceType as CouncilAttendanceTypes,
      place,
      details,
      topics,
      announcementText,
      onlineAccess,
      councilType: councilType as CouncilTypes,
      digitalVoting,
      participants,
      president,
      secretary,
      minQuorum,

      publicDocuments: uploadedDocuments,
      announcementTemplate: announcementTemplate
        ? announcementTemplate['_id']
        : null,
      actTemplate: actTemplateId,
      actCertificateTemplate: certificationTemplateId,
      society: society?.['_id'],
    };

    const announcementDocName = `${i18n.t('AnnouncementCouncil')}`;
    const announcementDocDescription = `${i18n.t(
      'GeneratedByTheTemplate'
    )} ${i18n.t('AnnouncementTemplate')}_${name}`;

    const documentId = await createDocumentFromHtml({
      html: announcementTemplate.editorModel,
      tagsData: { society, council: newCouncil },
      documentName: announcementDocName,
      documentDescription: announcementDocDescription,
      society,
      user,
    });

    if (documentId) {
      newCouncil.announcementDocument = documentId;
    }

    const { actDocumentId, certificationDocumentId } =
      await generateCouncilDocuments({
        announcementTemplateId: announcementTemplate['_id'],
        actTemplateId,
        certificationTemplateId,
        society,
        council: newCouncil,
        userId: user['_id'],
      });

    if (actDocumentId) {
      newCouncil.actDocument = actDocumentId;
    }
    if (certificationDocumentId) {
      newCouncil.actCertificateDocument = certificationDocumentId;
    }

    const council = await store.dispatch(createCouncil(newCouncil));

    return council;
  } catch (error) {
    throw new Error();
  }
};
