/* eslint-disable react/no-array-index-key */
import { useDispatch } from 'react-redux';

import actionTypes from 'constants/actionTypes';

import { setModal } from 'modules/_shared/redux/modalActions';
import AddCouncil from 'modules/_shared/components/Modals/AddCouncil';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { useTranslate } from 'hooks/useTranslate';
import sizes from 'constants/sizes';

const CouncilsCtaSection = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleClickNewCouncil = () => {
    dispatch(setModal(<AddCouncil />));
  };

  const handleClickCopyCouncil = () => {
    dispatch(
      setModal(
        <AddCouncil
          action={actionTypes.DUPLICATE as keyof typeof actionTypes}
          size={sizes.XL}
        />
      )
    );
  };

  const handleClickTutorial = () => {
    window.open('https://www.sttok.com/juntas-consejos', '_blank');
  };

  const handleClickDigitalSignature = () => {
    window.open('https://www.sttok.com/juntas-consejos', '_blank');
  };

  const ctaCards = [
    {
      title: 'Nuevo Consejo',
      icon: 'ni ni-bulb-fill',
      color: 'primary',
      description: 'Crea un nuevo Consejo.',
      onClick: handleClickNewCouncil,
      enabled: true,
    },
    {
      title: 'Copiar un Consejo',
      icon: 'ni ni-spark-fill',
      color: 'blue',
      description: 'Crear nuevo consejo en base a los datos de uno anterior.',
      onClick: handleClickCopyCouncil,
      enabled: true,
      comingSoon: true,
    },
    {
      title: 'Ver un tutorial',
      icon: 'ni ni-youtube-fill',
      color: 'danger',
      description: 'Conoce cómo se gestionan los Consejos con Sttok.',
      onClick: handleClickTutorial,
      enabled: false,
    },
    {
      title: 'Firma Digital',
      icon: 'ni ni-pen',
      color: 'purple',
      description: 'Firma digital del Acta o Certificado.',
      onClick: handleClickDigitalSignature,
      enabled: true,
      comingSoon: true,
    },
  ];

  return (
    <>
      <PageListHeader subTitle={t('WhatDoYouWantToDo')} />

      <div className="nk-block council-cta-section">
        <div className="row g-gs">
          {ctaCards.map(
            (ctaCard, index) =>
              ctaCard.enabled && (
                <div className="col-12 col-md" key={`cta-card-${index} `}>
                  <div
                    className={`card card-full bg-lighter rounded-xl ${
                      !ctaCard?.comingSoon
                        ? 'hover-shadow cursor-pointer'
                        : 'disabled cursor-not-allowed'
                    }`}
                    onClick={!ctaCard?.comingSoon ? ctaCard.onClick : undefined}
                  >
                    <div className="card-inner">
                      <div className="d-flex justify-content-between">
                        <div
                          className={`user-avatar text-${ctaCard.color} bg-${ctaCard.color}-dim mb-3`}
                        >
                          <em className={`icon ni ${ctaCard.icon}`} />
                        </div>
                        <div className="div">
                          {ctaCard?.comingSoon && (
                            <span className="badge badge-pill bg-outline-light text-soft ml-2">
                              Próximamente
                            </span>
                          )}
                        </div>
                      </div>
                      <h6
                        className={`fs-4 ${
                          ctaCard?.comingSoon ? 'text-soft' : ''
                        }`}
                      >
                        {ctaCard.title}
                      </h6>
                      <p
                        className={`fs-11px ${
                          ctaCard?.comingSoon ? 'text-soft' : ''
                        }`}
                      >
                        {ctaCard.description}
                      </p>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default CouncilsCtaSection;
