import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { User } from 'types';

import isSuperAdmin from 'utils/isSuperAdmin';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
  user: User;
};

const DraftMenu: FC<Props> = ({ societyId, user }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="3">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-edit" />
            </span>
            <span className="nk-menu-text">{t('Drafts')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="3">
        <ul className="nk-menu-sub">
          <MenuItem linkTo={`/borradores/${societyId}`} text="DraftsList" />

          <MenuItem
            linkTo={`/borrador-ampliacion/${societyId}`}
            text="DraftCapitalIncrease"
          />

          <MenuItem
            linkTo={`/borrador-venta/${societyId}`}
            text="DraftSellShares"
          />

          <MenuItem
            linkTo={`/borrador-nota/${societyId}`}
            text="DraftConvertibleNote"
          />

          <MenuItem
            linkTo={`/borrador-compra-venta/${societyId}`}
            text="DraftSellParticipations"
          />

          <MenuItem linkTo={`/borrador-safe/${societyId}`} text="DraftSAFE" />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default DraftMenu;
