import { useTranslate } from 'hooks/useTranslate';

type Props = {
  planName: string;
};

const LetterTabInfoView = ({ planName }: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <h6>{t('LetterAndConditions')}</h6>
      <p className="text-justify w-100">
        {t('EachBeneficiaryWillHaveInvLetter')} <b>{'{'}</b>
        .&nbsp;
        {t('AlsoIncludesVestingCalendar')}
      </p>
      <p className="text-justify w-100">
        <b>{t('DocumentPreview')}:</b> {t('InTheTab')} &quot;
        {t('DocumentPreview')}
        &quot; {t('YouCanSee')}
      </p>
      <p className="text-justify w-100">
        <b>{t('LetterAndConditionsOfTheP')}:</b> {planName}
      </p>
    </>
  );
};

export default LetterTabInfoView;
