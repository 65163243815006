import { Contribution, Operation, Partner, Society } from 'types';
import operationTypes from 'constants/operationTypes';

import { getPartnerOperationsFromType } from './getPartnerOperationsFromType';

export const getPartnerContributionData = (
  partnerId: string,
  contributionOperation: Operation
) => {
  const partnerContribution = contributionOperation.contributions.find(
    (contribution: Contribution) => contribution.partner === partnerId
  );

  if (!partnerContribution) return null;

  return partnerContribution;
};

const getPartnerAmountFormContribution = (
  partnerId: string,
  contributionOperation: Operation
) => {
  const partnerContribution = getPartnerContributionData(
    partnerId,
    contributionOperation
  );

  if (!partnerContribution) return 0;

  return partnerContribution.amount;
};

export const getPartnerContributionsData = (
  partner: Partner,
  society: Society
) => {
  const contributionOperations = getPartnerOperationsFromType({
    partner,
    society,
    operationType: operationTypes.CONTRIBUTION,
  });

  const partnerContributionsData = contributionOperations.map((operation) => {
    const partnerContribution = getPartnerContributionData(
      partner['_id'],
      operation
    );

    return {
      date: operation.date,
      transactionType: operation.operationType,
      amount: partnerContribution?.amount || 0,
    };
  });

  return partnerContributionsData;
};

export const getPartnerAmountFormContributions = (
  partner: Partner,
  society: Society
) => {
  const contributionOperations = getPartnerOperationsFromType({
    partner,
    society,
    operationType: operationTypes.CONTRIBUTION,
  });

  const partnerAmount = contributionOperations.reduce((acc, operation) => {
    const amount = getPartnerAmountFormContribution(partner['_id'], operation);

    return acc + amount;
  }, 0);

  return partnerAmount;
};
