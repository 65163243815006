import { useTranslate } from 'hooks/useTranslate';
import { FC, memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import sizes from 'constants/sizes';
import menuTypes from 'constants/menuTypes';
import { State } from 'redux/initialState';
import { useGetActualSociety } from 'hooks/useGetActualSociety';

import Page from 'modules/_shared/components/Pages/Page';
import Inbox from 'modules/communications/components/InboxDetails';
import Button from 'modules/_shared/components/Buttons';
import InboxSidenav from 'modules/communications/components/InboxSidenav';
import SendEmail from 'modules/_shared/components/Modals/SendEmail';
import { setModal } from 'modules/_shared/redux/modalActions';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import InboxDetails from 'modules/communications/components/InboxDetails/components/InboxDetails';
import { useGetMenu } from 'modules/administratives/hooks/useGetMenu';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CommunicationSettings from 'modules/communications/components/CommunicationSettings';
import { useGetCommunicationsSectionPermissions } from 'modules/communications/hooks/useGetCommunicationSectionPermissions';

import useGetActivePage, { communicationPages } from './hooks/useGetActivePage';
import useGetCurrentCommunication from './hooks/useGetCurrentCommunication';

import './Communications.scss';

const Communications: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, pageType, communicationId } = useParams();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const communications = useSelector((state: State) => state.communications);

  const { isAdmin, isReadOnly } = useGetCommunicationsSectionPermissions().data;
  const { currentCommunication } = useGetCurrentCommunication({
    communicationId,
    communications,
  }).data;
  const pageResult = useGetActivePage({ pageType, communicationId });

  const { activePage } = pageResult.data;
  const { handleActiveChangePage } = pageResult.actions;

  const [totalCount, setTotalCount] = useState(0);

  useGetMenu(menuTypes.NULL);
  useGetActualSociety(societyId);

  const handleOpenNewCommsModal = () => {
    if (isReadOnly) return;
    // @ts-ignore
    dispatch(setModal(<SendEmail size={sizes.XL} />));
  };

  const pages = {
    [communicationPages.INBOX]: <Inbox onUpdateTotalCount={setTotalCount} />,
    [communicationPages.DETAILS]: (
      <InboxDetails communication={currentCommunication} />
    ),
    [communicationPages.SETTINGS]: (
      <CommunicationSettings society={actualSociety} />
    ),
  };

  return (
    <Page className="communications-page">
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('Communications')}
        actions={[
          <GoBackButton />,
          isAdmin && (
            <Button
              icon="ni ni-send"
              onClick={handleOpenNewCommsModal}
              disabled={isReadOnly}
            >
              {t('NewCommunication')}
            </Button>
          ),
        ]}
      />

      <div className="nk-content-wrap">
        <div className="nk-ibx">
          <InboxSidenav
            activePage={activePage}
            onChangePage={handleActiveChangePage}
            societyId={societyId}
            totalCount={totalCount}
          />

          <div className="nk-ibx-body bg-white">{pages[activePage]}</div>
        </div>
      </div>
    </Page>
  );
};

export default memo(Communications);
