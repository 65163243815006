import Page from 'modules/_shared/components/Pages/Page';
import NotificationsPageBody from './components/NotificationsPageBody';

const Notifications = () => (
  <Page className="bg-lighter">
    <NotificationsPageBody />
  </Page>
);

export default Notifications;
