import { updateCouncil } from 'modules/administratives/redux/councilActions';
import { generateCouncilDocuments } from 'modules/administratives/utils/generateCouncilDocuments';
import { store } from 'redux/store';
import { Council, Society, User } from 'types';

type ChangeActTemplateProps = {
  council: Council;
  society: Society;
  user: User;
  selectedTemplate: string;
};

export const changeActTemplate = async ({
  council,
  society,
  user,
  selectedTemplate,
}: ChangeActTemplateProps) => {
  if (council) {
    const { actDocumentId } = await generateCouncilDocuments({
      actTemplateId: selectedTemplate,
      society,
      council,
      userId: user['_id'],
    });

    const newCouncilData = {
      _id: council._id,
      actTemplate: selectedTemplate,
      actDocument: actDocumentId,
    };
    await store.dispatch(updateCouncil(newCouncilData));
  }
};
