import views from '../constants/views';

function viewOptions(i18n) {
  return [
    {
      name: 'equity',
      icon: 'ni-book-read',
      text: i18n.t('EquityStory'),
      dropdown: true,
      action: ({ setView }) => {
        setView(views.EQUITY_STORY);
      },
    },
    {
      name: 'commercial',
      icon: 'ni-book-read',
      text: i18n.t('CommercialRegister'),
      dropdown: true,
      action: ({ setView }) => {
        setView(views.COMMERCIAL_REGISTER);
      },
    },
    {
      name: 'summaryInvestors',
      icon: 'ni-book-read',
      text: i18n.t('SummaryInvestorsOption'),
      dropdown: true,
      action: ({ setView }) => {
        setView(views.SUMMARY_INVESTORS);
      },
    },
    {
      name: 'summary',
      icon: 'ni-book-read',
      text: i18n.t('SummaryOption'),
      dropdown: true,
      action: ({ setView }) => {
        setView(views.SUMMARY);
      },
    },
    {
      name: 'summaryAccumulated',
      icon: 'ni-book-read',
      text: i18n.t('SummaryAccumulatedOption'),
      dropdown: true,
      action: ({ setView }) => {
        setView(views.SUMMARY_ACCUMULATED);
      },
    },
  ];
}
export default viewOptions;
