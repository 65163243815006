import { updateCouncil } from 'modules/administratives/redux/councilActions';
import { generateCouncilDocuments } from 'modules/administratives/utils/generateCouncilDocuments';
import { store } from 'redux/store';
import { Council, Society, User } from 'types';

type ChangeCertificationTemplateProps = {
  council: Council;
  society: Society;
  user: User;
  selectedTemplate: string;
};
export const changeCertificationTemplate = async ({
  council,
  society,
  user,
  selectedTemplate,
}: ChangeCertificationTemplateProps) => {
  if (council) {
    const { certificationDocumentId } = await generateCouncilDocuments({
      certificationTemplateId: selectedTemplate,
      society,
      council,
      userId: user['_id'],
    });

    const newCouncilData = {
      _id: council._id,
      actCertificateTemplate: selectedTemplate,
      actCertificateDocument: certificationDocumentId,
    };
    await store.dispatch(updateCouncil(newCouncilData));
  }
};
