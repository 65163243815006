import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import ContributionDataView from './ContributionDataView';
import { useContributionModal } from '../context/ContributionModalContext';
import ContributionResumeView from './ContributionResumeView';

const ContributionModalBody: FC = () => {
  const { data } = useContributionModal();

  return (
    <Modal.Body>
      <div className="nk-modal">
        {data.resumeView ? (
          <ContributionResumeView />
        ) : (
          <ContributionDataView />
        )}
      </div>
    </Modal.Body>
  );
};

export default ContributionModalBody;
