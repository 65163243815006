import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';

import { Document } from 'types';

import { getDocumentIcon } from 'utils/documentIcons';

import ToggleMenu from 'modules/_shared/components/ToggleMenu';
import { useDispatch } from 'react-redux';
import { setModal } from 'modules/_shared/redux/modalActions';
import DocumentViewer from 'modules/_shared/components/Modals/DocumentViewer';

type Props = {
  index: number;
  document: Document;
  hasOptions?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleDownloadDocument: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveDocument?: (index: number) => void;
  isAdminView?: boolean;
  isSummaryVersion?: boolean;
  textSize?: number;
};

const DocumentRow: FC<Props> = ({
  index,
  document,
  hasOptions = true,
  handleDownloadDocument,
  handleRemoveDocument,
  isAdminView = true,
  isSummaryVersion,
  textSize = 250,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const isReadOnly = false;

  const documentName = document.name;
  const documentIcon = getDocumentIcon(document);
  const documentDate = document.date;

  const availableOptions = {
    DOWNLOAD: (
      <a
        className="d-flex cursor-pointer"
        key={`document-download-option-${index}`}
        onClick={() => handleDownloadDocument(index)}
      >
        <em className="icon ni ni-download fs-20px" />
        <span className="ml-1">{t('Download')}</span>
      </a>
    ),
    DELETE: (
      <a
        className="d-flex cursor-pointer"
        key={`document-delete-option-${index}`}
        onClick={() => handleRemoveDocument && handleRemoveDocument(index)}
      >
        <em className="icon ni ni-trash fs-20px" />
        <span className="ml-1">{t('Delete')}</span>
      </a>
    ),
  };

  const toggleOptions = isAdminView
    ? [availableOptions.DOWNLOAD, availableOptions.DELETE]
    : [availableOptions.DOWNLOAD];

  const handleOpenDocument = () => {
    if (isSummaryVersion) {
      dispatch(setModal(<DocumentViewer document={document} size="xl" />));
    }
  };

  return (
    <div className="nk-files-list" onClick={handleOpenDocument}>
      <div
        className={`nk-file-item nk-file ${
          isSummaryVersion ? 'cursor-pointer' : ''
        }`}
      >
        <div className="nk-file-info">
          <div className="nk-file-title">
            <div className="nk-file-icon">
              <span className="nk-file-icon-type">
                <img src={documentIcon} alt="icon-file" />
              </span>
            </div>
            <div className="nk-file-name">
              <div
                data-tooltip-id="document-name"
                data-tooltip-content={documentName}
                className={`w-${textSize}px d-block text-ellipsis`}
              >
                <span>{documentName}</span>
                <Tooltip id="document-name" place="top" />
              </div>
            </div>
          </div>
        </div>

        <div className="nk-file-meta">
          <div className="tb-lead">
            {documentDate ? format(new Date(documentDate), 'dd/MM/yy') : '-'}
          </div>
        </div>

        <div className="nk-file-members">
          <span
            className="btn btn-icon btn-trigger"
            onClick={() => handleDownloadDocument(index)}
          >
            <em className="icon ni ni-file-download" />
          </span>
        </div>

        {!isReadOnly && hasOptions && (
          <div className="text-left pr-4">
            <ToggleMenu toggleOptions={toggleOptions} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentRow;
