import { Society, SocietyDirector } from 'types';
import { isActiveDirector } from 'modules/administratives/utils/isActiveDirector';

type Props = {
  society: Society;
};

const getActiveAdminMembersCount = (society: Society) => {
  let count = 0;
  society?.directors?.forEach((director) => {
    const isActive = isActiveDirector(director);
    if (isActive) {
      count += 1;
    }
  });
  return count;
};

const getAdminMembers = (society: Society) => society?.directors || [];

export const getAdminMembersData = ({ society }: Props) => {
  let membersCount = 0;
  let members: SocietyDirector[] = [];

  if (society) {
    membersCount = getActiveAdminMembersCount(society);
    members = getAdminMembers(society);
  }

  return { members, membersCount };
};
