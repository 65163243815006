import { FC } from 'react';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import NotifyPartnersDividendsModalHeader from './components/NotifyPartnersDividendsModalHeader';
import NotifyPartnersDividendsModalBody from './components/NotifyPartnersDividendsModalBody';
import NotifyPartnersDividendsModalFooter from './components/NotifyPartnersDividendsModalFooter';
import { useNotifyPartnersDividendsModal } from './context/NotifyPartnersDividendsModalContext';

const NotifyPartnersDividendsModal: FC = () => {
  const { data } = useNotifyPartnersDividendsModal();

  return (
    <>
      <NotifyPartnersDividendsModalHeader />
      <NotifyPartnersDividendsModalBody />
      <NotifyPartnersDividendsModalFooter />

      {data.isLoading && <CustomLoading />}
    </>
  );
};

export default NotifyPartnersDividendsModal;
