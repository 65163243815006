import { FC } from 'react';

import { Operation } from 'types';

import DividendDocumentModalHeader from './components/DividendDocumentModalHeader';
import DividendDocumentModalBody from './components/DividendDocumentModalBody';

type DividendDocumentModalProps = {
  operation: Operation;
};

const DividendDocumentModal: FC<DividendDocumentModalProps> = ({
  operation,
}) => (
  <>
    <DividendDocumentModalHeader />
    <DividendDocumentModalBody operation={operation} />
  </>
);

export default DividendDocumentModal;
