export const replaceAccentedVowels = (text: string) => {
  const accentMap: { [key: string]: string } = {
    á: 'a',
    Á: 'A',
    é: 'e',
    É: 'E',
    í: 'i',
    Í: 'I',
    ó: 'o',
    Ó: 'O',
    ú: 'u',
    Ú: 'U',
  };

  return text.replace(/[áéíóúÁÉÍÓÚ]/g, (letter) => accentMap[letter]);
};
