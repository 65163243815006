/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';

import { useTranslate } from 'hooks/useTranslate';
import Icon from '../Icon/Icon';
import paginationItems from './paginationItems';

const animatedComponents = makeAnimated();

const getStylesFromStatus = ({ isFocused, isSelected }) => {
  if (isSelected) return '#6576FF';
  if (isFocused) return '#6576FF50';

  return 'white';
};

const DataTablePagination = ({
  itemPerPage = 10,
  totalItems,
  paginate,
  currentPage,
  onChangeRowsPerPage,
  customItemPerPage,
  setRowsPerPage,
  className = '',
}) => {
  const { t } = useTranslate();
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(
    () =>
      paginationItems.find((item) => item.value === itemPerPage) ||
      paginationItems[0]
  );

  let pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    if (itemPerPage === 0) {
      pageNumbers = [1];
      break;
    }

    pageNumbers.push(i);
  }

  const paginationNumber = () => {
    if (pageNumbers.length <= 5) {
      return pageNumbers;
    }
    if (pageNumbers.length >= 5 && currentPage <= 4) {
      return [1, 2, 3, 4, 5, '...', pageNumbers[pageNumbers.length - 1]];
    }
    if (
      pageNumbers.length >= 5 &&
      currentPage >= pageNumbers[pageNumbers.length - 4]
    ) {
      return [
        1,
        '...',
        pageNumbers[pageNumbers.length - 5],
        pageNumbers[pageNumbers.length - 4],
        pageNumbers[pageNumbers.length - 3],
        pageNumbers[pageNumbers.length - 2],
        pageNumbers[pageNumbers.length - 1],
      ];
    }
    if (
      pageNumbers.length > 5 &&
      currentPage > 4 &&
      currentPage < pageNumbers[pageNumbers.length - 4]
    ) {
      return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        pageNumbers[pageNumbers.length - 1],
      ];
    }
  };

  const paginationItms = paginationNumber();

  const firstPage = () => {
    paginate(1);
  };

  const lastPage = () => {
    paginate(pageNumbers[pageNumbers.length - 1]);
  };

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };

  const getFirstItem = () => {
    if (totalItems === 0) {
      return 0;
    }
    return (currentPage - 1) * itemPerPage + 1;
  };

  const getLastItem = () => {
    if (totalItems === 0) {
      return 0;
    }
    return Math.min(currentPage * itemPerPage, totalItems);
  };

  const getNewCurrentPage = (selectedOption) =>
    Math.ceil(getFirstItem() / selectedOption.value);

  const handleChangeItemsPerPage = (selectedOption) => {
    let newCurrentPage;

    if (selectedOption.value === 0) {
      newCurrentPage = 1;
      paginate(newCurrentPage);
      setSelectedItemsPerPage(totalItems);
      onChangeRowsPerPage(totalItems);
      setRowsPerPage(totalItems);
    } else {
      newCurrentPage = getNewCurrentPage(selectedOption);
      paginate(newCurrentPage);
      setSelectedItemsPerPage(selectedOption);
      onChangeRowsPerPage(selectedOption.value);
      setRowsPerPage(selectedOption.value);
    }
  };

  useEffect(() => {
    onChangeRowsPerPage(customItemPerPage);
    setRowsPerPage(customItemPerPage);
  }, [customItemPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`card-inner-group border-top ${className}`}>
      <div className="card-inner position-relative card-tools-toggle">
        <div className="card-title-group w-100">
          <Row className="d-flex flex-row justify-content-between align-items-center w-100">
            <Col
              sm="6"
              md="2"
              className="col-3 justify-content-center align-items-center order-sm-1 order-md-0 py-1"
            >
              <div>
                <Select
                  value={selectedItemsPerPage}
                  options={paginationItems}
                  closeMenuOnSelect
                  onChange={handleChangeItemsPerPage}
                  components={animatedComponents}
                  placeholder={t('ToShow')}
                  menuPlacement="auto"
                  styles={{
                    option: (baseStyles, status) => ({
                      ...baseStyles,
                      cursor: 'pointer',
                      backgroundColor: getStylesFromStatus(status),
                    }),
                  }}
                />
              </div>
            </Col>
            <Col
              sm="12"
              md="8"
              className="col-6 d-flex justify-content-center align-items-center order-sm-0 order-md-1 py-1"
            >
              <Pagination aria-label="Page navigation example">
                <PaginationItem disabled={currentPage - 1 === 0}>
                  <PaginationLink
                    className="page-link-first"
                    onClick={(ev) => {
                      ev.preventDefault();
                      firstPage();
                    }}
                    href="#first"
                  >
                    <Icon name="chevrons-left" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem disabled={currentPage - 1 === 0}>
                  <PaginationLink
                    className="page-link-prev"
                    onClick={(ev) => {
                      ev.preventDefault();
                      prevPage();
                    }}
                    href="#prev"
                  >
                    <Icon name="chevrons-left" />
                    <span>Prev</span>
                  </PaginationLink>
                </PaginationItem>

                {paginationItms?.map((item) => (
                  <PaginationItem
                    disabled={isNaN(item)}
                    className={`d-none d-sm-block ${
                      currentPage === item ? 'active' : ''
                    }`}
                    key={item}
                  >
                    <PaginationLink
                      tag="a"
                      href="#pageitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        paginate(item);
                      }}
                    >
                      {item}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem
                  disabled={pageNumbers[pageNumbers.length - 1] === currentPage}
                >
                  <PaginationLink
                    className="page-link-next"
                    onClick={(ev) => {
                      ev.preventDefault();
                      nextPage();
                    }}
                    href="#next"
                  >
                    <span>Next</span>
                    <Icon name="chevrons-right" />
                  </PaginationLink>
                </PaginationItem>
                <PaginationItem
                  disabled={pageNumbers[pageNumbers.length - 1] === currentPage}
                >
                  <PaginationLink
                    className="page-link-next"
                    onClick={(ev) => {
                      ev.preventDefault();
                      lastPage();
                    }}
                    href="#last"
                  >
                    <Icon name="chevrons-right" />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
            <Col
              sm="6"
              md="2"
              className="col-3 d-flex justify-content-center align-items-center text-md-right order-sm-2 order-md-2 py-1"
            >
              <div
                className="dataTables_info"
                id="DataTables_Table_2_info"
                role="status"
                aria-live="polite"
              >
                {getFirstItem()} - {getLastItem()} {t('Of')} {totalItems}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default DataTablePagination;
