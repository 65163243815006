import i18n from 'i18n/config';

export default [
  {
    icon: 'ni-book-read',
    text: i18n.t('CapitalIncrease'),
    url: '/borrador-ampliacion',
  },
  {
    icon: 'ni-book-read',
    text: i18n.t('DraftSellShares'),
    url: '/borrador-venta',
  },
  {
    icon: 'ni-book-read',
    text: i18n.t('DraftConvertibleNote'),
    url: '/borrador-nota',
  },
  {
    icon: 'ni-book-read',
    text: i18n.t('DraftSellParticipations'),
    url: '/borrador-compra-venta',
  },
  {
    icon: 'ni-book-read',
    text: i18n.t('DraftSAFE'),
    url: '/borrador-safe',
  },
];
