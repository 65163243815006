import { FC, memo } from 'react';

import NumberFormat from 'react-number-format';

import operationTypes from 'constants/operationTypes';
import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';

type TransactionDetailsOperationTableFooterProps = {
  operationType: string;
  totalShares: number;
  totalSum: number;
};

const TransactionDetailsOperationTableFooter: FC<
  TransactionDetailsOperationTableFooterProps
> = ({ operationType, totalShares, totalSum }) => {
  const { t } = useTranslate();

  const getTableCell = (value: number, format: any) => (
    <td className="text-right">
      <NumberFormat
        value={value}
        displayType="text"
        thousandSeparator={format?.thousandSeparator}
        decimalSeparator={format?.decimalSeparator}
        decimalScale={format?.decimalScale}
        suffix={format?.suffix}
        fixedDecimalScale={format?.fixedDecimalScale}
      />
    </td>
  );

  const showShares = operationType !== operationTypes.CONTRIBUTION;
  const showNominalValue = operationType !== operationTypes.CONTRIBUTION;
  const showPremium = operationType !== operationTypes.CONTRIBUTION;

  return (
    <>
      <tfoot>
        <tr>
          <td />
          <td>{t('Total').toUpperCase()}</td>

          {operationType !== operationTypes.DIVIDENDS_DISTRIBUTION ? (
            showShares && getTableCell(totalShares, numberFormat)
          ) : (
            <td className="text-right" />
          )}

          {showNominalValue && <td />}
          {showPremium && <td />}

          {operationType === operationTypes.CAPITAL_INCREASE && (
            <>
              <td />
              <td />
            </>
          )}
          {getTableCell(totalSum, currencyFormatDecimals)}

          <td />
        </tr>
      </tfoot>
    </>
  );
};

export default memo(TransactionDetailsOperationTableFooter);
