import { FC, useState } from 'react';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { useMixpanel } from 'react-mixpanel-browser';

import { Council } from 'types';
import { State } from 'redux/initialState';
import { DATE_FORMAT, TIME_FORMAT } from 'constants/defaultConstants';
import { getCouncilDetailsUrlByRole } from 'modules/administratives/utils/getCouncilDetailsUrl';
import CouncilBadges from 'modules/administratives/components/CouncilBadges';
import councilStatus from 'modules/administratives/constants/councilStatus';
import menuOptions from 'modules/administratives/pages/CouncilsList/utils/menuOptions';
import MenuDots from 'modules/_shared/components/MenuDots';
import { getCouncilStatusData } from '../../CouncilDetails/utils/getCouncilStatusData';
import { getMemberIdFromCouncilParticipants } from '../utils/getMemberIdFromCouncilParticipants';

type CouncilsRecentsSectionRowProps = {
  council: Council;
  index: number;
};

const CouncilsRecentsSectionRow: FC<CouncilsRecentsSectionRowProps> = ({
  council,
  index,
}) => {
  const { t, i18n } = useTranslate();
  const history = useHistory();
  const mixpanel = useMixpanel();
  const { memberId } = useParams();

  const { isAdmin, isDirector } = useSelector(
    (state: State) => state.society?.role || {}
  );
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const [currentMenuOptions] = useState(menuOptions(i18n));

  const { statusLabel, statusBadge, statusValue } =
    getCouncilStatusData(council);

  const handleClickRow = () => {
    const currentMemberId =
      memberId ||
      getMemberIdFromCouncilParticipants(user.email, council.participants);

    if (!isAdmin && !currentMemberId) return;

    const newRoute = getCouncilDetailsUrlByRole(
      council,
      isAdmin,
      isDirector,
      currentMemberId
    );
    if (newRoute) {
      history.push(newRoute);
    }
  };

  return (
    <tr className="nk-tb-item cursor-pointer">
      <td className="nk-tb-col nk-tb-col-check d-none">
        <div className="custom-control custom-control-sm custom-checkbox notext">
          <input type="checkbox" className="custom-control-input" id="did-01" />
          <label className="custom-control-label" htmlFor="did-01" />
        </div>
      </td>

      <td className="nk-tb-col w-50px" onClick={handleClickRow}>
        <div className="caption-text sub-text fs-12px mt-1 bg-white w-20px h-20px rounded-circle text-center mx-auto lh-20px">
          {index + 1}
        </div>
      </td>

      <td className="nk-tb-col" onClick={handleClickRow}>
        <div className="caption-text fs-14px mt-1">{council.name}</div>
      </td>

      <td className="nk-tb-col tb-col-sm" onClick={handleClickRow}>
        {CouncilBadges.Status && (
          <CouncilBadges.Status
            t={t}
            style={statusBadge}
            text={statusLabel}
            status={statusValue as keyof typeof councilStatus}
          />
        )}
      </td>

      <td className="nk-tb-col tb-col-md" onClick={handleClickRow}>
        <div className="caption-text fs-14px mt-1 text-soft d-inline-flex flex-wrap gx-2">
          <div>{format(new Date(council.date), DATE_FORMAT)}</div>
          <div>{format(new Date(council.date), TIME_FORMAT)}</div>
        </div>
      </td>

      <td className="nk-tb-col tb-col-end py-1">
        <div className="position-absolute top-0 right-0 mt-2 mr-4 z-1000">
          <MenuDots
            menuOptions={currentMenuOptions}
            // @ts-ignore
            id={council._id}
            params={{
              actualSociety,
              council,
              isReadOnly: !isAdmin,
              user,
              mixpanel,
            }}
            direction="right"
          />
        </div>
      </td>
    </tr>
  );
};

export default CouncilsRecentsSectionRow;
