import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { store } from 'redux/store';
import { State } from 'redux/initialState';
import { Dividend, Document, Operation } from 'types';
import { getDocument } from 'modules/documents/redux/documentActions';
import dividendsTemplates from 'constants/dividendsTemplates';
import {
  getPartnerDataToDividendDocument,
  PartnerDividendDocumentData,
} from '../utils/getPartnerDataToDividendDocument';

type DividendDocumentModalBodyProps = {
  dividends: Dividend[];
  template: Document | null;
};

export const useDividendDocumentModalBody = (operation: Operation) => {
  const [data, setData] = useState<DividendDocumentModalBodyProps>({
    dividends: [],
    template: null,
  });

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const loadDividendParticipants = () => {
    const participants = (operation.dividends as Dividend[]).map(
      (dividend: Dividend) => {
        const partnerId = dividend.partner as string;

        return getPartnerDataToDividendDocument({
          partnerId,
          operation,
          society: actualSociety,
        }) as PartnerDividendDocumentData;
      }
    );

    setData((prev) => ({
      ...prev,
      dividends: participants,
    }));
  };

  const fetchDividendTemplate = useCallback(async () => {
    try {
      const societyTemplate = actualSociety?.templates?.dividends;

      const templateId =
        societyTemplate ||
        dividendsTemplates[
          process.env.NODE_ENV as 'development' | 'production'
        ];
      const document = await store.dispatch(getDocument(templateId));

      setData((prev) => ({
        ...prev,
        template: document,
      }));
    } catch (error) {
      console.error(error); // eslint-disable-line
    }
  }, [actualSociety]);

  useEffect(() => {
    if (operation && actualSociety) {
      loadDividendParticipants();
    }
  }, [operation, actualSociety]); // eslint-disable-line

  useEffect(() => {
    fetchDividendTemplate();
  }, [fetchDividendTemplate]);

  return {
    data,
  };
};
