type Props = {
  hasSEPAOption: boolean;
  hasTaxTemplate: boolean;
  hasDividendTemplate: boolean;
  // eslint-disable-next-line no-undef
  handleGenerateBankDocument: VoidFunction;
  // eslint-disable-next-line no-undef
  handleGenerateTaxDeclaration: VoidFunction;
  // eslint-disable-next-line no-undef
  handleOpenDividendModal: VoidFunction;
};

export default [
  {
    id: 'download-SEPA',
    icon: 'ni-file-text',
    rawText: 'SEPADocument',
    action: ({ handleGenerateBankDocument }: Props) =>
      handleGenerateBankDocument(),
    dropdown: true,
    dontShowCurrent: ({ hasSEPAOption }: Props) => !hasSEPAOption,
  },
  {
    id: 'download-tax-declaration',
    icon: 'ni-file-pdf',
    rawText: 'TaxDeclaration',
    action: ({ handleGenerateTaxDeclaration }: Props) =>
      handleGenerateTaxDeclaration(),
    dropdown: true,
    dontShowCurrent: ({ hasTaxTemplate }: Props) => !hasTaxTemplate,
  },
  {
    id: 'download-dividend',
    icon: 'ni-file-pdf',
    rawText: 'DividendsDocument',
    action: ({ handleOpenDividendModal }: Props) => handleOpenDividendModal(),
    dropdown: true,
  },
];
