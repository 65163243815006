/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { State } from 'redux/initialState';
import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import { FC, createContext, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import errorSwal from 'utils/errorSwal';
import successSwal from 'utils/successSwal';
import isValidEmail from 'utils/isValidEmail';

import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';

import { EmailData } from 'types';
import { SendTestEmailModalContextType } from '../types/SendTestEmailModalContextType';

import { sendTestEmailModalInitValue } from '../constants/sendTestEmailModalInitValue';

const SendTestEmailModalContext = createContext<SendTestEmailModalContextType>(
  sendTestEmailModalInitValue
);

export const SendTestEmailModalProvider: FC<{
  children: React.ReactNode;
  emailData: EmailData;
}> = ({ children, emailData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const user = useSelector((state: State) => state.user);

  const [recipientEmail, setRecipientEmail] = useState<string>();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeRecipientEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEmail = event.target.value;
    const validEmail: boolean = isValidEmail(newEmail);

    setInvalidEmail(!validEmail);
    setRecipientEmail(newEmail);
  };

  const handleSendEmail = async () => {
    try {
      setIsLoading(true);
      const DEFAULT_RECIPIENT = 'Destinatario';

      const testEmalData = {
        ...emailData,
        to: [{ email: recipientEmail?.trim(), name: DEFAULT_RECIPIENT }],
      };

      await store.dispatch(sendCommEmail(testEmalData, false));

      successSwal(t('TestEmailSentSuccessfully'));
      setIsLoading(false);
      handleCloseModal();
    } catch (error) {
      errorSwal(t('ErrorSendingTestEmail'));
      setIsLoading(false);
    }
  };

  return (
    <SendTestEmailModalContext.Provider
      value={{
        data: {
          isLoading,
          recipientEmail,
          invalidEmail,
        },
        actions: {
          handleChangeRecipientEmail,
          handleCloseModal,
          handleSendEmail,
        },
      }}
    >
      {children}
    </SendTestEmailModalContext.Provider>
  );
};

export const useSendTestEmailModal = () =>
  useContext(SendTestEmailModalContext);
