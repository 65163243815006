import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

import { Editor } from 'ckeditor5';

import PreviewDropdown from 'modules/_shared/components/Dropdowns/PreviewDropdown';

import useGetEditorModuleSectionValues from '../hooks/useGetEditorModuleSectionLabel';

type Props = {
  section: string;
  templateName: string;
  isDraft: boolean;
  isEditing: boolean;
  isPublic: boolean;
  isTemplate: boolean;
  editorInstance: Editor | null;
  councilId: string;
  isLoading: boolean;
  handleRequestSignature: () => void; // eslint-disable-line
  handleSaveTemplate: () => void; // eslint-disable-line
  handleChangeIsEditing: (value: boolean) => void; // eslint-disable-line
  handleChangePublicStatus: (value: boolean) => void; // eslint-disable-line
};

const EditorModuleHeader: FC<Props> = ({
  section,
  templateName,
  isDraft,
  isEditing,
  isPublic,
  isTemplate,
  editorInstance,
  councilId,
  isLoading,
  handleRequestSignature,
  handleSaveTemplate,
  handleChangeIsEditing,
  handleChangePublicStatus,
}: Props) => {
  const { t } = useTranslate();
  const isAdmin = true; // TODO: Implement permissions
  const isReadOnly = false; // TODO: Implement permissions

  const { moduleHeaderLabel, moduleOptions } = useGetEditorModuleSectionValues({
    currentSection: section,
  }).data;

  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between position-relative">
        <div className="nk-block-head-content">
          <h5 className="title nk-block-title">
            {isDraft && `${t('Draft')} - `}
            {t(moduleHeaderLabel)}

            <div
              className="custom-control-sm custom-switch float-right d-flex align-content-center fw-normal"
              style={{ marginTop: 4 }}
              data-tooltip-id="visibilityTooltip"
            >
              <span
                style={{
                  marginRight: '48px',
                  fontSize: '12px',
                  lineHeight: '1.125rem',
                  paddingTop: '0',
                }}
                className={`${!isPublic ? 'text-soft fw-normal' : 'fw-bold'}`}
              >
                {t('Public')}
              </span>
              <input
                type="checkbox"
                id="price-switch"
                className="custom-control-input float-right"
                checked={!isPublic}
                disabled={false}
                onChange={(event) =>
                  handleChangePublicStatus(!event.target.checked)
                }
              />
              <label
                className={`custom-control-label ${
                  isPublic ? 'text-soft fw-normal' : 'fw-bold'
                }`}
                htmlFor="price-switch"
              >
                {t('Private')}
              </label>
            </div>

            <Tooltip id="visibilityTooltip" place="top" className="ml-3">
              <span className="fw-normal fs-14px">
                {t(isPublic ? 'VisibleToDirectors' : 'VisibleOnlyToAdmins')}
              </span>
            </Tooltip>
          </h5>

          <div className="nk-block-des">
            <p>
              {t('UsedTemplate')}:&nbsp;
              <span className="text-primary">
                {isTemplate ? templateName : '-'}
              </span>
            </p>
          </div>
        </div>
        <div className="nk-block-head-content">
          <ul className="nk-block-tools g-3">
            {!isEditing ? (
              <>
                <li>
                  <button
                    type="button"
                    className="btn btn-outline-light ml-1"
                    onClick={handleRequestSignature}
                    disabled={isReadOnly || !isAdmin || isLoading}
                  >
                    <em className="icon ni ni-edit-alt" />
                    <span>{t('RequestSignature')}</span>
                  </button>
                </li>

                <li>
                  <PreviewDropdown
                    menuOptions={moduleOptions}
                    params={{
                      editorInstance,
                      councilId,
                      handleChangeIsEditing,
                    }}
                    disabled={isReadOnly || !isAdmin || isLoading}
                    direction="down"
                  />
                </li>
              </>
            ) : (
              <li>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveTemplate}
                  disabled={isReadOnly || !isAdmin}
                >
                  <em className="icon ni ni-check-round-cut mr-2" />
                  {t('Save')}
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EditorModuleHeader;
