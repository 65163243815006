import { FC, useEffect, useState } from 'react';
import { Society } from 'types';
import {
  societyEntities,
  SocietyEntities,
  useGetActualSocietyProgressively,
} from 'hooks/useGetActualSocietyProgressively';
import './SocietyProgressBar.scss';

type SocietyProgressBarProps = {
  entities?: SocietyEntities[];
  societyId: string;
  showLabels?: boolean;
  onProgressComplete?: (data: Society) => void; // eslint-disable-line no-unused-vars
  labelStyle?: string;
  wrapperStyle?: string;
};

type SocietyProgressParams = {
  progress: number;
  currentStep: string;
  data: any;
  error: string | null;
  isLoading: boolean;
};

const SocietyProgressBar: FC<SocietyProgressBarProps> = ({
  entities,
  societyId,
  showLabels = false,
  onProgressComplete,
  labelStyle = '',
  wrapperStyle = 'mb-2',
}) => {
  const defaultEntities = [
    societyEntities.BASIC,
    societyEntities.SHARES,
    societyEntities.OPERATIONS,
    societyEntities.PARTNERS,
    societyEntities.BENEFICIARIES,
    societyEntities.INVESTORS,
    societyEntities.DRAFTS,
    societyEntities.STOCK_PLANS,
  ];

  const [isVisible, setIsVisible] = useState(true);

  const { progress, currentStep, data } = useGetActualSocietyProgressively(
    societyId,
    entities || defaultEntities
  ) as SocietyProgressParams;

  const currentLabel = () => {
    if (progress === 100) return 'Sociedad cargada';
    return currentStep || ' ';
  };

  useEffect(() => {
    if (progress === 100) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        if (onProgressComplete) onProgressComplete(data);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [progress, data, onProgressComplete]);

  if (!isVisible) return null;

  return (
    <div className={`progress-bar-wrapper w-100 ${wrapperStyle}`}>
      {showLabels && (
        <div className="d-flex justify-between align-center">
          <span className={`text-soft ${labelStyle}`}>{currentLabel()}</span>
        </div>
      )}
      <div className="progress progress-lg">
        <div
          className="progress-bar"
          data-progress={progress}
          style={{ width: `${progress}%` }}
        >
          {progress}%
        </div>
      </div>
    </div>
  );
};

export default SocietyProgressBar;
