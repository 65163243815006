/* eslint-disable react/no-array-index-key */
import Accordion from 'react-bootstrap/Accordion';

import './SideNav.scss';

type ExtendedMenuLoaderProps = {
  rows: number;
};

const ExtendedMenuLoader = ({ rows }: ExtendedMenuLoaderProps) => (
  <Accordion id="main-menu__extended" className="pl-4 p-md-0 bg-transparent">
    {Array.from({ length: rows || 5 }).map((_, index) => (
      <p
        className="placeholder-glow mb-4"
        key={`extended-menu-loader-row-${index}`}
      >
        <span className="placeholder col-1 h-20px" />
        <span className="placeholder col-9 h-20px ml-1" />
      </p>
    ))}
  </Accordion>
);

export default ExtendedMenuLoader;
