export default [
  {
    value: 'UNREAD',
    style: 'Unread',
  },
  {
    value: 'READ',
    style: 'Read',
  },
];
