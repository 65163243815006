import { FC, memo } from 'react';
import NoItemsImage from 'assets/images/no-items.svg';

type EmptyStateCardProps = {
  mainMessage?: string;
  infoMessage?: string;
  imageIcon?: string;
  bordered?: boolean;
};

const EmptyStateCard: FC<EmptyStateCardProps> = ({
  mainMessage,
  infoMessage,
  imageIcon = NoItemsImage,
  bordered = true,
}) => (
  <div className="nk-block">
    <div className={`card hover-shadow ${bordered ? 'card-bordered' : ''}`}>
      <div className="card-inner card-inner-lg">
        <div className="nk-help">
          <div className="nk-help-img">
            <img src={imageIcon} alt="imageIcon" />
          </div>

          <div className="nk-help-text flex-grow-0">
            {mainMessage && <h5>{mainMessage}</h5>}
            {infoMessage && <p className="text-soft">{infoMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default memo(EmptyStateCard);
