import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Notification } from 'types';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { useNotifications } from 'modules/administratives/pages/Notifications/hooks/useNotifications';
import { useGetPenfingNotifications } from 'modules/administratives/pages/Notifications/hooks/useGetPendingNotifications';

import NotificationCard from './NotificationCard';

const NotificationsPageBody: FC = () => {
  const { t } = useTranslate();

  const notifications = useSelector(
    (state: State) => state?.globalNotifications
  ) as Notification[];

  const { isLoading } = useNotifications();

  const { notificationsCount, notificationsPending, notificationsReadPercent } =
    useGetPenfingNotifications({ notifications }).data;

  const hasData = notifications?.length > 0;

  return (
    <div className="nk-content nk-content-fluid p-0">
      <div className="container-xl wide-xl">
        <div className="nk-content-body pt-1">
          <div className="nk-block">
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <h3 className="mb-0">{t('Notifications')}</h3>
              <div className="caption-text text-dark">
                {notificationsCount}{' '}
                <span className="text-base">
                  con {notificationsPending} pendientes.
                </span>
              </div>
            </div>
            <div className="progress progress-md bg-primary bg-opacity-10 mt-3">
              <div
                className="progress-bar bg-primary"
                data-progress={notificationsReadPercent || 0}
                style={{ width: `${notificationsReadPercent || 0}%` }}
              />
            </div>
            <div className="fs-14px text-dark mt-2">
              Puedes ver la configuración de las notificaciones y alertas en la
              sección Datos Sociedad --&gt; Configuración Alertas{' '}
              <a href="#">Configurar</a>.
            </div>
          </div>

          {hasData && (
            <div className="nk-block">
              <div className="nk-history">
                <div className="nk-history-item">
                  <div className="nk-history-symbol">
                    <div className="nk-history-symbol-dot" />
                  </div>
                  <div className="nk-history-content">
                    <h5>10 Dic, 2024</h5>
                  </div>
                </div>

                {notifications?.map((notification: Notification) => (
                  <NotificationCard
                    key={notification._id}
                    notification={notification}
                  />
                ))}
              </div>
            </div>
          )}

          {isLoading && <CustomLoading />}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPageBody;
