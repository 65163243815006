import { getRecipientsInfo } from 'modules/communications/redux/communicationActions';
import { getDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { State } from 'redux/initialState';
import { Council, Document, SocietyDirector } from 'types';

type CouncilMemberData = {
  council?: Council | null;
  participant?: SocietyDirector | null;
  documents?: any;
};

const councilMembersInitialState = {
  council: null,
  participant: null,
  documents: null,
};

export const useCouncilMember = () => {
  const dispatch = useDispatch();

  const { councilId, memberId } = useParams();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const councils = useSelector((state: State) => state.councils);
  const documents = useSelector((state: State) => state.documents);

  const [data, setData] = useState<CouncilMemberData>(
    councilMembersInitialState
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCurrentMemberData = (directorId: string) => {
    setIsLoading(true);

    if (actualSociety && directorId) {
      const participant = actualSociety?.directors?.find(
        (director: SocietyDirector) => director._id === directorId
      );

      setData((prev) => ({
        ...prev,
        participant,
      }));
    }

    setIsLoading(false);
  };

  const getCurrentCouncilData = (councilId: string) => {
    setIsLoading(true);

    if (councilId) {
      const council = councils?.find((council) => council._id === councilId);
      setData((prev) => ({
        ...prev,
        council,
      }));
    }

    setIsLoading(false);
  };

  const getCurrentDocumentsData = async (council: Council) => {
    try {
      const [
        announcementCommunication,
        announcementDocument,
        actDocument,
        actCertificateDocument,
        actDocumentSignature,
        actCertificateDocumentSignature,
      ] = (await Promise.all([
        council.announcementCommunication
          ? getRecipientsInfo(council.announcementCommunication, 'documentInfo')
          : null,
        council.announcementDocument
          ? dispatch(getDocument(council.announcementDocument))
          : null,
        council.actDocument ? dispatch(getDocument(council.actDocument)) : null,
        council.actCertificateDocument
          ? dispatch(getDocument(council.actCertificateDocument))
          : null,
        null,
        null,
      ])) as [Document, any, any, any, any, any];

      const currentDocuments = {
        announcementCommunication,
        announcementDocument,
        actDocument,
        actCertificateDocument,
        actDocumentSignature,
        actCertificateDocumentSignature,
      };

      setData((prev) => ({
        ...prev,
        documents: currentDocuments,
      }));
    } catch (error) {
      console.error('🚀 cclog ~ getCurrentDocumentsData ~ error', error);
    }
  };

  useEffect(() => {
    if (actualSociety && memberId) {
      getCurrentMemberData(memberId as string);
    }
  }, [actualSociety, memberId]); // eslint-disable-line

  useEffect(() => {
    if (councils && councilId) {
      getCurrentCouncilData(councilId);
    }
  }, [councils, councilId]); // eslint-disable-line

  useEffect(() => {
    if (data.council) {
      getCurrentDocumentsData(data.council);
    }
  }, [data.council]); // eslint-disable-line

  return {
    data,
    isLoading,
  };
};
