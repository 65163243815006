import { FC } from 'react';
import { Council } from 'types';
import CouncilsCard from './CouncilsCard';

type CouncilsListBodyProps = {
  councils: Council[];
};

const CouncilsListBody: FC<CouncilsListBodyProps> = ({ councils }) => (
  <div className="nk-content-wrap">
    {councils?.map((council) => (
      <CouncilsCard key={council._id} council={council} />
    ))}
  </div>
);

export default CouncilsListBody;
