import { SocietyDirector } from 'types';

export const isActiveDirector = (director: SocietyDirector, date?: Date) => {
  if (!director) return false;
  if (director?.directorStatus === 'ACTIVE') return true;
  if (!director?.endDate) return true;

  const directorEndDate = new Date(director.endDate).getTime();
  const currentDate = date ? date.getTime() : new Date().getTime();

  return currentDate <= directorEndDate;
};
