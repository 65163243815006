import { FC } from 'react';

import EditLetterStatusModalHeader from './components/EditLetterStatusModalHeader';
import EditLetterStatusModalBody from './components/EditLetterStatusModalBody';
import EditLetterStatusModalFooter from './components/EditLetterStatusModalFooter';

import CustomLoading from '../../CustomLoading';

import { useEditLetterStatusModal } from './context/EditLetterStatusModalContext';

import '../Modals.scss';

const EditLetterStatusModal: FC = () => {
  const { data } = useEditLetterStatusModal();

  return (
    <>
      <EditLetterStatusModalHeader />
      <EditLetterStatusModalBody />
      <EditLetterStatusModalFooter />

      {data.isLoading && <CustomLoading />}
    </>
  );
};

export default EditLetterStatusModal;
