import { FC } from 'react';

import { useNotifyPartnersDividendsModal } from '../context/NotifyPartnersDividendsModalContext';

import { PartnerData } from '../types/PartnerData';
import PartnersTableViewRow from './PartnersTableViewRow';

const PartnersTableViewBody: FC = () => {
  const { data } = useNotifyPartnersDividendsModal();

  return (
    <table className="table mh-200px overflow-y-auto">
      {data.expandView && (
        <tbody>
          {data.partnersData?.map((partner: PartnerData, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`partner-${index}`} className="tb-tnx-item">
              <PartnersTableViewRow index={index} partner={partner} />
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default PartnersTableViewBody;
