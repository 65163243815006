/* eslint-disable react/react-in-jsx-scope */
import { dateTimeFormat, timeFormat } from 'constants/formats';
import { format, isToday, parseISO } from 'date-fns';
import { format as formatUTC, utcToZonedTime } from 'date-fns-tz';
import i18n from 'i18n/config';
import { store } from 'redux/store';

import {
  getRecipientsInfo,
  updateCommEmail,
} from 'modules/communications/redux/communicationActions';

export const recipientText = {
  PARTNERS: 'Partners',
  BENEFICIARIES: 'Beneficiaries',
  INVESTORS: 'Investors',
  DIRECTORS: 'AdminOrgan.',
  ALL: 'All',
};

export const getRecipientsText = (recipients, presetGroup) => {
  if (presetGroup !== 'CUSTOM') {
    return i18n.t(recipientText[presetGroup]);
  }
  if (recipients?.length === 1) {
    return recipients[0]?.email;
  }
  if (recipients?.length > 1) {
    return 'Varios';
  }

  return recipients
    ?.map((recipient) => recipient?.name || recipient?.email)
    .join(', ');
};

export const getDateTime = (date) => {
  if (!date) return '';

  const currentDate = new Date(date);
  const dateTime = isToday(currentDate)
    ? format(currentDate, timeFormat)
    : format(currentDate, dateTimeFormat);

  return dateTime;
};

export const getUTCDateTime = (date) => {
  const parsedTime = parseISO(date);

  if (Number.isNaN(parsedTime)) return '';

  const formatInTimeZone = (date, fmt, tz) =>
    formatUTC(utcToZonedTime(date, tz), fmt, { timeZone: tz });

  return formatInTimeZone(parsedTime, 'yyyy-MM-dd kk:mm:ss xxx', 'UTC');
};

export const getMailStatus = (recipient, t) => {
  const deliveredHtml = (
    <div className="d-flex align-items-center">
      <em className="text-success icon ni ni-shield-check-fill fs-20px mr-1" />
      <span>{t('DeliveredCommStatus')}</span>
    </div>
  );
  const sentHtml = (
    <div className="d-flex align-items-center">
      <em className="text-gray icon ni ni-shield-alert-fill fs-20px mr-1" />
      <span>{t('SentCommStatus')}</span>
    </div>
  );
  const bouncedHtml = (
    <div className="d-flex align-items-center">
      <em
        className="text-warning icon ni ni-shield-alert-fill fs-20px mr-1"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={recipient?.queued_reason}
        style={{ cursor: 'help' }}
      />
      <span>{t('BouncedCommStatus')}</span>
    </div>
  );
  const rejectedHtml = (
    <div className="d-flex align-items-center">
      <em
        className="text-danger icon ni ni-shield-alert-fill fs-20px mr-1"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title={recipient?.reject_reason}
        style={{ cursor: 'help' }}
      />
      <span>{t('RejectedCommStatus')}</span>
    </div>
  );

  switch (recipient?.status) {
    case 'delivered':
      return deliveredHtml;
    case 'queued':
    case 'sent':
      return sentHtml;
    case 'bounced':
      return bouncedHtml;
    case 'rejected':
      return rejectedHtml;
    default:
      return '-';
  }
};

export const getMailDeliveredDate = (recipient) => {
  const date = recipient?.smtp_event?.ts ?? recipient?.opens_detail?.[0]?.ts;
  if (!date) return '-';

  const dateObj = new Date(date * 1000);

  return dateObj.toUTCString();
};

export const getDeliveredIp = (recipient) => {
  let deliveryIp = recipient?.smtp_event?.destination_ip;

  const hasOpenEvent = !!recipient?.opens_detail?.length;

  if (hasOpenEvent) {
    const openEvents = recipient?.opens_detail;
    const latestEvent = openEvents[0];

    deliveryIp = latestEvent?.ip;
  }

  return deliveryIp;
};

const haveNoFinalStatus = (recipients) =>
  recipients?.some(
    (recipient) =>
      recipient?.status === 'sent' || recipient?.status === 'queued'
  );

export const updateRecipients = async (communication) => {
  let recipients = communication?.recipients;
  let changed = false;

  // Return current recipients if all have a final status
  if (!haveNoFinalStatus(recipients)) {
    return recipients;
  }

  // Get the info of recipients mails if some recipient has not a final status
  const recipientsInfo = await getRecipientsInfo(
    communication['_id'],
    'recipientsInfo'
  );

  const newRecipients = [];
  // Update the recipients infos
  recipientsInfo?.forEach((newRecipient) => {
    recipients?.forEach((recipient) => {
      if (recipient?.messageId === newRecipient?._id) {
        if (recipient?.status === 'sent' || recipient?.status === 'queued') {
          switch (newRecipient?.state) {
            case 'sent':
              if (
                newRecipient?.smtp_events[0]?.ts ||
                newRecipient?.opens_detail?.length > 0
              ) {
                newRecipients.push({
                  ...recipient,
                  status: 'delivered',
                  smtp_event: newRecipient?.smtp_events[0],
                  opens_detail: newRecipient?.opens_detail,
                });
                changed = true;
              } else {
                newRecipients.push({
                  ...recipient,
                  status: 'sent',
                });
              }
              break;
            case 'bounced':
              changed = true;
              newRecipients.push({
                ...recipient,
                status: 'bounced',
              });
              break;
            case 'rejected':
              changed = true;
              newRecipients.push({
                ...recipient,
                status: 'rejected',
              });
              break;
            default:
              break;
          }
        } else newRecipients.push(recipient);
      }
    });
  });

  // Update the recipients list if there are any changes from server response
  if (changed) {
    await store.dispatch(
      updateCommEmail(communication['_id'], communication.society, {
        recipients: newRecipients,
      })
    );
  }
  if (newRecipients.length > 0) recipients = newRecipients;

  return recipients;
};
