/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import Page from 'modules/_shared/components/Pages/Page';
import { Link } from 'react-router-dom';
import Logo from './account.svg';

const AdminCenter = () => (
  <Page>
    <div className="nk-content-body">
      <h1>
        <img
          src={Logo}
          alt="Logo"
          style={{ height: '70px', marginRight: '20px' }}
        />
        4dmin C3nt3r
      </h1>

      <div className="nk-block-head-content d-flex justify-content-between">
        <Link className="btn btn-secondary" to="/admin-center">
          Admin Center
        </Link>
      </div>

      <hr />
    </div>
  </Page>
);

export default AdminCenter;
