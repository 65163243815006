/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { currencyFormat, numberFormat } from 'constants/formats';
import sizes from 'constants/sizes';

import isSuperAdmin from 'utils/isSuperAdmin';
import { societyHasTitles } from 'utils/societyHasTitles';

import UpdateShares from 'modules/_shared/components/Modals/UpdateShares';
import DocumentsColumn from 'modules/_shared/components/DocumentsColumn';

import './PartnerSharesRow.scss';
import { useGetShareTitle } from './hooks/useGetShareTitle';

const PartnerSharesRow = ({ share, index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const user = useSelector((state) => state?.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state) => state?.society?.actualSociety || {}
  );

  const hasTilesEnabled = societyHasTitles(actualSociety);

  const { sharesTitle, document } = useGetShareTitle({ share }).data;

  const [sharesCount] = useState(share?.to - share?.from + 1);

  const handleClickRow = () => {
    if (isAdmin || isSuperAdmin(user)) {
      dispatch(
        setModal(
          <UpdateShares share={share} society={actualSociety} size={sizes.XL} />
        )
      );
    }
  };

  return (
    <tr className={`${isAdmin || isSuperAdmin(user) ? 'row__editable' : ''}`}>
      <td className="d-none d-md-table-cell w-20px vertical-align-middle">
        <b>{index}</b>
      </td>
      <td className="text-right vertical-align-middle" onClick={handleClickRow}>
        {sharesCount ? (
          <NumberFormat
            value={sharesCount}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      <td
        className="text-right d-none d-md-table-cell vertical-align-middle"
        onClick={handleClickRow}
      >
        <NumberFormat
          value={share?.nominalValue || '-'}
          displayType="text"
          {...currencyFormat}
          decimals={2}
        />
      </td>
      <td className="text-left vertical-align-middle">
        {share?.shareClass || ''}
      </td>

      <td
        className="text-right d-table-cell d-md-none vertical-align-middle"
        onClick={handleClickRow}
      >
        {share?.from ? (
          <NumberFormat
            value={share.from}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
        <br />
        {share?.to ? (
          <NumberFormat
            value={share?.to}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>

      <td
        className="text-right d-none d-md-table-cell vertical-align-middle"
        onClick={handleClickRow}
      >
        {share?.from ? (
          <NumberFormat
            value={share.from}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      <td
        className="text-right d-none d-md-table-cell vertical-align-middle"
        onClick={handleClickRow}
      >
        {share?.to ? (
          <NumberFormat
            value={share?.to}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      {isAdmin && (
        <td
          className="text-right d-none d-md-table-cell vertical-align-middle"
          onClick={handleClickRow}
        >
          {share?.encumbrances?.length > 0 ? (
            <>
              <em
                className="icon ni ni-info text-gray"
                id={`encumbrance-info-${share['_id']}`}
              />

              <Tooltip
                anchorId={`encumbrance-info-${share['_id']}`}
                place="top"
                style={{ zIndex: 9999 }}
              >
                <div className="fw-normal">
                  {t('EncumbrancesInfo')}
                  <br />

                  <ul className="list text-left">
                    {share.encumbrances?.map((encumbrance) => (
                      <li key={encumbrance.from}>{encumbrance.name}</li>
                    ))}
                  </ul>
                </div>
              </Tooltip>
            </>
          ) : (
            '-'
          )}
        </td>
      )}

      {hasTilesEnabled && (
        <td className="d-none d-md-table-cell vertical-align-middle text-center">
          {sharesTitle ? (
            <DocumentsColumn
              index={index}
              documentsCount={1}
              documents={document ? [document] : []}
              hideOnMobile={false}
              tagsObject={{ society: actualSociety, title: sharesTitle }}
            />
          ) : (
            <span>-</span>
          )}
        </td>
      )}
    </tr>
  );
};
export default PartnerSharesRow;
