import { FC } from 'react';
import CellData from 'modules/_shared/components/Tables/CellData';
import { PartnerRow } from '../types/PartnerRow';

type Props = {
  societyId: string;
  partner: PartnerRow;
};

const PartnerRowLine: FC<Props> = ({ societyId, partner }) =>
  CellData.CoOwnerPartner({
    name: partner?.name,
    url: partner?.id ? `/detalle-socio/${societyId}/${partner?.id}` : null,
  });

export default PartnerRowLine;
