import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { SocietyDirector } from 'types';

import NoItemsAvailable from 'modules/_shared/components/EmptyStates/NoItemsAvailable';
import AdminMemberTableRow from './AdminMembersTableRow';

type Props = {
  societyId: string;
  adminMembers: SocietyDirector[];
  isReadOnly?: boolean;
};

const AdminMembersTableBody: FC<Props> = ({
  societyId,
  adminMembers,
  isReadOnly,
}) => {
  const { t } = useTranslate();
  const hasData = adminMembers?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          adminMembers?.map((member: SocietyDirector, index: number) => (
            <tr className="nk-tb-item" key={`member-row-${member?._id}`}>
              <AdminMemberTableRow
                index={index}
                societyId={societyId}
                member={member}
                isReadOnly={isReadOnly}
              />
            </tr>
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoMembersAvailable')} />
        )}
      </tbody>
    </>
  );
};

export default AdminMembersTableBody;
