import { EmailIssue, Partner } from 'types';
import isValidEmail from 'utils/isValidEmail';

const getInvalidEmails = (partners: Partial<Partner>[]): EmailIssue[] => {
  const result: EmailIssue[] = [];

  partners.forEach((partner) => {
    const name = partner?.name || '';
    const email = partner?.email || '';

    const validEmial = isValidEmail(email);
    if (!validEmial) {
      result.push({ name, email });
    }
  });
  return result;
};

export default getInvalidEmails;
