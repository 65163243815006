import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Contribution, Operation, Partner, Society } from 'types';
import { getActualPartner } from 'utils/filters';
import { currencyFormatDecimals } from 'constants/formats';
import { operationLabels } from '../constants/operationLabels';

const numericCell = (value: number) => (
  <NumberFormat
    displayType="text"
    value={value}
    thousandSeparator={currencyFormatDecimals?.thousandSeparator}
    decimalSeparator={currencyFormatDecimals?.decimalSeparator}
    decimalScale={3}
    fixedDecimalScale={currencyFormatDecimals?.fixedDecimalScale}
  />
);

const getPartnerLink = (
  societyId: string,
  partnerId: string,
  partnerName: string
) => (
  <Link
    className="text-primary"
    to={`/detalle-socio/${societyId}/${partnerId}`}
  >
    {partnerName}
  </Link>
);

const getOperationName = (operation: Operation) =>
  operationLabels[operation?.alias || operation.operationType];

const getTableRow = (
  contribution: Contribution,
  societyId: string,
  partner: Partner,
  operation: Operation
) => {
  const partnerAmount = contribution?.amount || 0;
  const partnerPercent = contribution?.percent || 0;

  return (
    <tr key={contribution['_id']}>
      <td className="text-left" key={`partner-link-${contribution['_id']}`}>
        {getPartnerLink(societyId, partner['_id'], partner.name)}
      </td>
      <td className="text-left" key={`operation-name-${contribution['_id']}`}>
        {getOperationName(operation)}
      </td>
      <td className="text-right" key={`partner-amount-${contribution['_id']}`}>
        {numericCell(partnerAmount)}€
      </td>
      <td className="text-right" key={`partner-percent-${contribution['_id']}`}>
        {numericCell(partnerPercent)}%
      </td>
    </tr>
  );
};

export const getContributionOperationRows = (
  operation: Operation,
  actualSociety: Society
) => {
  const rows = operation?.contributions.map((contribution: Contribution) => {
    const partner: Partner = getActualPartner(
      actualSociety,
      contribution?.partner
    );

    const societyId = actualSociety?.['_id'] || '';

    return getTableRow(contribution, societyId, partner, operation);
  });

  return rows;
};
