import { store } from 'redux/store';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { Council, Document } from 'types';
import { useState } from 'react';

import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateCouncil } from 'modules/administratives/redux/councilActions';
import councilTemplateTypes from 'modules/administratives/pages/CouncilDetails/constants/councilTemplateTypes';
import { addDocument } from 'modules/documents/redux/documentActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import ReplaceTemplateWithDocumentModalHeader from './components/ReplaceTemplateWithDocumentModalHeader';
import ReplaceTemplateWithDocumentModalFooter from './components/ReplaceTemplateWithDocumentModalFooter';
import ReplaceTemplateWithDocumentModalBody from './components/ReplaceTemplateWithDocumentModalBody';

type Props = {
  councilId: string;
  templateType: string;
};

const ReplaceTemplateWithDocumentModal = ({
  councilId,
  templateType,
}: Props) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state?.user);
  const council = useSelector((state: State) =>
    state?.councils.find((council: Council) => council._id === councilId)
  );

  const [selectedDocument, setSelectedDocument] = useState<string>();
  const [documentFile, setDocumentFile] = useState<File | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangeDocumentFile = (file: File) => {
    setDocumentFile(file);
    setSelectedDocument(undefined);
  };

  const handleChangeSelectedDocument = (selectedOption: any) => {
    setSelectedDocument(selectedOption.value);
    setDocumentFile(undefined);
  };

  const createDocumentData = () => ({
    file: documentFile,
    date: new Date(),
    size: documentFile?.size,
    name: documentFile?.name,
    author: user?._id,
    society: council?.society,
    fileType: fileTypes[documentFile?.type as keyof typeof fileTypes],
    category: documentTypes.DOCUMENTS,
    description: t('DocumentLinkedManually'),
  });

  const updateCouncilDocument = async (uploadedDocument: Document | string) => {
    const documentValue =
      typeof uploadedDocument === 'string'
        ? uploadedDocument
        : uploadedDocument._id;

    const councilData = {
      _id: council?._id,
      [templateType === councilTemplateTypes.ACT
        ? 'actDocument'
        : 'actCertificateDocument']: documentValue,
    };

    await store.dispatch(updateCouncil(councilData));
  };

  const handleChangeTemplate = async () => {
    try {
      setIsLoading(true);

      if (documentFile) {
        const documentData = createDocumentData();
        const uploadedDocument = await store.dispatch(
          addDocument(documentData)
        );
        await updateCouncilDocument(uploadedDocument);
      } else if (selectedDocument) {
        await updateCouncilDocument(selectedDocument);
      }

      successSwal(t('DocumentReplacedSuccesfully'));
    } catch (error) {
      errorSwal(t('ErrorReplacingDocument'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <ReplaceTemplateWithDocumentModalHeader
        templateType={templateType}
        handleCloseModal={handleCloseModal}
      />

      <ReplaceTemplateWithDocumentModalBody
        council={council as Council}
        documentFile={documentFile}
        handleChangeSelectedDocument={handleChangeSelectedDocument}
        handleChangeDocumentFile={handleChangeDocumentFile}
      />

      <ReplaceTemplateWithDocumentModalFooter
        selectedTemplate={selectedDocument}
        documentFile={documentFile}
        handleChangeTemplate={handleChangeTemplate}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default ReplaceTemplateWithDocumentModal;
