import { axiosV1 } from 'interceptors/axios';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';
import { decryptResponse } from 'utils/token';
import { addConfigurationToEmailData } from 'utils/emails';

import { dispatchError } from 'utils/dispatchError';
import { addAlert } from '../../_shared/redux/alertActions';
import { setModal } from '../../_shared/redux/modalActions';

export function sendCommEmail(newEmailData, showAlert = true) {
  const { files, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch, getState) => {
    try {
      const state = getState();
      const emailDataWithConfiguration = addConfigurationToEmailData(
        state.society.actualSociety,
        emailData
      );

      if (files) {
        [...files].forEach((file) => {
          formData.append('files', file);
        });
      }
      formData.append('data', JSON.stringify(emailDataWithConfiguration));

      const { data } = await axiosV1.post(`/comms`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_STOCK_COMMUNICATION,
        communication: decodedData,
      });
      dispatch(setModal(null));
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes['COMMUNICATIONS_EMAIL_SENT']));
      }
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_COMMUNICATIONS_EMAIL']));
    }
  };
}

export function getEmails() {
  return async (dispatch) => {
    try {
      const { data } = await axiosV1.get(`/contact`);

      const decodedData = await decryptResponse(data);
      dispatch({ type: 'GET_EMAILS', payload: decodedData });
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_GET_EMAILS']));
    }
  };
}

/**
 * Deprecated, use getCommunications instead
 * This version updates the store with the communications
 */
export const getCommEmails = (societyId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/comms`, {
      params: { societyId },
    });

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_STOCK_COMMUNICATIONS,
      communications: decodedData,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error getting communications', error);
  }
};

/**
 * This version update the store and return the data with the communications
 */
export const getCommunications = (societyId, params) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/comms`, {
      params: { societyId, ...params },
    });

    const decodedData = await decryptResponse(data);
    const communications = decodedData.data;

    dispatch({
      type: types.GET_STOCK_COMMUNICATIONS,
      communications,
    });

    return decodedData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error getting communications', error);
  }
};

export const getCommunication = (communicationId) => async (dispatch) => {
  try {
    const { data } = await axiosV1.get(`/comms/${communicationId}`);

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const getRecipientsInfo = async (communicationId, queryType) => {
  const query = { params: { queryType } };

  try {
    const { data } = await axiosV1.get(`/comms/${communicationId}`, query);

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const updateCommEmail =
  (id, societyId, emailData) => async (dispatch) => {
    const body = {
      id,
      societyId,
      ...emailData,
    };

    try {
      const { data } = await axiosV1.put(`/comms`, body);

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_STOCK_COMMUNICATION,
        communication: decodedData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error updating communication', error);
    }
  };

export const deleteCommEmail = (id) => async (dispatch) => {
  try {
    const { data } = await axiosV1.delete(`/comms/${id}`);

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_STOCK_COMMUNICATION,
      communication: decodedData,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error deleting communication', error);
  }
};
