/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { useState, useCallback, useEffect, useMemo } from 'react';
import { TranslateFn, useTranslate } from 'hooks/useTranslate';
import { axiosV2 } from 'interceptors/axios';
import { Society } from 'types';
import { decryptResponse } from 'utils/token';

export type SocietyEntities =
  | 'basic'
  | 'shares'
  | 'operations'
  | 'partners'
  | 'beneficiaries'
  | 'investors'
  | 'drafts'
  | 'stockPlans'
  | 'communications';

export const societyEntities: Record<string, SocietyEntities> = {
  BASIC: 'basic',
  SHARES: 'shares',
  OPERATIONS: 'operations',
  PARTNERS: 'partners',
  BENEFICIARIES: 'beneficiaries',
  INVESTORS: 'investors',
  DRAFTS: 'drafts',
  STOCK_PLANS: 'stockPlans',
  COMMUNICATIONS: 'communications',
};

const fullListEntities: SocietyEntities[] = Object.values(societyEntities);

const entitiesLabels = {
  [societyEntities.BASIC]: 'LoadingBasicInfo',
  [societyEntities.SHARES]: 'LoadingShares',
  [societyEntities.OPERATIONS]: 'LoadingOperations',
  [societyEntities.PARTNERS]: 'LoadingPartners',
  [societyEntities.BENEFICIARIES]: 'LoadingBeneficiaries',
  [societyEntities.INVESTORS]: 'LoadingInvestors',
  [societyEntities.DRAFTS]: 'LoadingDrafts',
  [societyEntities.STOCK_PLANS]: 'LoadingPlans',
  [societyEntities.COMMUNICATIONS]: 'LoadingCommunications',
};

type LoadingStepsProps = {
  societyId: string;
  entities: SocietyEntities[];
  t: TranslateFn;
};

const getLoadingSteps = ({ societyId, entities, t }: LoadingStepsProps) =>
  entities.map((entity) => ({
    name: t(entitiesLabels[entity]),
    percent: Math.floor(100 / entities.length),
    endpoint: `/societies/${societyId}?progressive=true&partial=${entity}`,
  }));

export const useGetActualSocietyProgressively = (
  societyId: string,
  entities: SocietyEntities[] = fullListEntities
) => {
  const { t } = useTranslate();

  const [progress, setProgress] = useState<number>(0);
  const [currentStep, setCurrentStep] = useState<string>('');
  const [data, setData] = useState<Partial<Society>>({});
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadSteps = useMemo(
    () => getLoadingSteps({ societyId, entities, t }),
    [societyId, entities, t]
  );

  const loadSociety = useCallback(async () => {
    if (!societyId) {
      return;
    }

    let accumulatedData = {};
    let accumulatedProgress = 0;

    try {
      for (const step of loadSteps) {
        setCurrentStep(step.name);

        if (step.endpoint) {
          const query = { params: { societyId } };
          const response = await axiosV2.get(step.endpoint, query);
          const decodedData = await decryptResponse(response.data);

          accumulatedData = {
            ...accumulatedData,
            ...decodedData,
          };
          setData(accumulatedData);
        }

        accumulatedProgress += step.percent;
        setProgress(accumulatedProgress);
      }

      setProgress(100);
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError(String(err));
      }
    } finally {
      setIsLoading(false);
    }
  }, [societyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadSociety();
  }, [loadSociety]);

  return { progress, currentStep, data, error, isLoading };
};
