import { format } from 'date-fns';

import { SharesTitle } from 'types';
import { dateFormat } from 'constants/formats';

import { getPartnersDataFromTitle } from 'modules/partners/pages/TitleRegistryBook/utils/getPartnersDataFromTitle';
import { formatCurrency } from './formats';

export const getTitlePartnerNameTag = (title: SharesTitle) => {
  const partners = getPartnersDataFromTitle(title);

  return partners[0]?.name || '';
};

export const getTitlePartnerCifTag = (title: SharesTitle) => {
  const partners = getPartnersDataFromTitle(title);

  return partners[0]?.cif || '';
};

export const getTitlePartnerCapitalTag = (title: SharesTitle) =>
  title?.sharesCapital ? formatCurrency(title.sharesCapital) : '-';

export const getTitlePartnerDateTag = (title: SharesTitle) =>
  title?.createdAt
    ? format(new Date(title?.createdAt), dateFormat)
    : '[TITULO_FECHA]';

export const getTitlePartnerNumerationTag = (title: SharesTitle) =>
  title?.intervals
    ?.map((interval) => `${interval?.shareFrom} / ${interval?.shareTo}`)
    .join(', ') || '';
