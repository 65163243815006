import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getDocument } from 'modules/documents/redux/documentActions';
import { Document } from 'types';
import documentTypes from 'constants/documentTypes';
import { getDocumentFrame } from 'utils/getDocumentFrame';

async function getDocumentById(documentId: string) {
  const document = await store.dispatch(getDocument(documentId));

  return document;
}

type Props = {
  documentId: string | undefined;
  templateId: string | undefined;
};

export default function useGetDocumentData({ documentId, templateId }: Props) {
  const [htmlContent, setHtmlContent] = useState<string>();
  const [templateName, setTemplateName] = useState<string>('');
  const [document, setDocument] = useState<Document>();
  const [documentFrame, setDocumentFrame] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const handleIsLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const fetchDocument = async (documentId: string) => {
    const document = await getDocumentById(documentId);

    const htmlContent = document?.editorModel || '';
    if (document?.category === documentTypes.DOCUMENTS) {
      const documentFrame = await getDocumentFrame(document?._id);
      setDocumentFrame(documentFrame);
    } else {
      setDocumentFrame(null);
    }

    setHtmlContent(htmlContent);
    setDocument(document);
  };

  const fetchTemplateDocument = async (templateId: string) => {
    const templateDocument = await getDocumentById(templateId);
    const templateName = templateDocument?.name || '';

    setTemplateName(templateName);
  };

  const fetchDocumentData = useCallback(async () => {
    if (documentId && templateId) {
      try {
        setIsLoading(true);
        await fetchDocument(documentId);
        await fetchTemplateDocument(templateId);
        setIsLoading(false);
      } catch {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
  }, [documentId, templateId]);

  useEffect(() => {
    fetchDocumentData();
  }, [fetchDocumentData]);

  return {
    data: { htmlContent, templateName, document, documentFrame },
    isLoading,
    isError,
    actions: { handleIsLoading },
  };
}
